import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import { DashboardCalendarEventTooltip } from "./event-tooltip";

function DashboardMeetingCalendar({ onStartAndEndDateChange, events }: any) {
  return (
    <>
      <FullCalendar
        height={650}
        headerToolbar={{
          left: "prev,next",
          right: "today",
          center: "title",
        }}
        buttonText={{
          today: "Today",
        }}
        datesSet={(arg) => {
          onStartAndEndDateChange(arg);
        }}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventContent={(e: any) => {
          return (
            <DashboardCalendarEventTooltip
              title={e.event._def.title}
              event={e.event._def.extendedProps}
            />
          );
        }}
      />
    </>
  );
}

export { DashboardMeetingCalendar };
