import { adminLayoutHOC } from "../HOC/layouts";
import { Route, withRouter, Switch, Redirect } from "react-router";
import { compose } from "redux";
import { PageNotFound } from "../components/errors";
import { ROUTE_PATHS } from "./route-paths";
import { ROOT_PATH } from "../constants";
import Marketplace from "../screens/marketplace";
import MarketplaceForm from "../screens/marketplace/form";

export const marketplaceRoutePaths = {
  MARKETPLACE: {
    ROOT: `${ROOT_PATH}/marketplace`,
    LIST: `${ROOT_PATH}/marketplace/list`,
    ADD: `${ROOT_PATH}/marketplace/new`,
    EDIT: `${ROOT_PATH}/marketplace/edit/:id`,
  },
};

function MarketPlaceRoutes() {
  return (
    <Switch>
      <Route
        exact
        path={ROUTE_PATHS.MARKETPLACE.LIST}
        component={compose(withRouter, adminLayoutHOC)(Marketplace)}
      />
      <Route
        exact
        path={ROUTE_PATHS.MARKETPLACE.ADD}
        component={compose(withRouter, adminLayoutHOC)(MarketplaceForm)}
      />
      <Route
        exact
        path={ROUTE_PATHS.MARKETPLACE.EDIT}
        component={compose(withRouter, adminLayoutHOC)(MarketplaceForm)}
      />
      <Route
        path={ROUTE_PATHS.NOT_FOUND}
        exact={true}
        component={PageNotFound}
      />
      <Redirect to={ROUTE_PATHS.NOT_FOUND} />
    </Switch>
  );
}

export { MarketPlaceRoutes };
