import { useEffect } from "react";
import { useSelector } from "react-redux";
import { CLIENT_INVITATION_STATUS_LIST_COLUMNS } from "./table-config";
import { Box, Grid } from "@mui/material";
import clientHOC from "../../client-hoc";
import { ClientInvitationStatusListTable } from "./table";
import { AppDatePicker } from "../../../../components/datepickers";
import { useStyles } from "./styles";

function ClientInvitationStatus({
  getClientInvitationStatusList,
  clientInvitationStatusList,
}: any) {
  const classes = useStyles();
  const { selectedPage, selectedPageSize, selectedDate } = useSelector(
    (state: any) => state.clients.info.invitationStatus.filters
  );

  useEffect(() => {
    getClientInvitationStatusList({
      selectedPage,
      selectedPageSize,
      selectedDate,
    });
  }, []);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getClientInvitationStatusList({
      selectedPage,
      selectedPageSize,
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container spacing={2} mt={1} direction="column">
        <Grid item container justifyContent={"flex-end"}>
          <AppDatePicker
            dateFormat={"MM-dd-yyyy"}
            datepickerClass={classes.datepickerClass}
            selectedDate={selectedDate}
            setDate={(selectedDate) =>
              getClientInvitationStatusList({
                selectedPage: 1,
                selectedPageSize,
                selectedDate: selectedDate || "",
              })
            }
            placeholder="Date"
            isClearable={true}
          />
        </Grid>
        <Grid item>
          <ClientInvitationStatusListTable
            list={clientInvitationStatusList}
            columns={CLIENT_INVITATION_STATUS_LIST_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              selectedPage,
            }}
          ></ClientInvitationStatusListTable>
        </Grid>
      </Grid>
    </Box>
  );
}

export default clientHOC(ClientInvitationStatus);
