import { Grid } from "@mui/material";
import { ButtonWithIcon } from "../../../../components/buttons";
import { AMBASSADOR_DETAILS_LABEL_VALUES } from "./ambassador-details-data";
import ambassadorHOC from "../../ambassador-hoc";
import { AmbassadorInfoDetails } from "./details";

function AmbassadorsInfo({
  onEditClick,
  onDeleteClick,
  ambassadorDetails,
}: any) {
  return (
    <Grid container sx={{ mt: 2 }}>
      <Grid item xs={12} container justifyContent="flex-end">
        <ButtonWithIcon
          handleOnClick={() => onEditClick(ambassadorDetails.id)}
          text={"EDIT"}
          icon={{ position: "startIcon", name: "edit_square" }}
          sx={{ mr: 1 }}
        />
        <ButtonWithIcon
          handleOnClick={() => onDeleteClick(ambassadorDetails.id)}
          text={"DELETE"}
          icon={{ position: "startIcon", name: "delete" }}
          background="#d90706"
        />
      </Grid>
      <Grid item xs={12}>
        <AmbassadorInfoDetails
          data={ambassadorDetails}
          fieldList={AMBASSADOR_DETAILS_LABEL_VALUES}
        />
      </Grid>
    </Grid>
  );
}

export default ambassadorHOC(AmbassadorsInfo);
