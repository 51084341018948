import { AppModalDialogBox } from "../../../../components/modals";
import { Box, Button, Typography } from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";

export default function AmbassadorAnswersModal({
  onClose,
  questions,
  clientDetails,
}) {
  return (
    <AppModalDialogBox
      isModalOpen={true}
      title="Answers"
      handleClose={onClose}
      size={"md"}
      actionButtonRenderer={<Button onClick={onClose}>Close</Button>}
    >
      <Box mb={2}>
        <Typography
          color="#6B778C"
          component={Box}
          display={"flex"}
          alignItems={"center"}
          gap={1}
          fontSize={13}
        >
          Company/product offering/value prop:
        </Typography>
        <Typography variant="caption" component={"p"} fontSize={12}>
          {clientDetails?.other_info}
        </Typography>
      </Box>
      {questions
        ? Object.keys(questions)?.map((que, index) => (
            <Box mb={2} key={`questions-${index}`}>
              <Typography
                color="#6B778C"
                component={Box}
                display={"flex"}
                alignItems={"center"}
                gap={1}
                fontSize={13}
              >
                <QuizIcon fontSize="small" />
                {questions?.[que]?.q?.question}
              </Typography>

              <Typography
                variant="caption"
                component={Box}
                display={"flex"}
                alignItems={"center"}
                gap={1}
                fontSize={13}
              >
                <Typography mr={0.5} fontSize={12}>
                  Answer: {questions?.[que]?.a || "N/A"}
                </Typography>
              </Typography>
            </Box>
          ))
        : "N/A"}
    </AppModalDialogBox>
  );
}
