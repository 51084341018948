import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  datepickerClass: {
    width: "238px !important",
    height: "56px !important",
    fontFamily: "Roboto !important",
    fontSize: "16px !important",
  },
  completed: {
    backgroundColor: "red"
  }
}));
