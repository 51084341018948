import {
  SET_OPPORTUNITY_LIST,
  SET_OPPORTUNITY_LIST_FILTERS,
  initialOpportunityListState,
  SET_OPPORTUNITY_LIST_LOADING,
  RESET_OPPORTUNITY_LIST_FILTERS,
  RESET_OPPORTUNITY_STORE,
  initialOpportunityResponseListState,
  SET_OPPORTUNITY_RESPONSE_LIST_LOADING,
  RESET_OPPORTUNITY_RESPONSE_LIST_FILTERS,
  SET_OPPORTUNITY_RESPONSE_LIST_FILTERS,
  SET_OPPORTUNITY_RESPONSE_LIST,
} from "./actions";

const initialOpportunity = {
  list: { ...initialOpportunityListState },
  responseList: { ...initialOpportunityResponseListState },
};

const opportunity = (state = initialOpportunity, action) => {
  switch (action.type) {
    case SET_OPPORTUNITY_LIST_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: action.payload,
        },
      };
    case SET_OPPORTUNITY_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          data: action.payload.data,
          total: action.payload.total,
        },
      };
    case SET_OPPORTUNITY_LIST_FILTERS:
      return {
        ...state,
        list: {
          ...state.list,
          filters: {
            ...state.list.filters,
            ...action.payload,
          },
        },
      };
    case RESET_OPPORTUNITY_LIST_FILTERS:
      return {
        ...state,
        list: { ...initialOpportunityListState },
      };
    case SET_OPPORTUNITY_RESPONSE_LIST_LOADING:
      return {
        ...state,
        responseList: {
          ...state.responseList,
          loading: action.payload,
        },
      };
    case SET_OPPORTUNITY_RESPONSE_LIST:
      return {
        ...state,
        responseList: {
          ...state.responseList,
          ...action.payload,
          data: action.payload.data,
          total: action.payload.total,
        },
      };
    case SET_OPPORTUNITY_RESPONSE_LIST_FILTERS:
      return {
        ...state,
        responseList: {
          ...state.responseList,
          filters: {
            ...state.responseList.filters,
            ...action.payload,
          },
        },
      };
    case RESET_OPPORTUNITY_RESPONSE_LIST_FILTERS:
      return {
        ...state,
        responseList: { ...initialOpportunityResponseListState },
      };
    case RESET_OPPORTUNITY_STORE:
      return { ...initialOpportunity };
    default:
      return state;
  }
};

export default opportunity;
