import {
  DEFAULT_SELECTED_PAGE,
  DEFAULT_SELECTED_PAGE_SIZE,
} from "../../../constants";

const SET_AMBASSADORS_LIST = "SET_AMBASSADORS_LIST";
const SET_AMBASSADOR_LIST_FILTERS = "SET_AMBASSADOR_LIST_FILTERS";
const SET_AMBASSADOR_LIST_LOADING = "SET_AMBASSADOR_LIST_LOADING";

const initialAmbassadorListState = {
  data: undefined,
  filters: {
    selectedPage: DEFAULT_SELECTED_PAGE,
    selectedPageSize: DEFAULT_SELECTED_PAGE_SIZE,
    searchValue: "",
  },
  total: undefined,
};

const setAmbassadorListLoading = (payload) => ({
  type: SET_AMBASSADOR_LIST_LOADING,
  payload,
});

const setAmbassadorsList = (payload) => ({
  type: SET_AMBASSADORS_LIST,
  payload,
});

const setAmbassadorListFilters = (payload = undefined) => ({
  type: SET_AMBASSADOR_LIST_FILTERS,
  payload,
});

export {
  initialAmbassadorListState,
  setAmbassadorListLoading,
  setAmbassadorsList,
  setAmbassadorListFilters,
  SET_AMBASSADORS_LIST,
  SET_AMBASSADOR_LIST_FILTERS,
  SET_AMBASSADOR_LIST_LOADING,
};
