import { useRef, useEffect } from "react";

function useEffectAfterMount(callback, dependencies) {
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      // Skip effect on mount
      isMounted.current = true;
      return;
    }

    // Run the callback when dependencies change, skipping the initial mount
    return callback();
  }, dependencies); // dependencies array passed to useEffect
}

export default useEffectAfterMount;
