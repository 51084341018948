import { SquareChip } from "../../../../components/chips/square-chip";
import { CONST_INVITATION_STATUS_LIST } from "../../../../constants/lists";
import { getInvitationStatus } from "../../../../services/utils";

function InvitationStatus({ data }) {
  const status = getInvitationStatus(data);

  return (
    <SquareChip
      text={CONST_INVITATION_STATUS_LIST[status]?.label}
      color={CONST_INVITATION_STATUS_LIST[status]?.fontColor}
      bgColor={CONST_INVITATION_STATUS_LIST[status]?.bgColor}
    />
  );
}

export { InvitationStatus };
