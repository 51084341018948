import { RENDERER_TYPES } from "../../../../enums";

const AMBASSADOR_DETAILS_LABEL_VALUES = [
  { field: "email", label: "Email" },
  {
    field: "linkedIn",
    label: "Linkedin",
    renderer: RENDERER_TYPES.LINKEDIN_URL_WITH_ICON,
  },
  { field: "title", label: "Title" },
  { field: "company", label: "Company" },
  {
      field: "region",
      label: "Category",
  },
  {
    field: "no_of_emp",
    label: "Est. Employees",
  },
  { field: "industry", label: "Industry" },
  { field: "city", label: "Location", renderer: RENDERER_TYPES.CITY_STATE },
  { field: "timezone", label: "Timezone", renderer: RENDERER_TYPES.TIMEZONE },
  {
    field: "experience",
    label: "Years of experience",
    renderer: RENDERER_TYPES.NUMBER,
  },
  {
    field: "investment_budget",
    label: "Investment Budget",
    renderer: RENDERER_TYPES.INVESTMENT_BUDGET,
  },
  {
    field: "previous_company",
    label: "Previous Companies",
    renderer: RENDERER_TYPES.CHIP_LIST,
  },
  {
    field: "topics",
    label: "Areas of Expertise",
    renderer: RENDERER_TYPES.CHIP_LIST,
  },
];

export { AMBASSADOR_DETAILS_LABEL_VALUES };
