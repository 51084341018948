import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
  },
  value: {
    color: "#000000",
    fontWeight: 400,
    fontSize: 14,
  },
  actionButton: {
    marginRight: 15,
  },
}));
