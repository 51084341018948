import { Grid } from "@mui/material";
import { ButtonWithIcon } from "../../../../components/buttons";
import clientHOC from "../../client-hoc";
import { CLIENT_DETAILS_LABEL_VALUES } from "./client-details-data";
import { ClientInfoDetails } from "./details";
import { useSelector } from "react-redux";

function ClientsInfo({ onEditClick, onDeleteClick, clientDetails }: any) {
  const { noOfEmp } = useSelector((state: any) => state.common);

  const modifyClientDetails = () => {
    const empListIds = noOfEmp?.data?.map(({ _id }) => _id);

    const details = {
      ...clientDetails,
      target_industries: clientDetails.target_industries.join(","),
      target_no_of_emp:
        noOfEmp?.data &&
        clientDetails.target_no_of_emp
          ?.map((item) => {
            return noOfEmp?.data?.[empListIds?.indexOf(item)]?.label;
          })
          .join(","),
    };
    return details;
  };

  return (
    <Grid container sx={{ mt: 2 }}>
      <Grid item xs={12} container justifyContent="flex-end">
        <ButtonWithIcon
          handleOnClick={() => onEditClick(clientDetails.id)}
          text={"EDIT"}
          icon={{ position: "startIcon", name: "edit_square" }}
          sx={{ mr: 1 }}
        />
        <ButtonWithIcon
          handleOnClick={() => onDeleteClick(clientDetails.id)}
          text={"DELETE"}
          icon={{ position: "startIcon", name: "delete" }}
          background="#d90706"
        />
      </Grid>
      <Grid item xs={12}>
        <ClientInfoDetails
          data={modifyClientDetails()}
          fieldList={CLIENT_DETAILS_LABEL_VALUES}
        />
      </Grid>
    </Grid>
  );
}

export default clientHOC(ClientsInfo);
