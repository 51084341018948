import { adminLayoutHOC } from "../HOC/layouts";
import { Route, withRouter, Switch, Redirect } from "react-router";
import { compose } from "redux";
import AmbassadorsList from "../screens/ambassadors/list";
import { AddOrUpdateAmbassador } from "../screens/ambassadors/add-or-update";
import AmbassadorDetails from "../screens/ambassadors/ambassador-details";
import { PageNotFound } from "../components/errors";
import { ROOT_PATH } from "../constants";
import { ROUTE_PATHS } from "./route-paths";

export const ambassadorRoutePaths = {
  AMBASSADORS: {
    ROOT: `${ROOT_PATH}/ambassadors`,
    LIST: `${ROOT_PATH}/ambassadors/ambassadors-list`,
    ADD: `${ROOT_PATH}/ambassadors/add`,
    EDIT: `${ROOT_PATH}/ambassadors/edit/:id`,
    DETAILS: `${ROOT_PATH}/ambassadors/details/:id/:tab`,
  },
};

function AmbassadorRoutes() {
  return (
    <Switch>
      <Route
        exact
        path={ROUTE_PATHS.AMBASSADORS.LIST}
        component={compose(withRouter, adminLayoutHOC)(AmbassadorsList)}
      />
      <Route
        exact
        path={ROUTE_PATHS.AMBASSADORS.ADD}
        component={compose(withRouter, adminLayoutHOC)(AddOrUpdateAmbassador)}
      />
      <Route
        exact
        path={ROUTE_PATHS.AMBASSADORS.EDIT}
        component={compose(withRouter, adminLayoutHOC)(AddOrUpdateAmbassador)}
      />
      <Route
        exact
        path={ROUTE_PATHS.AMBASSADORS.DETAILS}
        component={compose(withRouter, adminLayoutHOC)(AmbassadorDetails)}
      />
      <Route
        path={ROUTE_PATHS.NOT_FOUND}
        exact={true}
        component={PageNotFound}
      />
      <Redirect to={ROUTE_PATHS.NOT_FOUND} />
    </Switch>
  );
}

export { AmbassadorRoutes };
