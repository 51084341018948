import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INVITATION_LIST_COLUMNS } from "./table-config";
import { useStyles } from "./styles";
import { ButtonWithIcon } from "../../../components/buttons";
import { Box, Grid } from "@mui/material";
import invitationHOC from "../invitation-hoc";
import { AppDatePicker } from "../../../components/datepickers";
import { InvitationListTable } from "./table";
import { AutoSelectbox } from "../../../components/selectbox/autoselect";
import { searchClients } from "../../../apis/clients.api";
import { resetInvitationListFilters } from "../../../redux/modules/invitations/list-actions";

function InvitationList({
  onNewInvitationClick,
  getInvitationList,
  invitationList,
  onInvitationRejectClick,
  onInvitationAceeptClick,
  onInvitationDeleteClick,
  onRescheduleInvitationClick,
}: any) {
  const classes = useStyles();
  const [clientName, setClientName] = useState("");
  const [clientList, setClientList] = useState([]);
  const [clientSelectionError, setClientSelectionError] = useState("");
  const [selectedClient, setSelectedClient] = useState({
    id: "",
    value: "",
  });

  const { selectedPage, selectedPageSize, searchValue, selectedDate } =
    useSelector((state: any) => state.invitations.list.filters);
  const dispatch = useDispatch();

  useEffect(() => {
    getInvitationList({
      selectedPage,
      selectedPageSize,
      searchValue,
      selectedDate,
    });

    return () => {
      dispatch(resetInvitationListFilters());
    };
  }, []);

  const onClientSelect = (text) => {
    const clientId: any = clientList.find(
      (clientItem) => clientItem.firstName === text
    )?.id;

    clientId &&
      setSelectedClient({
        id: clientId,
        value: text,
      });

    getInvitationList({
      selectedPage,
      selectedPageSize,
      searchValue: clientId,
      selectedDate,
    });
  };

  const handleOnClientNameChange = async (e, isSelectClick) => {
    const text = e.target.value;
    setClientName(e.target.value);
    setClientSelectionError("");

    e.isSelectClick && onClientSelect(text);

    if (!text || text?.length < 3) {
      setSelectedClient({
        id: "",
        value: "",
      });

      return false;
    }

    const res = await searchClients({
      selectedPage: 1,
      selectedPageSize: 10,
      searchValue: text,
    });

    if (res.success) {
      setClientList(res.data);
    }
  };

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getInvitationList({
      selectedPage,
      selectedPageSize,
      searchValue,
      selectedDate,
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item xs={8} container spacing={2}>
          <Grid item xs={6} sm={6} md={5}>
            <AutoSelectbox
              list={clientList}
              inlineLabel
              listProperties={{
                field: "firstName",
                value: "id",
              }}
              requireOnChange
              onChange={handleOnClientNameChange}
              label={"Select Client"}
              error={clientSelectionError}
              labelStyle={{
                fontSize: 16,
                fontWeight: 400,
                color: "#000000DE",
              }}
              textfieldStyle={{ height: 22 }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <AppDatePicker
              dateFormat={"MM-dd-yyyy"}
              selectedDate={selectedDate}
              setDate={(selectedDate) =>
                getInvitationList({
                  searchValue: "",
                  selectedPage: 1,
                  selectedPageSize,
                  selectedDate: selectedDate || "",
                })
              }
              datepickerClass={classes.datepickerClass}
              placeholder="Date"
              isClearable={true}
            />
          </Grid>
        </Grid>
        <Grid>
          <ButtonWithIcon
            handleOnClick={onNewInvitationClick}
            text={"New Invitation".toUpperCase()}
            icon={{ position: "startIcon", name: "add" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <InvitationListTable
            list={invitationList}
            columns={INVITATION_LIST_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPage,
              selectedPageSize,
              searchValue,
              selectedDate,
            }}
            onInvitationAceeptClick={onInvitationAceeptClick}
            onInvitationRejectClick={onInvitationRejectClick}
            onInvitationDeleteClick={onInvitationDeleteClick}
            onRescheduleInvitationClick={onRescheduleInvitationClick}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default invitationHOC(React.memo(InvitationList));
