import { IconButton, InputAdornment } from "@mui/material";
import { AppTextField } from "../textfields";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";

function Searchbox({label,  onEnter, value, onClear, style = {} }:any) {
  return (
    <AppTextField
      id={"ambassador-search"}
      name={"search"}
      label={label ||"Search"}
      value={value}
      autoComplete={"off"}
      inlineLabel
      style={style}
      onEnter={onEnter}
      inputProps={{
        endAdornment: value && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onClear}
              edge="end"
            >
              <HighlightOffSharpIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default Searchbox;
