import { amountFormat } from "../../../services/utils";
import { MP_COMPANY_DETAILS } from "../utils";

export default function MPProfileCompanyDetails({ data }) {
  const renderOtherDetails = (od) => {
    switch (od?.key) {
      case "pricing":
        return data?.pricing?.split(",")?.map((p) => (
          <span className="tag" key={`pricing-chip-${p}`}>
            {p?.replaceAll("$", "")?.replaceAll("-", "")}
          </span>
        ));
      case "pricingStructure":
        return data?.pricingStructure?.split(",")?.map((p) => (
          <span className="tag" key={`pricing-structure-chip-${p}`}>
            {p}
          </span>
        ));
      default:
        return od?.prefix
          ? !isNaN(data[od?.key])
            ? `${amountFormat(data[od?.key])}M`
            : ""
          : data[od?.key];
    }
  };

  return (
    <ul className="vendor-other-details">
      {MP_COMPANY_DETAILS?.filter((od) => data[od?.key])?.map((od) => (
        <li
          key={`marketplace-other-details-${od.key}`}
          className={od?.className ?? ""}
        >
          <strong>{od?.label}: </strong>
          {renderOtherDetails(od)}
        </li>
      ))}
    </ul>
  );
}
