import { CLEAR_ALL } from "..";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
export const SET_SERVER_ERROR = "SET_SERVER_ERROR";
export const SET_SHOW_NOTIFICATIONS = "SET_SHOW_NOTIFICATIONS";
export const SET_UI_LOADING = "SET_UI_LOADING";
export const SET_TERMS_READ_COMPLETED = "SET_TERMS_READ_COMPLETED";
export const SET_OPEN_SIDEBAR = "SET_OPEN_SIDEBAR";
export const SET_INITIAL_LIST_FETCHED = "SET_INITIAL_LIST_FETCHED";

export const uiInitialState = {
  isServerError: {
    error: false,
    message: "",
  },
  showNotifications: {
    type: "",
    message: "",
    timeout: 500,
    show: false,
  },
  isLoading: false,
  openSidebar: isBrowser ? true : false,
  termsReadCompleted: false,
  initialListFetched: false,
};

const UI = (state = uiInitialState, action) => {
  switch (action.type) {
    case SET_SERVER_ERROR:
      return { ...state, isServerError: action.payload };
    case SET_OPEN_SIDEBAR:
      return { ...state, openSidebar: action.payload };
    case SET_SHOW_NOTIFICATIONS:
      return {
        ...state,
        showNotifications: { ...state.showNotifications, ...action.payload },
      };
    case SET_UI_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_TERMS_READ_COMPLETED:
      return { ...state, termsReadCompleted: action.payload };
    case SET_INITIAL_LIST_FETCHED:
      return { ...state, initialListFetched: action.payload };
    case CLEAR_ALL:
      return {
        ...uiInitialState,
      };
    default:
      return state;
  }
};

export default UI;
