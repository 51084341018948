import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import UI from "./UI";
import auth from "./auth";
import clients from "./clients";
import ambassadors from "./ambassadors";
import invitations from "./invitations";
import common from "./common";
import opportunity from "./opportunity";
export const CLEAR_ALL = "CLEAR_ALL";

export const resetStore = () => ({
  type: CLEAR_ALL,
});

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    UI,
    auth,
    clients,
    ambassadors,
    invitations,
    common,
    opportunity,
  });
