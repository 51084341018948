function Unauthorized() {
  return (
    <div>
      <h1>You are not authorized to view this page</h1>
      <h2>
        <p>
          You can go back to our <a href="/">Homepage</a>.
        </p>
      </h2>
    </div>
  );
}

export { Unauthorized };
