import { Box, Typography } from "@mui/material";

function SquareChip({
  text,
  bgColor,
  color,
  fontSize = 13,
  fontWeight = 600,
  sx
}: any) {
  return (
    <Box
      component={"div"}
      display="flex"
      alignItems="center"
      justifyContent={"center"}
      textAlign={"center"}
      sx={{ background: bgColor, height: 40, p:2, borderRadius:5, ...sx}}
    >
      <Typography
        fontSize={fontSize}
        color={color}
        fontWeight={fontWeight}
        variant="caption"
      >
        {text}
      </Typography>
    </Box>
  );
}

export { SquareChip };
