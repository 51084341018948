import { ROUTE_PATHS } from "../../../routes/route-paths";
import SvgIcons from "../../../svgs";

const BREADCRUMB_ICON_NAME = SvgIcons.Invitations;

const INVITATIONS_BREADCRUMB = {
  [ROUTE_PATHS.INVITATIONS.LIST]: {
    isRoot: true,
    paths: [{ label: "Invitations", icon: BREADCRUMB_ICON_NAME }],
    title: "Invitations",
  },
  [ROUTE_PATHS.INVITATIONS.NEW_INVITATION]: {
    isRoot: false,
    paths: [
      {
        label: "Invitations",
        url: ROUTE_PATHS.INVITATIONS.LIST,
        icon: BREADCRUMB_ICON_NAME,
      },
      { label: "NEW INVITATION" },
    ],

    title: "Invitations",
  },
};

export { INVITATIONS_BREADCRUMB };
