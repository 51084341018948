import { useEffect, useState } from "react";
import { AppModalDialogBox } from "../../../../components/modals";
import ViewersListData from "./list";
import { useDispatch } from "react-redux";
import { getOpportunityVendorViewersList } from "../../../../apis/opportunity";

export default function ViewersList({ opportunityVendorId, handleModalClose }) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  
  useEffect(() => {
    fetchViewersList();
  }, []);

  const fetchViewersList = async () => {
    const response: any = await dispatch(
      getOpportunityVendorViewersList(opportunityVendorId)
    );

    if (response?.success) {
      setData(response?.data);
    }
  };

  return (
    <AppModalDialogBox
      isModalOpen={true}
      title="Viewers"
      handleClose={handleModalClose}
      maxHeight={600}
      size={"xs"}
    >
      <ViewersListData data={data} />
    </AppModalDialogBox>
  );
}
