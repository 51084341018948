import {
  TextField,
  Typography,
  Grid,
  Paper,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { userLogin } from "../../../apis/auth.api";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";
import { LOGIN_FORM_ERROR_MAAPING } from "./form-data";
import { validateForm } from "../../../services/validators";
import { ROUTE_PATHS } from "../../../routes/route-paths";
import { Button } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const initialFields = {
  email: "",
  password: "",
};

const initialErrors = {
  email: "",
  password: "",
};

function Login() {
  const [formFields, setFormField] = useState(initialFields);
  const [formErrors, setFormErrors] = useState(initialErrors);
  const { isLoggedIn } = useSelector((state: any) => state.auth.user);
  const [showPassword, setShowPassword] = useState(false);

  const handleOnChange = (e) => {
    setFormField({
      ...formFields,
      [e.target.name]: e.target.value,
    });
    setFormErrors({
      ...formErrors,
      [e.target.name]: "",
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { errors, isError } = validateForm(
      formFields,
      initialErrors,
      LOGIN_FORM_ERROR_MAAPING
    );

    if (!isError) {
      const response = await userLogin(formFields);

      if (response.success) {
        window.location.href = ROUTE_PATHS.DASHBOARD;
      }
    } else {
      setFormErrors(errors);
    }
  };

  if (isLoggedIn) return <Redirect to={ROUTE_PATHS.DASHBOARD} />;

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight={"90vh"}
    >
      <Paper
        sx={{
          maxWidth: 380,
          borderRadius: 2,
          border: "1px solid #DFE0EB",
          p: 8,
        }}
      >
        <Grid
          sx={{ maxWidth: 316 }}
          container
          component="form"
          onSubmit={onSubmit}
          noValidate
        >
          <Grid item container justifyContent={"center"} xs={12}>
            <Typography sx={{ mb: 5, mt: 2 }} fontSize={24} fontWeight={700}>
              {"Log In"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              sx={{ fontSize: 12, fontWeight: 700 }}
              htmlFor="login-email"
            >
              Email
            </InputLabel>
            <TextField
              id="login-email"
              variant="outlined"
              placeholder="Email address"
              name="email"
              fullWidth
              InputProps={{
                sx: {
                  fontSize: 14,
                  fontWeight: 400,
                },
              }}
              sx={{ mb: 2 }}
              size="small"
              onChange={handleOnChange}
              value={formFields.email}
              error={formErrors.email ? true : false}
              helperText={formErrors.email}
              autoComplete={"off"}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              sx={{ fontSize: 12, fontWeight: 700 }}
              htmlFor="login-password"
            >
              Password
            </InputLabel>
            <TextField
              id="login-password"
              placeholder="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              sx={{ mb: 2 }}
              size="small"
              InputProps={{
                sx: { fontSize: 14, fontWeight: 400 },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <Visibility sx={{ width: 16 }} />
                      ) : (
                        <VisibilityOff sx={{ fontSize: 16 }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              name="password"
              fullWidth
              onChange={handleOnChange}
              value={formFields.password}
              error={formErrors.password ? true : false}
              helperText={formErrors.password}
              autoComplete={"off"}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={onSubmit}
              fullWidth={true}
              type="submit"
              sx={{ height: 48 }}
            >
              Log In
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default Login;
