import clsx from "clsx";
import { Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import { useStyles } from "./styles";

interface Props {
  label?: string;
  labelClass?: string;
  checked?: boolean;
  onChange?: Function;
  id?: any;
}

function StyledCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      id={props.id}
      checked={props.checked}
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      onChange={props.onChange}
      {...props}
    />
  );
}

function AppCheckbox({ label, labelClass, checked, onChange, id, isDisabled }: any) {
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <StyledCheckbox id={id} onChange={onChange} checked={checked} disabled={isDisabled} />
        }
        label={<span className={labelClass}>{label}</span>}
      />
    </FormGroup>
  );
}

export { AppCheckbox };
