import { Grid, Container } from "@material-ui/core";
import { Redirect, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useAuthStyles } from "../styles";
import SignupForm from "./signup-form";
import BecomeHostForm from "./become-host-form";
import { ENUM_SIGNUP_TYPE } from "../../../enums";
import { ROUTE_PATHS } from "../../../routes/route-paths";
import { useEffect } from "react";

function Signup({ setConfig }: any) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const signupType = params.get("type");

  const authClasses = useAuthStyles();

  useEffect(() => {
    signupType === ENUM_SIGNUP_TYPE.BECOME_HOST &&
      setConfig({
        showLogo: false,
      });
  }, []);

  return (
    <Container className={authClasses.root}>
      <Grid
        className={authClasses.root}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          item
          xs={12}
          className={authClasses.contentWrapper}
        >
          {signupType === ENUM_SIGNUP_TYPE.SIGNUP ? (
            <SignupForm />
          ) : (
            <BecomeHostForm />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default Signup;
