import { SET_USER_INFO, UPDATE_USER_INFO } from ".";

export const setUserInfo = (payload) => ({
  type: SET_USER_INFO,
  payload,
});

export const updateUserInfo = (payload) => ({
  type: UPDATE_USER_INFO,
  payload,
});
