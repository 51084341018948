import { Grid } from "@mui/material";

import { AmbassadorWisePaymentListTable } from "./ambassador-wise-payments-table";
import { AMBASSADOR_WISE_PAYMENTS_COLUMNS } from "./table-config";

function ClientPaymentListExpandedRowData({ data, index }: any) {
  return (
    <Grid container sx={{ mt: 2, mb: 2 }} justifyContent={"center"}>
      <Grid item>
        <AmbassadorWisePaymentListTable
          rows={data.payments}
          columns={AMBASSADOR_WISE_PAYMENTS_COLUMNS}
        />
      </Grid>
    </Grid>
  );
}

export { ClientPaymentListExpandedRowData };
