import { SimpleTable } from "../../../../components/mui/table";
import { ENUM_CURRENCY, RENDERER_TYPES } from "../../../../enums";
import { numberFormat } from "../../../../services/utils";
import {
  DATE_FORMATS,
  formatDate,
} from "../../../../services/date-and-time.utils";
import ClientInvitationStatusListExpandedRowData from "./expanded-row-data";
import { InvitationStatus } from "../../../invitations/components/invitation-status";
import { Typography } from "@mui/material";

function ClientInvitationStatusListTable({
  list,
  columns,
  handlePageFilters,
  appliedFilters,
}) {
  const { selectedPage, selectedPageSize } = appliedFilters;

  const handleTDRenderer = (row, column) => {
    switch (column.rendererType) {
      case RENDERER_TYPES.FULL_DATE:
        return `${formatDate(
          row[column.field],
          DATE_FORMATS.fullDateWithTime
        )}`;
      case RENDERER_TYPES.AMOUNT:
        return `${numberFormat(row[column.field], ENUM_CURRENCY.USD)}`;
      case RENDERER_TYPES.INVITATION_STATUS:
        return <InvitationStatus data={row} />;
      case RENDERER_TYPES.PROJECT_NAME:
        if (column?.field === "projectName" && row?.isDummy) {
          return <Typography fontSize={13}>Manual Meeting</Typography>;
        }
        return row[column.field];
      default:
        break;
    }
  };
  return (
    <SimpleTable
      tdRenderer={handleTDRenderer}
      rows={list?.data}
      columns={columns}
      fetching={list?.loading}
      total={list?.total}
      onPageSettingsChange={handlePageFilters}
      showPagination={false}
      renderExpandedRowData={({ row }) => (
        <ClientInvitationStatusListExpandedRowData data={row} />
      )}
      exandableRow
    ></SimpleTable>
  );
}

export { ClientInvitationStatusListTable };
