import { makeStyles } from "@material-ui/core";
import { Box, Breadcrumbs } from "@mui/material";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router";
import { AppText } from "../../typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const useStyles = makeStyles((theme) => {
  return {
    parentRootLink: {
      color: "#000000",
      fontSize: 20,
      fontWeight: 500,
      fontFamily: "Poppins",
    },
    currentRoute: {
      fontSize: 14,
      fontWeight: 600,
      fontFamily: "Open Sans",
      color: "#8C8C8C",
    },
  };
});

function AppBreadcrumbs({ currentRoutePaths, customLabel }: any) {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    url
  ) => {
    event.preventDefault();

    history.push(url);
  };

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon sx={{ color: "#000000" }} fontSize="small" />
      }
      aria-label="breadcrumb"
    >
      {currentRoutePaths?.map((item, index) => (
        <Box display={"flex"} alignItems="center" key={index}>
          {index === 0 && <img style={{ marginRight: 6 }} src={item.icon} />}
          {index !== currentRoutePaths.length - 1 ? (
            <Link
              className={classes.parentRootLink}
              href={item?.url}
              onClick={(e) => handleClick(e, item?.url)}
            >
              <AppText
                text={item?.label?.toUpperCase()}
                textClass={classes.parentRootLink}
              />
            </Link>
          ) : !item.isCustom ? (
            <AppText
              text={item?.label?.toUpperCase()}
              textClass={classes.parentRootLink}
            />
          ) : (
            <AppText
              text={customLabel?.toUpperCase()}
              textClass={classes.parentRootLink}
            />
          )}
        </Box>
      ))}
    </Breadcrumbs>
  );
}

export { AppBreadcrumbs };
