import { makeStyles } from "@material-ui/core";

export const useStyles: any = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    backgroundColor: "#000000",
    height: "36px",
    padding: 0,
    borderRadius: 0,
  },
  primaryActionButton: {
    height: "54px",
    textTransform: "none",
    backgroundColor: "#E15143",
    marginRight: theme.spacing(1),
    borderRadius: 30,
    "&:disabled": {
      backgroundColor: "#AFB2B8",
    },
    "&:hover": {
      backgroundColor: "#0C6AC0",
    },
    color: "white",
    fontWeight: 600,
    fontSize: 16,
  },
  defaultActionButton: {
    maxWidth: "313px",
    textTransform: "none",
    height: "54px",
    borderRadius: 30,
    backgroundColor: "white",
    border: "1px solid #1890FF",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "white",
    },
    color: "#1890FF",
    fontWeight: 600,
    fontSize: 16,
  },
}));
