import { Grid } from "@mui/material";
import { ClientWisePaymentListTable } from "./client-wise-payments-table";
import { CLIENT_WISE_PAYMENTS_COLUMNS } from "./table-config";

function AmbassadorPaymentListExpandedRowData({ data, index }: any) {
  return (
    <Grid container sx={{ mt: 2, mb: 2 }} justifyContent={"center"}>
      <Grid item>
        <ClientWisePaymentListTable
          rows={data}
          columns={CLIENT_WISE_PAYMENTS_COLUMNS}
        />
      </Grid>
    </Grid>
  );
}

export { AmbassadorPaymentListExpandedRowData };
