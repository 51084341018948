import { Button, Typography } from "@mui/material";

function SaveAndCancelButton({
  primaryText,
  defaultText,
  onPrimaryActionClick,
  onDefaultButtonClick,
  isPrimaryButtonDisabled = false,
}: any) {
  return (
    <>
      <Button
        sx={{
          background: "#e0e0e0",
          minWidth: 84,
          height: 36,
          mr: 1,
        }}
        onClick={onDefaultButtonClick}
      >
        <Typography sx={{ color: "#070639", fontSize: 14, fontWeight: 500 }}>
          {defaultText}
        </Typography>
      </Button>
      <Button
        sx={{ minWidth: 84, height: 36 }}
        variant="contained"
        onClick={onPrimaryActionClick}
        disabled={isPrimaryButtonDisabled}
      >
        <Typography sx={{ color: "white", fontSize: 14, fontWeight: 500 }}>
          {primaryText}
        </Typography>
      </Button>
    </>
  );
}

export { SaveAndCancelButton };
