export * from "./list-actions";
export * from "./info-actions";

export const SET_SELECTED_AMBASSADOR = "SET_SELECTED_AMBASSADOR";
export const RESET_AMBASSADORS_STORE = "RESET_AMBASSADORS_STORE";

export const setSelectedAmbassador = (payload = undefined) => ({
  type: SET_SELECTED_AMBASSADOR,
  payload,
});

export const resetAmbassadorStore = () => ({
  type: RESET_AMBASSADORS_STORE,
});

export const resetSelectedAmbassador = () => ({
  type: SET_SELECTED_AMBASSADOR,
  payload: null,
});
