import { InitialNameAvatar } from "../../../../components/images/initial-name-avatar";
import { CustomizedTable } from "../../../../components/mui/table/custom-table";
import { RENDERER_TYPES } from "../../../../enums";
import {
  DATE_FORMATS,
  formatDate,
} from "../../../../services/date-and-time.utils";
import { convertToFullName } from "../../../../services/utils";
import { ProjectStatus } from "../../../invitations/components/project-status";

function AmbassadorWiseSlotDetailsListTable({ rows, columns }) {
  const handleTDRenderer = (row, column) => {
    switch (column.rendererType) {
      case RENDERER_TYPES.FULL_DATE:
        return (
          row[column.field] &&
          `${formatDate(row[column.field], DATE_FORMATS.fullDateWithTime)}`
        );
      case RENDERER_TYPES.MEETING_STATUS:
        return <ProjectStatus data={row} />;
      case RENDERER_TYPES.FULL_NAME:
        return (
          <InitialNameAvatar
            text={convertToFullName(
              row.actionPerformBy.firstName,
              row.actionPerformBy.lastName
            )}
            name={row.actionPerformBy.firstName}
          />
        );
      default:
        break;
    }
  };

  return (
    <CustomizedTable
      tdRenderer={handleTDRenderer}
      rows={rows}
      columns={columns}
    ></CustomizedTable>
  );
}

export { AmbassadorWiseSlotDetailsListTable };
