export  * from "./info-actions";
export * from "./list-actions";

export const SET_SELECTED_CLIENT = "SET_SELECTED_CLIENT";
export const RESET_CLIENTS_STORE = "RESET_CLIENTS_STORE";

export const setSelectedClient = (payload = undefined) => ({
  type: SET_SELECTED_CLIENT,
  payload,
});

export const resetClientStore = () => ({
  type: RESET_CLIENTS_STORE,
});

export const resetSelectedClient = () => ({
  type: SET_SELECTED_CLIENT,
  payload: null,
});
