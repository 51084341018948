import { TextField, FormControl } from "@material-ui/core";
import { useStyles } from "./styles";
import { AppText } from "../typography";
import { useEffect, useState } from "react";

function AppTextField({
  label = "",
  placeholder,
  inlineLabel = false,
  fullWidth = true,
  textFieldClass,
  rootClass,
  onChange,
  variant = "outlined",
  id,
  name,
  value,
  error,
  inputFieldClass,
  otherProps,
  endAdornment,
  inputProps = {},
  onEnter,
  width,
  disabled,
  style = {},
  rootStyle = {},
}: any) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onInputChange = (e) => {
    setInputValue(e.target.value);

    onChange && onChange(e);
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      variant="outlined"
      style={rootStyle}
      className={rootClass}
    >
      {!inlineLabel && <AppText text={label} textClass={classes.inputTitle} />}

      <TextField
        {...otherProps}
        id={id}
        InputProps={{ className: `${inputFieldClass}`, ...inputProps }}
        label={inlineLabel && label}
        variant={variant}
        name={name}
        placeholder={typeof placeholder === "string" ? placeholder : ""}
        fullWidth
        disabled={disabled}
        onChange={onInputChange}
        value={inputValue}
        onKeyPress={(e) => e.key === "Enter" && onEnter && onEnter(inputValue)}
        error={error ? true : false}
        helperText={error}
        autoComplete={"off"}
        style={{
          ...style,
          ...(width && { width }),
        }}
      />
    </FormControl>
  );
}

export { AppTextField };
