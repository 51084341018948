import {
  DEFAULT_SELECTED_PAGE,
  DEFAULT_SELECTED_PAGE_SIZE,
} from "../../../constants";

const SET_AMBASSADOR_MEETINGS_LIST_LOADING =
  "SET_AMBASSADOR_MEETINGS_LIST_LOADING";
const SET_AMBASSADOR_MEETINGS_LIST = "SET_AMBASSADOR_MEETINGS_LIST";
const SET_AMBASSADOR_MEETINGS_FILTERS = "SET_AMBASSADOR_MEETINGS_FILTERS";
const SET_AMBASSADOR_PAYMENTS_LIST_LOADING =
  "SET_AMBASSADOR_PAYMENTS_LIST_LOADING";
const SET_AMBASSADOR_PAYMENTS_LIST = "SET_AMBASSADOR_PAYMENTS_LIST";
const SET_AMBASSADOR_PAYMENTS_FILTERS = "SET_AMBASSADOR_PAYMENTS_FILTERS";
const RESET_AMBASSADOR_INFO = "RESET_AMBASSADOR_INFO";

const initialAmbassadorInfoState = {
  meetings: {
    data: undefined,
    loading: null,
    filters: {
      selectedPage: DEFAULT_SELECTED_PAGE,
      selectedPageSize: DEFAULT_SELECTED_PAGE_SIZE,
    },
    total: undefined,
  },
  payments: {
    data: undefined,
    loading: null,
    filters: {
      selectedPage: DEFAULT_SELECTED_PAGE,
      selectedPageSize: DEFAULT_SELECTED_PAGE_SIZE,
    },
    total: undefined,
  },
};

const setAmbassadorMeetinsListLoading = (payload) => ({
  type: SET_AMBASSADOR_MEETINGS_LIST_LOADING,
  payload,
});

const setAmbassadorMeetinsList = (payload) => ({
  type: SET_AMBASSADOR_MEETINGS_LIST,
  payload,
});

const setAmbassadorMeetingsFilters = (payload = undefined) => ({
  type: SET_AMBASSADOR_MEETINGS_FILTERS,
  payload,
});

const setAmbassadorPaymentsListLoading = (payload) => ({
  type: SET_AMBASSADOR_PAYMENTS_LIST_LOADING,
  payload,
});

const setAmbassadorPaymentsList = (payload) => ({
  type: SET_AMBASSADOR_PAYMENTS_LIST,
  payload,
});

const setAmbassadorPaymentsFilters = (payload = undefined) => ({
  type: SET_AMBASSADOR_PAYMENTS_FILTERS,
  payload,
});

const resetAmbassadorInfo = () => ({
  type: RESET_AMBASSADOR_INFO,
});

export {
  initialAmbassadorInfoState,
  setAmbassadorMeetinsListLoading,
  setAmbassadorMeetinsList,
  setAmbassadorMeetingsFilters,
  setAmbassadorPaymentsListLoading,
  setAmbassadorPaymentsList,
  setAmbassadorPaymentsFilters,
  resetAmbassadorInfo,
  SET_AMBASSADOR_MEETINGS_LIST_LOADING,
  SET_AMBASSADOR_MEETINGS_LIST,
  SET_AMBASSADOR_MEETINGS_FILTERS,
  SET_AMBASSADOR_PAYMENTS_LIST_LOADING,
  SET_AMBASSADOR_PAYMENTS_LIST,
  SET_AMBASSADOR_PAYMENTS_FILTERS,
  RESET_AMBASSADOR_INFO,
};
