import { getApi } from "../services/axios";
import APIs from ".";

export function getDashboardSummaryData({ date }) {
  return async function (dispatch) {
    const res = await Promise.allSettled([
      getApi(APIs.DASHBOARD.GET_NEW_MEETING_COUNT({ date })),
      getApi(APIs.DASHBOARD.GET_NEW_AMBASSADOR_COUNT({ date })),
      getApi(APIs.DASHBOARD.GET_NEW_CLIENT_COUNT({ date })),
      getApi(APIs.DASHBOARD.GET_NEW_REVENUE_COUNT({ date })),
    ]);

    return res;
  };
}

export function getDashboardEvents({ startDate, endDate }) {
  return async function (dispatch) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Make two separate API calls
    const res = await getApi(
      APIs.DASHBOARD.GET_EVENTS({
        startDate: start,
        endDate: end,
      })
    );

    return res?.data;
  };
}
