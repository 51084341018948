import * as _ from "lodash";
import { REGEX } from "../constants";
import { ENUM_VALIDATION_TYPES } from "../enums";

export function checkTimeslotSelectionForLateNight(datetime) {
  const hr = new Date(datetime).getHours();

  if (hr >= 0 && hr <= 6) {
    return true;
  }

  return false;
}

function fieldValidator({ value, placeHolder, required = true, type }: any) {
  if (!value && required) {
    return `${placeHolder} is required`;
  }

  if (Array.isArray(value) && !value.length) {
    return `${placeHolder} is required`;
  }

  const validatorMessage = `${placeHolder} is not valid`;

  switch (type) {
    case ENUM_VALIDATION_TYPES.EMAIL:
      if (!REGEX.EMAIL.test(value)) return validatorMessage;
      break;
    case ENUM_VALIDATION_TYPES.CORPORATE_EMAIL:
      if (
        !REGEX.EMAIL.test(value) ||
        value?.toLowerCase().match(/gmail|hotmail|outlook|yahoo/g)
      )
        return "Please enter your corporate email address";
      break;
    case ENUM_VALIDATION_TYPES.NAME:
      if (!REGEX.NAME.test(value)) return validatorMessage;
      break;
    case ENUM_VALIDATION_TYPES.URL:
      if (!REGEX.URL.test(value)) return `${placeHolder} url is not valid`;
      break;
    case ENUM_VALIDATION_TYPES.PHONE:
      if (!REGEX.PHONE.test(value)) return `${placeHolder} is not valid`;
      break;
    case ENUM_VALIDATION_TYPES.POSITIVE_INTEGER_NUMBER:
      if (!REGEX.NUMBERS.POSITIVE_INTEGER_NUMBER.test(value))
        return validatorMessage;
      break;
    case ENUM_VALIDATION_TYPES.NUMBER_WITH_DOT:
      if (!REGEX.NUMBERS.NUMBER_WITH_DOT.test(value)) return validatorMessage;
      break;
    default:
      return "";
  }

  return "";
}

function runValidation(fields, initialErrors, FORM_ERROR_MAAPING) {
  let isError = false;
  let errors = _.cloneDeep(initialErrors);

  FORM_ERROR_MAAPING.map((item) => {
    errors[item.fieldName] = fieldValidator({
      ...item,
      value: fields[item.fieldName],
    });
  });

  isError = Object.keys(errors).filter((field) => errors[field]).length
    ? true
    : false;

  return { errors, isError };
}

export const validateForm = (
  formFields,
  initialErrors,
  LOGIN_FORM_ERROR_MAAPING
) => {
  const { errors, isError } = runValidation(
    formFields,
    initialErrors,
    LOGIN_FORM_ERROR_MAAPING
  );

  return { errors, isError };
};
