import moment from "moment";

const validateSlots = (selectedDateTime, upcomingMeetings) => {
  let isFormValid = true;
  let error = "";
  upcomingMeetings.map((upcomingMeetingItem) => {
    if (
      moment(selectedDateTime.to).diff(
        moment(upcomingMeetingItem.selectedTimeSlot.from),
        "minutes"
      ) > 0 &&
      moment(upcomingMeetingItem.selectedTimeSlot.to).diff(
        moment(selectedDateTime.from),
        "minutes"
      ) > 0
    ) {
      error = "Found overlaping with client's upcoming meetings";
      isFormValid = false;
    }
  });

  return { isFormValid, error };
};

export { validateSlots };
