import React, { useEffect, useState } from "react";
import { AmbassadorListTable } from "../../../ambassadors/list/table";
import { CLIENT_CRITERIA_AMBASSADOR_LIST_COLUMNS } from "./table-config";
import { getAmbassadorListBasedOnCriteria } from "../../../../apis/clients.api";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

export default function ClientCriteriaAmbassador() {
  const [ambassadorList, setAmbassadorList] = useState({
    data: null,
    total: null,
    loading: null,
  });
  const dispatch = useDispatch();
  const { id }: any = useParams();

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    setAmbassadorList({
      ...ambassadorList,
      loading: true,
    });
    const response: any = await dispatch(getAmbassadorListBasedOnCriteria(id));

    if (response.success) {
      setAmbassadorList({
        ...ambassadorList,
        data: response.data?.targeted,
        loading: false,
      });

      return;
    }

    setAmbassadorList({
      ...ambassadorList,
      loading: false,
    });
  };

  return (
    <AmbassadorListTable
      list={ambassadorList}
      columns={CLIENT_CRITERIA_AMBASSADOR_LIST_COLUMNS}
      showPagination={false}
    />
  );
}
