import { Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { LabelAndValue } from "./label-and-value";

function AppText({
  id,
  onClick,
  text,
  textClass,
  children,
  link,
  variant = "caption",
  color,
  fontSize,
  fontWeight,
  lineHeight,
  showAnimation = false,
  cursor = "",
  gutterBottom,
  component,
}: any) {
  const classes = useStyles();

  const renderText = () => {
    return text || children;
  };

  return (
    <Typography
      gutterBottom={gutterBottom}
      component={component}
      variant={variant}
      className={`${link && classes.linkText} ${textClass} `}
      onClick={onClick}
      id={id}
      style={{
        color,
        fontSize,
        fontWeight,
        lineHeight,
        cursor,
      }}
    >
      {
        renderText()
      }
    </Typography>
  );
}

export { AppText, LabelAndValue };
