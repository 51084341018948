import { RENDERER_TYPES } from "../../../../enums";

const AMBASSADOR_MEETINGS_COLUMNS = [
  {
    title: "Client ",
    field: "firstName",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_NAME_WITH_AVATAR,
    width: "20%"
  },
  {
    title: "Topic",
    field: "topics",
    dataType: "string",
    rendererType: RENDERER_TYPES.CHIP_LIST,
  },
  {
    title: "Date",
    field: "date",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_DATE_FROM,
    width: "20%"
  },
  {
    title: "Status",
    field: "status",
    dataType: "string",
    rendererType: RENDERER_TYPES.MEETING_STATUS,
  },
];

const AMBASSADOR_MEETINGS_ROWS = [
  {
    firstName: "Nixie",
    lastName: "Sailor",
    topic: "Paychex",
    date: "August 2, 2022 09:30 AM",
    isCompleted: true,
    isRejected: false,
  },
  {
    firstName: "Nixie",
    lastName: "Sailor",
    topic: "Paychex",
    date: "August 2, 2022 09:30 AM",
    isCompleted: true,
    isRejected: false,
  },
  {
    firstName: "Nixie",
    lastName: "Sailor",
    topic: "Paychex",
    date: "August 2, 2022 09:30 AM",
    isCompleted: false,
    isRejected: true,
  },
  {
    firstName: "Nixie",
    lastName: "Sailor",
    topic: "Paychex",
    date: "August 2, 2022 09:30 AM",
    isCompleted: true,
    isRejected: false,
  },
  {
    firstName: "Nixie",
    lastName: "Sailor",
    topic: "Paychex",
    date: "August 2, 2022 09:30 AM",
    isCompleted: true,
    isRejected: false,
  },
  {
    firstName: "Nixie",
    lastName: "Sailor",
    topic: "Paychex",
    date: "August 2, 2022 09:30 AM",
    isCompleted: false,
    isRejected: false,
  },
];

export { AMBASSADOR_MEETINGS_COLUMNS, AMBASSADOR_MEETINGS_ROWS };
