import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ButtonWithIcon } from "../../../components/buttons";
import Searchbox from "../../../components/searchbox";
import { AmbassadorListTable } from "../../ambassadors/list/table";
import AmbassadorFilterOptions from "../../ambassadors/list/filter-options";
import { NEW_INVITATION_AMBASSADOR_LIST_COLUMNS } from "./table-config";
import invitationHOC from "../invitation-hoc";
import { compose } from "redux";
import ambassadorHOC from "../../ambassadors/ambassador-hoc";

function NewMeetingInvitation({
  getAmbassadorList,
  ambassadorList,
  toggleMenuOptions,
  onCreateInvitationClick,
}) {
  const { selectedPageSize, searchValue, selectedPage, selectedFilters } =
    useSelector((state: any) => state.ambassadors.list.filters);
  const [selectedAmbassadors, setSelectedAmbassadors] = useState([]);

  useEffect(() => {
    getAmbassadorList({
      selectedPage,
      selectedPageSize,
      searchValue,
      selectedFilters,
    });
  }, []);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getAmbassadorList({
      selectedPage,
      selectedPageSize,
      searchValue,
      selectedFilters,
    });
  };

  const handleSearchValueChange = (value = "") => {
    getAmbassadorList({
      searchValue: value,
      selectedPage: 1,
      selectedPageSize,
    });
  };

  const handleOnRowSelectionChange = ({ row }) => {
    const tempSelectedAmbassadors = [...selectedAmbassadors];
    const selectedIds = [...tempSelectedAmbassadors].map(({ id }) => id);
    const indexOfSelectedRow = selectedIds.indexOf(row.id);

    if (indexOfSelectedRow === -1) {
      setSelectedAmbassadors([...selectedAmbassadors, row]);
    } else {
      tempSelectedAmbassadors.splice(indexOfSelectedRow, 1);
      setSelectedAmbassadors(tempSelectedAmbassadors);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid
        container
        alignItems={"center"}
        spacing={1}
        justifyContent={"space-between"}
      >
        <Grid item xs={6} md={6}>
          <Searchbox
            onEnter={handleSearchValueChange}
            value={searchValue}
            onClear={() => handleSearchValueChange("")}
          />
        </Grid>
        {selectedAmbassadors.length > 0 && (
          <Grid item xs={6} container justifyContent={"flex-end"}>
            <ButtonWithIcon
              handleOnClick={() =>
                onCreateInvitationClick({ ambassadors: selectedAmbassadors })
              }
              text={"CREATE INVITATION"}
              icon={{ position: "startIcon", name: "add" }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} spacing={2} direction="column">
        <Grid item>
          <AmbassadorFilterOptions />
        </Grid>
        <Grid item>
          <AmbassadorListTable
            list={ambassadorList}
            toggleMenuOptions={toggleMenuOptions}
            columns={NEW_INVITATION_AMBASSADOR_LIST_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              searchValue,
              selectedPage,
              selectedFilters,
            }}
            onRowSelect={handleOnRowSelectionChange}
            selectedRows={{
              field: "id",
              values: selectedAmbassadors.map(({ id }) => id),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default compose(invitationHOC, ambassadorHOC)(NewMeetingInvitation);
