import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Divider, ListSubheader } from "@mui/material";
import { convertToFullName } from "../../services/utils";
import {
  convertToTimezone,
  DATE_FORMATS,
} from "../../services/date-and-time.utils";

export default function ClientUpcomingMeetingList({ list }) {
  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Upcoming meetings
          <Divider />
        </ListSubheader>
      }
    >
      {list?.length > 0 ? (
        list?.map((item) => (
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <CalendarMonthIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={convertToFullName(
                item.ambassador.firstName,
                item.ambassador.lastName
              )}
              secondary={convertToTimezone(
                item.selectedTimeSlot.from,
                item.client.timezone.text,
                DATE_FORMATS.fullDateWithTime
              )}
            />
            <Divider />
          </ListItem>
        ))
      ) : (
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <CalendarMonthIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText secondary={"No upcoming meeting scheduled"} />
          <Divider />
        </ListItem>
      )}
    </List>
  );
}
