import { TextField } from "@material-ui/core";
import { ENUM_SIGNUP_TYPE } from "../../../enums";
import { useAuthStyles } from "../styles";

function SignupOtherDetails({
  handleOnChange,
  formFields,
  formErrors,
  signupType,
}: any) {
  const authClasses = useAuthStyles();

  return (
    <>
      <TextField
        className={authClasses.textField}
        id="signup-url"
        label="Linkedin Profile"
        variant="outlined"
        type="text"
        name="linkedInProfile"
        fullWidth
        onChange={handleOnChange}
        value={formFields.linkedInProfile}
        autoComplete={"off"}
      />

      <TextField
        className={authClasses.textField}
        id="signup-title"
        label="Title"
        variant="outlined"
        name="title"
        fullWidth
        onChange={handleOnChange}
        value={formFields.title}
        error={formErrors.title ? true : false}
        helperText={formErrors.title}
        autoComplete={"off"}
      />

      <TextField
        className={authClasses.textField}
        id="signup-industry"
        label="Industry"
        variant="outlined"
        name="industry"
        fullWidth
        onChange={handleOnChange}
        value={formFields.industry}
        error={formErrors.industry ? true : false}
        helperText={formErrors.industry}
        autoComplete={"off"}
      />

      {ENUM_SIGNUP_TYPE.SIGNUP === signupType && (
        <TextField
          className={authClasses.textField}
          id="signup-topicToDiscuss"
          label="Tell us a bit more about what you’d like to discuss:"
          variant="outlined"
          name="topicToDiscuss"
          multiline
          rows={5}
          fullWidth
          onChange={handleOnChange}
          value={formFields.topicToDiscuss}
          error={formErrors.topicToDiscuss ? true : false}
          helperText={formErrors.topicToDiscuss}
          autoComplete={"off"}
          style={{ marginBottom: "100px" }}
        />
      )}
    </>
  );
}

export { SignupOtherDetails };
