import { CLEAR_ALL } from "..";
import {
  CONST_LOOKING_FOR_LIST,
  CONST_MP_CERTIFICATION_LIST,
  CONST_MP_PRICING,
  CONST_MP_PRICING_STRUCTURE,
} from "../../../constants/lists";
import { getLast100Years } from "../../../services/utils";
export const SET_LIST_LOADING = "SET_LIST_LOADING";
export const SET_LIST_SUCCESS = "SET_LIST_SUCCESS";
export const SET_LIST_DATA = "SET_LIST_DATA";
export const SET_LIST_FAILED = "SET_LIST_FAILED";

const last100YearArray = getLast100Years();

export const setListLoading = (payload = undefined) => ({
  type: SET_LIST_LOADING,
  payload,
});

export const setListData = (payload = undefined) => ({
  type: SET_LIST_DATA,
  payload,
});

export const setListSuccess = (payload = undefined) => ({
  type: SET_LIST_SUCCESS,
  payload,
});

export const setListFailed = (payload = undefined) => ({
  type: SET_LIST_FAILED,
  payload,
});

const defaultValues = {
  data: null,
  loading: null,
  success: null,
  message: null,
};

export const commonInitialState = {
  timezones: { ...defaultValues },
  topics: { ...defaultValues },
  region: { ...defaultValues },
  category: { ...defaultValues },
  industry: { ...defaultValues },
  title: { ...defaultValues },
  noOfEmp: { ...defaultValues },
  opportunity_categories: { ...defaultValues },
  state: { ...defaultValues },
  country_state: { ...defaultValues },
  countries: { ...defaultValues },
  intrestedInPriority: { ...defaultValues },
  mp_categories: defaultValues,
  mp_pricing_structures: {
    data: CONST_MP_PRICING_STRUCTURE,
    loading: null,
    success: null,
    error: null,
  },
  mp_pricing: {
    data: CONST_MP_PRICING,
    loading: null,
    success: null,
    error: null,
  },
  mp_year: {
    data: last100YearArray,
    loading: null,
    success: null,
    error: null,
  },
  mp_certifications: {
    data: CONST_MP_CERTIFICATION_LIST,
    loading: null,
    success: null,
    error: null,
  },
  lookingFor: {
    data: CONST_LOOKING_FOR_LIST,
    loading: null,
    success: null,
    error: null,
  },
};

const common = (state = commonInitialState, action) => {
  switch (action.type) {
    case SET_LIST_LOADING:
      return {
        ...state,
        [action.payload.list]: {
          ...state[action.payload.list],
          loading: action.payload.loading,
        },
      };
    case SET_LIST_DATA:
      return {
        ...state,
        [action.payload.list]: {
          ...state[action.payload.list],
          data: action.payload.data,
        },
      };
    case SET_LIST_SUCCESS:
      return {
        ...state,
        [action.payload.list]: {
          ...state[action.payload.list],
          success: action.payload.success,
        },
      };
    case SET_LIST_FAILED:
      return {
        ...state,
        [action.payload.list]: {
          ...state[action.payload.list],
          error: action.payload.error,
        },
      };
    case CLEAR_ALL:
      return {
        ...commonInitialState,
      };
    default:
      return state;
  }
};

export default common;
