import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme, ...props }: any) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme, ...props }: any) => ({
  "&:nth-of-type(odd)": {},

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function CustomizedTable({ rows, columns, tdRenderer }) {
  function Row(props) {
    const { row, index } = props;

    return (
      <StyledTableRow key={`rowItem-${index}`}>
        {columns.map((columnItem, i) => {
          switch (columnItem["field"]) {
            default:
              return (
                <TableCell align={columnItem.align} key={`columnItem-${i}`}>
                  {columnItem.rendererType
                    ? tdRenderer(row, columnItem, i)
                    : row[columnItem.field]}
                </TableCell>
              );
          }
        })}
      </StyledTableRow>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map(({ title, align, width }, index) => (
              <StyledTableCell
                sx={{ width }}
                align={align}
                key={`column-${index}`}
              >
                {title}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length ? (
            rows.map((row, index) => (
              <Row index={index} row={row} key={`rowItem-${index}`} />
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell
                colSpan={columns.length}
                style={{ borderBottom: 0 }}
              >
                {"No Data Found."}
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export { CustomizedTable };
