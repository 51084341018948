import { RENDERER_TYPES } from "../../../../enums";

const CLIENT_INVITATION_STATUS_LIST_COLUMNS = [
  { field: "expandable" },
  {
    title: "Date",
    field: "createdAt",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_DATE,
    width: "20%"
  },
  {
    title: "Project Name",
    field: "projectName",
    dataType: "string",
    width: "80%",
    rendererType: RENDERER_TYPES.PROJECT_NAME
  },
];

const AMBASSADOR_INVITATION_STATUS_LIST_COLUMNS = [
  {
    title: "Date",
    field: "createdAt",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_DATE,
    width: "25%"
  },
  {
    title: "Action By",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_NAME,
    width: "50%"
  },
  {
    title: "Status",
    field: "status",
    dataType: "string",
    rendererType: RENDERER_TYPES.MEETING_STATUS,
    width: "25%"
  },
];

const INVITATION_STATUS_ROWS = [
];

export {
  CLIENT_INVITATION_STATUS_LIST_COLUMNS,
  AMBASSADOR_INVITATION_STATUS_LIST_COLUMNS,
  INVITATION_STATUS_ROWS,
};
