import { getApi, postApi, putApi } from "../services/axios";
import APIs from ".";
import {
  setInvitationsList,
  setInvitationListFilters,
  setInvitationsListLoading,
} from "../redux/modules/invitations/actions";

export function getInvitations({
  selectedPage,
  selectedPageSize,
  searchValue,
  selectedDate,
}) {
  return async function (dispatch) {
    const res = await getApi(
      APIs.INVITATIONS.GET_INVITATION_LIST(
        selectedPage,
        selectedPageSize,
        searchValue,
        selectedDate
      ),
      {
        validateUser: true,
        loadingFn: setInvitationsListLoading,
      }
    );

    if (res.success) {
      dispatch(
        setInvitationsList({
          ...res.data,
          data: res.data,
          total: res.total,
        })
      );

      dispatch(
        setInvitationListFilters({
          selectedPage,
          selectedPageSize,
          searchValue,
          selectedDate,
        })
      );
    }

    return res;
  };
}

export function submitInvitationApproval({ id, type, data }) {
  return async function (dispatch) {
    const res = await postApi(
      APIs.INVITATIONS.SUMIT_INVITATION_APPROVAL(id, type),
      data,
      {
        validateUser: true,
      }
    );

    return res;
  };
}

export function updateInvitation(payload) {
  return async function (dispatch) {
    const { id, additionalMembers, updatedTimeSlot } = payload;

    const res = await putApi(
      APIs.INVITATIONS.UPDATE_INVITATION({ id }),
      {
        ...(additionalMembers && { additionalMembers }),
        ...(updatedTimeSlot && { updatedTimeSlot }),
      },
      {
        validateUser: true,
      }
    );

    return res;
  };
}

export function submitArchiveMeeting(payload) {
  return async function (dispatch) {
    const { id, action } = payload;

    const res = await postApi(
      APIs.INVITATIONS.ARCHIEVE_MEETING({ id }),
      { action },
      {
        validateUser: true,
      }
    );

    return res;
  };
}
