import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(24, 144, 255, 0.1)",
    color: "#1890FF",
  },
}));

function AppChip({
  handleDelete,
  onClick,
  label,
  wrapperClass,
  icon,
  bgColor,
  style = {},
  fontColor,
  size="small"
}: any) {
  const classes = useStyles();
  return (
    <Chip
      className={`${classes.root} ${wrapperClass}`}
      sx={{
        ...(bgColor && { background: bgColor }),
        ...(fontColor && { color: fontColor }),
      }}
      style={{ ...style }}
      label={label}
      icon={icon}
      clickable
      onDelete={handleDelete}
      onClick={onClick}
      size={size}
    />
  );
}

export { AppChip };
