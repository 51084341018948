import { Typography } from "@mui/material";
import { ROUTE_PATHS } from "../../routes/route-paths";

function Logo({ color = "#FFFFFF" }) {
  const onLogoClick = () => {
    window.location.href = ROUTE_PATHS.EXTERNAL_WEBSITE_LINK;
  };

  return (
    <Typography
      onClick={onLogoClick}
      variant="h6"
      sx={{
        cursor: "pointer",
        color,
        fontWeight: 600,
        fontSize: 24,
      }}
    >
      EXECWEB
    </Typography>
  );
}

export { Logo };
