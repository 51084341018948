import * as _ from "lodash";
import { REGEX } from "../../../constants";
import { ENUM_SIGNUP_TYPE } from "../../../enums";

function validateFirstName(value) {
  if (!value) {
    return "First name is required";
  }

  if (!REGEX.NAME.test(value)) return "First name is not valid";

  return "";
}

function validateLastName(value) {
  if (!value) {
    return "Last name is required";
  }

  if (!REGEX.NAME.test(value)) return "Last name is not valid";

  return "";
}

function validateEmail(value) {
  if (!value) {
    return "Email is required";
  }

  if (!REGEX.EMAIL.test(value)) return "Email is not valid";

  return "";
}

function validateRequiredFields(value, placeholder) {
  if (!value) {
    return `${placeholder} is required`;
  }

  return "";
}

export function signupValidator(fields, initialErrors, signupType) {
  let isError = false;
  let errors = _.cloneDeep(initialErrors);
  let {
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    title,
    industry,
    region,
    topicToDiscuss,
    expertise,
    roundTableRate,
    roundTableHostPerWeek,
    meetingRate,
  } = fields;

  errors.email = validateEmail(email);
  errors.firstName = validateFirstName(firstName);
  errors.lastName = validateLastName(lastName);
  errors.phoneNumber = validateRequiredFields(phoneNumber, "Phone number");
  errors.title = validateRequiredFields(title, "Title");
  errors.industry = validateRequiredFields(industry, "Industry");
  if (ENUM_SIGNUP_TYPE.BECOME_HOST === signupType) {
    errors.region = validateRequiredFields(region, "Region");
    errors.expertise = validateRequiredFields(expertise, "Areas of Expertise");
    errors.roundTableRate = validateRequiredFields(
      roundTableRate,
      "This field"
    );
    errors.roundTableHostPerWeek = validateRequiredFields(
      roundTableHostPerWeek,
      "This field"
    );
    errors.meetingRate = validateRequiredFields(meetingRate, "This field");
  }

  if (ENUM_SIGNUP_TYPE.SIGNUP === signupType) {
    errors.password = validateRequiredFields(password, "Password");
    errors.topicToDiscuss = validateRequiredFields(
      topicToDiscuss,
      "Topics to discuss"
    );
  }

  isError = Object.keys(errors).filter((field) => errors[field]).length
    ? true
    : false;

  return { errors, isError };
}
