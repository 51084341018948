import { Divider, Grid } from "@mui/material";
import { LabelAndValue } from "../../../components/typography";

export default function ContactInfo({ data }) {
  return (
    <Grid container spacing={2}>
      {data.map((item, index) => (
        <Grid item xs={12} key={index}>
          {Object.entries(item).map(([key, value]) => (
            <LabelAndValue
              key={key}
              label={key.charAt(0).toUpperCase() + key.slice(1)}
              value={value}
              sx={{ my: 1 }}
            />
          ))}
          <Divider sx={{ my: 1 }} />
        </Grid>
      ))}
    </Grid>
  );
}
