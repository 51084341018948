import { Avatar, Box, Typography } from "@mui/material";

const defaultStyle = {
  avatar: {
    bgcolor: "#070639",
    width: 43,
    height: 43,
    fontSize: 14,
    fontWeight: 700,
  },
  text: {
    fontSize: 13,
    ml: 1,
    color: "#6C7293",
  },
};

function InitialNameAvatar({
  name,
  style = {},
  text,
  textStyle = {},
  variant,
  rootStyle = {},
  onClick,
  textClass,
  image = false,
  src,
}: any) {
  const renderAvatar = () => {
    if (image)
      return (
        <Avatar
          style={style}
          sx={{
            ...defaultStyle.avatar,
            ...(onClick && { cursor: "pointer" }),
          }}
          onClick={onClick}
          src={src}
        />
      );
    return (
      <Avatar
        style={style}
        sx={{
          ...defaultStyle.avatar,
          ...(onClick && { cursor: "pointer" }),
        }}
        onClick={onClick}
      >
        {name[0]}
      </Avatar>
    );
  };

  if (!text) return renderAvatar();

  return (
    <Box
      style={rootStyle}
      component={"div"}
      display="flex"
      alignItems={"center"}
      onClick={onClick}
      sx={{
        ...(onClick && { cursor: "pointer" }),
      }}
    >
      {renderAvatar()}
      <Typography
        style={textStyle}
        sx={{
          ...defaultStyle.text,
        }}
        variant={variant || "h3"}
      >
        {text}
      </Typography>
    </Box>
  );
}

export { InitialNameAvatar };
