import moment from "moment-timezone";

export const DATE_FORMATS = {
  fullDateWithDay: "eee MMMM d, yyyy",
  fullDateWithTime: "LLL",
  fullDate: "LL",
  hourMin: "hh:mm",
  hourMinWithAMPM: "hh:mm A",
  yyymmdd: "YYYY-MM-DD",
  hourWithAMPM: "h:mmA",
};

export function formatDate(date, format) {
  return moment(date).format(format);
}

export function concatDateTime(date, time) {
  return new Date(
    `${moment(date).format("YYYY-MM-DD")} ${moment(time).format("HH:mm")}`
  ).toISOString();
}

export function convertToTimezone(date, timezone, format) {
  return moment(date)?.tz(timezone)?.format(format);
}

export function get2ndDateOfMonth(date) {
  return moment(date).startOf("month").add(1, "days");
}

export function concatSelectedFromToTime(data, timeOnly = false) {
  if (timeOnly) {
    return `${formatDate(
      data?.from,
      DATE_FORMATS.hourMinWithAMPM
    )}-${formatDate(data?.to, DATE_FORMATS.hourMinWithAMPM)}`;
  }

  return `${formatDate(
    data?.from,
    DATE_FORMATS.fullDateWithTime
  )} - ${formatDate(data?.to, DATE_FORMATS.hourMinWithAMPM)}`;
}

export function formatYYYYMMDDDate(dateStr) {
  if (!dateStr) return;
  const dateTimeStr = dateStr?.from || dateStr;
  const date = new Date(dateTimeStr);
  return date?.toISOString()?.split("T")?.[0];
}

export function getGroupTimesByDate(times) {
  const groupedByDate = times?.reduce((acc, range) => {
    const date = formatYYYYMMDDDate(range.from);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(range);
    return acc;
  }, {});

  return groupedByDate;
}
