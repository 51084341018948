import { getApi, putApi } from "../services/axios";
import APIs from ".";

export function getAmbassadorRequest() {
  return async function (dispatch) {
    const res = await getApi(APIs.CONNECT.GET_AMBASSADOR_CONNECT_REQUEST, {
      validateUser: true,
    });

    if (res.success) {
    }

    return res;
  };
}

export function getClientRequest() {
  return async function (dispatch) {
    const res = await getApi(APIs.CONNECT.GET_CLIENT_CONNECT_REQUEST, {
      validateUser: true,
    });

    if (res.success) {
    }

    return res;
  };
}

export function onApproveUserRequst(id) {
  return async function (dispatch) {
    const res = await putApi(
      APIs.CONNECT.APPROVE_USER_REQUEST(id),
      {},
      {
        validateUser: true,
      }
    );

    if (res.success) {
    }

    return res;
  };
}

export function onRejectUserRequst(id) {
  return async function (dispatch) {
    const res = await putApi(
      APIs.CONNECT.REJECT_USER_REQUEST(id),
      {},
      {
        validateUser: true,
      }
    );

    if (res.success) {
    }

    return res;
  };
}
