import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import ambassadorHOC from "../../ambassador-hoc";
import { AmbassadorPaymentListTable } from "./table";
import { AMBASSADOR_PAYMENTS_COLUMNS } from "./table-config";
import { numberFormat } from "../../../../services/utils";
import { ENUM_CURRENCY } from "../../../../enums";

function AmbassadorPayments({
  getAmbassadorPaymentsList,
  ambassadorPaymentsList,
}: any) {
  const { selectedPage, selectedPageSize } = useSelector(
    (state: any) => state.ambassadors.info.payments.filters
  );

  useEffect(() => {
    getAmbassadorPaymentsList({ selectedPage, selectedPageSize });
  }, []);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getAmbassadorPaymentsList({
      selectedPage,
      selectedPageSize,
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container spacing={2} direction="column">
        {ambassadorPaymentsList?.data?.length > 0 && (
          <Grid item xs={3} container justifyContent="flex-end">
            <Card
              elevation={5}
              sx={{
                height: 134,
                borderRadius: 0,
                width: 294,
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: 134 - 7,
                }}
              >
                <Typography fontWeight={500} fontSize={14}>
                  {"Total"}
                </Typography>
                <Typography fontWeight={500} fontSize={24} variant="caption">
                  {numberFormat(
                    ambassadorPaymentsList.total,
                    ENUM_CURRENCY.USD
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item>
          <AmbassadorPaymentListTable
            list={ambassadorPaymentsList}
            columns={AMBASSADOR_PAYMENTS_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              selectedPage,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ambassadorHOC(AmbassadorPayments);
