import { getApi, postApi } from "../services/axios";
import APIs from ".";
import {
  setOpportunityList,
  setOpportunityListFilters,
  setOpportunityListLoading,
} from "../redux/modules/opportunity/list-actions";
import {
  setOpportunityResponseList,
  setOpportunityResponseListFilters,
  setOpportunityResponseListLoading,
} from "../redux/modules/opportunity/response-list-actions";

export function submitOpportunityEmailTemplate(payload) {
  return async function (dispatch) {
    const res = await postApi(APIs.OPPORTUNITY.SUBMIT_EMAIL_TEMPLATE, payload, {
      validateUser: true,
    });

    if (res.success) {
    }

    return res;
  };
}

export function getOpportunityVendorViewersList(id) {
  return async function (dispatch) {
    const res = await getApi(
      APIs.OPPORTUNITY.GET_OPPORTUNITIES_VENDOR_VIEWERS_LIST(id),
      {
        validateUser: true,
      }
    );

    if (res.success) {
    }

    return res;
  };
}

export function convrtToVendor(id) {
  return async function (dispatch) {
    const res = await postApi(
      APIs.OPPORTUNITY.SUBMIT_CONVERT_TO_VENDOR(id),
      {},
      {
        validateUser: true,
      }
    );

    if (res.success) {
    }

    return res;
  };
}

export function getOpportunities({ selectedPage, selectedPageSize }) {
  return async function (dispatch) {
    const res = await getApi(
      APIs.OPPORTUNITY.GET_OPPORTUNITIES_LIST({
        selectedPage,
        selectedPageSize,
      }),
      {
        validateUser: true,
        loadingFn: setOpportunityListLoading,
      }
    );

    if (res.success) {
      dispatch(
        setOpportunityList({
          data: res.data,
          total: res.total,
        })
      );

      dispatch(
        setOpportunityListFilters({
          selectedPage,
          selectedPageSize,
        })
      );
    }
    return res;
  };
}

export function getOpportunityResponse({
  selectedPage,
  selectedPageSize,
  selectedFilters,
}) {
  return async function (dispatch) {
    const payload = {
      ...(selectedFilters?.companyName && {
        companyName: selectedFilters?.companyName,
      }),
      ...(selectedFilters?.industryCategory?.length && {
        industryCategory: selectedFilters?.industryCategory,
      }),
      ...(selectedFilters?.lookingFor?.length && {
        lookingFor: selectedFilters?.lookingFor,
      }),
    };

    const res = await postApi(
      APIs.OPPORTUNITY.GET_OPPORTUNITY_RESPONSE_LIST({
        selectedPage,
        selectedPageSize,
      }),
      payload,
      {
        validateUser: true,
        loadingFn: setOpportunityResponseListLoading,
      }
    );

    if (res.success) {
      dispatch(
        setOpportunityResponseList({
          data: res.data,
          total: res.total,
        })
      );

      dispatch(
        setOpportunityResponseListFilters({
          selectedPage,
          selectedPageSize,
          selectedFilters,
        })
      );
    }
    return res;
  };
}
