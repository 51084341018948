import {
  TextField,
  FormControl,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete, Typography } from "@mui/material";
import { useState } from "react";
import { ENUM_AUTOSELECT_LIST_TYPES } from "../../../enums";

export const useStyles = makeStyles((theme) => ({
  error: {
    color: "#f44336",
  },
}));

function AutoSelectbox({
  list,
  fullWidth = true,
  label,
  field,
  onChange,
  listProperties,
  id,
  error,
  value,
  placeholder = "",
  inlineLabel = false,
  labelStyle = {},
  textfieldStyle = {},
  listType = ENUM_AUTOSELECT_LIST_TYPES.ARRAY,
  requireOnChange = false,
  selectedItemList = [],
}: any) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");

  return (
    <FormControl fullWidth={fullWidth}>
      {!inlineLabel && (
        <Typography fontSize={14} fontWeight={400} style={{ ...labelStyle }}>
          {label}
        </Typography>
      )}
      <Autocomplete
        id={id}
        value={value}
        getOptionDisabled={(option) => {
          return selectedItemList.find(
            (sl) => sl[listProperties["field"]] === option
          )
            ? true
            : false;
        }}
        options={
          list?.map((item) => {
            return listType === ENUM_AUTOSELECT_LIST_TYPES.ARRAY_OF_OBJECT
              ? item
              : listProperties
              ? item[listProperties.field]
              : item;
          }) || []
        }
        getOptionLabel={(option) => option?.[listProperties["field"]] || option}
        onChange={(e, value) => {
          onChange({
            target: {
              name: field,
              value,
            },
            isSelectClick: true,
          });
        }}
        freeSolo
        renderInput={(params) => {
          const inputProps: any = params.inputProps;
          inputProps.autoComplete = "off";
          inputProps.style = { ...textfieldStyle };
          return (
            <TextField
              error={error ? true : false}
              {...params}
              inputProps={inputProps}
              id={field}
              variant="outlined"
              placeholder={inlineLabel ? label : placeholder}
              name={field}
              onChange={(e) => {
                setSearchValue(e.target.value);
                requireOnChange && onChange(e);
              }}
              label={inlineLabel && label}
            />
          );
        }}
      />
      <FormHelperText className={classes.error}>{error}</FormHelperText>
    </FormControl>
  );
}

export { AutoSelectbox };
