import {
  initalClientListState,
  initialClientInfoState,
  SET_CLIENTS_LIST,
  SET_SELECTED_CLIENT,
  SET_CLIENT_LIST_FILTERS,
  RESET_CLIENTS_STORE,
  SET_CLIENT_MEETINGS_LIST,
  SET_CLIENT_MEETINGS_FILTERS,
  SET_CLIENT_PAYMENTS_LIST,
  SET_CLIENT_PAYMENTS_FILTERS,
  RESET_CLIENT_INFO,
  SET_CLIENTS_LIST_LOADING,
  SET_CLIENT_MEETINGS_LIST_LOADING,
  SET_CLIENT_PAYMENTS_LIST_LOADING,
  SET_CLIENT_INVITATION_STATUS_LIST_LOADING,
  SET_CLIENT_INVITATION_STATUS_LIST,
  SET_CLIENT_INVITATION_STATUS_FILTERS,
  SET_CLIENT_CAMPAIGNS_LIST_LOADING,
  SET_CLIENT_CAMPAIGNS_LIST,
} from "./actions";

import * as _ from "lodash";

const initialClients = {
  list: { ...initalClientListState },
  info: { ...initialClientInfoState },
  selectedClient: undefined,
};

const clients = (state = initialClients, action) => {
  switch (action.type) {
    case SET_CLIENTS_LIST_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: action.payload,
        },
      };
    case SET_CLIENTS_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          total: action.payload.total,
        },
      };
    case SET_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.payload,
      };
    case SET_CLIENT_LIST_FILTERS:
      return {
        ...state,
        list: {
          ...state.list,
          filters: {
            ...state.list.filters,
            ...action.payload,
          },
        },
      };
    case SET_CLIENT_MEETINGS_LIST_LOADING:
      return {
        ...state,
        info: {
          ...state.info,
          meetings: {
            ...state.info.meetings,
            loading: action.payload,
          },
        },
      };
    case SET_CLIENT_MEETINGS_LIST:
      return {
        ...state,
        info: {
          ...state.info,
          meetings: {
            ...state.info.meetings,
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };
    case SET_CLIENT_MEETINGS_FILTERS:
      return {
        ...state,
        info: {
          ...state.info,
          meetings: {
            ...state.info.meetings,
            filters: {
              ...state.info.meetings.filters,
              ...action.payload,
            },
          },
        },
      };
    case SET_CLIENT_INVITATION_STATUS_LIST_LOADING:
      return {
        ...state,
        info: {
          ...state.info,
          invitationStatus: {
            ...state.info.invitationStatus,
            loading: action.payload,
          },
        },
      };
    case SET_CLIENT_INVITATION_STATUS_LIST:
      return {
        ...state,
        info: {
          ...state.info,
          invitationStatus: {
            ...state.info.invitationStatus,
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };
    case SET_CLIENT_INVITATION_STATUS_FILTERS:
      return {
        ...state,
        info: {
          ...state.info,
          invitationStatus: {
            ...state.info.invitationStatus,
            filters: {
              ...state.info.invitationStatus.filters,
              ...action.payload,
            },
          },
        },
      };
    case SET_CLIENT_PAYMENTS_LIST_LOADING:
      return {
        ...state,
        info: {
          ...state.info,
          payments: {
            ...state.info.payments,
            loading: action.payload,
          },
        },
      };
    case SET_CLIENT_PAYMENTS_LIST:
      return {
        ...state,
        info: {
          ...state.info,
          payments: {
            ...state.info.payments,
            ...action.payload
          },
        },
      };
    case SET_CLIENT_CAMPAIGNS_LIST_LOADING:
      return {
        ...state,
        info: {
          ...state.info,
          campaigns: {
            ...state.info.campaigns,
            loading: action.payload,
          },
        },
      };
    case SET_CLIENT_CAMPAIGNS_LIST:
      return {
        ...state,
        info: {
          ...state.info,
          campaigns: {
            ...state.info.campaigns,
            ...action.payload
          },
        },
      };
    case SET_CLIENT_PAYMENTS_FILTERS:
      return {
        ...state,
        info: {
          ...state.info,
          payments: {
            ...state.info.payments,
            filters: {
              ...state.info.payments.filters,
              ...action.payload,
            },
          },
        },
      };
    case RESET_CLIENT_INFO:
      return {
        ...state,
        info: { ...initialClientInfoState },
      };
    case RESET_CLIENTS_STORE:
      return _.cloneDeep(initialClients);
    default:
      return state;
  }
};

export default clients;
