import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { AmbassadorWiseSlotDetailsListTable } from "./ambassador-wise-slot-details-table";
import { AMBASSADOR_INVITATION_STATUS_LIST_COLUMNS } from "./table-config";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { convertToFullName } from "../../../../services/utils";
import { LabelAndValue } from "../../../../components/typography";
import {
  CONST_PROJECT_ARCHIVE_REASON,
  CONST_PROJECT_STATUS_LIST,
} from "../../../../constants/lists";
import { useState } from "react";
import clientHOC from "../../client-hoc";
import FastForwardIcon from "@mui/icons-material/FastForward";
import { useDispatch, useSelector } from "react-redux";
import { submitArchiveMeeting } from "../../../../apis/invitations.api";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import {
  ENUM_PROJECT_ARCHIVE_REASON,
  ENUM_PROJECT_STATUS,
} from "../../../../enums";
import { AppAlertBox } from "../../../../components/modals/alert-box";
import { GLOBAL_TEXT } from "../../../../constants";
import AmbassadorAnswersModal from "./ambassador-answers";
import InvitationSlotsModal from "./invitation-slots";
import { AppModalDialogBox } from "../../../../components/modals";
import TransferRoleModal from "./transfer-role-modal";
import useToggle from "../../../../hooks/useToggle";

function ClientInvitationStatusListExpandedRowData({
  data,
  clientDetails,
  getClientInvitationStatusList,
}: any) {
  const { selectedPage, selectedPageSize, selectedDate } = useSelector(
    (state: any) => state.clients.info.invitationStatus.filters
  );

  const handleArchieveSuccess = () => {
    getClientInvitationStatusList({
      selectedPage,
      selectedPageSize,
      selectedDate: selectedDate || "",
    });
  };

  return (
    <Grid container justifyContent={"center"} spacing={2} sx={{ mt: 2, mb: 2 }}>
      <Grid item xs={9}>
        {data?.ambassadors?.map((ambassador, slotListIndex) => (
          <Accordion
            defaultExpanded={slotListIndex === 0}
            key={`accordion-${slotListIndex}`}
            sx={{ mt: 1 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                background: getAccordionBgColor(ambassador),
                color: "white",
                "&.Mui-expanded": {
                  minHeight: 45,
                  maxHeight: 45,
                },
              }}
            >
              <Typography>
                {convertToFullName(ambassador?.firstName, ambassador?.lastName)}
              </Typography>
              {data.isDummy && (
                <Box display={"flex"} ml={1} alignItems={"center"}>
                  (Manual meeting <FastForwardIcon />)
                </Box>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                mb={1}
              >
                {ambassador?.invitationStatus ===
                  ENUM_PROJECT_STATUS.APPROVED_BY_CLIENT && (
                  <DelegateSchedulingBtn
                    ambassadorInvitationId={ambassador?.ambassadorInvitationId}
                  />
                )}
                {ambassador?.invitationStatus !==
                  ENUM_PROJECT_STATUS.ARCHIVE && (
                  <InvitationArchieveButton
                    data={ambassador}
                    onArchieveSuccess={handleArchieveSuccess}
                  />
                )}
              </Box>

              <AmbassadorWiseSlotDetailsListTable
                key={`slot-details-${ambassador?.id}-${slotListIndex}`}
                rows={ambassador?.history}
                columns={AMBASSADOR_INVITATION_STATUS_LIST_COLUMNS}
              />
              <Grid container alignItems={"center"} mt={1}>
                <Grid item xs={12} sm={4} display={"flex"} gap={1}>
                  {ambassador.rejectMessage && (
                    <RejectMessage message={ambassador.rejectMessage} />
                  )}
                  {ambassador.invitationStatus ===
                    ENUM_PROJECT_STATUS.ARCHIVE && ambassador?.secondaryStatus && (
                    <RejectMessage
                      message={`${
                        CONST_PROJECT_ARCHIVE_REASON[
                          ENUM_PROJECT_ARCHIVE_REASON[
                            ambassador?.secondaryStatus?.toUpperCase()
                          ]
                        ]
                      }`}
                      label={"Archived"}
                    />
                  )}
                </Grid>

                <Grid item xs={12} sm={4} textAlign={"right"}>
                  {((ambassador?.isCalendly &&
                    CONST_PROJECT_STATUS_LIST[ambassador?.invitationStatus]
                      ?.step >= 3) ||
                    ambassador?.availableTimeSlots?.length > 0) && (
                    <ShowSlotsModalButton data={ambassador} />
                  )}
                </Grid>

                <Grid item xs={12} sm={4} textAlign={"right"}>
                  {ambassador?.questions && (
                    <ShowAnswersModalButton
                      questions={ambassador?.questions}
                      clientDetails={clientDetails}
                    />
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
}

function getAccordionBgColor(ambassador) {
  const status =
    ambassador?.history?.[ambassador?.history?.length - 1]?.invitationStatus;

  return CONST_PROJECT_STATUS_LIST[status]?.bgColor;
}

function ShowAnswersModalButton({ questions, clientDetails }) {
  const [showAnswersModal, setShowAnswersModal] = useState(false);

  const handleShowAnswerClick = () => {
    setShowAnswersModal(!showAnswersModal);
  };

  return (
    <>
      <Typography
        color={"primary"}
        fontWeight={600}
        fontSize={14}
        sx={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={handleShowAnswerClick}
      >
        Show Answers
      </Typography>
      {showAnswersModal && (
        <AmbassadorAnswersModal
          questions={questions}
          clientDetails={clientDetails}
          onClose={handleShowAnswerClick}
        />
      )}
    </>
  );
}

function ShowSlotsModalButton({ data }) {
  const [showSlotaModal, setShowSlotsModal] = useState(false);

  const handleShowSlotsClick = () => {
    setShowSlotsModal(!showSlotaModal);
  };

  return (
    <>
      <Typography
        color={"primary"}
        fontWeight={600}
        component={"span"}
        fontSize={14}
        sx={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={handleShowSlotsClick}
      >
        View slots
      </Typography>
      {showSlotaModal && (
        <InvitationSlotsModal onClose={handleShowSlotsClick} data={data} />
      )}
    </>
  );
}

function InvitationArchieveButton({ data, onArchieveSuccess }) {
  const dispatch = useDispatch();
  const [showArhieveConfirmModal, setShowArhieveConfirmModal] = useState(false);
  const [archiveReason, setArchiveReason] = useState("cancelled");

  const handleArchiveMeeting = async () => {
    const response: any = await dispatch(
      submitArchiveMeeting({
        id: data?.ambassadorInvitationId,
        action: archiveReason,
      })
    );

    if (response?.success) {
      onArchieveSuccess();
    }
  };

  const handleArchiveMeetingClick = () => {
    setShowArhieveConfirmModal(true);
  };

  const handleArchiveReasonChange = (event) => {
    setArchiveReason(event.target.value);
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-end"}
      mb={1}
    >
      <ArchiveOutlinedIcon color="error" fontSize="inherit" />
      <Typography
        color={"error"}
        fontWeight={600}
        fontSize={14}
        sx={{
          cursor: "pointer",
        }}
        onClick={handleArchiveMeetingClick}
      >
        Archive
      </Typography>
      {showArhieveConfirmModal && (
        <AppAlertBox
          isModalOpen={showArhieveConfirmModal}
          onConfirm={handleArchiveMeeting}
          onCancel={() => {
            setShowArhieveConfirmModal(false);
          }}
          message={
            <Box display={"flex"}>
              <FormControl>
                <FormLabel id="archive-radio-buttons-group-label">
                  Choose Reason
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="archive-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={archiveReason}
                  onChange={handleArchiveReasonChange}
                >
                  <FormControlLabel
                    value="cancelled"
                    control={<Radio />}
                    label="Cancelled"
                  />
                  <FormControlLabel
                    value="re_schedule"
                    control={<Radio />}
                    label="Rescheduled"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          }
        />
      )}
    </Box>
  );
}

function RejectMessage({ message, label = "Message" }) {
  return (
    <LabelAndValue
      label={label}
      labelxs={12}
      labelsm={12}
      labelmd={12}
      value={
        <Typography variant="caption" fontWeight={600} color={"error"}>
          {message}
        </Typography>
      }
      sx={{ mb: 2, mt: 2 }}
    />
  );
}

function DelegateSchedulingBtn({ ambassadorInvitationId }) {
  const [showAddMemberDilogbox, setShowAddMemberDilogbox] = useToggle(false);

  const handleTransferRoleClose = () => {
    setShowAddMemberDilogbox();
  };

  const handleTransferRoleClick = () => {
    setShowAddMemberDilogbox();
  };

  return (
    <>
      <Button
        onClick={handleTransferRoleClick}
        size="small"
        aria-describedby={ambassadorInvitationId}
        sx={{
          background: "#B031E3",
          color: "white",
          ":hover": { opacity: ".9", background: "#B031E3" },
        }}
      >
        Delegate Scheduling
      </Button>

      {showAddMemberDilogbox && (
        <AppModalDialogBox
          title={"Add Member"}
          onClose={handleTransferRoleClose}
          isModalOpen={showAddMemberDilogbox}
        >
          <TransferRoleModal
            onClose={handleTransferRoleClose}
            id={ambassadorInvitationId}
          />
        </AppModalDialogBox>
      )}
    </>
  );
}

export default clientHOC(ClientInvitationStatusListExpandedRowData);
