import { Card, CardContent, Grid, Typography } from "@mui/material";

const SUMMARY = [
  { label: "Completed Projects", field: "doneMeetings", btColor: "#41C197" },
  { label: "Continuous Project", field: "upComingMeetings", btColor: "#F65C8D" },
  { label: "Remaining Meetings", field: "pendingMeetings", btColor: "#F8D58B" },
];

function SummaryItem({ item, data }) {
  return (
    <Grid item xs={12} md={4}>
      <Card
        elevation={5}
        sx={{
          borderTop: `7px solid ${item.btColor}`,
          height: 134,
          borderRadius: 0,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            height: 134 - 7,
          }}
        >
          <Typography fontWeight={500} fontSize={14}>
            {item.label}
          </Typography>
          <Typography
            fontWeight={500}
            fontSize={24}
            sx={{ color: item.btColor }}
            variant="caption"
          >
            {data[item.field]}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

function ClientMeetingSummary({ data }: any) {
  return (
    <>
      {SUMMARY.map((item, index) => (
        <SummaryItem key={index} item={item} data={data} />
      ))}
    </>
  );
}

export { ClientMeetingSummary };
