import {
  initialAmbassadorInfoState,
  initialAmbassadorListState,
  SET_AMBASSADORS_LIST,
  SET_SELECTED_AMBASSADOR,
  SET_AMBASSADOR_LIST_FILTERS,
  RESET_AMBASSADORS_STORE,
  SET_AMBASSADOR_MEETINGS_LIST,
  SET_AMBASSADOR_MEETINGS_FILTERS,
  SET_AMBASSADOR_PAYMENTS_LIST,
  SET_AMBASSADOR_PAYMENTS_FILTERS,
  RESET_AMBASSADOR_INFO,
  SET_AMBASSADOR_LIST_LOADING,
  SET_AMBASSADOR_PAYMENTS_LIST_LOADING,
  SET_AMBASSADOR_MEETINGS_LIST_LOADING,
} from "./actions";

import * as _ from "lodash";

const initialAmbassadors = {
  list: { ...initialAmbassadorListState },
  selectedAmbassador: undefined,
  info: { ...initialAmbassadorInfoState },
};

const ambassadors = (state = initialAmbassadors, action) => {
  switch (action.type) {
    case SET_AMBASSADOR_LIST_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: action.payload,
        },
      };
    case SET_AMBASSADORS_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          total: action.payload.total,
        },
      };
    case SET_SELECTED_AMBASSADOR:
      return {
        ...state,
        selectedAmbassador: action.payload,
      };
    case SET_AMBASSADOR_LIST_FILTERS:
      return {
        ...state,
        list: {
          ...state.list,
          filters: {
            ...state.list.filters,
            ...action.payload,
          },
        },
      };
    case SET_AMBASSADOR_MEETINGS_LIST_LOADING:
      return {
        ...state,
        info: {
          ...state.info,
          meetings: {
            ...state.info.meetings,
            loading: action.payload,
          },
        },
      };
    case SET_AMBASSADOR_MEETINGS_LIST:
      return {
        ...state,
        info: {
          ...state.info,
          meetings: {
            ...state.info.meetings,
            data: action.payload.data,
            total: action.payload.total,
          },
        },
      };
    case SET_AMBASSADOR_MEETINGS_FILTERS:
      return {
        ...state,
        info: {
          ...state.info,
          meetings: {
            ...state.info.meetings,
            filters: {
              ...state.info.meetings.filters,
              ...action.payload,
            },
          },
        },
      };
    case SET_AMBASSADOR_PAYMENTS_LIST_LOADING:
      return {
        ...state,
        info: {
          ...state.info,
          payments: {
            ...state.info.payments,
            loading: action.payload,
          },
        },
      };
    case SET_AMBASSADOR_PAYMENTS_LIST:
      return {
        ...state,
        info: {
          ...state.info,
          payments: {
            ...state.info.payments,
            ...action.payload
          },
        },
      };
    case SET_AMBASSADOR_PAYMENTS_FILTERS:
      return {
        ...state,
        info: {
          ...state.info,
          payments: {
            ...state.info.payments,
            filters: {
              ...state.info.payments.filters,
              ...action.payload,
            },
          },
        },
      };
    case RESET_AMBASSADOR_INFO:
      return {
        ...state,
        info: { ...initialAmbassadorInfoState },
      };
    case RESET_AMBASSADORS_STORE:
      return { ...initialAmbassadors };
    default:
      return state;
  }
};

export default ambassadors;
