import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import MultipleSelect from "../../../components/mui/select/multi-select";
import { StackOfChips } from "../../../components/mui/stack";
import ambassadorHOC from "../ambassador-hoc";
import { AutoSelectbox } from "../../../components/selectbox/autoselect";
import { ENUM_AUTOSELECT_LIST_TYPES } from "../../../enums";
import useEffectAfterMount from "../../../hooks/useEffectAfterMount";

function AmbassadorFilterOptions({
  simpleList = false,
  getAmbassadorList,
  getAmbassadorSimpleList,
  isEstEmployee = true,
}) {
  const lists = useSelector((state: any) => state.common);
  const { selectedPageSize, searchValue, selectedPage, selectedFilters } =
    useSelector((state: any) => state.ambassadors.list.filters);
  const [selectedValues, setSelectedValues]: any = useState({
    industries: [],
    titles: [],
    number_of_employees: [],
    region: [],
    intrestedInPriority: [],
    topics: [],
  });

  useEffectAfterMount(() => {
    getAmbassadorsByFilter();
  }, [selectedValues]);

  const getAmbassadorsByFilter = async () => {
    const payload = {
      ...(selectedValues.titles?.length && { titles: selectedValues.titles }),
      ...(selectedValues.industries?.length && {
        industries: selectedValues.industries,
      }),
      ...(selectedValues.number_of_employees?.length && {
        number_of_employees: selectedValues.number_of_employees,
      }),
      ...(selectedValues.region?.id && {
        region: [selectedValues.region?.id],
      }),
      ...(selectedValues.topics?.length &&
        selectedValues.intrestedInPriority?.value && {
          topics: {
            labels: lists?.topics?.data
              ?.filter((l) => selectedValues.topics?.includes(l?._id))
              ?.map((l) => l?.label),
            priority: selectedValues.intrestedInPriority?.value || "",
          },
        }),
    };

    simpleList
      ? getAmbassadorSimpleList({
          selectedFilters: payload,
          selectedPageSize,
          searchValue,
          selectedPage,
        })
      : getAmbassadorList({
          selectedFilters: payload,
          selectedPageSize,
          searchValue,
          selectedPage,
        });
  };

  const handleFilterChange = (e) => {
    setSelectedValues({
      ...selectedValues,
      [e?.target?.name]: e?.target?.value,
    });
  };

  const getStackChips = () => {
    const formatedChipLabel = (t, v) => (
      <>
        <Typography variant="caption" fontWeight={600}>
          {t}:
        </Typography>
        <Typography variant="caption">{`${v}`}</Typography>
      </>
    );

    return [
      ...(lists?.title?.data
        ?.filter((v) => selectedValues?.titles?.includes(v?._id))
        ?.map((v) => {
          return {
            ...v,
            label: formatedChipLabel("Title", v?.label),
            field: "titles",
          };
        }) || []),
      ...(lists?.industry?.data
        ?.filter((v) => selectedValues?.industries?.includes(v?._id))
        ?.map((v) => {
          return {
            ...v,
            label: formatedChipLabel("Industry", v?.label),
            field: "industries",
          };
        }) || []),
      ...(lists?.noOfEmp?.data
        ?.filter((v) => selectedValues?.number_of_employees?.includes(v?._id))
        ?.map((v) => {
          return {
            ...v,
            label: formatedChipLabel("No.#Emp", v?.label),
            field: "number_of_employees",
          };
        }) || []),
      ...(lists?.topics?.data
        ?.filter((v) => selectedValues?.topics?.includes(v?._id))
        ?.map((v) => {
          return {
            ...v,
            label: formatedChipLabel("Topic", v?.label),
            field: "topics",
          };
        }) || []),
    ];
  };

  const handleOnItemRemove = (field, value) => {
    const tmpList = selectedValues[field];

    setSelectedValues({
      ...selectedValues,
      [field]: tmpList.filter((item) => item !== value),
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid sx={{ mt: 1 }} container item xs={12} md={12}>
        <StackOfChips
          data={getStackChips()}
          onRemove={handleOnItemRemove}
          labelField="label"
          valueField="_id"
        ></StackOfChips>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12} md={3} lg={3} sm={12}>
          <MultipleSelect
            label="Title"
            options={lists?.title?.data || []}
            selectedValues={selectedValues?.titles}
            onValueChange={handleFilterChange}
            valueField="_id"
            labelField="label"
            name="titles"
          ></MultipleSelect>
        </Grid>
        {isEstEmployee && (
          <Grid item xs={12} md={3} lg={3} sm={12}>
            <MultipleSelect
              label="Est. # of employees"
              options={lists?.noOfEmp?.data || []}
              selectedValues={selectedValues?.number_of_employees}
              onValueChange={handleFilterChange}
              valueField="_id"
              labelField="label"
              name="number_of_employees"
            ></MultipleSelect>
          </Grid>
        )}
        <Grid item xs={12} md={3} lg={3} sm={12}>
          <MultipleSelect
            label="Industry"
            options={lists?.industry?.data || []}
            selectedValues={selectedValues?.industries}
            onValueChange={handleFilterChange}
            valueField="_id"
            labelField="label"
            name="industries"
          ></MultipleSelect>
        </Grid>
        <Grid item xs={12} md={3} lg={3} sm={12}>
          <AutoSelectbox
            field={"region"}
            id={"region"}
            inlineLabel={true}
            label="Category"
            textfieldStyle={{ height: 24 }}
            list={lists?.region?.data || []}
            onChange={handleFilterChange}
            value={selectedValues?.region}
            listProperties={{
              field: "id",
              value: "value",
            }}
            listType={ENUM_AUTOSELECT_LIST_TYPES.ARRAY_OF_OBJECT}
            requireOnChange={true}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3} sm={12}>
          <MultipleSelect
            label="Topics"
            options={lists?.topics?.data || []}
            selectedValues={selectedValues?.topics}
            onValueChange={handleFilterChange}
            valueField="_id"
            labelField="label"
            name="topics"
          ></MultipleSelect>
        </Grid>
        {selectedValues?.topics?.length > 0 && (
          <Grid item xs={12} md={3} lg={3} sm={12}>
            <AutoSelectbox
              field={"intrestedInPriority"}
              id={"intrestedInPriority"}
              inlineLabel={true}
              label="Priority"
              textfieldStyle={{ height: 24 }}
              list={lists?.intrestedInPriority?.data || []}
              onChange={handleFilterChange}
              value={selectedValues?.intrestedInPriority}
              listProperties={{
                field: "label",
                value: "value",
              }}
              listType={ENUM_AUTOSELECT_LIST_TYPES.ARRAY_OF_OBJECT}
              requireOnChange={true}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default ambassadorHOC(AmbassadorFilterOptions);
