import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 25,
  },
  clientSelectBox: {
    height: 50,
  },
  table: {
    minWidth: 650,
  },
  header: {
    backgroundColor: "#FAFAFA",
  },
  headerContent: {
    color: "#4D4D4D",
    fontSize: 14,
    fontWeight: "bold",
  },
  approveButton: {
    height: 35,
    padding: 5,
    width: 100,
    background: "#1b5e20",
    color: "white",
    marginBottom: theme.spacing(2),
  },
  rejectButton: {
    height: 35,
    width: 100,
    background: "#d32f2f",
    color: "white",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: theme.spacing(3),
  },
  actionButton: {
    height: 36,
    width: 180,
  },
  searchboxClass: {
    width: "376px",
    marginRight: 10,
  },
  datepickerClass: {
    width: "238px !important",
    height: "56px !important",
    fontFamily: "Roboto !important",
    fontSize: "16px !important",
  },
}));
