import { useEffect } from "react";
import { useSelector } from "react-redux";
import { CLIENT_PAYMENTS_COLUMNS } from "./table-config";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import clientHOC from "../../client-hoc";
import { ClientPaymentListTable } from "./table";
import { ClientPaymentSummary } from "./summary";

function ClientPayments({
  getClientPaymentsList,
  selectedClient,
  clientPaymentsList,
}: any) {
  const { selectedPage, selectedPageSize } = useSelector(
    (state: any) => state.clients.info.payments.filters
  );

  useEffect(() => {
    getClientPaymentsList({ selectedPage, selectedPageSize });
  }, []);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getClientPaymentsList({
      selectedPage,
      selectedPageSize,
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
      <Grid container spacing={2} direction="column">
        <Grid
          item
          xs={3}
          spacing={2}
          container
          justifyContent={"space-between"}
        >
          <ClientPaymentSummary data={clientPaymentsList} />
        </Grid>

        <Grid item xs={12}>
          <ClientPaymentListTable
            list={clientPaymentsList}
            columns={CLIENT_PAYMENTS_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              selectedPage,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default clientHOC(ClientPayments);
