import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";

export default function PreQualifyQuestions({ questions }) {
  return (
    <Grid container sx={{ mt: 2, p: 2 }} component={Paper}>
      <Typography>Questions</Typography>
      <Divider sx={{ width: "100%", my: 2 }} />
      {questions ? (
        Object.keys(questions)?.map((que, index) => (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Box key={`questions-${index}`}>
                  <Typography
                    color="#6B778C"
                    component={Box}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                    fontSize={13}
                  >
                    <QuizIcon fontSize="small" />
                    {questions?.[que]?.question}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize={12} component={"span"}>
                  {"Type: "}
                </Typography>
                <Typography fontSize={12} component={"span"}>
                  {questions?.[que]?.type}
                </Typography>
              </Grid>
              {questions?.[que]?.type === "MultiSelect" && (
                <Grid item xs={12}>
                  <Typography fontSize={12} component={"span"}>
                    {"Options: "}
                  </Typography>
                  <Typography fontSize={12} component={"span"}>
                    {questions?.[que]?.options?.join(", ")}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Divider sx={{ width: "100%", my: 2 }} />
          </>
        ))
      ) : (
        <Typography fontSize={12} color={"error.main"}>
          Qualify Questions are not available
        </Typography>
      )}
    </Grid>
  );
}
