import { ROUTE_PATHS } from "../../../routes/route-paths";
import SvgIcons from "../../../svgs";

const BREADCRUMB_ICON_NAME = SvgIcons.Client;

const APP_BREADCRUMB = {
  [ROUTE_PATHS.CONNECT_REQUESTS]: {
    isRoot: true,
    paths: [{ label: "Connect", icon: BREADCRUMB_ICON_NAME }],
    title: "Connect",
  },
  [ROUTE_PATHS.OPPORTUNITY]: {
    isRoot: true,
    paths: [{ label: "Opportunity", icon: SvgIcons.Opportunity }],
    title: "Opportunity",
  },
};

export { APP_BREADCRUMB };