enum ENUM_MEETING_STATUS {
  APPROVED = "approved",
  PENDING = "pending",
  REJECTED = "rejected",
  DONE = "done",
}

enum ENUM_PAYMENT_STATUS {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

enum ENUM_USER_ROLES {
  ADMIN = "admin",
  SUPER_ADMIN = "super-admin",
  USER = "user",
}

enum ENUM_PLAN_TYPES {
  INVITE_TO_HOST = "invite_to_host" as any,
  STRATEGY_CALL = "strategy_call" as any,
  PACKAGE = "package" as any,
  ROUND_TABLE_PACKAGE_4 = "round_table_package_4" as any,
  ROUND_TABLE_PACKAGE_12 = "round_table_package_12" as any,
  ONE_TO_ONE_CALL = "one_to_one_call" as any,
}

enum ENUM_FORM_INPUT_TYPES {
  TEXTFIELD = "textfield",
  SELECTBOX = "selectbox",
  MULTI_SELECTBOX = "multi_selectbox",
  CHIP_TEXT_FIELD = "chip_text_field",
  AUTO_SELECTBOX = "auto_selectbox",
  MULTY_EMAIL_INPUT = "multy_email_input",
  CHECKBOX = "CHECKBOX",
}

enum RENDERER_TYPES {
  FULL_NAME = "FULL_NAME",
  FULL_NAME_WITH_AVATAR = "FULL_NAME_WITH_AVATAR",
  CLICKABLE_FULL_NAME_WITH_AVATAR = "CLICKABLE_FULL_NAME_WITH_AVATAR",
  AMOUNT = "AMOUNT",
  ACTION = "ACTION",
  NUMBER = "NUMBER",
  MULTIPLE_TEXT_RENDERER = "MULTIPLE_TEXT_RENDERER",
  FULL_DATE = "FULL_DATE",
  MEETING_STATUS = "MEETING_STATUS",
  PAYMENT_STATUS = "PAYMENT_STATUS",
  INVITATION_STATUS = "INVITATION_STATUS",
  CITY_STATE = "CITY_STATE",
  CHIP_LIST = "CHIP_LIST",
  DATE_RANGE = "DATE_RANGE",
  FULL_DATE_FROM = "FULL_DATE_FROM",
  INVESTMENT_BUDGET = "INVESTMENT_BUDGET",
  TIMEZONE = "TIMEZONE",
  NEW_TAB_ROUTE_LINK = "NEW_TAB_ROUTE_LINK",
  LINKEDIN_URL_WITH_ICON = "LINKEDIN_URL_WITH_ICON",
  PROJECT_NAME = "PROJECT_NAME",
}

enum ENUM_LINKEDIN_LOGIN_USER_TYPES {
  USER = "USER",
  HOST = "HOST",
}

enum ENUM_SIGNUP_TYPE {
  SIGNUP = "signup",
  BECOME_HOST = "becomehost",
}

enum ENUM_VALIDATION_TYPES {
  NAME = "name",
  EMAIL = "email",
  CORPORATE_EMAIL = "corporate_email",
  URL = "url",
  PHONE = "phone",
  POSITIVE_INTEGER_NUMBER = "positive_integer_number",
  NUMBER_WITH_DOT = "number_with_dot",
  PASSWORD = "password",
  CONFIRM_PASSWORD = "confirm_password",
  USD_CURRENCY = "usd_currency",
}

enum ENUM_SUCCESS_MESSAGE_TEMPLATE {
  REQUEST_SENT_SUCCESS = "REQUEST_SENT_SUCCESS",
  PAYMENT_SUCCESS_MESSAGE = "PAYMENT_SUCCESS_MESSAGE",
  BECOME_A_HOST_SUCCESS = "BECOME_A_HOST_SUCCESS",
}

enum ENUM_PRICING_STRUCTURE {
  PER_SEAT = "per seat",
  PER_DATA = "per data",
}

enum ENUM_CURRENCY {
  USD = "USD",
}

enum ENUM_INVITATION_STATUS_HISTORY {
  DONE = "done",
  REJECTED = "rejected",
  CREATED = "created",
  ADDED_TIME_SLOTS_BY_CLIENT = "added_time_slots_by_client",
  AWAITING_FOR_TIME_SLOT = "awaiting_for_time_slot",
  SELECTED_TIME_SLOT_BY_AMBASSADOR = "selected_time_slot_by_ambassador",
  AWAITING_FOR_TIME_SLOT_SELECTION = "awaiting_for_time_slot_selection",
  SEND_MEETING_LINK = "send_meeting_link",
  UPDATE_INVITATION = "update_invitation",
}

enum ENUM_AUTOSELECT_LIST_TYPES {
  ARRAY = "ARRAY",
  ARRAY_OF_OBJECT = "ARRAY_OF_OBJECT",
}

enum ENUM_PROJECT_STATUS {
  DONE = "done",
  REJECTED = "rejected",
  REJECTED_BY_CLIENT = "rejected_by_client",
  REJECTED_BY_AMBASSADOR = "rejected_by_ambassador",
  CREATED = "created",
  REQUESTED = "requested",
  AWAITING_FOR_RESPONSE = "awaiting_for_response",
  RESPONDED = "responded",
  ADDED_TIME_SLOTS_BY_CLIENT = "added_time_slots_by_client",
  AWAITING_FOR_TIME_SLOT = "awaiting_for_time_slot",
  SELECTED_TIME_SLOT_BY_AMBASSADOR = "selected_time_slot_by_ambassador",
  AWAITING_FOR_TIME_SLOT_SELECTION = "awaiting_for_time_slot_selection",
  SEND_MEETING_LINK = "send_meeting_link",
  UPDATE_INVITATION = "update_invitation",
  DONE_BY_CLIENT = "done_by_client",
  DONE_BY_AMBASSADOR = "done_by_ambassador",
  CANCELED_BY_CLIENT = "canceled_by_client",
  CANCELED_BY_AMBASSADOR = "canceled_by_ambassador",
  APPROVED_BY_CLIENT = "approved_by_client",
  UPDATE_ATTENDEES_LIST_BY_AMBASSADOR = "update_attendees_list_by_ambassador",
  UPDATE_ATTENDEES_LIST_BY_CLIENT = "update_attendees_list_by_client",
  ARCHIVE = "archive",
}

enum ENUM_LOOKING_FOR {
  ONE2ONE_INTRODUCTORY_SALES_MEETINGS = "ONE2ONE_INTRODUCTORY_SALES_MEETINGS",
  ONE2ONE_ADVISORY = "ONE2ONE_ADVISORY",
  INVESTORS = "INVESTORS",
  ADVISORY_BOARD_MEMBERS = "ADVISORY_BOARD_MEMBERS",
}

enum ENUM_INTRESTED_IN_PRIORITY_LIST {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high"
}

enum ENUM_PROJECT_ARCHIVE_REASON {
  CANCELLED = "cancelled",
  RE_SCHEDULE = "re_schedule",
}

export {
  ENUM_MEETING_STATUS,
  ENUM_PAYMENT_STATUS,
  ENUM_USER_ROLES,
  ENUM_PLAN_TYPES,
  ENUM_FORM_INPUT_TYPES,
  RENDERER_TYPES,
  ENUM_LINKEDIN_LOGIN_USER_TYPES,
  ENUM_SIGNUP_TYPE,
  ENUM_VALIDATION_TYPES,
  ENUM_SUCCESS_MESSAGE_TEMPLATE,
  ENUM_CURRENCY,
  ENUM_INVITATION_STATUS_HISTORY,
  ENUM_AUTOSELECT_LIST_TYPES,
  ENUM_PROJECT_STATUS,
  ENUM_LOOKING_FOR,
  ENUM_INTRESTED_IN_PRIORITY_LIST,
  ENUM_PROJECT_ARCHIVE_REASON,
  ENUM_PRICING_STRUCTURE
};
