import Apis from ".";
import { setListData, setListLoading } from "../redux/modules/common";
import { getApi, patchtApi, postApi, putApi } from "../services/axios";

export function getMarketplaceVendorData(id) {
  return async function (dispatch) {
    const res = await getApi(
      Apis.MARKETPLACE.GET_MARKETPLACE_VENDOR_DATA_BY_ID(id)
    );

    if (res.success) {
    }

    return res;
  };
}

export function getMarketplaceCategories() {
  return async function (dispatch) {
    const res = await getApi(Apis.MARKETPLACE.GET_MARKETPLACE_CATEGORIES_LIST, {
      loadingFn: (loading) =>
        setListLoading({
          list: "mp_categories",
          loading,
        }),
    });

    if (res.success) {
      await dispatch(
        setListData({
          list: "mp_categories",
          data: res?.data,
        })
      );
    }

    return res;
  };
}

export function fetchKeywordList(searchValue="") {
  return async function (dispatch) {
    const res = await getApi(
      Apis.MARKETPLACE.SEARCH_KEYWORDS_LIST(searchValue)
    );

    if (res.success) {
    }

    return res;
  };
}

export function getMarketplace(payload = {}, params = {}) {
  return async function (dispatch) {
    const res = await postApi(
      Apis.MARKETPLACE.GET_MARKETPLACE_LIST(params),
      payload
    );

    if (res.success) {
    }

    return res;
  };
}

export function postShareProfile(payload = {}, id) {
  return async function (dispatch) {
    const res = await postApi(
      Apis.MARKETPLACE.SUBMIT_SHARE_PROFILE(id),
      payload
    );

    if (res.success) {
    }

    return res;
  };
}

export function submitMarketplaceForm(id, payload) {
  return async function (dispatch) {
    delete payload.email;

    if (id) {
      const res = await putApi(Apis.MARKETPLACE.UPDATE_FORM(id), payload);

      if (res.success) {
      }

      return res;
    } else {
      const res = await postApi(Apis.MARKETPLACE.SUBMIT_FORM, payload);

      if (res.success) {
      }

      return res;
    }
  };
}

export function uploadMarketplaceFile(id, field, payload) {
  return async function (dispatch) {
    const res = await patchtApi(
      Apis.MARKETPLACE.UPLOAD_MARKETPLACE_FILE(field, id),
      payload,
      {
        notifyError: true,
        notifySuccess: false,
        validateUser: true,
      }
    );

    if (res.success) {
    }
    return res;
  };
}
