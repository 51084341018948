import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { useRef, useState } from "react";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";
import ImageCropper from "./image-cropper";
import { readFile } from "./canvas-utils";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import useToggle from "../../../hooks/useToggle";

export function PhotoFileUpload({
  preview,
  aspect,
  cropShape = "rect",
  onUpload,
  filename,
  component = null,
  accept="image/*"
}) {
  const picRef: any = useRef();
  const [showCropImageModal, setShowCropImageModal] = useToggle(false);
  const [fileUpload, setFileUpload]: any = useState({
    file: "",
    imageDataUrl: "",
  });

  const handleUplaod = async (e) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    let imageDataUrl = await readFile(file);

    setFileUpload({ file, imageDataUrl });

    e.target.value = null;

    setShowCropImageModal();
  };

  const handleSubmit = (file) => {
    onUpload(file);
    setFileUpload({ file: "", imageDataUrl: "" });
    setShowCropImageModal();
  };

  return (
    <>
      <Box
        mb={2}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
      >
        <IconButton disableFocusRipple disableTouchRipple disableRipple onClick={() => picRef.current.click()} size="large">
          {!component && (
            <>
              <Box
                sx={{
                  transform: "translate(60px, -40px)",
                  zIndex: 1,
                  position:"absolute"
                }}
              >
                {preview && (
                  <Avatar sx={{ height: 35, width: 35 }}>
                    <ModeEditOutlineTwoToneIcon />
                  </Avatar>
                )}
              </Box>

              <Avatar sx={{ height: 129, width: 129 }} src={preview}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={1}
                  alignItems={"center"}
                  maxWidth={100}
                >
                  <CameraAltOutlinedIcon fontSize={"large"} />
                  <Typography fontSize={12} fontWeight={500}>
                    Upload Your Company Logo
                  </Typography>
                </Box>
              </Avatar>
            </>
          )}

          {component}

          <input ref={picRef} type="file" accept={accept} hidden onChange={handleUplaod} />
        </IconButton>
      </Box>
      {showCropImageModal && (
        <ImageCropper
          file={fileUpload}
          cropShape={cropShape}
          aspect={aspect}
          onSubmit={handleSubmit}
          onClose={setShowCropImageModal}
          filename={filename}
        />
      )}
    </>
  );
}
