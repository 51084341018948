import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonWithIcon } from "../../../components/buttons";
import { Box, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ambassadorHOC from "../ambassador-hoc";
import AmbassadorFilterOptions from "./filter-options";
import { AmbassadorListTable } from "./table";
import { AMBASSADOR_LIST_COLUMNS } from "./table-config";
import Searchbox from "../../../components/searchbox";

function AmbassadorsList({
  onAddAmbassadorClick,
  getAmbassadorList,
  ambassadorList,
  toggleMenuOptions,
}) {
  const { selectedPageSize, searchValue, selectedPage, selectedFilters } =
    useSelector((state: any) => state.ambassadors.list.filters);

  useEffect(() => {
    getAmbassadorList({
      selectedPage,
      selectedPageSize,
      searchValue,
      selectedFilters,
    });
  }, []);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getAmbassadorList({
      selectedPage,
      selectedPageSize,
      searchValue,
      selectedFilters,
    });
  };

  const handleSearchValueChange = (value = "") => {
    getAmbassadorList({
      searchValue: value,
      selectedPage: 1,
      selectedPageSize,
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <Searchbox
              onEnter={handleSearchValueChange}
              value={searchValue}
              onClear={() => handleSearchValueChange("")}
            />
          </Grid>
          <Grid item xs={6} container justifyContent={"flex-end"}>
            <ButtonWithIcon
              handleOnClick={onAddAmbassadorClick}
              text={"ADD"}
              icon={{ position: "startIcon", name: "add" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="column">
        <Grid item sx={{ maxWidth: "100% !important" }} xs={12}>
          <AmbassadorFilterOptions />
        </Grid>
        <Grid item xs={12}>
          <AmbassadorListTable
            list={ambassadorList}
            toggleMenuOptions={toggleMenuOptions}
            columns={AMBASSADOR_LIST_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              searchValue,
              selectedPage,
              selectedFilters,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ambassadorHOC(React.memo(AmbassadorsList));
