import { SquareChip } from "../../../../components/chips/square-chip";
import { CONST_PROJECT_STATUS_LIST } from "../../../../constants/lists";
import { getProjectStatus } from "../../../../services/utils";

function ProjectStatus({ data }) {
  const status = getProjectStatus(data);

  return (
      <SquareChip
        text={CONST_PROJECT_STATUS_LIST[status]?.label}
        color={"white"}
        bgColor={CONST_PROJECT_STATUS_LIST[status]?.bgColor}
        fontWeight = {"bolder"}
      />
  );
}

export { ProjectStatus };
