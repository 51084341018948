import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { SaveAndCancelButton } from "../../../components/buttons";
import { AppModalDialogBox } from "../../../components/modals";
import { EmailAddressBox } from "../../components/email-addressbox";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { LabelAndValue } from "../../../components/typography";
import { convertToFullName } from "../../../services/utils";
import { updateInvitation } from "../../../apis/invitations.api";
import { useDispatch } from "react-redux";

function AddMemberModal({ event, title, onClose }) {
  const { ambassador, client, datetime, invitationId, selectedTimeSlot , iId} = event;
  
  const [emailIds, setEmailds]: any = useState({
    list: [],
    invalidEmailIds: [],
  });
  const dispatch = useDispatch();

  const handleOnEmailAddressesChange = (payload) => {
    setEmailds(payload);
  };

  const onSubmit = async () => {
    if (!emailIds.invalidEmailIds.length) {
      const payload = {
        additionalMembers: emailIds.list.map((email) => {
          return { email };
        }),
        id: iId,
      };

      const response: any = await dispatch(updateInvitation(payload));

      if (response.success) {
        onClose();
      }
    }
  };

  return (
    <AppModalDialogBox
      isModalOpen={true}
      handleClose={onClose}
      title={"Add Members"}
      size={"sm"}
      actionButtonRenderer={
        <SaveAndCancelButton
          primaryText={"Submit"}
          isPrimaryButtonDisabled={
            emailIds?.list?.length === 0 || emailIds.invalidEmailIds.length ? true: false
          }
          defaultText={"Cancel"}
          onPrimaryActionClick={onSubmit}
          onDefaultButtonClick={onClose}
        />
      }
    >
      <Grid container flexDirection={"column"}>
        <Box sx={{ mb: 2 }} alignItems="center" display="flex">
          <AccessTimeIcon color="primary" />
          <Typography sx={{ color: "black", ml: 1, fontSize: 14 }}>
            {title}
          </Typography>
        </Box>
        <LabelAndValue
          sx={{ mb: 1 }}
          labelxs={3}
          labelmd={3}
          labelsm={3}
          size={13}
          valuexs={7}
          label="Time"
          value={`${datetime}`}
        />
        <LabelAndValue
          sx={{ mb: 1 }}
          labelxs={3}
          labelmd={3}
          labelsm={3}
          size={13}
          valuexs={7}
          label="Ambassador"
          value={convertToFullName(ambassador.firstName, ambassador.lastName)}
        />
        <LabelAndValue
          sx={{ mb: 1 }}
          labelxs={3}
          labelmd={3}
          labelsm={3}
          valuexs={7}
          label="Client"
          size={13}
          value={convertToFullName(client.firstName, client.lastName)}
        />
      </Grid>
      <EmailAddressBox onValueChange={handleOnEmailAddressesChange} />
    </AppModalDialogBox>
  );
}

export { AddMemberModal };
