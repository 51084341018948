import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CLIENT_MEETINGS_COLUMNS } from "./table-config";
import { Box, Grid } from "@mui/material";
import clientHOC from "../../client-hoc";
import { ClientMeetingListTable } from "./table";
import { ClientMeetingSummary } from "./summary";
import { fetchClientMeetingData } from "../../../../apis/clients.api";

function ClientMeetings({
  getClientMeetingsList,
  clientMeetingsList,
  selectedClient,
}: any) {
  const { selectedPage, selectedPageSize } = useSelector(
    (state: any) => state.clients.info.meetings.filters
  );
  const [clientMeetingData, setClientMeetingData] = useState<any>({});

  useEffect(() => {
    getClientMeetingsList({ selectedPage, selectedPageSize });
    getClientMeetingData();
  }, []);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getClientMeetingsList({
      selectedPage,
      selectedPageSize,
    });
  };

  const getClientMeetingData = async () => {
    const response: any = await fetchClientMeetingData(selectedClient?.id);

    if (response.success) {
      setClientMeetingData(response.data);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
      <Grid container spacing={2} direction="column">
        <Grid
          item
          xs={3}
          spacing={2}
          container
          justifyContent={"space-between"}
        >
          <ClientMeetingSummary data={clientMeetingData} />
        </Grid>

        <Grid item xs={12}>
          <ClientMeetingListTable
            list={clientMeetingsList}
            columns={CLIENT_MEETINGS_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              selectedPage,
            }}
          ></ClientMeetingListTable>
        </Grid>
      </Grid>
    </Box>
  );
}

export default clientHOC(ClientMeetings);
