import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { OpportunityListTable } from "./table";
import { OPPORTUNITY_LIST_COLUMNS } from "./table-config";
import { getOpportunities } from "../../../apis/opportunity";

function OpportunityList({}) {
  const { selectedPageSize, selectedPage } = useSelector(
    (state: any) => state.opportunity.list.filters
  );
  const opportunityList = useSelector((state: any) => state.opportunity.list);
  const dispatch = useDispatch();

  useEffect(() => {
    getOpportunityList({
      selectedPage,
      selectedPageSize,
    });
  }, []);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getOpportunityList({
      selectedPage,
      selectedPageSize,
    });
  };

  const getOpportunityList = async ({ selectedPage, selectedPageSize }) => {
    const payload = {
      selectedPage,
      selectedPageSize,
    };

    const response = await dispatch(getOpportunities(payload));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OpportunityListTable
            list={opportunityList}
            columns={OPPORTUNITY_LIST_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              selectedPage,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default OpportunityList;
