import { Grid, Typography } from "@mui/material";
import { InitialNameAvatar } from "../../../components/images/initial-name-avatar";
import { LabelAndValue } from "../../../components/typography";
import { convertToFullName, numberFormat } from "../../../services/utils";

function DetailsCard({ details }) {
  return (
    <Grid container sx={{ maxWidth: 450 }}>
      <Grid item xs={1.5}>
        <InitialNameAvatar
          image
          name={details.firstName}
          src={details.picture}
        />
      </Grid>
      <Grid item xs={10.5}>
        <Typography fontSize={14} sx={{ mb: 1 }} fontWeight={600}>
          {convertToFullName(details.firstName, details.lastName)}
        </Typography>
        <LabelAndValue
          fontWeight={{ value: 400 }}
          labelxs={6}
          labelmd={4}
          labelsm={3}
          label={"Title"}
          value={details.title}
        />
        <LabelAndValue
          fontWeight={{ value: 400 }}
          labelxs={6}
          labelmd={4}
          labelsm={3}
          label={"Company"}
          value={details.company}
        />
        <LabelAndValue
          fontWeight={{ value: 400 }}
          labelxs={6}
          labelmd={4}
          labelsm={3}
          label={"Timezone"}
          value={details.timezone?.text}
        />
        <LabelAndValue
          fontWeight={{ value: 400 }}
          labelxs={6}
          labelmd={4}
          labelsm={3}
          label={"No of Employees"}
          value={details.no_of_emp}
        />
        <LabelAndValue
          fontWeight={{ value: 400 }}
          labelxs={6}
          labelmd={4}
          labelsm={3}
          label={"Industry"}
          value={details.industry}
        />
      </Grid>
    </Grid>
  );
}

export { DetailsCard };
