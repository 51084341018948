import { Divider, Grid } from "@mui/material";
import { Fragment } from "react";
import { AppChip } from "../../../../components/chips";
import { InitialNameAvatar } from "../../../../components/images/initial-name-avatar";
import { LabelAndValue } from "../../../../components/typography";
import { ENUM_CURRENCY, RENDERER_TYPES } from "../../../../enums";
import {
  convertToFullName,
  getConvertedExternalUrl,
  numberFormat,
} from "../../../../services/utils";
import { useStyles } from "./styles";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "react-router-dom";

function ClientInfoDetails({ data, fieldList }) {
  const classes = useStyles();

  const renderValue = (detail) => {
    switch (detail.renderer) {
      case RENDERER_TYPES.AMOUNT:
        return `${numberFormat(data[detail.field], ENUM_CURRENCY.USD)}`;
      case RENDERER_TYPES.TIMEZONE:
        return `${data.timezone.value}`;
      case RENDERER_TYPES.NUMBER:
        return `${numberFormat(data[detail.field])}`;
      case RENDERER_TYPES.LINKEDIN_URL_WITH_ICON:
        return (
          <Link
            to={{ pathname: getConvertedExternalUrl(data[detail.field]) }}
            target="_blank"
            title="Linkedin URL"
          >
            <LinkedInIcon />
          </Link>
        );
      case RENDERER_TYPES.MULTIPLE_TEXT_RENDERER:
        return data?.[detail?.field]?.join(", ");
      case RENDERER_TYPES.CHIP_LIST:
        return data[detail.field]
          ?.split(",")
          ?.map((item) => (
            <AppChip
              key={item}
              bgColor="#F2F3F8"
              style={{ marginRight: 3, marginBottom: 3 }}
              label={item}
              fontColor={"#6C7293"}
            />
          ));
      default:
        return data[detail.field];
    }
  };

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <InitialNameAvatar
          style={{
            bgcolor: "#070639",
            width: 77,
            height: 77,
            fontSize: 32,
            fontWeight: 700,
            mr: 2,
          }}
          textStyle={{
            fontSize: 20,
          }}
          rootStyle={{
            marginBottom: 40,
          }}
          name={data.firstName}
          text={convertToFullName(data.firstName, data.lastName)}
        />
      </Grid>
      <Grid item xs={12}>
        {fieldList.map((detail, index) => (
          <Fragment key={index}>
            <LabelAndValue
              label={detail.label}
              value={renderValue(detail)}
              labelxs={6}
              labelmd={2}
              labelsm={3}
              color="#000000"
              size={14}
              fontWeight={{ label: 600, value: 400 }}
            />
            <Divider
              sx={{ mb: 2, mt: 1, width: "100%", border: "1px thin #D9D9D9" }}
            />
          </Fragment>
        ))}
      </Grid>
    </Grid>
  );
}

export { ClientInfoDetails };
