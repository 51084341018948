import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 25,
  },
  header: {
    backgroundColor: "#FAFAFA",
  },
  headerContent: {
    color: "#4D4D4D",
    fontSize: 14,
    fontWeight: "bold",
  },
  table: {
    minWidth: 650,
  },
  devider: { color: "#14C5E0" },
  badgeText: {
    fontFamily: "Open Sans",
    fontSize: 12,
    fontWeight: 400,
  },
  linkText: {
    fontWeight: 400,
    fontFamily: "Open Sans",
    fontSize: 14,
    marginRight: theme.spacing(1),
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: theme.spacing(3),
  },
  actionButton: {
    width: 90,
  },
}));
