import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  titleText: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#111111",
  },
  linkText: {
    color: "#1890FF",
    cursor: "pointer",
  },
}));
