import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { convertToFullName } from "../../../../services/utils";
import { Box } from "@mui/material";

export default function ViewersListData({ data }) {
  return (
    <List sx={{ width: "100%", bgcolor: "background.paper", p: 0 }}>
      {data?.map((i, index) => (
        <React.Fragment key={`viewers-list-data-${index}`}>
          <ListItem alignItems="flex-start" sx={{ p: 0 }}>
            <ListItemAvatar>
              <Avatar
                alt={convertToFullName(
                  i?.ambassador?.firstName,
                  i?.ambassador?.lastName
                )}
                src="/static/images/avatar/1.jpg"
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box mb={1}>
                  {convertToFullName(
                    i?.ambassador?.firstName,
                    i?.ambassador?.lastName
                  )}
                  <Typography fontSize={12} color={"GrayText"}>
                    {i?.ambassador?.email}
                  </Typography>
                </Box>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                    fontSize={11}
                  >
                    {`${i.count} views`}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          {index !== Object.keys(data)?.length - 1 && (
            <Divider component="li" />
          )}
        </React.Fragment>
      ))}
    </List>
  );
}
