import * as _ from "lodash";
import { REGEX } from "../constants";
import { ENUM_VALIDATION_TYPES } from "../enums";

function fieldValidator(value, { label, required = true, validators }, data) {
  if ((value === "" || value === null || value === undefined) && required) {
    return `${label} is required`;
  }

  if ((value === "" || (Array.isArray(value) && !value?.length)) && !required)
    return "";

  if (Array.isArray(value) && !value.length) {
    return `${label} is required`;
  }

  if (!validators) return "";

  const validatorDefaultMessage = `${label} is not valid`;
  let message = "";

  for (let index = 0; index < validators.length; index++) {
    const validateItem = validators[index];

    switch (validateItem.type) {
      case ENUM_VALIDATION_TYPES.EMAIL:
        if (!REGEX.EMAIL.test(value))
          message = validateItem.message || validatorDefaultMessage;
        break;
      case ENUM_VALIDATION_TYPES.CORPORATE_EMAIL:
        if (
          !REGEX.EMAIL.test(value) ||
          value?.toLowerCase().match(/gmail|hotmail|outlook|yahoo/g)
        )
          message = validateItem.message || validatorDefaultMessage;
        break;
      case ENUM_VALIDATION_TYPES.CONFIRM_PASSWORD:
        const matchValue = validateItem.matchField
          ? data[validateItem.matchField]
          : data.password;
        if (value !== matchValue)
          message = validateItem.message || validatorDefaultMessage;
        break;
      case ENUM_VALIDATION_TYPES.NAME:
        if (!REGEX.NAME.test(value))
          message = validateItem.message || validatorDefaultMessage;
        break;
      case ENUM_VALIDATION_TYPES.URL:
        if (!REGEX.URL.test(value))
          message = validateItem.message || `${label} url is not valid`;
        break;
      case ENUM_VALIDATION_TYPES.PHONE:
        if (!REGEX.PHONE.test(value))
          message = validateItem.message || `${label} is not valid`;
        break;
      case ENUM_VALIDATION_TYPES.POSITIVE_INTEGER_NUMBER:
        if (!REGEX.NUMBERS.POSITIVE_INTEGER_NUMBER.test(value))
          message = validateItem.message || validatorDefaultMessage;
        break;
      case ENUM_VALIDATION_TYPES.NUMBER_WITH_DOT:
        if (!REGEX.NUMBERS.NUMBER_WITH_DOT.test(value))
          message = validateItem.message || validatorDefaultMessage;
        break;
      case ENUM_VALIDATION_TYPES.USD_CURRENCY:
        if (!REGEX.CURRENCY.USD.test(value))
          message = validateItem.message || validatorDefaultMessage;
        break;
      default:
        message = "";
    }

    if (message) {
      break;
    }
  }

  return message;
}

function runValidation(FORM_ERROR_MAAPING, fieldList) {
  let isError = false;
  for (let index = 0; index < Object.keys(fieldList).length; index++) {
    const field = Object.keys(fieldList)[index];
    const fieldObj = FORM_ERROR_MAAPING.fields[field];

    fieldObj.error = fieldValidator(fieldList[field], fieldObj, fieldList);

    if (fieldObj.error) {
      isError = true;
    }
  }

  return {
    ...FORM_ERROR_MAAPING,
    isValid: !isError,
  };
}

export { runValidation };
