import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import ambassadorHOC from "../../../ambassadors/ambassador-hoc";
import { compose } from "redux";
import { useState } from "react";
import { AutoSelectbox } from "../../../../components/selectbox/autoselect";
import { ENUM_AUTOSELECT_LIST_TYPES } from "../../../../enums";
import { getAmbassadorsListFromSearch } from "../../../../apis/ambassadors.api";
import { useDispatch, useSelector } from "react-redux";
import { convertToFullName } from "../../../../services/utils";
import { AppDatePicker } from "../../../../components/datepickers";
import {
  DATE_FORMATS,
  convertToTimezone,
} from "../../../../services/date-and-time.utils";
import { submitManualMeeting } from "../../../../apis/clients.api";

function ClientManualMeeting() {
  const [selectedAmbassador, setSelectedAmbassador]: any = useState();
  const [selectedSlotTimeFrom, setSelectedSlotTimeFrom]: any = useState();
  const [anbassadorList, setAmbassadorList] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const clientId = useSelector(
    (state: any) => state?.clients?.selectedClient?.id
  );
  const dispatch = useDispatch();

  function convertToAmbassadorTimezone(date, timezone) {
    const to = new Date(date?.getTime() + 45 * 60000);
    return {
      selectedSlotTimeFrom: date,
      selectedSlotTimeTo: to,
      convertedSlotTimeFrom: convertToTimezone(
        date,
        timezone,
        DATE_FORMATS.fullDateWithTime
      ),
      convertedSlotTimeTo: convertToTimezone(
        to,
        timezone,
        DATE_FORMATS.fullDateWithTime
      ),
    };
  }

  const handleOnChange = async (e) => {
    const text = e?.target?.value;

    if (typeof text === "object") {
      setSelectedAmbassador(text);
      setSelectedSlotTimeFrom();
    }

    const res: any = await dispatch(getAmbassadorsListFromSearch(text));

    if (res.success) {
      const list = res?.data?.map((r) => {
        return {
          fullName: convertToFullName(r?.firstName, r?.lastName),
          id: r?.id,
          timezone: r?.timezone,
        };
      });
      setAmbassadorList(list);
    }
  };

  const handleDateChange = ({ date, timezone }) => {
    const selectedDatetime: any = convertToAmbassadorTimezone(date, timezone);

    setSelectedSlotTimeFrom(selectedDatetime);
  };

  const handleScheduleClick = async () => {
    const payload = {
      clientId: clientId,
      ambassadorId: selectedAmbassador?.id,
      timeSlot: {
        from: selectedSlotTimeFrom?.selectedSlotTimeFrom,
        to: selectedSlotTimeFrom?.selectedSlotTimeTo,
      },
    };

    const response: any = await dispatch(submitManualMeeting(payload));

    if (response?.success) {
      setForceUpdate(true);
      setSelectedAmbassador("");
      setSelectedSlotTimeFrom();
      setForceUpdate(false);
    }
  };

  if (forceUpdate) return null;

  return (
    <Container>
      <Box
        display={"flex"}
        flexDirection={"column"}
        p={2}
        component={Paper}
        mt={3}
      >
        <Typography component={"p"}>Schedule</Typography>
        <Divider />

        <Grid container justifyContent={"center"} mt={2} direction="column">
          <Grid item xs={4}>
            <AutoSelectbox
              label={"Search Ambassador"}
              fullWidth={false}
              field={"ambassadorList"}
              id={"ambassadorList"}
              inlineLabel={false}
              textfieldStyle={{ height: 6, width: 213 }}
              list={anbassadorList || []}
              onChange={handleOnChange}
              value={selectedAmbassador}
              error={""}
              listProperties={{
                field: "fullName",
                value: "id",
              }}
              listType={ENUM_AUTOSELECT_LIST_TYPES.ARRAY_OF_OBJECT}
              requireOnChange={true}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography fontSize={14}>Select Date</Typography>
            <AppDatePicker
              dateFormat={"Pp"}
              showTimeSelect
              selectedDate={selectedSlotTimeFrom?.selectedSlotTimeFrom}
              setDate={(date) =>
                handleDateChange({
                  date,
                  timezone: selectedAmbassador?.timezone?.value,
                })
              }
              minDate={new Date()}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Button
              variant="contained"
              disabled={!selectedAmbassador && !selectedSlotTimeFrom}
              onClick={handleScheduleClick}
            >
              Schedule
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default compose(ambassadorHOC)(ClientManualMeeting);
