import { IMAGES_PATH } from "../../constants";
import { AvatarImage } from "./avatar.image";

function Image({
  name,
  customClass,
  page,
  onClick,
  customStyle,
  height,
  width,
  url,
  badgeIconName,
  avatar,
  small,
  label,
  defaultImage,
}: any) {
  const getImagePath = () => {
    if (url) return url;
    if (page) {
      return `${IMAGES_PATH}${page}/${name}.png`;
    } else {
      return `${IMAGES_PATH}/${name}.png`;
    }
  };

  const getBadgeIconPath = () => {
    if (page) {
      return `${IMAGES_PATH}${page}/${badgeIconName}.png`;
    } else {
      return `${IMAGES_PATH}/${badgeIconName}.png`;
    }
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = defaultImage
      ? `${IMAGES_PATH}/${defaultImage}.png`
      : `${IMAGES_PATH}/default-image.png`;
  };

  function renderImage() {
    return (
      <>
        <img
          onError={addDefaultSrc}
          src={getImagePath()}
          className={`${customClass}`}
          onClick={onClick}
          style={{
            maxWidth: "100%",
            cursor: onClick && "pointer",
            ...customStyle,
          }}
          height={height}
          width={width}
        />
      </>
    );
  }

  if (avatar) {
    return (
      <AvatarImage
        src={getImagePath()}
        defaultImage={addDefaultSrc}
        small={small}
        label={label}
      />
    );
  }

  return renderImage();
}

export { Image };
