import { makeStyles } from "@material-ui/core";
import { IMAGES_PATH } from "../../constants";
import { AppText } from "../typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  profileImage: {
    height: 80,
    width: 80,
    borderRadius: "50%",
    marginRight: theme.spacing(2),
  },
  text: {
    fontWeight: 400,
    marginLeft: theme.spacing(1),
  },
}));

function AvatarImage({
  customClass,
  onClick,
  customStyle,
  height,
  width,
  addDefaultSrc,
  src,
  small,
  rootClass,
  link,
  label,
  textClass,
  defaultImage,
}: any) {
  const classes = useStyles();

  const getDefaultImage = (ev) => {
    ev.target.src = `${IMAGES_PATH}/default-profile-image.png`
  };

  return (
    <div
      className={`${classes.root} ${rootClass}`}
      onClick={onClick}
      style={{ cursor: onClick && "pointer", ...customStyle }}
    >
      <img
        onError={getDefaultImage}
        src={src}
        className={`${classes.profileImage} ${customClass}`}
        onClick={onClick}
        style={{
          maxWidth: "100%",
          ...(small && { height: 40, width: 40 }),
          ...customStyle,
        }}
        height={height}
        width={width}
      />
      <AppText
        link={link}
        text={label}
        textClass={`${classes.text} ${textClass}`}
      />
    </div>
  );
}

export { AvatarImage };
