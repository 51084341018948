import {
  SET_INVITATIONS_LIST,
  SET_INVITATION_LIST_FILTERS,
  initialInvitationListState,
  SET_INVITATIONS_LIST_LOADING,
  RESET_INVITATION_LIST_FILTERS,
  RESET_INVITATION_STORE,
} from "./actions";

const initialInvitations = {
  list: { ...initialInvitationListState },
};

const invitations = (state = initialInvitations, action) => {
  switch (action.type) {
    case SET_INVITATIONS_LIST_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: action.payload,
        },
      };
    case SET_INVITATIONS_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
          data: action.payload.data,
          total: action.payload.total,
        },
      };
    case SET_INVITATION_LIST_FILTERS:
      return {
        ...state,
        list: {
          ...state.list,
          filters: {
            ...state.list.filters,
            ...action.payload,
          },
        },
      };
    case RESET_INVITATION_LIST_FILTERS:
      return {
        ...state,
        list: { ...initialInvitationListState },
      };
    case RESET_INVITATION_STORE:
      return { ...initialInvitations };
    default:
      return state;
  }
};

export default invitations;
