import { RENDERER_TYPES } from "../../../../enums";

const CLIENT_CAMPAIGNS_COLUMNS = [
  {
    title: "Date",
    field: "date",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_DATE_FROM,
    width: "90%"
  },
  {
    title: "Budget",
    field: "budget",
    dataType: "string",
    rendererType: RENDERER_TYPES.AMOUNT,
    width: "10%"
  },
];

export { CLIENT_CAMPAIGNS_COLUMNS };
