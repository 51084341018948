import { RENDERER_TYPES } from "../../../enums";

const NEW_INVITATION_AMBASSADOR_LIST_COLUMNS = [
  { field: "checkbox" },
  { field: "expandable" },
  {
    title: "Name",
    field: "firstName",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_NAME_WITH_AVATAR,
  },
  { title: "Title", field: "title", dataType: "string" },
  { title: "Company", field: "company", dataType: "string" },
  {
    title: "Est. Employees",
    field: "no_of_emp",
    dataType: "string",
  },
  {
    title: "Industry",
    field: "industry",
    dataType: "string",
  },
  {
    title: "Rate",
    field: "round_table_package_12_rate",
    dataType: "string",
    rendererType: RENDERER_TYPES.AMOUNT,
  },
];

const AMBASSADOR_LIST_ROWS = [];

export { NEW_INVITATION_AMBASSADOR_LIST_COLUMNS, AMBASSADOR_LIST_ROWS };
