import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import Searchbox from "../../../components/searchbox";
import { CONST_LOOKING_FOR_LIST } from "../../../constants/lists";
import { useDispatch, useSelector } from "react-redux";
import { StackOfChips } from "../../../components/mui/stack";
import { IStackChip } from "../../../components/mui/stack/models";
import { setOpportunityResponseListFilters } from "../../../redux/modules/opportunity/response-list-actions";

function OpportunityResponseFilterOptions() {
  const dispatch = useDispatch();
  const industryList = useSelector(
    (state: any) => state.common.opportunity_categories.data
  );
  const { selectedFilters } = useSelector(
    (state: any) => state.opportunity.responseList.filters
  );
  const { lookingFor, companyName, industryCategory } = selectedFilters;

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;

    const v = typeof value === "string" ? value.split(",") : value;

    dispatch(
      setOpportunityResponseListFilters({
        selectedFilters: {
          ...selectedFilters,
          [name]: v,
        },
      })
    );
  };

  const handleSearchValueChange = (value = "") => {
    dispatch(
      setOpportunityResponseListFilters({
        selectedFilters: {
          ...selectedFilters,
          companyName: value,
        },
      })
    );
  };

  const getChipsDataFor = (data, title, field): IStackChip[] => {
    return data?.map((item) => {
      return {
        ...item,
        field,
        valueStr: item,
        label: (
          <>
            <Typography
              variant="caption"
              fontWeight={600}
            >{`${title}: `}</Typography>
            <Typography variant="caption">{`${item}`}</Typography>
          </>
        ),
      };
    });
  };

  const getStackChips = () => {
    return [
      ...getChipsDataFor(lookingFor, "Looking For", "lookingFor"),
      ...getChipsDataFor(industryCategory, "Industry", "industryCategory"),
    ];
  };

  const handleOnItemRemove = (field, item) => {
    let lv = [];

    switch (field) {
      case "lookingFor":
        lv = [...lookingFor];
        break;
      case "industryCategory":
        lv = [...industryCategory];
        break;
    }

    const index = lv.indexOf(item);
    if (index > -1) {
      lv.splice(index, 1);
    }

    handleChange({
      target: {
        name: field,
        value: lv,
      },
    });
  };

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"space-between"}
      spacing={2}
    >
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={4}>
          <Searchbox
            onEnter={handleSearchValueChange}
            value={companyName}
            onClear={() => handleSearchValueChange("")}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="industryCategory-multiple-checkbox-label">
              Industry
            </InputLabel>
            <Select
              labelId="industryCategory-multiple-checkbox-label"
              id="industryCategory-multiple-checkbox"
              multiple
              value={industryCategory}
              name="industryCategory"
              onChange={handleChange}
              input={<OutlinedInput label="Looking For" />}
              renderValue={(selected: any) => selected.join(", ")}
            >
              {industryList?.map(({ label }) => (
                <MenuItem key={label} value={label}>
                  <Checkbox checked={industryCategory.indexOf(label) > -1} />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="lookingfor-multiple-checkbox-label">
              Looking For
            </InputLabel>
            <Select
              labelId="lookingfor-multiple-checkbox-label"
              id="lookingfor-multiple-checkbox"
              multiple
              value={lookingFor}
              name="lookingFor"
              onChange={handleChange}
              input={<OutlinedInput label="Looking For" />}
              renderValue={(selected: any) => selected.join(", ")}
            >
              {CONST_LOOKING_FOR_LIST.map(({ label }) => (
                <MenuItem key={label} value={label}>
                  <Checkbox checked={lookingFor.indexOf(label) > -1} />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <StackOfChips
          data={getStackChips()}
          onRemove={handleOnItemRemove}
          labelField="label"
          valueField="valueStr"
        ></StackOfChips>
      </Grid>
    </Grid>
  );
}

export default OpportunityResponseFilterOptions;
