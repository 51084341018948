import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "./HOC/error-boundry";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./redux";
import { Provider } from "react-redux";
import Screens from "./screens";
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";

export const HostFilterContext: any = React.createContext({
  hostSelection: false,
});

export const HostCardContext: any = React.createContext({
  showDetails: "ALL",
});

declare module "@mui/material/styles" {
  interface Theme {
    palette: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#070639",
    },
    secondary: {
      main: "#f50057",
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 15,
    h3: {
      color: "#000000",
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ErrorBoundary>
          <Screens />
        </ErrorBoundary>
      </ConnectedRouter>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
