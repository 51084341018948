import {
  postApi,
  getApi,
  putApi,
  deleteApi,
  patchtApi,
} from "../services/axios";
import APIs from ".";
import {
  setAmbassadorPaymentsList,
  setSelectedAmbassador,
  setAmbassadorListFilters,
  setAmbassadorsList,
  setAmbassadorMeetingsFilters,
  setAmbassadorMeetinsList,
  setAmbassadorListLoading,
  setAmbassadorMeetinsListLoading,
  setAmbassadorPaymentsListLoading,
} from "../redux/modules/ambassadors/actions";

export function getAmbassadors({
  selectedPage,
  selectedPageSize,
  searchValue = "",
  selectedFilters,
}) {
  return async function (dispatch) {
    const payload = {
      ...selectedFilters,
    };
    const res = await postApi(
      APIs.AMBASSADORS.GET_AMBASSADORS({
        selectedPage,
        selectedPageSize,
        searchValue,
      }),
      payload,
      {
        validateUser: true,
        loadingFn: setAmbassadorListLoading,
      }
    );

    if (res.success) {
      dispatch(
        setAmbassadorsList({
          data: res.data,
          total: res.total,
        })
      );

      dispatch(
        setAmbassadorListFilters({
          selectedPage,
          selectedPageSize,
          searchValue,
          selectedFilters,
        })
      );
    }
    return res;
  };
}

export function getAmbassadorsListFromSearch(searchValue = "") {
  return async function (dispatch) {
    const payload = {};
    const res = await postApi(
      APIs.AMBASSADORS.GET_AMBASSADORS({
        selectedPage: 1,
        selectedPageSize: 100,
        searchValue,
      }),
      payload,
      {
        validateUser: true,
      }
    );

    if (res.success) {
    }
    return res;
  };
}

export function getAmbassadorsSimpleList({
  selectedPage,
  selectedPageSize,
  searchValue = "",
  selectedFilters,
}) {
  return async function (dispatch) {
    const payload = {
      ...selectedFilters,
    };
    const res = await postApi(
      APIs.AMBASSADORS.GET_AMBASSADORS_SIMPLE_LIST({
        selectedPage,
        selectedPageSize,
        searchValue,
      }),
      payload,
      {
        loadingFn: setAmbassadorListLoading,
      }
    );

    if (res.success) {
      dispatch(
        setAmbassadorsList({
          data: res.data,
          total: res.total,
        })
      );

      dispatch(
        setAmbassadorListFilters({
          selectedPage,
          selectedPageSize,
          searchValue,
          selectedFilters,
        })
      );
    }
    return res;
  };
}

export function getAmbassadorMeetings({
  selectedPage,
  selectedPageSize,
  ambassadorId,
}) {
  return async function (dispatch) {
    const res = await getApi(
      APIs.AMBASSADORS.GET_AMBASSADOR_MEETINGS({
        selectedPage,
        selectedPageSize,
        ambassadorId,
      }),
      {
        validateUser: true,
        loadingFn: setAmbassadorMeetinsListLoading,
      }
    );

    if (res.success) {
      dispatch(
        setAmbassadorMeetinsList({
          data: res.data,
          total: res.total,
        })
      );

      dispatch(
        setAmbassadorMeetingsFilters({
          selectedPage,
          selectedPageSize,
        })
      );
    }

    return res;
  };
}

export function getAmbassadorPayments({ ambassadorId }) {
  return async function (dispatch) {
    const res = await getApi(
      APIs.AMBASSADORS.GET_AMBASSADOR_PAYMENTS({
        ambassadorId,
      }),
      {
        validateUser: true,
        loadingFn: setAmbassadorPaymentsListLoading,
      }
    );

    if (res.success) {
      dispatch(
        setAmbassadorPaymentsList({
          ...res.data,
        })
      );
    }

    return res;
  };
}

export function getAmbassadorDetails(id) {
  return async function (dispatch) {
    const res = await getApi(APIs.AMBASSADORS.GET_AMBASSADOR_DETAILS(id), {
      validateUser: true,
    });

    if (res.success) {
      dispatch(setSelectedAmbassador(res.data));
    }

    return res;
  };
}

export async function deleteAmbassador(id) {
  const res = await deleteApi(APIs.AMBASSADORS.DELETE_AMBASSADOR(id), {
    validateUser: true,
  });

  return res;
}

export async function addAmbassador(payload) {
  const res = await postApi(APIs.AMBASSADORS.ADD_AMBASSADOR, payload, {
    validateUser: true,
  });

  if (res.success) {
  }

  return res;
}

export async function updateAmbassador(id, payload) {
  const res = await putApi(APIs.AMBASSADORS.UPDATE_AMBASSADOR(id), payload, {
    validateUser: true,
  });

  if (res.success) {
  }

  return res;
}

export async function uploadAmbassadorPhotoUpload(id, data) {
  const res = await patchtApi(APIs.AMBASSADORS.SUBMIT_PHOTO_UPLOAD(id), data, {
    validateUser: true,
  });

  if (res.success) {
  }

  return res;
}
