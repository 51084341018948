import { adminLayoutHOC } from "../HOC/layouts";
import { Route, withRouter, Switch, Redirect } from "react-router";
import { compose } from "redux";
import ClientsList from "../screens/clients/list";
import { AddOrUpdateClient } from "../screens/clients/add-or-update";
import ClientDetails from "../screens/clients/client-details";
import { PageNotFound } from "../components/errors";
import { ROUTE_PATHS } from "./route-paths";
import { ROOT_PATH } from "../constants";

export const clientRoutePaths = {
  CLIENTS: {
    ROOT: `${ROOT_PATH}/clients`,
    LIST: `${ROOT_PATH}/clients/clients-list`,
    ADD: `${ROOT_PATH}/clients/add`,
    EDIT: `${ROOT_PATH}/clients/edit/:id`,
    DETAILS: `${ROOT_PATH}/clients/details/:id/:tab`,
  },
};

function ClientRoutes() {
  return (
    <Switch>
      <Route
        exact
        path={ROUTE_PATHS.CLIENTS.LIST}
        component={compose(withRouter, adminLayoutHOC)(ClientsList)}
      />
      <Route
        exact
        path={ROUTE_PATHS.CLIENTS.ADD}
        component={compose(withRouter, adminLayoutHOC)(AddOrUpdateClient)}
      />
      <Route
        exact
        path={ROUTE_PATHS.CLIENTS.EDIT}
        component={compose(withRouter, adminLayoutHOC)(AddOrUpdateClient)}
      />
      <Route
        exact
        path={ROUTE_PATHS.CLIENTS.DETAILS}
        component={compose(withRouter, adminLayoutHOC)(ClientDetails)}
      />
      <Route
        path={ROUTE_PATHS.NOT_FOUND}
        exact={true}
        component={PageNotFound}
      />
      <Redirect to={ROUTE_PATHS.NOT_FOUND} />
    </Switch>
  );
}

export { ClientRoutes };
