import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 600,
    fontSize: 14,
    color: "#000000",
  },
  value: {
    color: "#000000",
    fontWeight: 400,
    fontSize: 14,
  },
  actionButton: {
    height: 36,
    width: 84,
    padding: 2,
    marginRight: 15,
  },
  deleteButton: {
    height: 36,
    background: "#d90706",
    padding: 2,
    width: 96,
  },
}));
