import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  getClientSlotDetails,
  submitAmbassadorSlotSelection,
} from "../../apis/ambassador-invite.api";
import {
  convertToTimezone,
  DATE_FORMATS,
  formatDate,
} from "../../services/date-and-time.utils";
import { AppText } from "../../components/typography";
import * as _ from "lodash";
import { useQuery } from "../../hooks/useQuery";
import { SuccessMessageTemplate } from "../../templates/success-message";
import { AppDatePicker } from "../../components/datepickers";
import EventIcon from "@material-ui/icons/Event";
import { DetailsCard } from "../components/details-card";
import { ClientTimezoneSlotDetails } from "./client-timezone-slot-details";
import { AppAlertBox } from "../../components/modals/alert-box";
import { checkTimeslotSelectionForLateNight } from "../../services/validators";
import { GLOBAL_TEXT } from "../../constants";
import { getClientUpcomingMeetings } from "../../apis/clients.api";
import { validateSlots } from "./utils";
import moment from "moment";

function AmbassadorClientSlotSelection() {
  const [clientSlotDetails, setClientSlotDetails]: any = useState();
  const [selectedSlot, setSelectedSlot] = useState("0");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [selectedDateTime, setSelectedDateTime]: any = useState();
  const [showSlotSelectionConfirmAlert, setShowSlotSelectionConfirmAlert] =
    useState(false);
  const [preserveSelection, setPreserveSelection]: any = useState();
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const queryParams = useQuery();

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    const token = queryParams.get("token");
    const ambassadorInvitationId = queryParams.get("ambassadorInvitationId");

    const res = await getClientSlotDetails(token, ambassadorInvitationId);

    if (res.success) {
      const clientUpcomingMeetings = await getClientUpcomingMeetings({
        clientId: res.data.client.id,
        token: queryParams.get("token"),
        invitationId: `ambassadorInvitationId=${queryParams.get(
          "ambassadorInvitationId"
        )}`,
      });

      setUpcomingMeetings(clientUpcomingMeetings);
      const payload = {
        ...res.data,
        availableTimeSlots: res.data.availableTimeSlots.map((item, index) => {
          return {
            ...item,
            id: index.toString(),
            convertedSlotTimeFrom: convertToTimezone(
              item.from,
              res.data.client.timezone?.value,
              DATE_FORMATS.fullDateWithTime
            ),
            convertedSlotTimeTo: convertToTimezone(
              item.to,
              res.data.client.timezone?.value,
              DATE_FORMATS.fullDateWithTime
            ),
          };
        }),
      };
      setClientSlotDetails(payload);
      setSelectedSlot(payload?.availableTimeSlots[0].id);
      setSelectedDateTime({
        from: new Date(),
        to: new Date(new Date().getTime() + 45 * 60000),
        convertedSlotTimeFrom: convertToTimezone(
          new Date(),
          payload.client.timezone?.value,
          DATE_FORMATS.fullDateWithTime
        ),
        convertedSlotTimeTo: convertToTimezone(
          new Date(new Date().getTime() + 45 * 60000),
          payload.client.timezone?.value,
          DATE_FORMATS.fullDateWithTime
        ),
      });
    }
  };

  const handleDateChange = ({ date }, confirmed = false) => {
    const datetime = {
      from: date,
      to: new Date(date?.getTime() + 45 * 60000),
      convertedSlotTimeFrom: convertToTimezone(
        date,
        clientSlotDetails.client.timezone?.value,
        DATE_FORMATS.fullDateWithTime
      ),
      convertedSlotTimeTo: convertToTimezone(
        new Date(date.getTime() + 45 * 60000),
        clientSlotDetails.client.timezone?.value,
        DATE_FORMATS.fullDateWithTime
      ),
    };

    if (
      !confirmed &&
      checkTimeslotSelectionForLateNight(datetime.convertedSlotTimeFrom)
    ) {
      setPreserveSelection({
        date,
      });
      setShowSlotSelectionConfirmAlert(true);

      return false;
    }
    setSelectedDateTime(datetime);

    const { isFormValid, error } = validateSlots(datetime, upcomingMeetings);

    setErrorMessage(error);
  };

  const onSubmit = async () => {
    const payload = {
      selectedTimeSlot:
        Number(selectedSlot) === clientSlotDetails.availableTimeSlots.length
          ? {
              from: selectedDateTime.from,
              to: selectedDateTime.to,
            }
          : {
              from: clientSlotDetails.availableTimeSlots[Number(selectedSlot)]
                .from,
              to: clientSlotDetails.availableTimeSlots[Number(selectedSlot)].to,
            },
      isCustom:
        Number(selectedSlot) === clientSlotDetails.availableTimeSlots.length,
    };
    const token = queryParams.get("token");
    const ambassadorInvitationId = queryParams.get("ambassadorInvitationId");

    const response: any = await submitAmbassadorSlotSelection(
      payload,
      token,
      ambassadorInvitationId
    );

    if (response.success) {
      setSubmitSuccess(response.message);
    }
  };

  if (submitSuccess) {
    return <SuccessMessageTemplate successMessage={submitSuccess} />;
  }

  if (!clientSlotDetails) return null;

  return (
    <>
      <Grid
        container
        direction={"column"}
        alignItems="center"
        justifyContent={"center"}
        sx={{ minHeight: "85vh" }}
      >
        <Card sx={{ maxWidth: 800 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DetailsCard details={clientSlotDetails.client} />
              </Grid>

              <Grid item xs={12} mt={1}>
                <AppText
                  text={`*The slots correspond to the following timezone ${clientSlotDetails?.ambassador?.timezone?.text}`}
                  color="blue"
                />
              </Grid>

              <Grid item xs={6} container>
                <ToggleButtonGroup
                  orientation="vertical"
                  value={selectedSlot}
                  defaultValue={"0"}
                  exclusive
                  fullWidth
                  onChange={(e: any, value) => {
                    value && setSelectedSlot(value.toString());
                  }}
                >
                  {[
                    ...clientSlotDetails?.availableTimeSlots,
                    // { id: "other" },
                  ].map((slotItem, slotIndex) => (
                    <ToggleButton
                      sx={{
                        display: "flex",
                        background:
                          selectedSlot.toString() === slotIndex.toString()
                            ? "#EBEDF2"
                            : "",
                        justifyContent: "flex-start",
                        height: 56,
                      }}
                      key={slotIndex}
                      value={slotIndex.toString()}
                      aria-label="list"
                    >
                      <>
                        <EventIcon />
                        <AppText
                          text={
                            slotIndex ===
                            clientSlotDetails?.availableTimeSlots.length
                              ? "Other"
                              : `${formatDate(
                                  slotItem.from,
                                  DATE_FORMATS.fullDateWithTime
                                )} -  ${formatDate(
                                  slotItem.to,
                                  DATE_FORMATS.fullDateWithTime
                                )}`
                          }
                        />
                      </>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
                {Number(selectedSlot) ===
                  clientSlotDetails.availableTimeSlots.length && (
                  <>
                    <Grid item container mt={2} mb={1} alignItems={"center"}>
                      <Grid item xs={2}>
                        <Typography variant="caption">From: </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <AppDatePicker
                          dateFormat={"Pp"}
                          showTimeSelect
                          selectedDate={selectedDateTime.from}
                          setDate={(date) => handleDateChange({ date })}
                          minDate={new Date()}
                        />
                      </Grid>
                    </Grid>
                    <Grid item container alignItems={"center"}>
                      <Grid item xs={2}>
                        <Typography variant="caption">To: </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <AppDatePicker
                          dateFormat={"Pp"}
                          showTimeInput
                          showTimeSelect
                          readOnly
                          selectedDate={selectedDateTime.to}
                          minDate={new Date()}
                          setDate={(date) => {}}
                        />
                      </Grid>
                    </Grid>
                    {errorMessage && (
                      <Grid item>
                        <Grid item xs={12}>
                          <AppText text={`* ${errorMessage}`} color="red" />
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>

              <Grid item xs={6} container alignItems={"center"}>
                {Number(selectedSlot) !==
                clientSlotDetails.availableTimeSlots.length ? (
                  <ClientTimezoneSlotDetails
                    name={clientSlotDetails.client.firstName}
                    from={
                      clientSlotDetails?.availableTimeSlots[
                        Number(selectedSlot)
                      ].convertedSlotTimeFrom
                    }
                    to={
                      clientSlotDetails?.availableTimeSlots[
                        Number(selectedSlot)
                      ].convertedSlotTimeTo
                    }
                  />
                ) : (
                  selectedDateTime.from && (
                    <ClientTimezoneSlotDetails
                      name={clientSlotDetails.client.firstName}
                      from={selectedDateTime.convertedSlotTimeFrom}
                      to={selectedDateTime.convertedSlotTimeTo}
                    />
                  )
                )}
              </Grid>

              <Grid item container justifyContent={"flex-end"}>
                <Button
                  disabled={
                    errorMessage &&
                    selectedSlot ===
                      (clientSlotDetails?.availableTimeSlots.length).toString()
                      ? true
                      : false
                  }
                  variant="contained"
                  onClick={onSubmit}
                >
                  {"Submit"}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {showSlotSelectionConfirmAlert && (
        <AppAlertBox
          isModalOpen={true}
          onConfirm={() => {
            handleDateChange(preserveSelection, true);
            setShowSlotSelectionConfirmAlert(false);
          }}
          onCancel={() => {
            setShowSlotSelectionConfirmAlert(false);
          }}
          message={
            GLOBAL_TEXT.ALERT_MESSAGES.CONFIRM_LATE_NIGHT_SLOT_SELECTION_CLIENT
          }
        />
      )}
    </>
  );
}

export { AmbassadorClientSlotSelection };
