import React from "react";
import { connect } from "react-redux";
import { ENUM_USER_ROLES } from "../../enums";
import { withRouter } from "react-router";
import { AdminPanelSidebar } from "../../components/layouts/sidebars";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material";
import { AppBreadcrumbs } from "../../components/layouts/breadcrumbs";
import { AuthActions } from "../../components/layouts/header/header-actions/auth-actions";
import SvgIcons from "../../svgs";
import { ROUTE_PATHS } from "../../routes/route-paths";
import { BREADCRUMBS } from "../../components/layouts/breadcrumbs/breadcrumb-paths";
import Container from "@mui/material/Container";
import StorefrontIcon from "@mui/icons-material/Storefront";
import LeaderboardRoundedIcon from '@mui/icons-material/LeaderboardRounded';

const SIDEBAR_MENU_ITEMS = [
  {
    label: "DASHBORD",
    image: SvgIcons.Dashboard,
    path: ROUTE_PATHS.DASHBOARD,
    rootPath: ROUTE_PATHS.DASHBOARD,
  },
  {
    label: "CLIENTS",
    image: SvgIcons.Client,
    path: ROUTE_PATHS.CLIENTS.LIST,
    rootPath: ROUTE_PATHS.CLIENTS.ROOT,
  },
  {
    label: "AMBASSADORS",
    image: SvgIcons.Ambassador,
    path: ROUTE_PATHS.AMBASSADORS.LIST,
    rootPath: ROUTE_PATHS.AMBASSADORS.ROOT,
  },
  {
    label: "INVITATIONS",
    image: SvgIcons.Invitations,
    path: ROUTE_PATHS.INVITATIONS.LIST,
    rootPath: ROUTE_PATHS.INVITATIONS.ROOT,
  },
  {
    label: "CONNECT",
    image: SvgIcons.Client,
    path: ROUTE_PATHS.CONNECT_REQUESTS,
    rootPath: ROUTE_PATHS.CONNECT_REQUESTS,
  },
  {
    label: "OPPORTUNITY",
    image: SvgIcons.Opportunity,
    path: ROUTE_PATHS.OPPORTUNITY,
    rootPath: ROUTE_PATHS.OPPORTUNITY,
  },
  {
    label: "Marketplace",
    icon: <StorefrontIcon sx={{color: "#979797"}} />,
    path: ROUTE_PATHS.MARKETPLACE.LIST,
    rootPath: ROUTE_PATHS.MARKETPLACE.ROOT,
  },
];

const adminLayoutHOC = (ComposedComponent) => {
  class AdminLayoutHOC extends React.Component<any, any> {
    constructor(props) {
      super(props);
      this.state = {
        customBreadcrumbLabel: "",
      };
    }

    render() {
      const { user, match } = this.props;

      const currentRoutePaths = BREADCRUMBS[match.path];

      const selectedSidebarMenuItem = SIDEBAR_MENU_ITEMS.find((sidebarItem) => {
        return match.path.search(sidebarItem.rootPath) !== -1;
      });

      if (!user.isLoggedIn || user.role === ENUM_USER_ROLES.USER) {
        this.props.history.push(ROUTE_PATHS.UNAUTHORIZED);
        return null;
      } else
        return (
          <Box sx={{ display: "flex" }}>
            <CssBaseline />

            <AdminPanelSidebar
              selected={selectedSidebarMenuItem}
              list={SIDEBAR_MENU_ITEMS}
            />
            <Box
              component="main"
              sx={{
                backgroundColor: "white",
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Box
                  component={"div"}
                  justifyContent="space-between"
                  alignItems={"center"}
                  display={"flex"}
                  sx={{ mb: 5 }}
                >
                  <AppBreadcrumbs
                    currentRoutePaths={currentRoutePaths?.paths}
                    customLabel={this.state.customBreadcrumbLabel}
                  />

                  <AuthActions />
                </Box>

                <ComposedComponent
                  {...this.props}
                  setBreadcrumbLabelCustomText={(text) => {
                    this.setState({
                      customBreadcrumbLabel: text,
                    });
                  }}
                />
              </Container>
            </Box>
          </Box>
        );
    }
  }

  const mapStateToProps = (state) => {
    return {
      user: state.auth.user,
    };
  };

  return connect(mapStateToProps, null)(withRouter(AdminLayoutHOC));
};

export default adminLayoutHOC;
