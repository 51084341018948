import { SimpleTable } from "../../../components/mui/table";
import { RENDERER_TYPES } from "../../../enums";
import { AppChip } from "../../../components/chips";
import { numberFormat } from "../../../services/utils";
import { Button, Typography } from "@mui/material";
import { AppAlertBox } from "../../../components/modals/alert-box";
import { GLOBAL_TEXT } from "../../../constants";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { convrtToVendor } from "../../../apis/opportunity";

function OpportunityResponseListTable({
  list,
  columns,
  handlePageFilters,
  appliedFilters,
  showPagination = true,
  onDetailsClick,
  refreshList = () => {},
}: any) {
  const { selectedPage, selectedPageSize } = showPagination
    ? appliedFilters
    : { selectedPage: 1, selectedPageSize: 1 };
  const [showConvertConfirm, setShowConvertConfirm] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const dispatch = useDispatch();

  const handleVendorConvert = (item) => {
    setShowConvertConfirm(true);
    setSelectedRecord(item);
  };

  const handleConvertConfirm = async () => {
    const response = await dispatch(convrtToVendor(selectedRecord?.id));
    refreshList({ ...appliedFilters });
    handleConvertClose();
  };

  const handleConvertClose = () => {
    setShowConvertConfirm(false);
    setSelectedRecord(null);
  };

  const handleTDRenderer = (row, column) => {
    switch (column.rendererType) {
      case RENDERER_TYPES.CHIP_LIST:
        return row?.[column.field]?.map((item) => (
          <AppChip
            key={item}
            bgColor="#F2F3F8"
            style={{ marginRight: 3, marginBottom: 3 }}
            label={item}
            fontColor={"#6C7293"}
            size={"small"}
          />
        ));
      case RENDERER_TYPES.AMOUNT:
        return numberFormat(row?.[column?.field]);
      case RENDERER_TYPES.ACTION:
        if (column.field === "viewCount") {
          if (!row.viewCount) return "-";

          return (
            <Typography
              component={"a"}
              href="#"
              onClick={(e) => {
                e?.preventDefault();
                onDetailsClick(row);
              }}
            >
              {numberFormat(row?.[column?.field])}
            </Typography>
          );
        }

        if (!row?.isConvertedToClient) {
          return (
            <Button
              size="small"
              sx={{ width: 150, height: 30, fontSize: 10, fontWeight: 600 }}
              variant="contained"
              color="info"
              onClick={() => handleVendorConvert(row)}
            >
              Convert to vendor
            </Button>
          );
        }

        return null;
      default:
        return row?.[column.field];
    }
  };

  return (
    <>
      <SimpleTable
        tdRenderer={handleTDRenderer}
        rows={list?.data}
        columns={columns}
        total={list?.total}
        fetching={list?.loading}
        onPageSettingsChange={handlePageFilters}
        selectedPage={selectedPage}
        selectedPageSize={selectedPageSize}
        showPagination={showPagination}
      />
      <AppAlertBox
        isModalOpen={showConvertConfirm}
        onConfirm={handleConvertConfirm}
        onCancel={handleConvertClose}
        message={GLOBAL_TEXT.ALERT_MESSAGES.CONFIRM_CONVERT_TO_VENDOR}
      />
    </>
  );
}

export { OpportunityResponseListTable };
