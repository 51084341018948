import {
  makeStyles,
  withStyles,
  Dialog,
  IconButton,
  Grid,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { AppText } from "../../typography";
import { SaveAndCancelButton } from "../../buttons";
import { Typography } from "@mui/material";
import { AppTextField } from "../../textfields";
import { useState } from "react";
import { validateForm } from "../../../services/validators";

const initialFields: any = {
  message: "",
};

const initialErrors = {
  ...initialFields,
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 16,
  },
  primaryButton: {
    maxWidth: "100px",
    height: "40px",
    backgroundColor: "#1890FF",
    marginRight: theme.spacing(1),
  },
  primaryButtonText: {
    fontSize: 16,
    fontWeight: 400,
  },
  defaultButton: {
    maxWidth: "100px",
    height: "40px",
    backgroundColor: "white",
    border: "1px solid #1890FF",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "white",
    },
  },
  defaultButtonText: {
    fontSize: 16,
    color: "#1890FF",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <AppText textClass={classes.title}>{children}</AppText>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function AppAlertBox({
  isModalOpen,
  handleClose,
  onConfirm,
  onCancel,
  maxButtonWidth,
  maxModalWidth = "xs",
  fullButtonWidth = true,
  message,
  withReason,
  textFieldProps,
  validate = false,
}: any) {
  const [formFields, setFormField] = useState(initialFields);
  const [formErrors, setFormErrors] = useState(initialErrors);

  const handleOnChange = (e) => {
    setFormField({
      ...formFields,
      [e.target.name]: e.target.value || "",
    });

    setFormErrors({
      ...formErrors,
      [e.target.name]: "",
    });
  };

  const onSubmit = () => {
    if (!validate) onConfirm();

    const { errors, isError } = validateForm(formFields, initialErrors, [
      {
        placeHolder: "Message",
        fieldName: "message",
      },
    ]);

    if (!isError) {
      const payload = {
        ...(withReason && { message: formFields["message"] }),
      };
      onConfirm(payload);
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
      fullWidth
      maxWidth={maxModalWidth}
      style={{ zIndex: 1000 }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {"Alert"}
      </DialogTitle>
      <DialogContent dividers>
        <Typography sx={{ mb: 1 }}>{message}</Typography>

        {withReason && (
          <AppTextField
            id={"alert-with-message"}
            name={"message"}
            label={"Please enter message"}
            inlineLabel
            onChange={handleOnChange}
            value={formFields["message"]}
            error={formErrors["message"]}
            autoComplete={"off"}
            otherProps={{ multiline: true, minRows: 5 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="flex-end">
          <SaveAndCancelButton
            primaryText={"Confirm"}
            defaultText={"Cancel"}
            onPrimaryActionClick={onSubmit}
            onDefaultButtonClick={onCancel}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export { AppAlertBox };
