function PageNotFound() {
  return (
    <div>
      <h1>404 Page not found!</h1>
      <h2>
        <p>
          You can go back to our <a href="/">Homepage</a>.
        </p>
      </h2>
    </div>
  );
}

export { PageNotFound };
