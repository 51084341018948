import { RENDERER_TYPES } from "../../../../enums";

const AMBASSADOR_REQUEST_LIST_COLUMNS = [
  {
    title: "Name",
    field: "firstName",
    dataType: "string",
    rendererType: RENDERER_TYPES.CLICKABLE_FULL_NAME_WITH_AVATAR,
  },
  { title: "Email", field: "email", dataType: "string" },
  { title: "Title", field: "ambassador.title", dataType: "string" },
  { title: "Company", field: "ambassador.company", dataType: "string" },
  {
    title: "LinkedIn",
    field: "linkedIn",
    dataType: "string",
    rendererType: RENDERER_TYPES.LINKEDIN_URL_WITH_ICON,
  },
  { title: "Action", rendererType: RENDERER_TYPES.ACTION },
];

const AMBASSADOR_REQUEST_LIST_ROWS = [
  {
    id: "63dcadd8bd14c5ba30ca6ba4",
    firstName: "Nirav Parekh",
    title: "Title",
    company: "Company",
    email: "nirav55.parekh@gmail.com",
    industry: "Industry",
    linkedIn: "https://www.linkedin.com/in/nirav-parekh-75728338/",
  },
];

export { AMBASSADOR_REQUEST_LIST_COLUMNS, AMBASSADOR_REQUEST_LIST_ROWS };
