import { ENUM_FORM_INPUT_TYPES } from "../../../../enums";

const PRIORITY_FIELDS = [
  {
    field: "label",
    label: "Cybersecurity Products You’re Interested In",
    inputType: ENUM_FORM_INPUT_TYPES.AUTO_SELECTBOX,
    listProperties: {
      field: "label",
      value: "label",
    },
  },
  {
    field: "priority",
    label: "Priority",
    inputType: ENUM_FORM_INPUT_TYPES.AUTO_SELECTBOX,
    listProperties: {
      field: "label",
      value: "value",
    },
  },
];

const PRIORITY_FIELDS_FORM_ERROR_MAAPING = [
  {
    placeHolder: "Cybersecurity Products You’re Interested In",
    fieldName: "label",
  },
  {
    placeHolder: "Priority",
    fieldName: "priority",
  },
];

export { PRIORITY_FIELDS, PRIORITY_FIELDS_FORM_ERROR_MAAPING };
