import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg, url2File } from "./canvas-utils";

export default function ImageCropper({
  file,
  aspect = 1,
  cropShape,
  onSubmit,
  onClose,
  filename,
}) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom]: any = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [initialCroppedArea, setInitialCroppedArea] = useState(undefined);
  const [fileObj, setFileObj]: any = useState();

  useEffect(() => {
    const croppedArea = JSON.parse(window.localStorage.getItem("croppedArea"));
    setInitialCroppedArea(croppedArea);
  }, []);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);

    showCroppedImage(croppedAreaPixels);
  };

  const showCroppedImage = async (croppedAreaPixels) => {
    try {
      const croppedImage = await getCroppedImg(
        file?.imageDataUrl,
        croppedAreaPixels
      );

      const fileObj = await url2File(croppedImage, filename);
      setFileObj(fileObj);
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = () => {
    onSubmit(fileObj);
  };

  return (
    <Dialog
      open={true}
      onClose={() => {}}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      keepMounted
      disableEscapeKeyDown
      maxWidth="md"
    >
      <DialogTitle>Crop</DialogTitle>
      <DialogContent>
        <Box display={"flex"} gap={2}>
          <Box width={"100%"}>
            {file?.imageDataUrl && (
              <Box
                width={"100%"}
                height={300}
                bgcolor={"#333"}
                mb={1}
                position={"relative"}
              >
                <Cropper
                  image={file?.imageDataUrl}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspect}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  cropShape={cropShape}
                  showGrid={false}
                  initialCroppedAreaPercentages={initialCroppedArea}
                />
              </Box>
            )}
            <Box display={"flex"} width={"100%"} gap={1}>
              <Typography mr={1} variant="overline">
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </Box>
          </Box>
        </Box>
        <Box
          mt={5}
          width={"100%"}
          display={"flex"}
          gap={1}
          justifyContent={"center"}
        >
          <Button size="medium" color="primary" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
          <Button
            size="medium"
            color="error"
            sx={{ color: "error.main" }}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
