import { useRef, useState } from "react";
import { PrimaryActionButton } from "../../components/buttons";
import { AppModalDialogBox } from "../../components/modals";
import {
  PrivacyPolicy,
  TermsOfServices,
} from "../../templates/terms-and-conditions";

import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  processBtn: {
    height: 40,
    maxWidth: 200,
  },
}));

function TermsAndConditionsModal({
  handleClose,
  onCompleted,
  isTermsReadCompleted,
}: any) {
  const classes = useStyles();
  const [readingCompleted, setReadingCompleted] = useState(
    isTermsReadCompleted
  );
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const rootEle: any = useRef();

  const scrollToTop = () => {
    rootEle.current.scrollIntoView();
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    bottom && !showPrivacyPolicy && setReadingCompleted(true);
  };

  const onPrivacyPolicyClick = (e) => {
    e.preventDefault();
    setShowPrivacyPolicy(true);
    scrollToTop();
  };

  const onTermsOfServiceClick = (e) => {
    e.preventDefault();
    setShowPrivacyPolicy(false);
    scrollToTop();
  };

  return (
    <AppModalDialogBox
      isModalOpen={true}
      title="Terms and Conditions"
      actionButtonRenderer={
        <PrimaryActionButton
          isDisabled={!readingCompleted}
          buttonClass={classes.processBtn}
          text={"Close"}
          handleOnClick={onCompleted}
        />
      }
      size={"md"}
      handleClose={handleClose}
      handleScroll={handleScroll}
    >
      <div ref={rootEle}>
        {!showPrivacyPolicy ? (
          <TermsOfServices onPrivacyPolicyClick={onPrivacyPolicyClick} />
        ) : (
          <PrivacyPolicy onTermsOfServiceClick={onTermsOfServiceClick} />
        )}
      </div>
    </AppModalDialogBox>
  );
}

export { TermsAndConditionsModal };
