import { Grid } from "@mui/material";
import React from "react";
import { AuthLayoutHeader } from "../../components/layouts/header/auth-layout-header";

const authLayoutHOC = (ComposedComponent) => {
  class AuthLayoutHOC extends React.Component<any, any> {
    constructor(props) {
      super(props);
      this.state = {
        pageConfig: {
          showLogo: true,
        },
      };
    }

    handlePageConfig = (config) => {
      this.setState({
        pageConfig: {
          ...this.state.pageConfig,
          pageConfig: { ...config },
        },
      });
    };

    render() {
      return (
        <Grid container style={{ backgroundColor: "#070639", height: "100vh" }}>
          <Grid item xs={12}>
            <AuthLayoutHeader configs={this.state.pageConfig} />
          </Grid>
          <Grid item xs={12}>
            <ComposedComponent
              {...this.props}
              setConfig={this.handlePageConfig}
            />
          </Grid>
        </Grid>
      );
    }
  }

  return AuthLayoutHOC;
};

export default authLayoutHOC;
