import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const SORTING_OPTIONS = [
  { value: "name", label: "Name" },
  { value: "categories", label: "Category" },
  { value: "no_of_emps", label: "No of Employees" },
  { value: "keywords", label: "Keywords" },
  { value: "country", label: "Country" },
  { value: "revenue", label: "Revenue" },
  { value: "founded", label: "Founded" },
];

export default function MPSortingFilters({ sort, setSort, setSortOrder }) {
  return (
    <>
      <div className="vf-form-col">
        <label>Sort</label>
        <select
          className="form-control"
          name="sort"
          onChange={setSort}
          value={sort.by}
        >
          {SORTING_OPTIONS?.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <FormControl sx={{ mt: 4 }}>
        <RadioGroup
          row
          aria-labelledby="sort-row-radio-buttons-group-label"
          name="sortOrder"
          onChange={setSortOrder}
        >
          <FormControlLabel
            value="asc"
            control={<Radio checked={sort?.order === "asc"} />}
            label="Ascending"
          />
          <FormControlLabel
            value="desc"
            control={<Radio checked={sort?.order === "desc"} />}
            label="Descending"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
}
