import { IconButton } from "@mui/material";
import { InitialNameAvatar } from "../../../components/images/initial-name-avatar";
import { SimpleTable } from "../../../components/mui/table";
import { ENUM_CURRENCY, RENDERER_TYPES } from "../../../enums";
import { convertToFullName, numberFormat } from "../../../services/utils";
import SvgIcons from "../../../svgs";
import { AmbassadorListExpandedRowData } from "./expanded-row-data";

function AmbassadorListTable({
  list,
  columns,
  handlePageFilters,
  toggleMenuOptions,
  appliedFilters,
  onRowSelect,
  selectedRows,
  showPagination = true,
}: any) {
  const { selectedPage, selectedPageSize } = showPagination
    ? appliedFilters
    : { selectedPage: 1, selectedPageSize: 1 };

  const handleTDRenderer = (row, column) => {
    switch (column.rendererType) {
      case RENDERER_TYPES.FULL_NAME_WITH_AVATAR:
        return (
          <InitialNameAvatar
            text={convertToFullName(row.firstName, row.lastName)}
            name={row.firstName}
            image
            src={row.photo || row.picture}
          />
        );
      case RENDERER_TYPES.AMOUNT:
        return `${numberFormat(row[column.field], ENUM_CURRENCY.USD)}`;
      case RENDERER_TYPES.NUMBER:
        return `${numberFormat(row[column.field])}`;
      case RENDERER_TYPES.ACTION:
        return (
          <IconButton
            key={row.id}
            onClick={(e) => toggleMenuOptions(e, row.id)}
          >
            <img src={SvgIcons.Circles} />
          </IconButton>
        );
      default:
        break;
    }
  };

  return (
    <>
      <SimpleTable
        tdRenderer={handleTDRenderer}
        rows={list?.data}
        columns={columns}
        total={list?.total}
        fetching={list?.loading}
        onPageSettingsChange={handlePageFilters}
        selectedPage={selectedPage}
        selectedPageSize={selectedPageSize}
        renderExpandedRowData={({ row }) => (
          <AmbassadorListExpandedRowData data={row} />
        )}
        exandableRow
        onRowSelect={onRowSelect}
        selectedRows={selectedRows}
        showPagination={showPagination}
      />
    </>
  );
}

export { AmbassadorListTable };
