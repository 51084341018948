import { RENDERER_TYPES } from "../../../enums";

const CLIENT_LIST_COLUMNS = [
  {
    title: "Name",
    field: "firstName",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_NAME_WITH_AVATAR,
  },
  { title: "Email", field: "email", dataType: "string" },
  { title: "Title", field: "title", dataType: "string" },
  {
    title: "Budget",
    field: "budget",
    dataType: "string",
    rendererType: RENDERER_TYPES.AMOUNT,
  },
  {
    title: "Balance",
    field: "balance",
    dataType: "string",
    rendererType: RENDERER_TYPES.AMOUNT,
  },
  {
    title: "Views",
    field: "viewCount",
    dataType: "string",
    rendererType: RENDERER_TYPES.ACTION,
  },
  { title: "Action", rendererType: RENDERER_TYPES.ACTION },
];

const CLIENT_LIST_ROWS = [];

export { CLIENT_LIST_COLUMNS, CLIENT_LIST_ROWS };
