import { SquareChip } from "../../../../components/chips/square-chip";
import { CONST_MEETING_STATUS_LIST } from "../../../../constants/lists";
import { getMeetingStatus } from "../../../../services/utils";

function MeetingStatus({ data }) {
  const status = getMeetingStatus(data);

  return (
    <SquareChip
      text={CONST_MEETING_STATUS_LIST[status]?.label}
      color={CONST_MEETING_STATUS_LIST[status]?.fontColor}
      bgColor={CONST_MEETING_STATUS_LIST[status]?.bgColor}
    />
  );
}

export { MeetingStatus };
