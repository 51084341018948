import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  formControl: {},
  title: {
    "font-style": "normal",
    "font-weight": 600,
    "font-size": "14px",
    "line-height": "24px",
  },
  error: {
    color: "#f44336",
  },
}));
