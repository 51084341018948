import { RENDERER_TYPES } from "../../../enums";

const DASHBOARD_SUMMARY = [
  {
    icon: "perm_contact_calendar",
    label: "New Meetings",
    rendererType: RENDERER_TYPES.NUMBER
  },
  {
    icon: "people_alt",
    label: "New Ambassadors",
    rendererType: RENDERER_TYPES.NUMBER
  },
  {
    icon: "people_alt",
    label: "New Clients",
    rendererType: RENDERER_TYPES.NUMBER
  },
  {
    icon: "people_alt",
    label: "New Revenue",
    rendererType: RENDERER_TYPES.AMOUNT
  },
];

export { DASHBOARD_SUMMARY };
