import { Container, Grid } from "@mui/material";
import { Logo } from "../../logo";
import { SupportHelpLineText } from "../../shared";

function AuthLayoutHeader({ configs }: any) {
  return (
    <Container sx={{ pt: 3 }}>
      <Grid container justifyContent={"space-between"}>
        {configs.showLogo && <Logo />}
        <SupportHelpLineText textColor="white" />
      </Grid>
    </Container>
  );
}

export { AuthLayoutHeader };
