import { TextField } from "@material-ui/core";
import { useAuthStyles } from "../styles";

function SignUpCommonDetails({ handleOnChange, formFields, formErrors }) {
  const authClasses = useAuthStyles();

  return (
    <>
      <TextField
        className={authClasses.textField}
        id="signup-firstName"
        label="First Name"
        variant="outlined"
        name="firstName"
        fullWidth
        onChange={handleOnChange}
        value={formFields.firstName}
        error={formErrors.firstName ? true : false}
        helperText={formErrors.firstName}
        autoComplete={"off"}
      />
      <TextField
        className={authClasses.textField}
        id="signup-lastName"
        label="Last Name"
        variant="outlined"
        name="lastName"
        fullWidth
        onChange={handleOnChange}
        value={formFields.lastName}
        error={formErrors.lastName ? true : false}
        helperText={formErrors.lastName}
        autoComplete={"off"}
      />

      <TextField
        className={authClasses.textField}
        id="signup-email"
        label="Email"
        variant="outlined"
        name="email"
        fullWidth
        onChange={handleOnChange}
        value={formFields.email}
        error={formErrors.email ? true : false}
        helperText={formErrors.email}
        autoComplete={"off"}
      />

      <TextField
        className={authClasses.textField}
        id="signup-phonenumber"
        label="Phone Number"
        variant="outlined"
        name="phoneNumber"
        fullWidth
        onChange={handleOnChange}
        value={formFields.phoneNumber}
        error={formErrors.phoneNumber ? true : false}
        helperText={formErrors.phoneNumber}
        autoComplete={"off"}
      />
    </>
  );
}

export { SignUpCommonDetails };
