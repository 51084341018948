import APIs from ".";
import { getApi, postApi, putApi } from "../services/axios";

export async function getClientAmbassadorInviteDetails(token, invitationId) {
  const res = await getApi(
    APIs.CLIENT_AMBASSADOR_INVITE.GET_INVITE_DETAILS(token, invitationId),
    {
      validateUser: false,
    }
  );

  return res;
}

export async function getClientSlotDetails(token, ambassadorInvitationId) {
  const res = await getApi(
    APIs.CLIENT_AMBASSADOR_INVITE.GET_CLIENT_SLOTS_SELECTION(
      token,
      ambassadorInvitationId
    ),
    {
      validateUser: false,
    }
  );

  return res;
}

export async function inviteClintAmbassadorSubmit(payload) {
  const res = await postApi(
    APIs.CLIENT_AMBASSADOR_INVITE.INVITE_CLIENT_AMBASSADOR,
    payload,
    {
      validateUser: true,
    }
  );

  if (res.success) {
  }

  return res;
}

export async function submitClientAmbassadorSlotSelection(
  payload,
  token,
  invitationId
) {
  const res = await postApi(
    APIs.CLIENT_AMBASSADOR_INVITE.SUBMIT_SLOT_SELECTION(token, invitationId),
    payload,
    {
      validateUser: false,
    }
  );

  if (res.success) {
  }

  return res;
}

export async function submitAmbassadorSlotSelection(
  payload,
  token,
  ambassadorInvitationId
) {
  const res = await putApi(
    APIs.CLIENT_AMBASSADOR_INVITE.SUBMIT_AMBASSADOR_SLOT_SELECTION(
      token,
      ambassadorInvitationId
    ),
    payload,
    {
      validateUser: false,
    }
  );

  if (res.success) {
  }

  return res;
}
