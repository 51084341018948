import React from "react";
import { connect } from "react-redux";
import store from "../../redux";
import { setTermsReadCompleted } from "../../redux/modules/UI/actions";
import { TermsAndConditionsModal } from "./terms-and-conditions.modal";

const acceptTermsHOC = (ComposedComponent) => {
  class AcceptTermsHOC extends React.Component<any, any> {
    constructor(props) {
      super(props);

      this.state = {
        termsAccepted: undefined,
        showTermsModal: false,
        isTermsReadCompleted: false,
      };
    }

    onTermsReadComplete = () => {
      this.setState({
        isTermsReadCompleted: true,
        showTermsModal: !this.state.showTermsModal,
      });

      store.dispatch(setTermsReadCompleted(true));
    };

    toggleTermsModal = () => {
      this.setState({
        showTermsModal: !this.state.showTermsModal,
      });
    };

    onTermsAcceptToggle = () => {
      this.setState({
        termsAccepted: !this.state.termsAccepted,
        showTermsModal: true,
      });
    };

    onShowPopup = () => {
      this.setState({
        showTermsModal: true,
      });
    };

    render() {
      const {
        termsAccepted,
        isTermsReadCompleted,
        showTermsModal,
      } = this.state;

      const { termsReadCompleted } = this.props;

      return (
        <>
          <ComposedComponent
            {...this.props}
            termsAccepted={termsAccepted}
            isTermsReadCompleted={isTermsReadCompleted}
            onTermsAcceptToggle={this.onTermsAcceptToggle}
            onShowPopup={this.onShowPopup}
          />

          {showTermsModal && termsAccepted !== false && (
            <TermsAndConditionsModal
              handleOnClose={this.toggleTermsModal}
              onCompleted={this.onTermsReadComplete}
              isTermsReadCompleted={isTermsReadCompleted || termsReadCompleted}
            />
          )}
        </>
      );
    }
  }

  const mapStateToProps = (state) => {
    return { termsReadCompleted: state.UI.termsReadCompleted };
  };

  return connect(mapStateToProps, null)(AcceptTermsHOC);
};

export { acceptTermsHOC };
