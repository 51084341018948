import { CustomizedTable } from "../../../../components/mui/table/custom-table";
import { ENUM_CURRENCY, ENUM_PAYMENT_STATUS, RENDERER_TYPES } from "../../../../enums";
import {
  DATE_FORMATS,
  formatDate,
} from "../../../../services/date-and-time.utils";
import { numberFormat } from "../../../../services/utils";
import { PaymentStatus } from "../../../components/payment-status";

function ClientWisePaymentListTable({ rows, columns }) {
  const handleTDRenderer = (row, column) => {
    switch (column.rendererType) {
      case RENDERER_TYPES.FULL_DATE:
        return (
          row[column.field] &&
          `${formatDate(row[column.field], DATE_FORMATS.fullDateWithTime)}`
        );
      case RENDERER_TYPES.AMOUNT:
        return `${numberFormat(row[column.field], ENUM_CURRENCY.USD)}`;
      case RENDERER_TYPES.PAYMENT_STATUS:
        return (
          <PaymentStatus
            data={{ ...row, status: ENUM_PAYMENT_STATUS.SUCCESS }}
          />
        );
      default:
        break;
    }
  };

  return (
    <CustomizedTable
      tdRenderer={handleTDRenderer}
      rows={rows}
      columns={columns}
    ></CustomizedTable>
  );
}

export { ClientWisePaymentListTable };
