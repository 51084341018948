import { useEffect } from "react";
import { useHistory } from "react-router";
import { userLogout } from "../../../apis/auth.api";
import { ROUTE_PATHS } from "../../../routes/route-paths";

function Logout() {
  const history = useHistory();

  useEffect(() => {
    onLogout();
  }, []);

  const onLogout = async () => {
    await userLogout();
    history.push(ROUTE_PATHS.auth.LOGIN);
  };

  return <div></div>;
}

export { Logout };
