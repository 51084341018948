import { Skeleton } from "@mui/material";

export default function ListSkeleton() {
  return (
    <>
      {[1, 2, 3].map((s) => (
        <div
          key={`vendor-details-block-list-skeleton-${s}`}
          className="vendor-detail-box"
          style={{ marginBottom: "20px" }}
        >
          <div className="vendor-detail-left">
            <div className="vendor-top">
              <div className="vendor-top-left">
                <div className="skeleton" />
                <img src="images/default.jpg" />
              </div>
              <div className="vendor-top-right">
                <div className="skeleton" />
                <h2>Trustlook</h2>
                <div className="link-with-icon">
                  <a target="_blank" href="https://www.trustlook.com/">
                    <span>
                      <img src="images/web.svg" />
                    </span>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/trustlook-mobile-security/about/"
                  >
                    <span>
                      <img src="images/linkedin.svg" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="vendor-desc">
              <div className="skeleton" />
              <p>
                Trustlook (www.trustlook.com) is a global leader in
                next-generation cybersecurity products based on artificial
                intellige...{" "}
                <span className="more-link" id="readMore">
                  Read More
                </span>
              </p>
            </div>
            <ul className="vendor-other-details">
              <div className="skeleton" />
              <li>
                <strong>Stage:</strong> Initial Revenues
              </li>
              <li>
                <strong>Revenues:</strong> $0.00
              </li>
              <li>
                <strong>Employee Size:</strong> 10+
              </li>
              <li>
                <strong>Founded:</strong> 2020
              </li>
              <li>
                <strong>Funding:</strong> $0.53
              </li>
              <li>
                <strong>Fund Year:</strong> 2023
              </li>
              <li>
                <strong>Country:</strong> Netherland
              </li>
              <li>
                <strong>State:</strong> Noord-Holland
              </li>
            </ul>
            <div className="lf-block">
              <div className="skeleton" />
              <h4>Looking For :</h4>
              <form className="looking-for-form">
                <div className="form-group custom-checkbox">
                  <input type="checkbox" name="contactType" id="clients" />
                  <label htmlFor="clients">Clients</label>
                </div>
                <div className="form-group custom-checkbox">
                  <input type="checkbox" name="contactType" id="advisors" />
                  <label htmlFor="advisors">Advisors</label>
                </div>
                <div className="form-group custom-checkbox">
                  <input
                    type="checkbox"
                    name="contactType"
                    id="designPartners"
                  />
                  <label htmlFor="designPartners">Design Partners</label>
                </div>
                <div className="form-group custom-checkbox">
                  <input type="checkbox" name="contactType" id="others" />
                  <label htmlFor="others">Others</label>
                </div>
                <div className="popup-btn-block">
                  <button className="btn">Contact</button>
                </div>
              </form>
            </div>
          </div>
          <div className="vendor-detail-right">
            <div className="keywords-block">
              <div className="skeleton" />
              <h2>Keywords</h2>
              <ul className="keywords-list">
                <li>mobile security</li>
                <li>mobile ransomware</li>
                <li>mobile antivirus</li>
                <li>mobile spyware</li>
                <li>android security</li>
                <li>android antivirus</li>
                <li>android malware</li>
                <li>ad fraud</li>
                <li>artificial intelligence</li>
                <li>machine learning</li>
              </ul>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export function CategoryListSkeleton() {
  return (
    <div className="vendor-listing-block element-with-scroll">
      <div className="categories-title">
        <h4>Categories</h4>
        <span> </span>
      </div>
      <ul className="vendor-list">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((mpc) => (
          <li key={`marketplace-categories-${mpc}`} className="clickable">
            <a
              style={{
                color: "white",
              }}
            >
              <Skeleton variant="text" width={200} sx={{ mr: 1 }} />
              <Skeleton variant="text" width={50} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
