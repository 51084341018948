import { RENDERER_TYPES } from "../../../enums";

const OPPORTUNITY_LIST_COLUMNS = [
  {
    title: "To",
    field: "emails",
    dataType: "string",
    rendererType: RENDERER_TYPES.CHIP_LIST,
  },
  {
    title: "Date",
    field: "createdAt",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_DATE_FROM,
  },
  {
    title: "Subject",
    field: "subject",
    dataType: "string",
  },
  {
    title: "Template",
    rendererType: RENDERER_TYPES.ACTION
  },
];

export { OPPORTUNITY_LIST_COLUMNS };
