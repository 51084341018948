import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import createRootReducer from "./modules";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from 'redux-devtools-extension';

export const history = createBrowserHistory();

const defaultState = {};

function configureStore(preloadedState = defaultState) {
    const store = createStore(
        createRootReducer(history),
        preloadedState,
        composeWithDevTools(applyMiddleware(
            routerMiddleware(history),
            thunk
        ))
    );

    return store;
}

const store = configureStore();

export default store;