import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import CalendarTodaySharpIcon from "@mui/icons-material/CalendarTodaySharp";
import { createPortal } from "react-dom";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const useStyles = makeStyles((theme) => ({
  datepickerTextField: {
    height: 40,
    fontFamily: "'Montserrat'",
    fontSize: 14,
    lineHeight: "24px !important",
    color: "#6B778C !important",
    fontWeight: 400,
  },
}));

const DateInput = forwardRef(
  (
    { value, onClick, datepickerClass, placeholder, isClearable, onClear }: any,
    ref: any
  ) => {
    const classes = useStyles();

    return (
      <TextField
        disabled
        className={clsx(classes.datepickerTextField, datepickerClass)}
        ref={ref}
        id="datepicker-textfield"
        variant="outlined"
        name="email"
        fullWidth
        value={value}
        placeholder={placeholder}
        autoComplete={"off"}
        label={""}
        onClick={onClick}
        InputProps={{
          className: `${classes.datepickerTextField} ${datepickerClass}`,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={onClick}
                edge="end"
              >
                <CalendarTodaySharpIcon sx={{ color: "#000000" }} />
              </IconButton>
              {(isClearable && value) && <IconButton
                aria-label="clear"
                onClick={onClear}
                edge="end"
              >
                <HighlightOffIcon sx={{ color: "red" }} />
              </IconButton>}
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

function AppDatePicker({
  setDate,
  selectedDate,
  datepickerClass,
  minDate,
  dateFormat,
  showTimeInput,
  showTimeSelect,
  isClearable,
  placeholder,
  readOnly = false,
}: any) {
  return (
    <DatePicker
      showYearDropdown
      timeIntervals={15}
      popperContainer={({ children }) => {
        return document.getElementById("custom-modal-dialog")
          ? createPortal(
              children,
              document.getElementById("custom-modal-dialog")
            )
          : createPortal(children, document.body);
      }}
      selected={selectedDate}
      placeholderText={placeholder}
      onChange={(date) => setDate(date)}
      customInput={
        <DateInput
          isClearable={isClearable}
          datepickerClass={datepickerClass}
          onClear={() => setDate("")}
        />
      }
      minDate={minDate}
      dateFormat={dateFormat}
      showTimeInput={showTimeInput}
      showTimeSelect={showTimeSelect}
      readOnly={readOnly}
    />
  );
}

export { AppDatePicker };
