import { makeStyles } from "@material-ui/core";

export const useAuthStyles = makeStyles((theme) => ({
  root: { paddingBottom: theme.spacing(5) },
  contentWrapper: { maxWidth: 470, textAlign: "center" },
  logo: { marginBottom: theme.spacing(2) },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "53px",
    color: "#111111",
    marginBottom: "27px",
  },
  textField: {
    height: "54px",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#111111",
    marginBottom: theme.spacing(3),
  },
  helpLineTextWrapper: {
    textAlign: "right",
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(5),
    display: "flex",
    justifyContent: "flex-end",
  },
}));
