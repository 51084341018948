import {
  SET_OPEN_SIDEBAR,
  SET_SERVER_ERROR,
  SET_SHOW_NOTIFICATIONS,
  SET_TERMS_READ_COMPLETED,
  SET_UI_LOADING,
} from ".";

export const reportServerError = (payload) => ({
  type: SET_SERVER_ERROR,
  payload,
});

export const setShowNotifications = (payload) => ({
  type: SET_SHOW_NOTIFICATIONS,
  payload,
});

export const setUILoading = (payload) => ({
  type: SET_UI_LOADING,
  payload,
});

export const setOpenSidebar = (payload) => ({
  type: SET_OPEN_SIDEBAR,
  payload,
});

export const setTermsReadCompleted = (payload) => ({
  type: SET_TERMS_READ_COMPLETED,
  payload,
});
