import { Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { ENUM_INTRESTED_IN_PRIORITY_LIST } from "../../../../enums";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { AppChip } from "../../../../components/chips";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";

function IntrestedBlock({ data, editable, onEditClick, onDeleteClick }) {
  return (
    <Paper sx={{ p: 1 }}>
      <Grid container alignItems={"flex-start"}>
        <Grid container item xs={10}>
          <Grid item xs={12} sm={11}>
            <Stack mb={1}>
              <Typography className="truncated-text" title={data?.label}>
                {data?.label}
              </Typography>
            </Stack>
            <AppChip
              label={data?.priority?.toUpperCase()}
              icon={<></>}
              style={{
                fontWeight: 700,
                backgroundColor: getPriorityColor(data?.priority),
                color: "white",
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={2} container justifyContent={"flex-end"}>
          <IconButton
            sx={{
              visibility: editable ? "visible" : "hidden",
              p: 0,
              verticalAlign: "middle",
            }}
            onClick={() => {
              onEditClick();
            }}
          >
            <ModeEditOutlinedIcon />
          </IconButton>

          <IconButton
            sx={{
              visibility: editable ? "visible" : "hidden",
              p: 0,
              verticalAlign: "middle",
            }}
            onClick={() => {
              onDeleteClick();
            }}
          >
            <DeleteForeverOutlinedIcon color="error" />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default IntrestedBlock;

export function getPriorityColor(priority) {
  switch (priority) {
    case ENUM_INTRESTED_IN_PRIORITY_LIST.HIGH:
      return "green";

    case ENUM_INTRESTED_IN_PRIORITY_LIST.MEDIUM:
      return "orange";

    case ENUM_INTRESTED_IN_PRIORITY_LIST.LOW:
      return "red";

    default:
      return "secondary.main";
  }
}
