import { useState } from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { EmailAddressBox } from "../../../components/email-addressbox";
import { SaveAndCancelButton } from "../../../../components/buttons";
import { transferRoleSubmit } from "../../../../apis/clients.api";

export default function TransferRoleModal({ onClose, id }) {
  const [emailIds, setEmailds]: any = useState({
    list: [],
    invalidEmailIds: [],
  });
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);

  const handleOnEmailAddressesChange = (payload) => {
    setEmailds(payload);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (!emailIds.invalidEmailIds.length) {
      const payload = {
        emails: emailIds.list,
        ambassadorInvitationId: id,
      };

      const response: any = await dispatch(transferRoleSubmit(payload));

      if (response.success) {
        onClose();
      }
    }
    setSubmitting(false);
  };

  return (
    <form onSubmit={onSubmit}>
      <EmailAddressBox
        inlineLabel={true}
        onValueChange={handleOnEmailAddressesChange}
      />
      <Box display={"flex"} mt={2} justifyContent={"flex-end"}>
        <SaveAndCancelButton
          primaryText="Transfer"
          defaultText="Cancel"
          onPrimaryActionClick={onSubmit}
          onDefaultButtonClick={onClose}
          isPrimaryButtonDisabled={
            !emailIds.list.length || emailIds.invalidEmailIds.length
          }
        />
      </Box>
    </form>
  );
}
