import { RENDERER_TYPES } from "../../../../enums";

const CLIENT_DETAILS_LABEL_VALUES = [
  { field: "email", label: "Email" },
  { field: "secondaryMembers", label: "Secondary Email" },
  {
    field: "linkedIn",
    label: "Linkedin",
    renderer: RENDERER_TYPES.LINKEDIN_URL_WITH_ICON,
  },
  { field: "title", label: "Title" },
  { field: "company", label: "Company" },
  { field: "timezone", label: "Timezone", renderer: RENDERER_TYPES.TIMEZONE },
  { field: "no_of_emp", label: "No of Emp." },
  { field: "funding", label: "Funding", renderer: RENDERER_TYPES.AMOUNT },
  { field: "revenues", label: "Revenues", renderer: RENDERER_TYPES.AMOUNT },
  {
    field: "target_no_of_emp",
    label: "Targeted No of Emp.",
    renderer: RENDERER_TYPES.CHIP_LIST,
  },
  {
    field: "target_account",
    label: "Target Client Accounts",
  },
  {
    field: "target_industries",
    label: "Targeted Industries",
    renderer: RENDERER_TYPES.CHIP_LIST,
  },
  { field: "budget", label: "Budget", renderer: RENDERER_TYPES.AMOUNT },
  {
    field: "licenseForMeeting",
    label: "License For Meeting",
  },
  { field: "viewAmbassadorThreshold", label: "Ambassador Threshold" },
  { field: "meetingThreshold", label: "Meeting Threshold" },
  {
    field: "viewNextBatchAmbassadorThreshold",
    label: "Next Batch Ambassador Threshold",
  },
];

export { CLIENT_DETAILS_LABEL_VALUES };
