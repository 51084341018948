import { InitialNameAvatar } from "../../../../components/images/initial-name-avatar";
import { SimpleTable } from "../../../../components/mui/table";
import { ENUM_CURRENCY, RENDERER_TYPES } from "../../../../enums";
import { convertToFullName, numberFormat } from "../../../../services/utils";
import { PaymentStatus } from "../../../components/payment-status";
import { ClientPaymentListExpandedRowData } from "./expanded-row-data";

function ClientPaymentListTable({
  list,
  columns,
  handlePageFilters,
  appliedFilters,
}) {
  const { selectedPage, selectedPageSize } = appliedFilters;

  const handleTDRenderer = (row, column) => {
    switch (column.rendererType) {
      case RENDERER_TYPES.FULL_NAME_WITH_AVATAR:
        return (
          <InitialNameAvatar
            text={convertToFullName(row.firstName, row.lastName)}
            name={row.firstName}
            image
            src={row.picture}
          />
        );
      case RENDERER_TYPES.FULL_DATE:
        return row.date;
      case RENDERER_TYPES.AMOUNT:
        return `${numberFormat(row[column.field], ENUM_CURRENCY.USD)}`;
      case RENDERER_TYPES.PAYMENT_STATUS:
        return <PaymentStatus data={row} />;
      default:
        break;
    }
  };

  return (
    <SimpleTable
      tdRenderer={handleTDRenderer}
      rows={list?.data}
      columns={columns}
      fetching={list?.loading}
      total={list?.total}
      onPageSettingsChange={handlePageFilters}
      showPagination={false}
      renderExpandedRowData={({ row }) => (
        <ClientPaymentListExpandedRowData data={row} />
      )}
      exandableRow
    ></SimpleTable>
  );
}

export { ClientPaymentListTable };
