import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { InitialNameAvatar } from "../../../../components/images/initial-name-avatar";
import ambassadorHOC from "../../ambassador-hoc";
import { RENDERER_TYPES } from "../../../../enums";
import { AMBASSADOR_MEETINGS_COLUMNS } from "./table-config";
import { AmbassadorMeetingsListTable } from "./table";
import { MeetingStatus } from "../../../invitations/components/meeting-status";
import { convertToFullName } from "../../../../services/utils";

function AmbassadorMeetings({
  getAmbassadorMeetingsList,
  ambassadorMeetingsList,
}: any) {
  const { selectedPage, selectedPageSize } = useSelector(
    (state: any) => state.ambassadors.info.meetings.filters
  );

  useEffect(() => {
    getAmbassadorMeetingsList({ selectedPage, selectedPageSize });
  }, []);

  const handleTDRenderer = (row, column) => {
    switch (column.rendererType) {
      case RENDERER_TYPES.FULL_NAME_WITH_AVATAR:
        return (
          <InitialNameAvatar
            text={convertToFullName(row.firstName, row.lastName)}
            name={row.firstName}
          />
        );
      case RENDERER_TYPES.FULL_DATE:
        return row.date;
      case RENDERER_TYPES.MEETING_STATUS:
        return <MeetingStatus data={row} />;
      default:
        break;
    }
  };

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getAmbassadorMeetingsList({
      selectedPage,
      selectedPageSize,
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <AmbassadorMeetingsListTable
            list={ambassadorMeetingsList}
            columns={AMBASSADOR_MEETINGS_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              selectedPage,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ambassadorHOC(AmbassadorMeetings);
