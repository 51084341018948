import { useEffect } from "react";
import { useSelector } from "react-redux";
import { CLIENT_CAMPAIGNS_COLUMNS } from "./table-config";
import { Box, Grid } from "@mui/material";
import clientHOC from "../../client-hoc";
import { ClientCampaignListTable } from "./table";
import { ButtonWithIcon } from "../../../../components/buttons";

function ClientCampaigns({
  onAddNewCampaignClick,
  getClientCampaignsList,
  clientCampaignsList,
}: any) {
  const { selectedPage, selectedPageSize } = useSelector(
    (state: any) => state.clients.info.campaigns.filters
  );

  useEffect(() => {
    getClientCampaignsList({ selectedPage, selectedPageSize });
  }, []);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getClientCampaignsList({
      selectedPage,
      selectedPageSize,
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container spacing={2} direction="column">
        <Grid item xs={12} container justifyContent="flex-end">
          <ButtonWithIcon
            handleOnClick={onAddNewCampaignClick}
            text={"New Campaign"}
            icon={{ position: "startIcon", name: "add" }}
            sx={{ mr: 1 }}
          />
        </Grid>
        <Grid item>
          <ClientCampaignListTable
            list={clientCampaignsList}
            columns={CLIENT_CAMPAIGNS_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              selectedPage,
            }}
          ></ClientCampaignListTable>
        </Grid>
      </Grid>
    </Box>
  );
}

export default clientHOC(ClientCampaigns);
