import styled from "styled-components";

function Divider({ children }) {
  return (
    <DividerWrapper>
      <div className="divider-border" />
      <span className="divider-content">{children}</span>
      <div className="divider-border" />
    </DividerWrapper>
  );
}

export { Divider };

const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 34px 0px 22px 0px;
  .divider-border {
    border-bottom: 1px solid #6b778c;
    width: 215px;
    @media (max-width: 400px) {
      width: 130px;
    }
  }
  .divider-content {
    padding: 0 10px 0 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #6b778c;
  }
`;
