import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import MuiTabs from "../../../components/mui/tabs";
import AmbassadorRequests from "./ambassador";
import ClientRequests from "./client";

function ConnectRequests() {
  const [selectedTab, setSelectedTab] = useState("ambassador");

  useEffect(() => {}, []);

  const renderTabItems = () => {
    switch (selectedTab) {
      case "ambassador":
        return <AmbassadorRequests />;
      case "client":
        return <ClientRequests />;
      default:
        return <AmbassadorRequests />;
    }
  };

  const handleTabChange = (value) => {
    setSelectedTab(value);
  };

  return (
    <Box display={"flex"} flexDirection="column">
      <MuiTabs
        items={[
          { value: "ambassador", label: "Ambasador" },
          { value: "client", label: "Client" },
        ]}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
      />
      {renderTabItems()}
    </Box>
  );
}

export { ConnectRequests };
