import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputTitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  error: {
    color: "#f44336",
  },
}));
