import { Button } from "@material-ui/core";
import { useStyles } from "./styles";

function PrimaryActionButton({
  text,
  handleOnClick,
  fullWidth = true,
  buttonStyle,
  buttonClass,
  textClass,
  isDisabled = false,
  maxWidth,
  height,
  maxHeight,
  fontSize,
  fontColor,
  fontWeight,
}: any) {
  const classes = useStyles();

  return (
    <Button
      fullWidth={fullWidth}
      variant="contained"
      className={`${classes.primaryActionButton} ${buttonClass} ${textClass}`}
      onClick={handleOnClick}
      type={"submit"}
      style={{
        maxWidth,
        height,
        maxHeight,
        fontSize,
        fontWeight,
        ...buttonStyle,
      }}
      disabled={isDisabled}
    >
      {text}
    </Button>
  );
}

export { PrimaryActionButton };
