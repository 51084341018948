import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  createButtonText: {
    fontSize: 16,
  },
  signupButton: {
    backgroundColor: "#E15143",
    borderRadius: "30px",
    maxWidth: "100%",
  },
  details: {
    textAlign: "center",
    maxWidth: 598,
    marginBottom: theme.spacing(2),
  },
  processBtn: {
    height: 40,
    maxWidth: 200,
  },
  termsConditionsText: {
    color: "blue",
    fontSize: 12,
  },
}));
