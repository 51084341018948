import Chip from "@mui/material/Chip";
import { IStackChip } from "../models";
import { Grid } from "@mui/material";

interface StackOfChipsProps {
  data: IStackChip[];
  onRemove?: any;
  onReset?: any;
  labelField?: string;
  valueField?: string;
}

export default function StackOfChips({
  data,
  onRemove,
  labelField,
  valueField,
}: StackOfChipsProps) {
  
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        {data &&
          data?.map((v, index) => (
            <Chip
              style={{ marginRight: 5, marginBottom: 5 }}
              key={index}
              label={v[labelField]}
              onDelete={() => onRemove(v.field, v[valueField])}
            />
          ))}
      </Grid>
    </Grid>
  );
}
