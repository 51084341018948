import { RENDERER_TYPES } from "../../../../enums";

const AMBASSADOR_PAYMENTS_COLUMNS = [
  { field: "expandable" },
  {
    title: "Client",
    field: "client",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_NAME_WITH_AVATAR,
  },
  {
    title: "Title",
    field: "title",
  },
  {
    title: "Company",
    field: "company",
  },
  {
    title: "Amount",
    field: "total",
    dataType: "string",
    rendererType: RENDERER_TYPES.AMOUNT,
  },
];

const CLIENT_WISE_PAYMENTS_COLUMNS = [
  {
    title: "Date",
    field: "createdAt",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_DATE,
  },
  {
    title: "Amount",
    field: "amount",
    dataType: "string",
    rendererType: RENDERER_TYPES.AMOUNT,
  },
  {
    title: "Status",
    field: "status",
    dataType: "string",
    rendererType: RENDERER_TYPES.PAYMENT_STATUS,
  },
];

const AMBASSADOR_PAYMENTS_ROWS = [
  {
    firstName: "Nirav",
    lastName: "Parekh",
    title: "VP of IT Governance, Risk and Compliance",
    company: "IBM",
    amount: 50000,
    payments: [
      {
        date: "12/02/2022",
        amount: 20000,
        status: "SUCCESS",
      },
      {
        date: "14/01/2022",
        amount: 20000,
        status: "SUCCESS",
      },
      {
        date: "01/12/2022",
        amount: 10000,
        status: "SUCCESS",
      },
    ],
  },
];

export {
  AMBASSADOR_PAYMENTS_COLUMNS,
  CLIENT_WISE_PAYMENTS_COLUMNS,
  AMBASSADOR_PAYMENTS_ROWS,
};
