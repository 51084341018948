import moment from "moment";

const validateSlots = (clientAmbassadorList, upcomingMeetings) => {
  let isFormValid = true;
  let isAtLeastOneSlotSelectedFromAllAmbassador = false;

  const updatedList = [...clientAmbassadorList].map((item, index) => {
    let isAllSlotsEmpty = item.slots.every(
      (itemslot) =>
        !itemslot.selectedSlotTimeFrom && !itemslot.selectedSlotTimeTo
    );
    let isClashedWithUpcomingMeeting = false;
    item.slots.map((itemslot) => {
      upcomingMeetings.map((upcomingMeetingItem) => {
        if (
          moment(itemslot.selectedSlotTimeTo).diff(
            moment(upcomingMeetingItem.selectedTimeSlot.from),
            "minutes"
          ) > 0 &&
          moment(upcomingMeetingItem.selectedTimeSlot.to).diff(
            moment(itemslot.selectedSlotTimeFrom),
            "minutes"
          ) > 0
        ) {
          itemslot.error = "Found overlaping with upcoming meetings";
          isClashedWithUpcomingMeeting = true;
        }
      });
    });

    if (isClashedWithUpcomingMeeting) {
      let isSlotsValid = item.slots.every((item) => item.error === "");

      return {
        ...item,
        isValid: isSlotsValid,
      };
    }

    if (!isAllSlotsEmpty) {
      isAtLeastOneSlotSelectedFromAllAmbassador = true;
      item.slots.map((slotsItem, slotsIndex) => {
        let overLapIndex = [];

        item.slots.map((slotsItemInner, slotsIndexInner) => {
          if (
            slotsIndexInner !== slotsIndex &&
            moment(slotsItem.selectedSlotTimeTo).diff(
              moment(slotsItemInner.selectedSlotTimeFrom),
              "minutes"
            ) > 0 &&
            moment(slotsItemInner.selectedSlotTimeTo).diff(
              moment(slotsItem.selectedSlotTimeFrom),
              "minutes"
            ) > 0 &&
            slotsItem.selectedSlotTimeFrom <=
              slotsItemInner.selectedSlotTimeTo &&
            slotsItemInner.selectedSlotTimeFrom < slotsItem.selectedSlotTimeTo
          ) {
            overLapIndex.push(slotsIndexInner + 1);
          }
        });

        if (overLapIndex.length) {
          slotsItem.error = `Found overlapping with slot ${overLapIndex.join(
            ","
          )}`;
          isFormValid = false;
        } else {
          if (
            (slotsItem.selectedSlotTimeTo && !slotsItem.selectedSlotTimeFrom) ||
            (!slotsItem.selectedSlotTimeTo && slotsItem.selectedSlotTimeFrom)
          ) {
            slotsItem.error = "From and To date are required";
            isFormValid = false;
          } else if (
            slotsItem.selectedSlotTimeTo &&
            slotsItem.selectedSlotTimeFrom &&
            slotsItem.selectedSlotTimeFrom >= slotsItem.selectedSlotTimeTo
          ) {
            slotsItem.error = "Please select valid date and time";
            isFormValid = false;
          } else {
            let ambassadorOverLapIndex = null;
            clientAmbassadorList.map(
              (otherAmbassadors, otherAmbassadorsIndex) => {
                if (
                  otherAmbassadorsIndex !== index &&
                  ambassadorOverLapIndex == null
                ) {
                  let isAllSlotsEmpty = otherAmbassadors.slots.every(
                    (itemslot) =>
                      !itemslot.selectedSlotTimeFrom &&
                      !itemslot.selectedSlotTimeTo
                  );
                  if (!isAllSlotsEmpty) {
                    otherAmbassadors.slots.map(
                      (slotsItemInnerMainAmbassador, slotsIndexInner) => {
                        if (
                          moment(
                            slotsItem.selectedSlotTimeTo
                          ).diff(
                            moment(slotsItemInnerMainAmbassador.selectedSlotTimeFrom),
                            "minutes"
                          ) > 0 &&
                          moment(
                            slotsItemInnerMainAmbassador.selectedSlotTimeTo
                          ).diff(
                            moment(slotsItem.selectedSlotTimeFrom),
                            "minutes"
                          ) > 0 &&
                          slotsItem.selectedSlotTimeFrom <=
                          slotsItemInnerMainAmbassador.selectedSlotTimeTo &&
                          slotsItemInnerMainAmbassador.selectedSlotTimeFrom <
                            slotsItem.selectedSlotTimeTo
                        ) {
                          ambassadorOverLapIndex = slotsIndexInner;
                        }
                      }
                    );

                    if (ambassadorOverLapIndex !== null) {
                      slotsItem.error = `Found overlapping with Ambassador ${
                        otherAmbassadors.firstName
                      }  slot ${ambassadorOverLapIndex + 1}`;
                      isFormValid = false;
                    }
                  }
                }
              }
            );
            if (ambassadorOverLapIndex == null) slotsItem.error = "";
          }
        }
      });

      let isSlotsValid = item.slots.every((item) => item.error === "");

      return {
        ...item,
        isValid: isSlotsValid,
      };
    } else {
      return {
        ...item,
        isValid: undefined,
      };
    }
  });

  if (!isAtLeastOneSlotSelectedFromAllAmbassador) {
    isFormValid = false;
  }

  return {
    isFormValid,
    updatedList,
    isAtLeastOneSlotSelectedFromAllAmbassador,
  };
};

function createSelectedClientSlotsPayload(list) {
  const obj = [];

  list
    .filter((item) => {
      return item.slots.find((slotItem) => slotItem.selectedSlotTimeFrom);
    })
    .map((item) => {
      let payload = {
        ambassadorId: item.id,
        availableTimeSlots: [],
      };

      payload.availableTimeSlots = item.slots
        .filter((slotItem) => {
          return slotItem.selectedSlotTimeFrom && slotItem.selectedSlotTimeTo;
        })
        .map((slotItem) => {
          return {
            from: slotItem.selectedSlotTimeFrom,
            to: slotItem.selectedSlotTimeTo,
          };
        });

      obj.push(payload);
    });

  return obj;
}

export { validateSlots, createSelectedClientSlotsPayload };
