import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import MuiTabs from "../../components/mui/tabs";
import OpportunityEmail from "./email";
import OpportunityList from "./opportunity-list";
import OpportunityResponseList from "./response-list";

function Opportunity() {
  const [selectedTab, setSelectedTab] = useState("email");

  useEffect(() => {}, []);

  const handleEmailTemplateSubmitSuccess=()=>{
    setSelectedTab("requests");
  }

  const renderTabItems = () => {
    switch (selectedTab) {
      case "email":
        return <OpportunityEmail onSubmitSuccess={handleEmailTemplateSubmitSuccess} />;
      case "requests":
        return <OpportunityList />;
      case "response":
        return <OpportunityResponseList />;
      default:
        return <OpportunityEmail onSubmitSuccess={handleEmailTemplateSubmitSuccess} />;
    }
  };

  const handleTabChange = (value) => {
    setSelectedTab(value);
  };

  return (
    <Box display={"flex"} flexDirection="column">
      <MuiTabs
        items={[
          { value: "email", label: "Email Template" },
          { value: "requests", label: "Requests" },
          { value: "response", label: "Response" },
        ]}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
      />
      {renderTabItems()}
    </Box>
  );
}

export default Opportunity;
