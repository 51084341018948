import { ENUM_VALIDATION_TYPES } from "../../../enums";

const LOGIN_FORM_ERROR_MAAPING = [
  {
    placeHolder: "Email",
    type: ENUM_VALIDATION_TYPES.EMAIL,
    fieldName: "email",
  },
  {
    placeHolder: "Password",
    fieldName: "password",
  },
];

export { LOGIN_FORM_ERROR_MAAPING };
