import { useEffect, useState } from "react";
import {
  MPSelectedIntrestedInList,
  MPSelectedKeywordsList,
  MPLogo,
  MPProfileDescription,
  MPProfileLinks,
  MPSelectedCategoriesList,
  MPSelectedCertificationsList,
  MPUploadsList,
} from "./components";
import MPProfileCompanyDetails from "./company-details";
import { useHistory } from "react-router";
import { ROUTE_PATHS } from "../../../routes/route-paths";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import { AppModalDialogBox } from "../../../components/modals";
import ShareForm from "./share-form";
import ContactInfo from "./contact-info";

export default function ProfileCard({
  fileUploads,
  logo,
  data,
  showEditBtn = false,
  showShareBtn = true,
  showViewCount = true,
}) {
  const [modifiedData, setModifiedData] = useState(data);
  const history = useHistory();
  const [showShareProfileModal, setShowShareProfileModal] = useState(false);
  const [shareBtnDisabled, setShareBtnDisabled] = useState(undefined);
  const [showContactInfo, setShowContactInfo] = useState(false);

  useEffect(() => {
    if (data) {
      const modifiedData = {
        ...data,
        keywords: Array.isArray(data?.keywords)
          ? data?.keywords
          : data?.keywords?.split(",")?.map((k) => ({ label: k })),
      };
      setModifiedData(modifiedData);
    }
  }, [data]);

  const handleShareProfile = (event) => {
    event.preventDefault();

    if (!data?.isRequestedForUpdate && shareBtnDisabled === undefined) {
      setShowShareProfileModal(true);
    }
  };

  const handleClose = () => {
    setShowShareProfileModal(false);
    setShowContactInfo(false);
  };

  const handleShareProfileSubmit = () => {
    setShareBtnDisabled(true);
    setShowShareProfileModal(false);
  };

  const handleShowContactInfoClick = () => {
    setShowContactInfo(true);
  };

  const handleEditClick = () => {
    history.push(ROUTE_PATHS.MARKETPLACE.EDIT.replace(":id", data?.id));
  };

  return (
    <div className="preview-data-block">
      <div className="vendor-detail-box">
        <div className="vendor-detail-left">
          <div className="vendor-top">
            <div className="vendor-top-left">
              <MPLogo logo={logo} />
            </div>
            <div className="vendor-top-right">
              <h2>{modifiedData?.name}</h2>

              <MPProfileLinks
                data={modifiedData}
                showViewCount={showViewCount}
              />
            </div>
          </div>
          <MPProfileDescription description={modifiedData?.description} />
          <MPProfileCompanyDetails data={modifiedData} />

          {modifiedData?.interestedId && (
            <MPSelectedIntrestedInList data={modifiedData?.interestedId} />
          )}
          {showShareBtn && (
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mt={2}
            >
              <div>
                <a
                  className="btn"
                  style={{
                    backgroundColor:
                      data?.isRequestedForUpdate || shareBtnDisabled
                        ? "grey"
                        : "",
                  }}
                  onClick={handleShareProfile}
                >
                  <span>Share</span>
                </a>
                {(data?.isRequestedForUpdate ||
                  shareBtnDisabled !== undefined) && (
                  <Tooltip
                    title="This profile has been already shared."
                    placement="right"
                    arrow
                  >
                    <IconButton
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      sx={{ padding: 0 }}
                    >
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              {data?.contactInfo && (
                <Typography
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  color={"#0000ac"}
                  onClick={handleShowContactInfoClick}
                >
                  Show Contact Info
                </Typography>
              )}
              {showEditBtn && (
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Box
                    alignItems={"center"}
                    display={"flex"}
                    component={Button}
                    variant="contained"
                    gap={1}
                    bgcolor={"#0000ac"}
                    mr="13px"
                    onClick={handleEditClick}
                  >
                    <ModeOutlinedIcon fontSize="inherit" />
                    <Typography component={"span"}>EDIT</Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </div>

        <div className="vendor-detail-right">
          {Array.isArray(modifiedData?.keywords) &&
            modifiedData?.keywords?.filter((k) => k?.["label"] !== "")?.length >
              0 && <MPSelectedKeywordsList keywords={modifiedData?.keywords} />}

          {modifiedData?.categories && (
            <MPSelectedCategoriesList categories={modifiedData?.categories} />
          )}

          {modifiedData?.certification && (
            <MPSelectedCertificationsList
              certifications={modifiedData?.certification}
            />
          )}

          {Object.keys(fileUploads)?.some((fl) => fileUploads[fl]) && (
            <MPUploadsList fileUploads={fileUploads} />
          )}
        </div>
      </div>
      {showShareProfileModal && (
        <AppModalDialogBox
          isModalOpen={true}
          handleClose={handleClose}
          title={"Share Profile"}
          size={"md"}
        >
          <ShareForm
            id={data?.id}
            onSubmitSuccess={handleShareProfileSubmit}
            onClose={handleClose}
          />
        </AppModalDialogBox>
      )}
      {showContactInfo && (
        <AppModalDialogBox
          isModalOpen={true}
          handleClose={handleClose}
          title={"Contact Info"}
          size={"sm"}
        >
          <ContactInfo data={data?.contactInfo} />
        </AppModalDialogBox>
      )}
    </div>
  );
}
