import Client from "./3126649 1.svg";
import Ambassador from "./icons8-ambassador-50 1.svg";
import Dashboard from "./icons8-dashboard-48 1.svg";
import Invitations from "./icons8-tear-off-calendar-50 1.svg";
import Circles from "./Circles.svg";
import Frame24 from "./Frame 24.svg";
import Frame25 from "./Frame 25.svg";
import Frame26 from "./Frame 26.svg";
import Opportunity from "./opportunity.svg";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";

const SvgIcons = {
  Client,
  Ambassador,
  Dashboard,
  Invitations,
  Circles,
  Frame24,
  Frame25,
  Frame26,
  SensorOccupiedIcon,
  Opportunity,
};

export default SvgIcons;
