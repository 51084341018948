import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import MuiTabs from "../../../components/mui/tabs";
import AmbassadorsInfo from "./info";
import AmbassadorMeetings from "./meetings";
import AmbassadorPayments from "./payments";
import ambassadorHOC from "../ambassador-hoc";
import { useDispatch } from "react-redux";
import {
  resetSelectedAmbassador,
  resetAmbassadorInfo,
} from "../../../redux/modules/ambassadors/actions";
import { convertToFullName, convertToUrl } from "../../../services/utils";
import { ROUTE_PATHS } from "../../../routes/route-paths";

function AmbassadorDetails({
  ambassadorDetails,
  getAmbassadorDetails,
  setBreadcrumbLabelCustomText,
}) {
  const { id, tab }: any = useParams();
  const [selectedTab, setSelectedTab] = useState(tab);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getAmbassadorDetails(id);

    return () => {
      dispatch(resetSelectedAmbassador());
      dispatch(resetAmbassadorInfo());
    };
  }, []);

  useEffect(() => {
    setBreadcrumbLabelCustomText(
      convertToFullName(
        ambassadorDetails?.firstName,
        ambassadorDetails?.lastName
      )
    );
  }, [ambassadorDetails]);

  const renderTabItems = () => {
    switch (selectedTab) {
      case "info":
        return <AmbassadorsInfo />;
      case "meetings":
        return <AmbassadorMeetings />;
      case "payments":
        return <AmbassadorPayments />;
      default:
        break;
    }
  };

  const handleTabChange = (value) => {
    setSelectedTab(value);

    const url = convertToUrl(ROUTE_PATHS.AMBASSADORS.DETAILS, {
      ":id": id,
      ":tab": value,
    });

    history.push(url);
  };

  if (!ambassadorDetails) return null;

  return (
    <Box display={"flex"} flexDirection="column">
      <MuiTabs
        items={[
          { value: "info", label: "Info" },
          { value: "meetings", label: "Meetings" },
          { value: "payments", label: "Payments" },
        ]}
        selectedTab={tab}
        onTabChange={handleTabChange}
      />
      {renderTabItems()}
    </Box>
  );
}

export default ambassadorHOC(AmbassadorDetails);
