import { MuiMultiSelect } from "../selectbox";
import { AppChip } from "../chips";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectboxClass: {
    marginBottom: theme.spacing(1),
  },
  chipClass: {
    margin: theme.spacing(0.3),
    maxWidth: 300,
    border: "1px solid #E15143",
    backgroundColor: "rgba(217, 79, 65, 0.1)",
    color: "#E15143",
  },
}));

function SelectboxWithChips({
  list,
  label,
  selectedValues = [],
  listProperties,
  handleOnRemove,
  handleChange,
  name,
  error,
  showChips = true,
  inputFieldClass,
  inlineLabel,
}: any) {
  const classes = useStyles();

  return (
    <>
      <MuiMultiSelect
        label={label}
        inputFieldClass={inputFieldClass}
        value={selectedValues}
        handleChange={handleChange}
        list={list}
        properties={listProperties}
        wrapperClass={classes.selectboxClass}
        name={name}
        error={error}
        inlineLabel={inlineLabel}
      />
      {showChips &&
        selectedValues.map((item, index) => (
          <AppChip
            label={item}
            key={index}
            wrapperClass={classes.chipClass}
            handleDelete={() => {
              handleOnRemove(item);
            }}
          />
        ))}
    </>
  );
}

export { SelectboxWithChips };
