import { Fragment, useEffect, useState } from "react";
import useToggle from "../../../../hooks/useToggle";
import { Button, Grid, Typography } from "@mui/material";
import AddOrUpdateIntrested from "./add-or-update";
import IntrestedBlock from "./intrested-block";
import { AppModalDialogBox } from "../../../../components/modals";

export default function AmbassadorIntrestedIn({ data, onPriorityChange }) {
  const [openEditModal, setOpenEditModal] = useToggle(false);
  const [selectedIntrested, setSelectedIntrested] = useState(null);
  const [priorities, setPriorities] = useState([]);

  useEffect(() => {
    data?.topics && setPriorities([...data?.topics]);
  }, [data?.topics]);

  useEffect(() => {
    selectedIntrested && setOpenEditModal();
  }, [selectedIntrested]);

  useEffect(() => {
    onPriorityChange(priorities);
  }, [priorities]);

  const onDelete = async (priority) => {
    const tmpPriority = [...priorities].filter(
      (t) => t?.label !== priority?.label
    );

    setPriorities(tmpPriority);
  };

  const onSubmit = (d) => {
    if (selectedIntrested) {
      let txp = [...priorities];
      const txpIndex = txp?.findIndex(
        (t) => t?.label === selectedIntrested?.label
      );
      txp[txpIndex] = d;

      setPriorities(txp);
    } else {
      setPriorities([...priorities, d]);
    }
    setOpenEditModal();
    setSelectedIntrested(null);
  };

  const onClose = () => {
    setOpenEditModal();
    setSelectedIntrested(null);
  };

  const topics = priorities
    ? Array.isArray(priorities)
      ? priorities
      : []
    : [];

  return (
    <Fragment>
      <Grid
        item
        xs={12}
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        mt={4}
      >
        <Typography fontSize={15} fontWeight={"500"} color="#000000">
          Cybersecurity Products You’re Interested In
        </Typography>
        <Button variant="outlined" size="small" onClick={setOpenEditModal}>
          + Add
        </Button>
      </Grid>
      <Grid item spacing={2} container mt={1}>
        {topics &&
          topics?.length > 0 &&
          topics?.map((intrested, intrestedIndex) => (
            <Grid item xs={12} sm={4} key={`intrestedlist-${intrestedIndex}`}>
              <IntrestedBlock
                data={intrested}
                editable
                onEditClick={() => setSelectedIntrested(intrested)}
                onDeleteClick={() => onDelete(intrested)}
              />
            </Grid>
          ))}
      </Grid>

      {openEditModal && (
        <AppModalDialogBox
          title={"Intrested In"}
          handleClose={onClose}
          isModalOpen
          size="xs"
        >
          <AddOrUpdateIntrested
            onSubmit={onSubmit}
            onCancel={onClose}
            selectedIntrested={selectedIntrested}
            priorityList={priorities}
          />
        </AppModalDialogBox>
      )}
    </Fragment>
  );
}
