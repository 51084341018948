import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Autocomplete, Box, Button, Chip, TextField } from "@mui/material";
import {
  MPCheckboxGroupProps,
  MPFileUploadProps,
  MPFilterKeywordInputSearchboxProps,
  MPFormGroupProps,
  MPLogoUploadBlockProps,
  MPMultiSelectAutocompleteChipTextFieldProps,
  MPPriceInputProps,
  MPSelectInputProps,
  MPServerErrorMessageProps,
  MPSingleSelectAutocompleteProps,
  MPTextareaInputProps,
  MPTextInputProps,
  OptionType,
} from "./interfaces";
import useClickOutside from "../../../hooks/useClickOutside";
import { formatNumberWithCommas } from "../../../services/utils";
import useEffectAfterMount from "../../../hooks/useEffectAfterMount";
import { IMAGES_PATH } from "../../../constants";
import { PhotoFileUpload } from "../../../components/images/crop-image-upload";
import { MPLogo } from "../profile-card/components";

export const MPFormGroup: React.FC<MPFormGroupProps> = ({
  label,
  children,
  error = "",
  helperText = "",
}) => (
  <div className={`form-grp ${error ? "form-error" : ""}`}>
    <label>{label}</label>
    {children}
    <span
      className={error ? "msg-note" : ""}
      style={{ color: helperText && "#666666" }}
    >
      {error || helperText}
    </span>
  </div>
);

export const MPTextInput: React.FC<MPTextInputProps> = ({
  type = "text",
  className = "form-control",
  ...props
}) => <input type={type} className={className} {...props} />;

export const MPTextareaInput: React.FC<MPTextareaInputProps> = ({
  className = "form-control",
  ...props
}) => <textarea className={className} {...props}></textarea>;

export const MPSelectInput: React.FC<MPSelectInputProps> = ({
  className = "form-control",
  options,
  valueKey = "id",
  labelKey = "label",
  flatList = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const ref = useClickOutside(() => setOpen(false));

  return (
    <div className="custom-select" ref={ref}>
      <select className={className} {...props} onClick={() => setOpen(!open)}>
        <option value="">Select</option>
        {options?.map((option, index) => (
          <option key={index} value={flatList ? option : option?.[valueKey]}>
            {flatList ? option : option?.[labelKey]}
          </option>
        ))}
      </select>
    </div>
  );
};

export const MPCheckboxGroup: React.FC<MPCheckboxGroupProps> = ({
  options,
  valueKey = "id",
  labelKey = "label",
  label = "Select",
  value = "",
  onChange,
  name = "",
  onFocus,
}) => {
  const [open, setOpen] = useState(false);
  const selectedValues = Array.isArray(value)
    ? value
    : value
    ? value?.split(",").reverse()
    : [];
  const ref = useClickOutside(() => setOpen(false));

  const handleToggle = (e) => {
    onFocus &&
      onFocus({
        ...e,
        target: {
          ...e.target,
          name,
        },
      });
    setOpen(!open);
  };

  const handleCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const updatedValues = selectedValues?.includes(id)
      ? selectedValues?.filter((val) => val !== id)
      : [...selectedValues, id];

    if (onChange) {
      onChange({
        ...e,
        target: {
          ...e.target,
          name,
          value: updatedValues?.join(","),
        },
      });
    }
  };

  return (
    <div className="custom-filter" ref={ref}>
      <button
        className="custom-filter-button"
        type="button"
        onClick={handleToggle}
      >
        {selectedValues?.filter((sv) => sv !== "")?.join(", ") || label}
      </button>
      <div
        className="custom-filter-dropdown"
        style={{ display: open ? "block" : "none" }}
      >
        {options?.map((option, index) => (
          <div className="form-group" key={index}>
            <input
              type="checkbox"
              name={name}
              id={option[valueKey]}
              checked={selectedValues?.includes(option[valueKey])}
              onChange={(e) => handleCheckboxChange(e, option[valueKey])}
            />
            <label htmlFor={option[valueKey]}> {option[labelKey]}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export const MPFileUpload: React.FC<MPFileUploadProps> = ({
  name,
  multiple = false,
  onChange,
  accept = ".pdf, .doc, .docx",
}) => (
  <input
    type="file"
    className="form-control"
    accept={accept}
    name={name}
    multiple={multiple}
    onChange={onChange}
  />
);

export const MPPriceInput: React.FC<MPPriceInputProps> = ({
  name,
  value,
  onChange,
  className = "form-control",
  onFocus,
}) => {
  const [displayValue, setDisplayValue] = useState(
    formatNumberWithCommas(value)
  );

  useEffect(() => {
    setDisplayValue(formatNumberWithCommas(value));
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let rawValue = e.target.value.replace(/,/g, "");

    // Strip any decimal part
    rawValue = rawValue.split(".")[0];

    if (!isNaN(Number(rawValue)) || rawValue === "") {
      setDisplayValue(formatNumberWithCommas(rawValue));
      onChange({
        ...e,
        target: {
          ...e.target,
          name,
          value: rawValue,
        },
      });
    }
  };

  return (
    <input
      type="text"
      className={className}
      name={name}
      value={displayValue}
      onChange={handleChange}
      onFocus={onFocus}
    />
  );
};

export const MPFilterKeywordInputSearchbox: React.FC<
  MPFilterKeywordInputSearchboxProps
> = ({
  options,
  value,
  name,
  onChange,
  onInputChange,
  freeSolo = false,
  placeholder,
  error,
  onFocus = () => {},
}: any) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    value?.split(",").filter(Boolean)
  );
  const [inputValue, setInputValue] = useState<any>();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
    setSelectedOptions(newValue);

    const syntheticEvent = {
      ...event,
      target: {
        ...event?.target,
        name,
        value: newValue?.join(","),
      },
    };

    onChange(
      syntheticEvent as React.ChangeEvent<{ name?: string; value: string }>
    );
  };

  const handleBlur = () => {
    if (inputValue && !selectedOptions.includes(inputValue)) {
      const newSelectedOptions = [...selectedOptions, inputValue];
      setSelectedOptions(newSelectedOptions);
      setInputValue("");
      const syntheticEvent = {
        target: {
          name,
          value: newSelectedOptions.join(","),
        },
      };
      onChange(
        syntheticEvent as React.ChangeEvent<{ name?: string; value: string }>
      );
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    const syntheticEvent = {
      ...event,
      target: {
        ...event?.target,
        name,
        value: newInputValue,
      },
    };

    setInputValue(newInputValue);

    onInputChange &&
      onInputChange(
        syntheticEvent as React.ChangeEvent<{ name?: string; value: string }>
      );
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      size="small"
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderTags={(value: string[], getTagProps) =>
        value?.map((option: string, index: number) => (
          <Chip
            variant="outlined"
            size="small"
            color="primary"
            label={option}
            key={`MultiSelectAutocompleteChipTextField-${index}`}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          variant="outlined"
          onBlur={handleBlur}
          onFocus={(e) => {
            onFocus &&
              onFocus({
                ...e,
                target: {
                  ...e.target,
                  name,
                },
              });
          }}
          placeholder={placeholder ?? "Type and press enter"}
          sx={{
            "& .MuiInputBase-input::placeholder": {
              fontSize: 13,
            },
          }}
        />
      )}
    />
  );
};

export const MPServerErrorMessage: React.FC<MPServerErrorMessageProps> = ({
  error,
}) => <div className="error-info-msg">{error}</div>;

export const MPMultiSelectAutocompleteChipTextField = forwardRef<
  { clearAll: () => void },
  MPMultiSelectAutocompleteChipTextFieldProps
>(
  (
    {
      options,
      value,
      name,
      onChange,
      onInputChange,
      freeSolo = false,
      placeholder,
    },
    ref
  ) => {
    const [selectedOptions, setSelectedOptions] = useState<string[]>(
      value.split(",").filter(Boolean)
    );
    const [inputValue, setInputValue] = useState<any>();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
      setSelectedOptions(newValue);

      const syntheticEvent = {
        ...event,
        target: {
          ...event?.target,
          name,
          value: newValue?.join(","),
        },
      };

      onChange(
        syntheticEvent as React.ChangeEvent<{ name?: string; value: string }>
      );
    };

    const handleBlur = () => {
      if (inputValue && !selectedOptions.includes(inputValue)) {
        const newSelectedOptions = [...selectedOptions, inputValue];
        setSelectedOptions(newSelectedOptions);
        setInputValue("");
        const syntheticEvent = {
          target: {
            name,
            value: newSelectedOptions.join(","),
          },
        };
        onChange(
          syntheticEvent as React.ChangeEvent<{ name?: string; value: string }>
        );
      }
    };

    const clearAll = () => {
      setSelectedOptions([]);
      setInputValue("");
      const syntheticEvent = {
        target: {
          name,
          value: "",
        },
      };
      onChange(
        syntheticEvent as React.ChangeEvent<{ name?: string; value: string }>
      );
    };

    const handleInputChange = (
      event: React.ChangeEvent<{}>,
      newInputValue: string
    ) => {
      const syntheticEvent = {
        ...event,
        target: {
          ...event?.target,
          name,
          value: newInputValue,
        },
      };

      setInputValue(newInputValue);

      onInputChange &&
        onInputChange(
          syntheticEvent as React.ChangeEvent<{ name?: string; value: string }>
        );
    };

    useImperativeHandle(ref, () => ({
      clearAll,
    }));

    return (
      <Autocomplete
        multiple
        freeSolo
        size="small"
        options={options}
        value={selectedOptions}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        renderTags={(value: string[], getTagProps) =>
          value?.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              size="small"
              color="primary"
              label={option}
              key={`MultiSelectAutocompleteChipTextField-${index}`}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            onBlur={handleBlur}
            placeholder={placeholder ?? "Type and press enter"}
            sx={{
              "& .MuiInputBase-input::placeholder": {
                fontSize: 13,
              },
            }}
          />
        )}
      />
    );
  }
);

export const MPSingleSelectAutocomplete: React.FC<
  MPSingleSelectAutocompleteProps
> = ({
  options,
  name,
  valueKey = "id",
  labelKey = "label",
  value,
  placeholder = "Select an option",
  onChange,
  onFocus,
  error = false,
}) => {
  const [inputValue, setInputValue] = useState<string>("");

  // Update the input value when the selected value changes
  useEffect(() => {
    const selectedOption = options?.find(
      (option) => option[valueKey] === value
    );
    setInputValue(selectedOption ? selectedOption[labelKey] : "");
  }, [value, options, valueKey, labelKey]);

  // Handle the change event
  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: OptionType | null
  ) => {
    const newValueString = newValue ? newValue[valueKey] : "";
    const syntheticEvent = {
      target: {
        name,
        value: newValueString,
      },
    };
    onChange(
      syntheticEvent as React.ChangeEvent<{ name?: string; value: string }>,
      newValueString
    );
  };

  const handleFocus = (e) => {
    onFocus &&
      onFocus({
        ...e,
        target: {
          ...e.target,
          name,
        },
      });
  };

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option?.[labelKey] || ""}
      value={options?.find((option) => option[valueKey] === value) || null}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      onFocus={handleFocus}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          error={error}
        />
      )}
      onClose={() => {
        if (!value) {
          setInputValue("");
        }
      }}
      size="small"
    />
  );
};

export const MPFilterMultiselectBox = forwardRef(
  ({ options, name, labelField, valueField, onChange, value }: any, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedOptions, setSelectedOptions] = React.useState(value || []);
    const dropdownRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropdownRef]);

    useEffectAfterMount(() => {
      onChange({
        target: { name, value: selectedOptions },
      });
    }, [selectedOptions]);

    const toggleOpen = () => {
      setIsOpen(!isOpen);
    };

    const handleOptionChange = (op) => {
      const selected = selectedOptions?.find(
        (so) => so?.[valueField] === op?.[valueField]
      );

      if (selected) {
        setSelectedOptions(
          selectedOptions?.filter((so) => so?.[valueField] !== op?.[valueField])
        );
      } else {
        setSelectedOptions([...selectedOptions, op]);
      }
    };

    const checkIsSelected = (selectedOptions, op, valueField) => {
      return selectedOptions?.some(
        (so) => so?.[valueField] === op?.[valueField]
      );
    };

    const clearAll = () => {
      setSelectedOptions([]);
    };

    useImperativeHandle(ref, () => ({
      clearAll,
    }));

    return (
      <div className="custom-filter" ref={dropdownRef}>
        <button
          className="custom-filter-button"
          type="button"
          onClick={toggleOpen}
        >
          {getSelectedItems(selectedOptions, labelField) ?? "Select"}
        </button>
        <div
          className="custom-filter-dropdown"
          style={{ display: isOpen ? "block" : "none" }}
        >
          {options?.map((op) => (
            <div
              key={`marketplace-selectbox-option-${name}-${op?.[valueField]}`}
              className="form-group"
            >
              <input
                type="checkbox"
                id={op?.[labelField]}
                name="executive"
                checked={checkIsSelected(selectedOptions, op, valueField)}
                onChange={(e) => handleOptionChange(op)}
              />
              <label htmlFor={op?.[labelField]}>{op?.[labelField]}</label>
            </div>
          ))}
        </div>
      </div>
    );
  }
);

function getSelectedItems(selectedOptions, labelField) {
  if (!selectedOptions?.length || !labelField) return null;

  return selectedOptions
    ?.map((so) => so?.[labelField])
    ?.reverse()
    ?.join(", ");
}

export const MPLogoUploadBlock: React.FC<MPLogoUploadBlockProps> = ({
  logo,
  onUpload,
}) => {
  return (
    <div className="image-upload-block">
      <PhotoFileUpload
        preview={logo ?? `${IMAGES_PATH}/default.jpg`}
        onUpload={onUpload}
        aspect={1}
        cropShape="rect"
        filename="company-logo"
        component={
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            <MPLogo logo={logo} className="avatar-preview-img" />

            <Button variant="contained" className="btn">
              Browse Image
            </Button>
          </Box>
        }
      />
    </div>
  );
};

export const ClearButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <span className="clear-filter clickable" onClick={onClick}>
    <img src={`${IMAGES_PATH}/close_clear.png`} />
    Clear Filter
  </span>
);
