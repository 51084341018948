import Routes from "../routes";
import { BrowserRouter } from "react-router-dom";
import { Notifications } from "../components/notifications";
import { AppSpinner } from "../components/spinners";
import ScrollIntoView from "../components/shared/scroll-view";
const AppView = Routes;

export default function Screens() {
  return (
    <BrowserRouter>
      <ScrollIntoView>
          <AppSpinner />
          <Notifications />
          <AppView />
      </ScrollIntoView>
    </BrowserRouter>
  );
}
