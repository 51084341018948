import { SquareChip } from "../../../components/chips/square-chip";
import { CONST_PAYMENT_STATUS_LIST } from "../../../constants/lists";
import { getPaymentStatus } from "../../../services/utils";

function PaymentStatus({ data }) {
  const status = getPaymentStatus(data);

  return (
    <SquareChip
      text={CONST_PAYMENT_STATUS_LIST[status]?.label}
      color={CONST_PAYMENT_STATUS_LIST[status]?.fontColor}
      bgColor={CONST_PAYMENT_STATUS_LIST[status]?.bgColor}
    />
  );
}

export { PaymentStatus };
