import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { LabelAndValue } from "../../components/typography";

function ClientTimezoneSlotDetails({ from, to, name }) {
  return (
    <Card>
      <CardContent>
        <Typography sx={{ color: "red" }} variant="caption">
          {`${name}'s timezone`}
          <Divider />
        </Typography>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <LabelAndValue
              label={"From"}
              value={from}
              labelxs={2}
              labelmd={2}
              labelsm={2}
              color={"#000000"}
              valueColor="blue"
              size={14}
              fontWeight={{ label: 600, value: 400 }}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelAndValue
              label={"To"}
              value={to}
              labelxs={2}
              labelmd={2}
              labelsm={2}
              color="#000000"
              valueColor="blue"
              size={14}
              fontWeight={{ label: 600, value: 400 }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export { ClientTimezoneSlotDetails };
