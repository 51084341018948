import { CLEAR_ALL } from "..";

export const SET_USER_INFO = "SET_USER_INFO";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

export const authInitialState = {
  user: {
    isLoggedIn: false,
  },
};

const auth = (state = authInitialState, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          isLoggedIn: true,
          ...action.payload.user,
          token: `Bearer ${action.payload.user.token}`,
        },
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case CLEAR_ALL: {
      return { ...authInitialState };
    }
    default:
      return state;
  }
};

export default auth;
