import {
  DEFAULT_SELECTED_PAGE,
  DEFAULT_SELECTED_PAGE_SIZE,
} from "../../../constants";

const SET_OPPORTUNITY_LIST_LOADING = "SET_OPPORTUNITY_LIST_LOADING";
const SET_OPPORTUNITY_LIST = "SET_OPPORTUNITY_LIST";
const SET_OPPORTUNITY_LIST_FILTERS = "SET_OPPORTUNITY_LIST_FILTERS";
const RESET_OPPORTUNITY_LIST_FILTERS = "RESET_OPPORTUNITY_LIST_FILTERS";

const initialOpportunityListState = {
  data: undefined,
  loading: null,
  filters: {
    selectedPage: DEFAULT_SELECTED_PAGE,
    selectedPageSize: DEFAULT_SELECTED_PAGE_SIZE,
  },
  total: undefined,
};

const setOpportunityListLoading = (payload) => ({
  type: SET_OPPORTUNITY_LIST_LOADING,
  payload,
});

const setOpportunityList = (payload) => ({
  type: SET_OPPORTUNITY_LIST,
  payload,
});

const setOpportunityListFilters = (payload = undefined) => ({
  type: SET_OPPORTUNITY_LIST_FILTERS,
  payload,
});

const resetOpportunityListFilters = () => ({
  type: RESET_OPPORTUNITY_LIST_FILTERS,
});

export {
  initialOpportunityListState,
  setOpportunityListLoading,
  setOpportunityList,
  setOpportunityListFilters,
  resetOpportunityListFilters,
  SET_OPPORTUNITY_LIST_LOADING,
  SET_OPPORTUNITY_LIST,
  SET_OPPORTUNITY_LIST_FILTERS,
  RESET_OPPORTUNITY_LIST_FILTERS,
};
