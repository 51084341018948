import { Menu, MenuItem, Typography } from "@mui/material";
import { AppText } from "../../../typography";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ROUTE_PATHS } from "../../../../routes/route-paths";
import { Avatar, Box, CardHeader } from "@mui/material";

function AuthActions() {
  let history = useHistory();
  const { user } = useSelector((state: any) => state.auth);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const onLogoutClick = () => {
    history.push(ROUTE_PATHS.auth.LOGOUT);
  };

  const onLinkClick = () => {};

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const renderMenu = () => (
    <Menu
      anchorEl={anchorEl}
      id={"navigation-menu"}
      keepMounted
      open={isMenuOpen}
      autoFocus={false}
      MenuListProps={{
        sx: { width: 150 },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={onLinkClick}>
        <Typography fontWeight={600} fontSize={13.89}>
          Profile
        </Typography>
      </MenuItem>
      <MenuItem onClick={onLogoutClick}>
        <Typography fontWeight={600} fontSize={13.89}>
          Logout
        </Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Box
        onClick={handleMenuOpen}
        display={"flex"}
        justifyContent="center"
        alignItems={"center"}
        sx={{ cursor: "pointer" }}
      >
        <CardHeader
          sx={{ padding: 0 }}
          titleTypographyProps={{
            sx: { fontWeight: 500, fontFamily: "Poppins" },
          }}
          avatar={
            <Avatar sx={{ bgcolor: "#070639" }}>{user.firstName[0]}</Avatar>
          }
          title={user.firstName}
        />
        <ExpandMoreIcon htmlColor="grey" />
      </Box>
      {renderMenu()}
    </>
  );
}

export { AuthActions };
