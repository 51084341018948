const INVITATION_ACCEPT_FORM_FIELDS = [
  { field: "zoomRecodingUrl", label: "Zoom Recording URL" },
  { field: "ambassadorFeedbackUrl", label: "Ambassador Feedback URL" },
  { field: "clientFeedbackUrl", label: "Client Feedback URL" },
];

const INVITATION_ACCEPT_FORM_ERROR_MAAPING = [
  {
    placeHolder: "Zoom Recording URL",
    fieldName: "zoomRecodingUrl",
  },
  {
    placeHolder: "Ambassador Feedback URL",
    fieldName: "ambassadorFeedbackUrl",
  },
  {
    placeHolder: "Client Feedback URL",
    fieldName: "clientFeedbackUrl",
  },
];

export { INVITATION_ACCEPT_FORM_FIELDS, INVITATION_ACCEPT_FORM_ERROR_MAAPING };
