import { Grid, Typography } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ChipTextField } from "../../../components/chips/chip-textfield";
import { REGEX } from "../../../constants";

const EmailAddressBox = forwardRef((props: any, ref: any) => {
  const {
    defaultValue = undefined,
    onValueChange,
    inlineLabel = false,
    label = "",
  }: any = props;
  const [emailIds, setEmailIds] = useState({
    list: [],
    invalidEmailIds: [],
  });

  useEffect(() => {
    defaultValue &&
      setEmailIds({
        ...emailIds,
        list: defaultValue,
      });
  }, [defaultValue]);

  useEffect(() => {
    onValueChange(emailIds);
  }, [emailIds]);

  useImperativeHandle(ref, () => ({
    resetEmailIds: () => {
      setEmailIds({
        list: [],
        invalidEmailIds: [],
      });
    },
  }));

  const handleOnChange = (e) => {
    const invalidEmailIndexes = [];
    if (e.target.value && e.target.value.length) {
      e.target.value.forEach((item, index) => {
        if (!REGEX.EMAIL.test(item)) {
          invalidEmailIndexes.push(index);
        }
      });
    }

    setEmailIds({
      invalidEmailIds: invalidEmailIndexes,
      list: e.target.value || "",
    });
  };

  if (inlineLabel)
    return (
      <ChipTextField
        id={"add-emailIds"}
        onChange={handleOnChange}
        error={
          emailIds.invalidEmailIds.length > 0 && "Please enter valid email ids"
        }
        value={emailIds.list}
        textfieldStyle={{ height: 27 }}
        invalidChips={emailIds.invalidEmailIds}
        label={label || "Add Member"}
        inlineLabel
      />
    );

  return (
    <Grid item container>
      <Grid item xs={3}>
        <Typography variant="caption">{label || "Add Member:"} </Typography>
      </Grid>
      <Grid item xs={9}>
        <ChipTextField
          id={"add-emailIds"}
          onChange={handleOnChange}
          error={
            emailIds.invalidEmailIds.length > 0 &&
            "Please enter valid email ids"
          }
          value={emailIds.list}
          textfieldStyle={{ height: 27 }}
          invalidChips={emailIds.invalidEmailIds}
        />
      </Grid>
    </Grid>
  );
});

export { EmailAddressBox };
