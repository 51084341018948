import { Container, Grid } from "@mui/material";
import { Logo } from "../../logo";
import { SupportHelpLineText } from "../../shared";

function AppLayoutHeader({ configs }) {
  return (
    <Container sx={{ pt: 3 }}>
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={6}>
          {configs.showLogo && <Logo color="black" />}
        </Grid>
        <Grid item xs={6} container justifyContent={"flex-end"}>
          <SupportHelpLineText textColor="black" />
        </Grid>
      </Grid>
    </Container>
  );
}

export { AppLayoutHeader };
