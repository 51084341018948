import React from "react";
import { connect } from "react-redux";
import { SomethingWentWrong } from "../components/errors";

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {}

  logout = () => {
    window.location.href = "/logout";

    return null;
  };

  render() {
    if (this.props.isServerError?.responseStatus === 401) {
      return this.logout();
    }
    if (this.state.hasError || this.props.isServerError.error) {
      return <SomethingWentWrong />;
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    isServerError: state.UI.isServerError,
  };
};

export default connect(mapStateToProps, null)(ErrorBoundary);
