function PrivacyPolicy({ onTermsOfServiceClick }) {
  return (
    <div>
      <h1
        dir="ltr"
        style={{
          lineHeight: "1.3800000000000001",
          textAlign: "center",
          marginTop: "0pt",
          marginBottom: "6pt",
        }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          EXECWEB PLATFORM PRIVACY POLICY
        </span>
      </h1>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Introduction
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              . &nbsp;
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  This privacy policy (“
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Privacy Policy
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”) describes the information collected by Execweb Inc. and its
                  affiliates (“
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  we
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ,” “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  us
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ,” “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  our
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ,” or “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Execweb
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”), how that information may be used, with whom it may be
                  shared, and the options you have to control the dissemination
                  and use of your information.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  By using or accessing: our websites, applications and
                  associated domains of www.Execweb.com, (b) a client account,
                  (c) any application made available by Execweb, or (d) any
                  other services we provide (collectively, the “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Platform
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”), you agree to the practices set forth in this Privacy
                  Policy and agree to be bound by our{" "}
                  <a href="#" onClick={onTermsOfServiceClick}>
                    Terms of Service
                  </a>
                  .
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  BY USING THE PLATFORM, YOU CONSENT TO THE COLLECTION AND
                  PROCESSING OF PERSONAL DATA DESCRIBED IN THIS AGREEMENT. IF
                  YOU DO NOT AGREE WITH THIS PRIVACY POLICY OR THE OUR TERMS OF
                  SERVICE, THEN YOU MUST NOT ACCESS OR USE THE PLATFORM.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Definitions
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              . Below are definitions of some of the important terms we use in
              this Privacy Policy. In addition, some terms are defined within
              the text of the Privacy Policy. If you see terms in this document
              that are capitalized but not defined, they have the definitions
              given to them in either the Terms of Service or the Data
              Processing Addendum [LINK TO DPA].
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Account
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ” means a client account, as described in our Terms of
                  Service.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Client
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ” means any individual who browses, inquires about or
                  purchases services offered the Platform.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Personal Data
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ” or “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  PII
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ” means information that alone or when in combination with
                  other information may be used to identify, contact, or locate
                  you, such as name, address, email address, IP address, login
                  credentials, profile information, or phone number. Personal
                  Data or PII excludes information that has been aggregated
                  and/or anonymized so that it does not allow a third party to
                  easily identify a specific individual (“
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  non-PII
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”).
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Your Information
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Information We Collect Automatically
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . When you use the Platform, we automatically collect and
                  store certain information about your computer or mobile device
                  and your activities. This information includes:
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Device Information/Specifications
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      . Technical information about your computer or mobile
                      device (e.g., type of device, web browser or operating
                      system, IP address and internet service provider) to
                      analyze trends, administer the Platform, prevent fraud,
                      track visitor movement in the aggregate, and gather broad
                      demographic information.
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Length and Extent of Usage
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      . &nbsp;How long you used the Platform and which services
                      and features you used.
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Information obtained through Cookies
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      . We use Cookies to keep track of some types of
                      information while you are visiting and using the Platform.
                      “Cookies” are very small files placed on your computer,
                      and they allow us to count the number of visitors to our
                      website(s) and distinguish repeat visitors from new
                      visitors. They also allow us to save user preferences and
                      track user trends. We rely on Cookies for the proper
                      operation of the Platform; therefore if your browser is
                      set to reject all Cookies, the Platform may not function
                      properly. Users who refuse Cookies assume all
                      responsibility for any resulting loss of functionality
                      with respect to the Platform.
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Information obtained through web beacons
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      . “
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Web Beacons
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      ” (also known as “clear gifs” and “pixel tags”) are small
                      transparent graphic images that are often used in
                      conjunction with Cookies in order to further personalize
                      the Platform for our users and to collect a limited set of
                      information about our visitors including geographical
                      location, length and extent of usage, and whether visitors
                      have registered for any of the Platform. We may also use
                      Web Beacons in email communications in order to understand
                      the behavior of our clients, such as whether an email has
                      been opened or acted upon.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Information You Provide Directly to Us
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . In the course of inquiring about the Platform or registering
                  for the Platform, you may provide us with some or all of the
                  following information:
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      First name, last name, email address, photo/profile image,
                      and/or phone number.
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Linked social media accounts such as Facebook, Twitter,
                      Google+ and LinkedIn.&nbsp;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Your payment information including billing address and
                      credit card information.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              How We Use Your Information
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  General Purposes
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Subject to the terms of this Privacy Policy, we may use the
                  information we collect from you for the following purposes:
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      to improve your user experience and help you efficiently
                      access your information and account after you log-in;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      respond to your comments and questions and provide client
                      service;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      communicate with you about the Platform and related
                      offers, promotions, news, upcoming events, and other
                      information we think will be of interest to you;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      monitor and analyze trends, usage and activities;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      ensure the security and integrity of the Platform;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      investigate and prevent fraud and other illegal
                      activities; and
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      provide, maintain, and improve the Platform and our
                      overall business.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Email Addresses
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . We use your email address for both “administrative” (e.g.,
                  confirming your registration) and “promotional” (e.g.,
                  newsletters, new listings, special discounts, event
                  notifications, special third-party offers) purposes. Email
                  messages we send to you may contain code that enables our
                  database to track your usage of the e-mails, including whether
                  the email was opened and what links (if any) were clicked. You
                  may opt-out of receiving promotional emails and other
                  promotional communications from us at any time via the opt-out
                  links provided in such communications, or by e-mailing
                  privacy@Execweb.com with your specific request. However, we
                  reserve the right to send you certain communications relating
                  to the Platform (such as service announcements, security
                  alerts, update notices, or other administrative messages)
                  without affording you the opportunity to opt in or out of
                  receiving such communications.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Use for Research
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . In addition to the uses outlined above, by using the
                  Platform, you agree to allow us to anonymously use the
                  information from you and your interaction with the Platform to
                  continue our research into event patterns and general trends,
                  so that we may continue to improve the Platform for our
                  clients. This research may be published in our blogs or
                  interviews. However, all of your responses will be kept
                  anonymous, and no Personal Data will be published without your
                  consent.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Financial information
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . We may use your financial information or authorized payment
                  method to process payment for any purchases, subscriptions or
                  sales made on the Platform, to protect against or identify
                  possible fraudulent transactions, and otherwise as needed to
                  manage our business. We transmit your financial information to
                  our third-party payment processors. We do not collect or store
                  your payment information on our own servers.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sharing Your Information
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              . The information we collect is used to improve the content and
              the quality of the Platform, and without your consent we will not
              otherwise share your Personal Data to/with any other party(s) for
              commercial purposes, except to provide the Platform, when we have
              your permission, or under the following circumstances:
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Public Information
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . &nbsp;Public information is any information you share with a
                  public audience, as well as information in your Public
                  Profile, or content you share on another public forum or on
                  public comments. Public information is available to anyone on
                  or off the Platform and can be seen or accessed through online
                  search engines, APIs, and offline media. &nbsp;If you publicly
                  post information on the Platform, that information will also
                  be public.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Service Providers
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . We may share your information with our third-party service
                  providers that support various aspects of our business
                  operations (e.g., analytics providers, security and technology
                  providers, and payment processors).
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Business Transfers
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . We may transfer your information to another company in
                  connection with a merger, corporate restructuring, sale of any
                  or all of our assets, or in the event of bankruptcy.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Aggregate Data
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . We may combine non-PII we collect with additional non-PII
                  collected from other sources. We also may share aggregated,
                  non-PII with third parties, including advisors, advertisers
                  and investors, for the purpose of conducting general business
                  analysis.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Your Rights and Choices
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Where applicable law requires (and subject to any relevant
                  exceptions under law), you may have the right to access,
                  update, change or delete Personal Data.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You can access, update, change or delete your Personal Data
                  either directly in your Account or by contacting us at&nbsp;
                </span>
                <a
                  href="mailto:privacy@execweb.com"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      fontFamily: '"Times New Roman"',
                      color: "#0000ff",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "underline",
                      WebkitTextDecorationSkip: "none",
                      textDecorationSkipInk: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    privacy@execweb.com
                  </span>
                </a>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  &nbsp;to request the required changes. You can exercise your
                  other rights (including deleting your Account) by contacting
                  us at the same email address.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You can also elect not to receive marketing communications by
                  changing your preferences in your Account or by following the
                  unsubscribe instructions in such communications.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  If you remove information that you posted to the Platform,
                  copies may remain viewable in cached and archived pages of the
                  Platform, or if other users or third parties using any
                  available API have copied or saved that information. In
                  addition, following termination or deactivation of your
                  account, we may retain information (including your profile
                  information) and user content for a commercially reasonable
                  time for backup, archival, and/or audit purposes. If you want
                  any Personal Data permanently deleted, you must request such
                  deletion by contacting us at privacy@Execweb.com.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Additionally, if we rely on consent for the processing of your
                  Personal Data, you have the right to withdraw it at any time.
                  When you do so, this will not affect the lawfulness of any
                  processing of your data that was completed before your consent
                  withdrawal.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Third Party Sites
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Platform may contain links to other websites. If you
                  choose to click on a third-party link, you will be directed to
                  that third party's website. The fact that we link to a website
                  is not an endorsement, authorization or representation of our
                  affiliation with that third party, nor is it an endorsement of
                  their privacy or information security policies or practices.
                  We do not exercise control over third-party websites. These
                  other websites may place their own Cookies or other files on
                  your computer, collect data or solicit Personal Data from you.
                  Other websites follow different rules regarding the use or
                  disclosure of the Personal Data you submit to them. We
                  encourage you to read the privacy policies or statements of
                  the other websites you visit.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              How We Respond to Legal Requests to Prevent Harm
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  We may access, preserve and share your information without
                  notice or consent from you in response to a legal request
                  (like a search warrant, court order or subpoena) if we have a
                  good faith belief that the law requires us to do so. This may
                  include responding to legal requests from jurisdictions
                  outside of the United States where we have a good faith belief
                  that the response is required by law in that jurisdiction,
                  affects users in that jurisdiction, and is consistent with
                  internationally recognized standards. We may also access,
                  preserve and share information when we have a good faith
                  belief it is necessary to: detect, prevent and address fraud
                  and other illegal activity; to protect ourselves, you and
                  others, including as part of investigations; or to prevent
                  death or imminent bodily harm. For example, we may provide
                  information to third-party partners about the reliability of
                  your account to prevent fraud and abuse on and off of our the
                  Platform. Information we receive about you may be accessed,
                  processed and retained for an extended period of time when it
                  is the subject of a legal request or obligation, governmental
                  investigation, or investigations concerning possible
                  violations of our terms or policies, or otherwise to prevent
                  harm. We also may retain information from accounts disabled
                  for violations of our terms for at least a year to prevent
                  repeat abuse or other violations of our terms.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Children’s Privacy
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              . Only individuals over the age of 18 are eligible to use the
              Platform. However, we pay particular attention to children's
              privacy. The Platform is not directed to children under the age of
              13 and we do not knowingly collect, maintain or use Personal Data
              from children under the age of 13. If you learn that your child
              has provided us with Personal Data, you may alert us at
              privacy@Execweb.com. Should we learn that a child under 13 has
              provided us with Personal Data, we will delete that information
              from our database and terminate the child's account.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Securing Your Personal Data
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Security Measures
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Unauthorized entry or use, hardware or software failure, the
                  inherent insecurity of the Internet and other factors, may
                  compromise the security of your Personal Data at any time.
                  Nevertheless, we strive to safeguard Personal Data to ensure
                  that information is kept private and secure at all times. We
                  maintain administrative, technical and physical safeguards
                  that are intended to appropriately protect against accidental
                  or unlawful destruction, accidental loss, unauthorized
                  alteration, unauthorized disclosure or access, misuse and any
                  other unlawful form of processing of, the Personal Data in our
                  possession. We employ security measures such as using
                  firewalls to protect against intruders, building redundancies
                  throughout our network (so that if one server goes down,
                  another can cover for it) and testing for and protecting
                  against network vulnerabilities.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Data Retention
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . We retain Personal Data regarding you or your use of the
                  Platform for as long as your Account is active or for as long
                  as needed to provide you or our Clients with the Platform. We
                  also retain Personal Data for as long as necessary to achieve
                  the purposes described in this Privacy Policy, for example, to
                  comply with our legal obligations, to protect us in the event
                  of disputes and to enforce our agreements and to protect our
                  and others' interests.&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The precise periods for which we keep your Personal Data vary
                  depending on the nature of the information and why we need it.
                  Factors we consider in determining these periods include the
                  minimum required retention period prescribed by law or
                  recommended as best practice, the period during which a claim
                  can be made with respect to an agreement or other matter,
                  whether the Personal Data has been aggregated or
                  pseudonymized, and other relevant criteria. For example, the
                  period we keep your email address is connected to how long
                  your Account is active, while the period for which we keep a
                  support message is based on how long has passed since the last
                  submission in the thread.&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Because you may use the Platform sporadically or come back to
                  us after an Account becomes inactive, we don't immediately
                  delete your Personal Data when your trial expires or you
                  cancel the Platform. Instead, we keep your Personal Data for a
                  reasonable period of time, so it will be there for you if you
                  come back.&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You may delete your Account by contacting us at
                  privacy@Execweb.com and we will delete the Personal Data we
                  hold about you (unless we need to retain it for the purposes
                  set out in this Privacy Policy).
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Using the Platform Outside of the United States
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Data Transfers
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . If you are visiting the Platform from outside the United
                  States, please be aware that your Personal Data may be
                  transferred to, stored, and processed in the United States
                  where our servers are located and our central database is
                  operated. By using the Platform, you understand and agree that
                  your information may be transferred to our facilities and
                  those third parties with whom we share it as described in this
                  Privacy Policy.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Privacy Standards for Data Transfer
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . We conduct our data transfers in accordance with Chapter V
                  of the European General Data Protection Regulation (the
                  “GDPR”). Specifically, we conduct data transfers subject to
                  the following standards:
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Privacy Shield
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      . We transfer, in accordance with Article 45 of the GDPR,
                      Personal Data to companies that have certified their
                      compliance with the EU-U.S. Privacy Shield Frameworks
                      (each individually and jointly, the “
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Privacy Shield
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      ”).
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Standard data protection clauses
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      . We may, in accordance with Article 46 of the GDPR,
                      transfer Personal Data to recipients that have entered
                      into a European Commission approved contract for the
                      transfer of personal data outside the European Economic
                      Area. &nbsp;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Other means
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      . We may, in accordance with Articles 45 and 46 of the
                      GDPR, transfer Personal Data to recipients that offer
                      adequate levels of data protection as evidenced by: (a)
                      location in a country or membership in an organization
                      that the European Commission or other appropriate
                      supervisory authority has confirmed, by decision, offers
                      an adequate level of data protection, (b) certification
                      through an approved certification mechanism pursuant to
                      Article 42 of the GDPR, (c) adherence to binding corporate
                      rules adopted pursuant to Article 47 of the GDPR, or (d)
                      any other mechanism that may demonstrate such adequacy
                      under the GDPR.
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Onward Transfers to Third Parties
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      . In the context of an onward transfer, Execweb has
                      responsibility for the processing of personal information
                      it receives under the Privacy Shield and subsequently
                      transfers to a third party acting as an agent on its
                      behalf. Execweb shall remain liable under the Principles
                      if its agent processes such personal information in a
                      manner inconsistent with the Principles, unless the
                      organization proves that it is not responsible for the
                      event giving rise to the damage.
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Compliance
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . In compliance with the Privacy Shield Principles, Execweb
                  commits to resolve complaints about our collection or use of
                  your personal information. &nbsp;EU individuals with inquiries
                  or complaints regarding our Privacy Shield policy should first
                  contact Execweb at:&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Execweb Inc.
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Attn: Privacy Officer
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  2807 Jackson Avenue, Floor 5, Long Island City, NY 11101.
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  privacy@execweb.com
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Execweb has further committed to refer unresolved Privacy
                  Shield complaints to the International Center for Dispute
                  Resolution - American Arbitration Association (“
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ICDR-AAA
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”), an alternative dispute resolution provider located in the
                  United States. If you do not receive timely acknowledgment of
                  your complaint from us, or if we have not addressed your
                  complaint to your satisfaction, please visit
                  http://go.adr.org/privacyshield.html for more information or
                  to file a complaint. &nbsp;The Platform of the ICDR-AAA are
                  provided at no cost to you.
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <br />
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Execweb complies with the EU-U.S. Privacy Shield Framework as
                  set forth by the U.S. Department of Commerce regarding the
                  collection, use, and retention of personal information
                  transferred from the European Union to the United States.
                  &nbsp;Execweb has certified to the U.S. Department of Commerce
                  that it adheres to the Privacy Shield Principles. &nbsp;If
                  there is any conflict between the terms in this privacy policy
                  and the Privacy Shield Principles, the Privacy Shield
                  Principles shall govern. &nbsp;To learn more about the Privacy
                  Shield program, and to view our certification, please visit
                  https://www.privacyshield.gov/
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Enforcement and Verification
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . For purposes of enforcing compliance with the Privacy
                  Shield, Execweb is subject to the investigatory and
                  enforcement authority of the US Federal Trade Commission. For
                  more information about the Privacy Shield, see the US
                  Department of Commerce's Privacy Shield website located at:
                  https://www.privacyshield.gov. To review Execweb's
                  representation on the Privacy Shield list, see the US
                  Department of Commerce's Privacy Shield self-certification
                  list located at: https://www.privacyshield.gov/list.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Binding Arbitration
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . You may have the option to select binding arbitration for
                  the resolution of your complaint under certain circumstances,
                  provided you have taken the following steps: (1) raised your
                  compliant directly with Execweb and provided us the
                  opportunity to resolve the issue; (2) made use of the
                  independent dispute resolution mechanism identified above; and
                  (3) raised the issue through the relevant data protection
                  authority and allowed the U.S. Department of Commerce an
                  opportunity to resolve the complaint at no cost to you. For
                  more information on binding arbitration, see U.S. Department
                  of Commerce's Privacy Shield Framework: Annex I (Binding
                  Arbitration) located at:
                  https://www.privacyshield.gov/article?id=ANNEX-I-introduction.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Changes to this Privacy Policy
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              . We may update this Privacy Policy from time to time at our sole
              discretion. When we post changes to this Privacy Policy, we will
              revise the “last updated” date at the top of this Privacy Policy.
              We recommend that you check the Platform from time to time to
              inform yourself of any changes in this Privacy Policy or any of
              our other policies.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              How to Contact Us
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              . If you have any questions about this Privacy Policy, please
              contact us by email at&nbsp;
            </span>
            <a
              href="mailto:privacy@Execweb.com"
              style={{ textDecoration: "none" }}
            >
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: '"Times New Roman"',
                  color: "#0000ff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                privacy@Execweb.com
              </span>
            </a>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .&nbsp;
            </span>
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </div>
  );
}

export { PrivacyPolicy };
