import { getApi, postApi } from "../services/axios";
import APIs from ".";
import { setListData, setListLoading } from "../redux/modules/common";
import { CONST_INTRESTED_IN_PRIORITY_LIST } from "../constants/lists";

export const getTimezoneList = () => {
  return async function (dispatch) {
    const res = await getApi(APIs.COMMON.GET_TIMEZONE_LIST, {
      validateUser: false,
      loadingFn: (loading) =>
        setListLoading({
          list: "timezones",
          loading,
        }),
    });

    if (res.success) {
      await dispatch(
        setListData({
          list: "timezones",
          data: res.data,
        })
      );
    } else {
    }

    return res;
  };
};

export const getTopicList = () => {
  return async function (dispatch) {
    const res = await getApi(APIs.COMMON.GET_TOPIC_LIST, {
      validateUser: false,
      loadingFn: (loading) =>
        setListLoading({
          list: "topics",
          loading,
        }),
    });

    if (res.success) {
      await dispatch(
        setListData({
          list: "topics",
          data: res.data,
        })
      );
    } else {
    }
  };
};

export const getAmbassadorTypeList = () => {
  return async function (dispatch) {
    const REGION = [
      { id: "EMEA", value: "EMEA" },
      { id: "US", value: "US" },
    ];

    await dispatch(
      setListData({
        list: "region",
        data: REGION,
      })
    );

    return REGION;
  };
};

export const getOpportunityCategoryList = () => {
  return async function (dispatch) {
    const res = await getApi(APIs.COMMON.GET_OPPORTUNITY_CATEGORIES, {
      validateUser: false,
      loadingFn: (loading) =>
        setListLoading({
          list: "opportunity_categories",
          loading,
        }),
    });

    if (res.success) {
      await dispatch(
        setListData({
          list: "opportunity_categories",
          data: res.data,
        })
      );
    } else {
    }

    return res;
  };
};

export const getCategoryList = () => {
  return async function (dispatch) {
    const res = await getApi(APIs.COMMON.GET_CATEGORIES, {
      validateUser: false,
      loadingFn: (loading) =>
        setListLoading({
          list: "category",
          loading,
        }),
    });

    if (res.success) {
      await dispatch(
        setListData({
          list: "category",
          data: res.data,
        })
      );
    } else {
    }

    return res;
  };
};

export const getIndustryList = () => {
  return async function (dispatch) {
    const res = await getApi(APIs.COMMON.GET_INDUSTRIES, {
      validateUser: false,
      loadingFn: (loading) =>
        setListLoading({
          list: "industry",
          loading,
        }),
    });

    if (res.success) {
      await dispatch(
        setListData({
          list: "industry",
          data: res.data,
        })
      );
    } else {
    }

    return res;
  };
};

export const getTitleList = () => {
  return async function (dispatch) {
    const res = await getApi(APIs.COMMON.GET_TITLES, {
      validateUser: false,
      loadingFn: (loading) =>
        setListLoading({
          list: "title",
          loading,
        }),
    });

    if (res.success) {
      await dispatch(
        setListData({
          list: "title",
          data: res.data,
        })
      );
    } else {
    }

    return res;
  };
};

export const getNoOfEmpList = () => {
  return async function (dispatch) {
    const res = await getApi(APIs.COMMON.GET_NO_OF_EMP, {
      validateUser: false,
      loadingFn: (loading) =>
        setListLoading({
          list: "noOfEmp",
          loading,
        }),
    });

    if (res.success) {
      await dispatch(
        setListData({
          list: "noOfEmp",
          data: res.data,
        })
      );
    } else {
    }

    return res;
  };
};

export const getStateList = () => {
  return async function (dispatch) {
    const res = await getApi(APIs.COMMON.GET_STATES, {
      validateUser: false,
      loadingFn: (loading) =>
        setListLoading({
          list: "state",
          loading,
        }),
    });

    if (res.success) {
      await dispatch(
        setListData({
          list: "state",
          data: res.data,
        })
      );
    } else {
    }

    return res;
  };
};

export const getIntrestedInPriorityList = () => {
  return async function (dispatch) {
    const res = CONST_INTRESTED_IN_PRIORITY_LIST;

    await dispatch(
      setListData({
        list: "intrestedInPriority",
        data: res,
      })
    );

    return res;
  };
};

export function getCountries() {
  return async function (dispatch) {
    const res = await getApi(APIs.COMMON.GET_COUNTRIES_LIST, {
      validateUser: false,
      externalUrl: true,
      loadingFn: (loading) =>
        setListLoading({
          list: "countries",
          loading,
        }),
    });

    if (!res.error) {
      await dispatch(
        setListData({
          list: "countries",
          data: res?.data,
        })
      );
    }

    return res;
  };
}

export function getStateListFromCountry({ country }) {
  return async function (dispatch) {
    const res = await postApi(
      APIs.COMMON.GET_STATE_LIST_FROM_COUNTRY,
      {
        country,
      },
      {
        validateUser: false,
        externalUrl: true,
        loadingFn: (loading) =>
          setListLoading({
            list: "country_state",
            loading,
          }),
      }
    );

    if (!res.error) {
      const statesList = res?.data?.states ?? [];
      dispatch(
        setListData({
          list: "country_state",
          data: statesList,
        })
      );
    } else {
      dispatch(
        setListData({
          list: "country_state",
          data: [],
        })
      );
    }
  };
}
