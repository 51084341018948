import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CLIENT_LIST_COLUMNS } from "./table-config";
import { useStyles } from "./styles";
import { ButtonWithIcon } from "../../../components/buttons";
import { Box, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import clientHOC from "../client-hoc";
import Searchbox from "../../../components/searchbox";
import { ClientListTable } from "./tables";
import ViewersList from "../../opportunity/response-list/viewers-list-modal";

function ClientsList({
  onAddClientClick,
  toggleMenuOptions,
  getClientList,
  clientList,
}: any) {
  const classes = useStyles();
  const { selectedPage, selectedPageSize, searchValue } = useSelector(
    (state: any) => state.clients.list.filters
  );
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [opportunityVendorId, setOpportunityVendorId] = useState(null);

  const handleShowDetailsClick = (row) => {
    setShowDetailsModal(true);
    setOpportunityVendorId(row?.id);
  };

  useEffect(() => {
    getClientList({ selectedPage, selectedPageSize, searchValue });
  }, []);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getClientList({
      selectedPage,
      selectedPageSize,
      searchValue,
    });
  };

  const handleModalClose = () => {
    setShowDetailsModal(false);
    setOpportunityVendorId(null);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <Searchbox
              onEnter={(value) =>
                getClientList({
                  searchValue: value,
                  selectedPage: 1,
                  selectedPageSize,
                })
              }
              value={searchValue}
              onClear={() => {
                getClientList({
                  searchValue: "",
                  selectedPage: 1,
                  selectedPageSize,
                });
              }}
            />
          </Grid>
          <Grid item xs={6} container justifyContent={"flex-end"}>
            <ButtonWithIcon
              handleOnClick={onAddClientClick}
              text={"ADD"}
              icon={{ position: "startIcon", name: "add" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <ClientListTable
            list={clientList}
            toggleMenuOptions={toggleMenuOptions}
            columns={CLIENT_LIST_COLUMNS}
            onShowViewCountDetailsClick={handleShowDetailsClick}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              searchValue,
              selectedPage,
            }}
          />
        </Grid>
      </Grid>
      {showDetailsModal && (
        <ViewersList
          opportunityVendorId={opportunityVendorId}
          handleModalClose={handleModalClose}
        />
      )}
    </Box>
  );
}

export default clientHOC(React.memo(ClientsList));
