import { adminLayoutHOC } from "../HOC/layouts";
import { Route, withRouter, Switch, Redirect } from "react-router";
import { compose } from "redux";
import { PageNotFound } from "../components/errors";
import InvitationList from "../screens//invitations/list";
import NewMeetingInvitation from "../screens//invitations/new-invitation";
import { ROUTE_PATHS } from "./route-paths";
import { ROOT_PATH } from "../constants";

export const invitationRoutePaths = {
  INVITATIONS: {
    ROOT: `${ROOT_PATH}/invitations`,
    LIST: `${ROOT_PATH}/invitations`,
    NEW_INVITATION: `${ROOT_PATH}/invitations/new-invitation`,
  },
};

function InvitationRoutes() {
  return (
    <Switch>
      <Route
        exact
        path={ROUTE_PATHS.INVITATIONS.LIST}
        component={compose(withRouter, adminLayoutHOC)(InvitationList)}
      />
      <Route
        exact
        path={ROUTE_PATHS.INVITATIONS.NEW_INVITATION}
        component={compose(withRouter, adminLayoutHOC)(NewMeetingInvitation)}
      />

      <Route
        path={ROUTE_PATHS.NOT_FOUND}
        exact={true}
        component={PageNotFound}
      />
      <Redirect to={ROUTE_PATHS.NOT_FOUND} />
    </Switch>
  );
}

export { InvitationRoutes };
