import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Grid, Typography } from "@mui/material";
import { EmailAddressBox } from "../../components/email-addressbox";

import { useDispatch } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { LabelAndValue } from "../../../components/typography";
import { AppTextField } from "../../../components/textfields";
import { EMAIL_TEMPLATE_CONFIG } from "../../opportunity/email/editor-data";
import { SaveAndCancelButton } from "../../../components/buttons";
import { useRef, useState } from "react";
import { postShareProfile } from "../../../apis/marketplace.api";

const { REACT_APP_TINYMCE_KEY } = process.env;

export default function ShareForm({ id, onSubmitSuccess, onClose }) {
  const [emailIds, setEmailds]: any = useState({
    list: [],
    invalidEmailIds: [],
  });
  const [subject, setSubject]: any = useState("");
  const [body, setBody]: any = useState("<p>[[click here]]</p>");
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const addMemberRef: any = useRef();
  const dispatch = useDispatch();

  function resetForm() {
    setIsSubmitClicked(false);
    setEmailds({
      list: [],
      invalidEmailIds: [],
    });
    addMemberRef.current.resetEmailIds();
    setSubject("");
    setBody("");
    onClose();
  }

  const handleOnSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleBodyChange = (content) => {
    setBody(content);
  };

  const handleOnEmailAddressesChange = (payload) => {
    setEmailds(payload);
  };

  const onSubmitClick = async () => {
    !isSubmitClicked && setIsSubmitClicked(true);

    const payload = {
      emails: emailIds.list,
      body,
      subject,
    };

    const response: any = await dispatch(postShareProfile(payload, id));

    if (response.success) {
      resetForm();
      onSubmitSuccess();
    }
  };

  const onCancelClick = () => {
    resetForm();
  };

  const isTagAdded = body?.indexOf("[[click here]]") !== -1;

  const isError =
    !emailIds.list.length ||
    emailIds.invalidEmailIds.length ||
    !body ||
    !subject ||
    !isTagAdded;

  return (
    <Box sx={{ minWidth: 275, mt: 1 }}>
      <Card variant="outlined">
        <CardContent>
          <Grid container flexDirection={"column"}>
            <LabelAndValue
              sx={{ mb: 1 }}
              labelxs={3}
              labelmd={3}
              labelsm={3}
              size={13}
              valuexs={7}
              label="To"
              renderValue={
                <EmailAddressBox
                  inlineLabel
                  onValueChange={handleOnEmailAddressesChange}
                  ref={addMemberRef}
                />
              }
            />
            <LabelAndValue
              sx={{ mb: 1 }}
              labelxs={3}
              labelmd={3}
              labelsm={3}
              size={13}
              valuexs={7}
              label="Subject"
              renderValue={
                <AppTextField
                  id={"email-temaplate-subject"}
                  name={"subject"}
                  label={""}
                  inlineLabel
                  onChange={handleOnSubjectChange}
                  value={subject}
                  autoComplete={"off"}
                />
              }
            />
            <LabelAndValue
              sx={{ mb: 1 }}
              labelxs={3}
              labelmd={3}
              labelsm={3}
              size={13}
              valuexs={7}
              label="Body"
              renderValue={
                <>
                  <Editor
                    apiKey={REACT_APP_TINYMCE_KEY}
                    onEditorChange={handleBodyChange}
                    initialValue="[[click here]]"
                    init={EMAIL_TEMPLATE_CONFIG}
                  />
                  {!isTagAdded && (
                    <Typography
                      color={"error"}
                      fontSize={10}
                      mt={1}
                      component={"p"}
                    >
                      {`Note: Please add [[click here]] tag in your template`}{" "}
                    </Typography>
                  )}
                </>
              }
            />
          </Grid>
        </CardContent>
        <CardActions>
          <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
            <SaveAndCancelButton
              onPrimaryActionClick={onSubmitClick}
              onDefaultButtonClick={onCancelClick}
              primaryText="Submit"
              defaultText="Cancel"
              isPrimaryButtonDisabled={isError}
            />
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
}
