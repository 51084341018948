import { useSelector } from "react-redux";
import { SomethingWentWrongWrapper } from "./styles";

function SomethingWentWrong() {
  const error = useSelector((state: any) => state.UI.isServerError);

  const renderError = () => {
    switch (error.responseStatus) {
      case 401:
        return (
          <p>
            You can go back to our <a href="/logout">Login</a>.
          </p>
        );
      default:
        return (
          <p>
            You can go back to our <a href="/">Home Page</a>.
          </p>
        );
    }
  };

  return (
    <SomethingWentWrongWrapper>
      <div className="error-502__container">
        <div className="error-502">
          <div className="error-502__text">
            <h1>
              {error.message} <b>:(</b>
            </h1>
            <h2>{renderError()}</h2>
          </div>
        </div>
      </div>
    </SomethingWentWrongWrapper>
  );
}

export { SomethingWentWrong };
