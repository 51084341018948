import {
  Box,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { IMAGES_PATH } from "../../../constants";
import ReadMoreLess from "../../../components/typography/read-more-less";
import {
  convertToHTTPSURL,
  downloadFile,
  getFileName,
  getFileTypeIcon,
  MP_INTRESTED_IN_ICONS,
} from "../utils";
import { useState } from "react";
import LinkIcon from "@mui/icons-material/Link";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000", // Dark background (Bootstrap-like)
    color: "#fff", // White text
    boxShadow: theme.shadows[1],
    fontSize: 12, // Adjusted font size (Bootstrap uses smaller text)
    borderRadius: 4, // Rounded corners
    padding: "8px 12px", // Padding to make it look Bootstrap-like
  },
}));

export function MPLogo({ logo, className = "" }) {
  return (
    <img
      src={logo ?? `${IMAGES_PATH}/default.jpg`}
      onError={(e: any) => {
        e.target.src = `${IMAGES_PATH}/default.jpg`;
      }}
      className={className}
    />
  );
}

export function MPProfileLinks({ data, showViewCount = true }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleCopyLink = async () => {
    try {
      // Replace this with the link you want to copy
      const linkToCopy = `${process.env.REACT_APP_CONNECT_APP_URL}/marketplace/profile/${data?.id}?token=${data?.profileToken}`;
      await navigator.clipboard.writeText(linkToCopy);

      // Show the tooltip for a brief moment
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  };

  return (
    <div className="link-with-icon">
      <a target="_blank" href={convertToHTTPSURL(data?.website)}>
        <span>
          <img src={`${IMAGES_PATH}/web.svg`} />
        </span>
      </a>
      <a target="_blank" href={convertToHTTPSURL(data?.linkedIn)}>
        <span>
          <img src={`${IMAGES_PATH}/linkedin.svg`} />
        </span>
      </a>
      <BootstrapTooltip
        title="Copied to clipboard"
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        placement="top"
        arrow
        disableTouchListener
      >
        <IconButton title="Copy link" onClick={handleCopyLink}>
          <LinkIcon sx={{ color: "#2e80ec", transform: "rotate(-45deg)" }} />
        </IconButton>
      </BootstrapTooltip>
      {data?.viewByCount > 0 && true && (
        <div className="card-views">
          <span>
            <img src={`${IMAGES_PATH}/show_view_icon.svg`} />
          </span>
          {data?.viewByCount}
        </div>
      )}
    </div>
  );
}

export function MPProfileDescription({ description, maxLength = 200 }) {
  return (
    <div className="vendor-desc">
      <p>
        <ReadMoreLess text={description} maxLength={maxLength} />
      </p>
    </div>
  );
}

export function MPSelectedIntrestedInList({ data }) {
  return (
    <div className="interested-in">
      <h2>Intrested In</h2>
      <div className="interested-in-row">
        {data
          ?.split(",")
          ?.filter((lf) => lf !== "")
          ?.map((lf, index) => (
            <div className="interested-in-col" key={`intrested-in-${index}`}>
              <div className="interested-in-box">
                <span>
                  <img src={`${IMAGES_PATH + MP_INTRESTED_IN_ICONS[lf]}`} />
                </span>
                <h5>{lf}</h5>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export function MPSelectedKeywordsList({ keywords }) {
  return (
    <Box className="keywords-block" mb={2}>
      <h2>Keywords</h2>

      <ul className="keywords-list">
        {keywords
          ?.filter((k) => k?.["label"] !== "")
          ?.map((mpk, index) => (
            <li key={`marketplace-keywords-${mpk["label"]}-${index}`}>
              {mpk["label"]}
            </li>
          ))}
      </ul>
    </Box>
  );
}

export function MPSelectedCategoriesList({ categories }) {
  return (
    <Box className="keywords-block" mb={2}>
      <h2>Categories</h2>

      <ul className="keywords-list">
        {categories
          ?.split(",")
          ?.filter((k) => k !== "")
          ?.map((mpc, index) => (
            <li key={`marketplace-categories-${mpc}-${index}`}>{mpc}</li>
          ))}
      </ul>
    </Box>
  );
}

export function MPSelectedCertificationsList({ certifications }) {
  return (
    <Box className="keywords-block" mb={2}>
      <h2>Certifications</h2>

      <ul className="keywords-list">
        {certifications
          ?.split(",")
          ?.filter((k) => k !== "")
          ?.map((mpc, index) => (
            <li key={`marketplace-certifications-${mpc}-${index}`}>{mpc}</li>
          ))}
      </ul>
    </Box>
  );
}

export function MPUploadsList({ fileUploads }) {
  const handleFileDownload = (e, file) => {
    e.preventDefault();

    downloadFile(file);
  };

  return (
    <div className="u-f-block">
      <h2>Uploads</h2>

      {Object.keys(fileUploads)?.map((key) => (
        <div className="u-f-box" key={key}>
          {fileUploads[key] && (
            <a
              href="#"
              className="u-f-icon"
              onClick={(e) => handleFileDownload(e, fileUploads[key])}
            >
              <img
                src={getFileTypeIcon(fileUploads[key])}
                alt={`${key} icon`}
              />
              <label>{getFileName(key)}</label>
            </a>
          )}
        </div>
      ))}
    </div>
  );
}
