import { RENDERER_TYPES } from "../../../../enums";
import { SimpleTable } from "../../../../components/mui/table";
import { InitialNameAvatar } from "../../../../components/images/initial-name-avatar";
import { MeetingStatus } from "../../../invitations/components/meeting-status";
import { convertToFullName } from "../../../../services/utils";
import {
  DATE_FORMATS,
  formatDate,
} from "../../../../services/date-and-time.utils";
import { AppChip } from "../../../../components/chips";

function AmbassadorMeetingsListTable({
  list,
  columns,
  handlePageFilters,
  appliedFilters,
}) {
  const { selectedPage, selectedPageSize } = appliedFilters;

  const handleTDRenderer = (row, column) => {
    switch (column.rendererType) {
      case RENDERER_TYPES.FULL_NAME_WITH_AVATAR:
        return (
          <InitialNameAvatar
            text={convertToFullName(
              row?.client?.firstName,
              row?.client?.lastName
            )}
            name={row?.client?.firstName}
          />
        );
      case RENDERER_TYPES.CHIP_LIST:
        return row?.ambassador.topics
          ?.map((t) => t?.label)
          ?.map((item) => (
            <AppChip
              key={item}
              bgColor="#F2F3F8"
              style={{ marginRight: 3, marginBottom: 3 }}
              label={item}
              fontColor={"#6C7293"}
            />
          ));
      case RENDERER_TYPES.FULL_DATE_FROM:
        return `${formatDate(
          row.selectedTimeSlot.from,
          DATE_FORMATS.fullDateWithTime
        )}`;
      case RENDERER_TYPES.MEETING_STATUS:
        return <MeetingStatus data={row} />;
      default:
        break;
    }
  };

  return (
    <SimpleTable
      tdRenderer={handleTDRenderer}
      rows={list?.data}
      columns={columns}
      fetching={list?.loading}
      total={list?.total}
      onPageSettingsChange={handlePageFilters}
      selectedPage={selectedPage}
      selectedPageSize={selectedPageSize}
    ></SimpleTable>
  );
}

export { AmbassadorMeetingsListTable };
