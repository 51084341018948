import {
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  ListItemText,
  Checkbox,
  FormHelperText,
  InputLabel,
  Typography,
} from "@mui/material";
import { useRef } from "react";
import { useStyles } from "./styles";
import { AppText } from "../../typography";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 80;
const MenuProps = {
  PaperProps: {
    style: {
      marginTop: 30,
    },
  },
};

const MuiMultiSelect = ({
  label,
  value,
  handleChange,
  list,
  properties,
  inlineLabel = false,
  wrapperClass,
  name,
  error,
  inputFieldClass,
}: any) => {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={`${classes.formControl} ${wrapperClass}`}
    >
      {!inlineLabel ? (
        <Typography>{label}</Typography>
      ) : (
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      )}
      <Select
        multiple
        id={"demo-simple-select-label"}
        variant="outlined"
        fullWidth
        value={value}
        name={name}
        label={label}
        onChange={handleChange}
        className={`${inputFieldClass}`}
        renderValue={(selected: any) => selected.join(", ")}
        MenuProps={MenuProps}
        error={error ? true : false}
      >
        {list?.map((item, index) => (
          <MenuItem key={index} value={item[properties.value]}>
            <Checkbox checked={value.indexOf(item[properties.value]) > -1} />
            <ListItemText primary={item[properties.value]} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText className={classes.error} sx={{ color: "#f44336" }}>
        {error}
      </FormHelperText>
    </FormControl>
  );
};

export default MuiMultiSelect;
