import { Grid, TextField } from "@material-ui/core";
import { BecomeHostOtherDetails } from "../become-host-other-details";
import { useStyles } from "../styles";
import { useAuthStyles } from "../../styles";
import { AppText } from "../../../../components/typography";
import { SignUpCommonDetails } from "../common-details";
import { useState } from "react";
import { userBecomeHost } from "../../../../apis/auth.api";
import {
  SuccessMessageTemplate,
} from "../../../../templates/success-message";
import { PrimaryActionButton } from "../../../../components/buttons";
import { signupValidator } from "../validator";
import { SignupOtherDetails } from "../other-details";
import { acceptTermsHOC } from "../../../terms-and-conditions/accept-terms.hoc";
import { AppCheckbox } from "../../../../components/checkboxes";
import { ENUM_SIGNUP_TYPE, ENUM_SUCCESS_MESSAGE_TEMPLATE } from "../../../../enums";

const initialFields = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  title: "",
  industry: "",
  region: "",
  linkedInProfile: "",
  expertise: "",
  roundTableRate: 1,
  roundTableHostPerWeek: 1,
  meetingRate: 1,
};

const initialErrors = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  title: "",
  industry: "",
  region: "",
  expertise: "",
  roundTableRate: "",
  roundTableHostPerWeek: "",
  meetingRate: "",
};

function BecomeHostForm({
  isTermsReadCompleted,
  onTermsAcceptToggle,
  termsAccepted,
}) {
  const classes = useStyles();
  const authClasses = useAuthStyles();
  const [becomeHostSubmitted, setBecomeHostSubmitted] = useState(false);
  const [formFields, setFormField] = useState<any>(initialFields);
  const [formErrors, setFormErrors] = useState<any>(initialErrors);

  const validateForm = () => {
    const { errors, isError } = signupValidator(
      formFields,
      initialErrors,
      ENUM_SIGNUP_TYPE.BECOME_HOST
    );

    setFormErrors(errors);

    return isError;
  };

  const handleOnChange = (e) => {
    setFormField({
      ...formFields,
      [e.target.name]: e.target.value,
    });
    setFormErrors({
      ...formErrors,
      [e.target.name]: "",
    });
  };

  const onBecomeHostSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      const payload = {
        ...formFields,
      };

      if (!payload.linkedInProfile) {
        delete payload.linkedInProfile;
      }

      const response = await userBecomeHost(payload);

      if (response.success) {
        setBecomeHostSubmitted(true);
      }
    }
  };

  if (becomeHostSubmitted)
    return (
      <SuccessMessageTemplate
        messageType={ENUM_SUCCESS_MESSAGE_TEMPLATE.BECOME_A_HOST_SUCCESS}
      />
    );

  return (
    <form onSubmit={onBecomeHostSubmit}>
      <Grid item className={classes.details} xs={12}>
        <AppText fontSize={14} fontWeight="400">
          Senior cybersecurity executive? Become an Execweb CISO Ambassador at no cost.
        </AppText>
      </Grid>
      <Grid item className={classes.details} xs={12}>
        <AppText fontSize={14} fontWeight="400">
          Fill out the form below, and we'll promptly review your professional background to assess the fit for our platform. Anticipate a timely response.
        </AppText>
      </Grid>

      <AppText textClass={authClasses.title} text="Sign Up" />

      <SignUpCommonDetails
        handleOnChange={handleOnChange}
        formFields={formFields}
        formErrors={formErrors}
      />

      <SignupOtherDetails
        handleOnChange={handleOnChange}
        formFields={formFields}
        formErrors={formErrors}
      />

      <BecomeHostOtherDetails
        handleOnChange={handleOnChange}
        formFields={formFields}
        formErrors={formErrors}
      />

      <AppCheckbox
        onChange={onTermsAcceptToggle}
        labelClass={classes.termsConditionsText}
        label="I Agree Terms & Conditions"
      />

      <PrimaryActionButton
        text={"Submit Application"}
        fullWidth={true}
        type="submit"
        textClass={classes.createButtonText}
        buttonClass={classes.signupButton}
        isDisabled={!isTermsReadCompleted || !termsAccepted}
      />
    </form>
  );
}

export default acceptTermsHOC(BecomeHostForm);
