import { Button, Icon, Typography } from "@mui/material";
import { useStyles } from "./styles";

function ButtonWithIcon({
  text,
  handleOnClick,
  icon,
  muiIcon,
  fullWidth = false,
  background,
  width,
  sx = {},
}: any) {
  const classes = useStyles();

  return (
    <Button
      fullWidth={fullWidth}
      onClick={handleOnClick}
      variant="contained"
      className={classes.button}
      {...(icon.position === "startIcon" && {
        startIcon: muiIcon || <Icon>{icon.name}</Icon>,
      })}
      {...(icon.position === "endIcon" && {
        endIcon: muiIcon || <Icon>{icon.name}</Icon>,
      })}
      sx={{
        ...sx,
        background,
        width,
      }}
    >
      <Typography sx={{ color: "white", fontSize: 14, fontWeight: 500 }}>
        {text}
      </Typography>
    </Button>
  );
}

export { ButtonWithIcon };
