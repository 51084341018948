import React, { useState } from "react";
import { Typography, Link } from "@mui/material";

export default function ReadMoreLess({ text, maxLength = 100 }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMoreLess = () => {
    setIsExpanded(!isExpanded);
  };

  if (!text) {
    return null;
  }

  if (text?.length <= maxLength) {
    return <Typography>{text}</Typography>;
  }

  return (
    <Typography>
      {isExpanded ? text : `${text?.substring(0, maxLength)}...`}
      <Link
        component="button"
        variant="caption"
        onClick={toggleReadMoreLess}
        sx={{ color: "rgb(0, 0, 172)" }}
      >
        {isExpanded ? "Read Less" : "Read More"}
      </Link>
    </Typography>
  );
}
