import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { AmbassadorRequestListTable } from "./table";
import { AMBASSADOR_REQUEST_LIST_COLUMNS } from "./table-config";
import { useDispatch } from "react-redux";
import {
  getAmbassadorRequest,
  onApproveUserRequst,
  onRejectUserRequst,
} from "../../../../apis/connect.api";
import { AppAlertBox } from "../../../../components/modals/alert-box";
import { GLOBAL_TEXT } from "../../../../constants";

function AmbassadorRequests() {
  const [list, setList] = useState({
    data: null,
    loading: null,
    total: 0,
  });
  const [showApproveConfirm, setShowApproveConfirm] = useState(false);
  const [showRejectConfirm, setShowRejectConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setList({
      ...list,
      loading: true,
    });

    onLoad();
  }, []);

  const onLoad = async () => {
    const response: any = await dispatch(getAmbassadorRequest());

    if (response.success) {
      setList({
        data: response.data,
        total: response.data.length,
        loading: false,
      });
    }
  };

  const onApproveConfirm = async () => {
    const response: any = await dispatch(onApproveUserRequst(selectedId));

    if (response.success) {
      onLoad();
    }
    setSelectedId(null);
    setShowApproveConfirm(false);
  };

  const onRejectConfirm = async () => {
    const response: any = await dispatch(onRejectUserRequst(selectedId));

    if (response.success) {
      onLoad();
    }
    setSelectedId(null);
    setShowRejectConfirm(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <AmbassadorRequestListTable
            list={list}
            columns={AMBASSADOR_REQUEST_LIST_COLUMNS}
            onApproveClick={(id) => {
              setSelectedId(id);
              setShowApproveConfirm(true);
            }}
            onRejectClick={(id) => {
              setSelectedId(id);
              setShowRejectConfirm(true);
            }}
          />
        </Grid>
      </Grid>

      <AppAlertBox
        isModalOpen={showApproveConfirm}
        onConfirm={onApproveConfirm}
        onCancel={() => {
          setSelectedId(null);
          setShowApproveConfirm(false);
        }}
        message={GLOBAL_TEXT.ALERT_MESSAGES.CONFIRM_APPROVE}
      />

      <AppAlertBox
        isModalOpen={showRejectConfirm}
        onConfirm={onRejectConfirm}
        onCancel={() => {
          setSelectedId(null);
          setShowRejectConfirm(false);
        }}
        message={GLOBAL_TEXT.ALERT_MESSAGES.CONFIRM_REJECT}
      />
    </Box>
  );
}

export default AmbassadorRequests;
