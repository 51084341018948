import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useEffect, useState } from "react";
import {
  getClientAmbassadorInviteDetails,
  submitClientAmbassadorSlotSelection,
} from "../../apis/ambassador-invite.api";
import { AppDatePicker } from "../../components/datepickers";
import { AppText } from "../../components/typography";
import { INITIAL_SLOTS } from "./form-data";
import * as _ from "lodash";
import { animateScroll as scroll } from "react-scroll";
import { validateSlots, createSelectedClientSlotsPayload } from "./utils";
import { useQuery } from "../../hooks/useQuery";
import { SuccessMessageTemplate } from "../../templates/success-message";
import { convertToFullName, showNotification } from "../../services/utils";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { DetailsCard } from "../components/details-card";
import {
  convertToTimezone,
  DATE_FORMATS,
} from "../../services/date-and-time.utils";
import { AmbassadorTimezoneSlotDetails } from "./ambassador-timezone-slot-details";
import { checkTimeslotSelectionForLateNight } from "../../services/validators";
import { AppAlertBox } from "../../components/modals/alert-box";
import { GLOBAL_TEXT } from "../../constants";
import { getClientUpcomingMeetings } from "../../apis/clients.api";
import ClientUpcomingMeetingList from "./client-upcoming-list";

function ClientAmbassadorSlotSelection() {
  const [clientAmbassadorList, setClientAmbassadorList]: any = useState();
  const [expanded, setExpanded] = useState(1);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [showSlotSelectionConfirmAlert, setShowSlotSelectionConfirmAlert] =
    useState(false);
  const [preserveSelection, setPreserveSelection]: any = useState();
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);

  const queryParams = useQuery();

  useEffect(() => {
    onLoad();
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const onLoad = async () => {
    const token = queryParams.get("token");
    const invitationId = queryParams.get("invitationId");
    const res = await getClientAmbassadorInviteDetails(token, invitationId);

    if (res.success) {
      const updatedClientAmbassadorList = res.data.ambassadors.map((item) => {
        return {
          ...item,
          slots: _.cloneDeep(INITIAL_SLOTS),
        };
      });
      setClientAmbassadorList(updatedClientAmbassadorList);

      const clientUpcomingMeetings = await getClientUpcomingMeetings({
        clientId: res.data.client.id,
        token: queryParams.get("token"),
        invitationId: `invitationId=${queryParams.get("invitationId")}`,
      });

      setUpcomingMeetings(clientUpcomingMeetings);
    }
  };

  function convertToAmbassadorTimezone(date, timezone) {
    const to = new Date(date?.getTime() + 45 * 60000);
    return {
      selectedSlotTimeFrom: date,
      selectedSlotTimeTo: to,
      convertedSlotTimeFrom: convertToTimezone(
        date,
        timezone,
        DATE_FORMATS.fullDateWithTime
      ),
      convertedSlotTimeTo: convertToTimezone(
        to,
        timezone,
        DATE_FORMATS.fullDateWithTime
      ),
    };
  }

  const handleOnAccordionSelect = (event, panel) => {
    setExpanded(expanded === panel ? false : panel);
  };

  const handleDateChange = (
    { date, selectedAmbassadorIndex, slotIndex, type, timezone },
    confirmed = false
  ) => {
    const updateAmbassadorList = _.cloneDeep(clientAmbassadorList);
    const selectedDatetime = convertToAmbassadorTimezone(date, timezone);

    if (
      !confirmed &&
      checkTimeslotSelectionForLateNight(selectedDatetime.convertedSlotTimeFrom)
    ) {
      setPreserveSelection({
        date,
        selectedAmbassadorIndex,
        slotIndex,
        type,
        timezone,
      });
      setShowSlotSelectionConfirmAlert(true);

      return false;
    }

    if (type === "FROM") {
      updateAmbassadorList[selectedAmbassadorIndex].slots[slotIndex] = {
        ...updateAmbassadorList[selectedAmbassadorIndex].slots[slotIndex],
        ...selectedDatetime,
      };
    } else {
      updateAmbassadorList[selectedAmbassadorIndex].slots[slotIndex] = {
        ...updateAmbassadorList[selectedAmbassadorIndex].slots[slotIndex],
        selectedSlotTimeTo: date,
      };
    }

    if (submitClicked) {
      const { updatedList, isAtLeastOneSlotSelectedFromAllAmbassador } =
        validateSlots(updateAmbassadorList, upcomingMeetings);
      setClientAmbassadorList([...updatedList]);

      if (!isAtLeastOneSlotSelectedFromAllAmbassador) {
        setFormErrorMessage("Please select slots for at least one Ambassador");
        scrollToTop();
        return false;
      } else {
        setFormErrorMessage("");
      }
    } else {
      setClientAmbassadorList(updateAmbassadorList);
    }
  };

  const onSubmit = async () => {
    const {
      isFormValid,
      updatedList,
      isAtLeastOneSlotSelectedFromAllAmbassador,
    } = validateSlots(clientAmbassadorList, upcomingMeetings);

    setSubmitClicked(true);
    setClientAmbassadorList(updatedList);

    if (!isAtLeastOneSlotSelectedFromAllAmbassador) {
      setFormErrorMessage("Please select slots for at least one Ambassador");
      scrollToTop();
      return false;
    } else {
      setFormErrorMessage("");
    }

    if (isFormValid) {
      const token = queryParams.get("token");
      const invitationId = queryParams.get("invitationId");
      const payload = {
        requestedDates: createSelectedClientSlotsPayload(clientAmbassadorList),
      };
      const response: any = await submitClientAmbassadorSlotSelection(
        payload,
        token,
        invitationId
      );
      if (response.success) {
        setSubmitSuccess(response.message);
      } else {
        showNotification({
          message:
            "Invalid slot selection. Please check all the slot selections!",
          type: "error",
        });
        setFormErrorMessage(response.message);
      }
    } else {
      showNotification({
        message:
          "Invalid slot selection. Please check all the slot selections!",
        type: "error",
      });
      scrollToTop();
    }
  };

  if (!clientAmbassadorList) return null;

  if (submitSuccess) {
    return <SuccessMessageTemplate successMessage={submitSuccess} />;
  }

  return (
    <>
      <Grid
        container
        justifyContent={upcomingMeetings?.length ? "space-around" : "center"}
        mt={5}
      >
        <Grid
          container
          item
          direction={"column"}
          alignItems="center"
          justifyContent={"center"}
          sx={{ minHeight: "85vh" }}
          md={8}
          xs={12}
          sm={12}
        >
          <Card sx={{ maxWidth: 950 }}>
            <CardContent>
              {formErrorMessage && (
                <AppText text={`* ${formErrorMessage}`} color="red" />
              )}
              <Grid container spacing={1} id="slot-selection">
                {clientAmbassadorList?.map((clientAmbassadorItem, index) => (
                  <Grid item xs={12}>
                    <Accordion
                      expanded={expanded === index + 1}
                      onChange={(e) => handleOnAccordionSelect(e, index + 1)}
                      key={index}
                      style={{
                        border:
                          clientAmbassadorItem.isValid === false
                            ? "2px solid red"
                            : clientAmbassadorItem.isValid === true &&
                              "2px solid green",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore style={{ color: "white" }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{
                          background:
                            expanded === index + 1 ? "#070639" : "gray",
                          color: "white",
                        }}
                      >
                        <Typography>
                          {convertToFullName(
                            clientAmbassadorItem.firstName,
                            clientAmbassadorItem.lastName
                          )}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item>
                            <DetailsCard details={clientAmbassadorItem} />
                          </Grid>
                          <Grid item xs={12} mt={1} mb={1}>
                            <AppText
                              text={`* Select slots time as per your timezone`}
                              color="blue"
                            />
                          </Grid>
                          {clientAmbassadorItem.slots.map(
                            (slotItem, slotIndex) => (
                              <Grid item container spacing={1} key={slotIndex}>
                                <Grid item xs={12}>
                                  <Typography variant="caption">{`Slot ${
                                    slotIndex + 1
                                  }`}</Typography>
                                  <Divider />
                                </Grid>
                                <Grid container item xs={8}>
                                  <Grid
                                    item
                                    xs={6}
                                    container
                                    alignItems={"center"}
                                  >
                                    <Typography
                                      sx={{ mr: 1 }}
                                      variant="caption"
                                    >
                                      From:
                                    </Typography>

                                    <AppDatePicker
                                      dateFormat={"Pp"}
                                      showTimeSelect
                                      selectedDate={
                                        slotItem.selectedSlotTimeFrom
                                      }
                                      setDate={(date) =>
                                        handleDateChange({
                                          date,
                                          selectedAmbassadorIndex: index,
                                          slotIndex,
                                          type: "FROM",
                                          timezone:
                                            clientAmbassadorItem.timezone
                                              ?.value,
                                        })
                                      }
                                      minDate={new Date()}
                                      key={`from-${index}-${slotIndex}`}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    container
                                    alignItems={"center"}
                                  >
                                    <Typography
                                      sx={{ mr: 1 }}
                                      variant="caption"
                                    >
                                      To:
                                    </Typography>

                                    <AppDatePicker
                                      dateFormat={"Pp"}
                                      showTimeInput
                                      readOnly
                                      minDate={new Date()}
                                      selectedDate={slotItem.selectedSlotTimeTo}
                                      setDate={(date) =>
                                        handleDateChange({
                                          date,
                                          selectedAmbassadorIndex: index,
                                          slotIndex,
                                          type: "TO",
                                          timezone:
                                            clientAmbassadorItem.timezone
                                              ?.value,
                                        })
                                      }
                                      key={`to-${index}-${slotIndex}`}
                                    />
                                  </Grid>
                                  {slotItem.error && (
                                    <Grid item xs={12}>
                                      <AppText
                                        text={`* ${slotItem.error}`}
                                        color="red"
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                                {slotItem.selectedSlotTimeFrom && (
                                  <Grid item xs={4} container>
                                    <AmbassadorTimezoneSlotDetails
                                      name={clientAmbassadorItem.firstName}
                                      from={slotItem.convertedSlotTimeFrom}
                                      to={slotItem.convertedSlotTimeTo}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            )
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
                <Grid item container xs={12} justifyContent={"flex-end"}>
                  <Button variant="contained" onClick={onSubmit}>
                    {"Submit"}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {upcomingMeetings.length > 0 && (
          <Grid container item md={4}>
            <ClientUpcomingMeetingList list={upcomingMeetings} />
          </Grid>
        )}
      </Grid>
      {showSlotSelectionConfirmAlert && (
        <AppAlertBox
          isModalOpen={true}
          onConfirm={() => {
            handleDateChange(preserveSelection, true);
            setShowSlotSelectionConfirmAlert(false);
          }}
          onCancel={() => {
            setShowSlotSelectionConfirmAlert(false);
          }}
          message={
            GLOBAL_TEXT.ALERT_MESSAGES
              .CONFIRM_LATE_NIGHT_SLOT_SELECTION_AMBASSADOR
          }
        />
      )}
    </>
  );
}

export { ClientAmbassadorSlotSelection };
