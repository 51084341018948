import { useState } from "react";
import { useDispatch } from "react-redux";
import { runValidation } from "../services/useForm-validator";

function useForm({ formFieldConfig, formFields }) {
  const [formInputs, setFormInputs] = useState(formFields);
  const [fieldConfig, setFormConfig] = useState(formFieldConfig);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  function getCustomizedRequestPayload(customPayload = {}) {
    let payload = {};
    Object.keys(formInputs)
      .filter((item) => !fieldConfig.nonRequiredPayloadFields?.includes(item))
      .forEach((input) => {
        payload = {
          ...payload,
          [input]: formInputs[input],
        };
      });

    return {
      ...payload,
      ...customPayload,
    };
  }

  const setFormData = (data) => {
    let obj = { ...formInputs };

    Object.keys(formInputs).map((inputKey) => {
      obj = {
        ...obj,
        ...(data?.hasOwnProperty(inputKey) && { [inputKey]: data?.[inputKey] }),
      };
    });

    setFormInputs(obj);
  };

  const updateFieldConfig = (updatedFormConfig) => {
    setFormConfig(updatedFormConfig);
  };

  const handleInputChange = (e) => {
    e.persist && e.persist();

    setFormInputs((formInputs) => ({
      ...formInputs,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheckedChange = (e) => {
    e.persist && e.persist();

    setFormInputs((formInputs) => ({
      ...formInputs,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleSubmit: any = async (
    e,
    fn = () => {},
    successFn = (payload) => {},
    errorFn = () => {}
  ) => {
    e?.preventDefault();
    const formData = runValidation(fieldConfig, formInputs);

    if (formData.isValid) {
      setSubmitting(true);

      const response: any = await dispatch(fn);

      setSubmitting(false);

      if (!response?.success) {
        if (errorFn) {
          return errorFn();
        }
      } else {
        formFieldConfig?.resetAfterSubmit && setFormInputs(formFields);
        if (successFn) {
          return successFn(response?.data);
        }
      }
    }

    setFormConfig(formData);
  };

  const runValidator = (e, cb) => {
    e?.preventDefault();
    const formData = runValidation(fieldConfig, formInputs);

    cb(formData);

    setFormConfig(formData);
  };

  const handleInputFocus = (e) => {
    e.persist && e.persist();

    setFormConfig({
      ...fieldConfig,
      fields: {
        ...fieldConfig.fields,
        [e.target.name]: {
          ...fieldConfig?.fields?.[e.target.name],
          error: "",
        },
      },
    });
  };

  return {
    handleSubmit,
    handleInputChange,
    getCustomizedRequestPayload,
    updateFieldConfig,
    handleCheckedChange,
    setFormData,
    runValidator,
    formInputs,
    fieldConfig,
    submitting,
    setFormConfig,
    handleInputFocus,
  };
}

export { useForm };
