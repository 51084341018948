import { TextField } from "@material-ui/core";
import { useAuthStyles } from "../styles";

function BecomeHostOtherDetails({ handleOnChange, formFields, formErrors }) {
  const authClasses = useAuthStyles();

  return (
    <>
      <TextField
        className={authClasses.textField}
        id="signup-region"
        label="Region"
        variant="outlined"
        name="region"
        fullWidth
        onChange={handleOnChange}
        value={formFields.region}
        error={formErrors.region ? true : false}
        helperText={formErrors.region}
        autoComplete={"off"}
      />

      <TextField
        className={authClasses.textField}
        id="signup-expertise"
        label="Tell us 3-5 of your areas of expertise"
        variant="outlined"
        name="expertise"
        fullWidth
        onChange={handleOnChange}
        value={formFields.expertise}
        error={formErrors.expertise ? true : false}
        helperText={formErrors.expertise}
        autoComplete={"off"}
      />

      {false && (
        <>
          <TextField
            className={authClasses.textField}
            id="signup-meetingRate"
            label="How many 60 mins 1:1 virtual meetings you can do per week"
            variant="outlined"
            name="meetingRate"
            fullWidth
            onChange={handleOnChange}
            value={formFields.meetingRate}
            error={formErrors.meetingRate ? true : false}
            helperText={formErrors.meetingRate}
            autoComplete={"off"}
            type="number"
          />

          <TextField
            className={authClasses.textField}
            id="signup-roundTableHostPerWeek"
            label="How many 45mins virtual roundtables of 5-20 execs you can host per week"
            variant="outlined"
            name="roundTableHostPerWeek"
            fullWidth
            onChange={handleOnChange}
            value={formFields.roundTableHostPerWeek}
            error={formErrors.roundTableHostPerWeek ? true : false}
            helperText={formErrors.roundTableHostPerWeek}
            autoComplete={"off"}
            type="number"
          />

          <TextField
            className={authClasses.textField}
            id="signup-roundTableRate"
            label="Your rates for a 1:1 virtual meeting and a virtual roundtable"
            variant="outlined"
            name="roundTableRate"
            fullWidth
            onChange={handleOnChange}
            value={formFields.roundTableRate}
            error={formErrors.roundTableRate ? true : false}
            helperText={formErrors.roundTableRate}
            autoComplete={"off"}
          />
        </>
      )}
    </>
  );
}

export { BecomeHostOtherDetails };
