import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import { INITIAL_MARKETPLACE_FILTER_DATA } from "..";
import {
  ClearButton,
  MPFilterMultiselectBox,
  MPMultiSelectAutocompleteChipTextField,
} from "../form/form-components";
import { Dialog, DialogContent } from "@mui/material";
import MPAdvanceFilters from "./advance-filters";
import MPSortingFilters from "./sorting-filters";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../../apis/common.api";
import { fetchKeywordList } from "../../../apis/marketplace.api";

const MarketplaceFilters = forwardRef(
  (
    {
      data = INITIAL_MARKETPLACE_FILTER_DATA,
      onFilterChange,
      onClose,
      open,
      categoryList,
    }: any,
    ref
  ) => {
    const [advFilters, setAdvFilters] = useState([
      { field: "", condition: "", value: "" },
    ]);
    const [advFilterErrors, setAdvFilterErrors] = useState<string[]>([]);
    const [formData, setFormData] = useState(INITIAL_MARKETPLACE_FILTER_DATA);
    const dispatch = useDispatch();
    const empList = useSelector((state: any) => state?.common?.noOfEmp?.data);
    const countryList = useSelector(
      (state: any) => state?.common?.countries?.data
    );
    const [keywordList, setKeywordList] = useState([]);
    const noOfEmpRef = useRef(null);
    const countryRef = useRef(null);
    const keywordsRef = useRef(null);
    const categoriesRef = useRef(null);
    const [sort, setSort] = useState({ by: "name", order: "asc" });

    useEffect(() => {
      loadFilters();
    }, []);

    useEffect(() => {
      setFormData(data);

      if (
        _.isEqual(
          removeCategories(data),
          removeCategories(INITIAL_MARKETPLACE_FILTER_DATA)
        )
      ) {
        noOfEmpRef?.current?.clearAll();
        countryRef?.current?.clearAll();
      }
    }, [data]);

    const loadFilters = async () => {
      await dispatch(getCountries());
    };

    const validateAdvFilters = () => {
      const errors = advFilters.map((filter, index) => {
        if (filter.field && (!filter.condition || !filter.value)) {
          return `Filter ${index + 1} is incomplete.`;
        }
        return null;
      });
      setAdvFilterErrors(errors);
      return errors.every((error) => error === null);
    };

    const onSubmit = (e) => {
      e?.preventDefault();

      if (!validateAdvFilters()) {
        return;
      }

      const completeFilters = advFilters.filter(
        (filter) => filter.field && filter.condition && filter.value
      );

      onFilterChange({ ...formData, advancedFilters: completeFilters, sort });
    };

    const handleClear = () => {
      noOfEmpRef?.current?.clearAll();
      countryRef?.current?.clearAll();
      keywordsRef?.current?.clearAll();
      categoriesRef?.current?.clearAll();
      setAdvFilters([{ field: "", condition: "", value: "" }]);
      setAdvFilterErrors([]);
      setSort({ by: "name", order: "asc" });
      setFormData(INITIAL_MARKETPLACE_FILTER_DATA);
    };

    const handleInputChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };

    const handleKeywordSearch = async (e) => {
      const response: any = await dispatch(fetchKeywordList(e.target.value));

      if (response?.data) {
        setKeywordList(response.data);
      }
    };

    const handleSortOrderChange = (e) => {
      setSort({
        ...sort,
        order: e.target.value,
      });
    };

    const handleSortChange = (e) => {
      setSort({
        ...sort,
        by: e.target.value,
      });
    };

    useImperativeHandle(ref, () => ({
      handleClear,
    }));

    return (
      <Dialog title={"Filters"} onClose={onClose} open={open} maxWidth="md">
        <DialogContent dividers sx={{ maxWidth: 700 }}>
          <div id="inline-content">
            <div className="vendor-filter-form">
              <div className="vendor-filter-title">
                <h2>Add Filter</h2>
              </div>
              <form>
                <MPAdvanceFilters
                  advFilters={advFilters}
                  advFilterErrors={advFilterErrors}
                  setAdvFilterErrors={setAdvFilterErrors}
                  setAdvFilters={setAdvFilters}
                />
                <div className="vf-form-row">
                  <div className="vf-form-col">
                    <label>Vendor Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={handleInputChange}
                      value={formData.name}
                    />
                  </div>
                  <div className="vf-form-col">
                    <label>Employee Size</label>
                    <MPFilterMultiselectBox
                      name={"no_of_emp"}
                      options={empList || []}
                      labelField="label"
                      valueField="id"
                      onChange={handleInputChange}
                      ref={noOfEmpRef}
                      value={formData.no_of_emp}
                    />
                  </div>
                  <div className="vf-form-col" style={{ width: "100%" }}>
                    <label>Keywords</label>
                    <MPMultiSelectAutocompleteChipTextField
                      name={"keywords"}
                      options={keywordList?.map((item) => {
                        return item?.label;
                      })}
                      value={formData.keywords}
                      onChange={handleInputChange}
                      ref={keywordsRef}
                      onInputChange={handleKeywordSearch}
                    />
                  </div>
                  <div className="vf-form-col" style={{ width: "100%" }}>
                    <label>Country</label>
                    <MPMultiSelectAutocompleteChipTextField
                      name={"country"}
                      options={countryList?.map((item) => {
                        return item?.country;
                      })}
                      value={formData.country}
                      onChange={handleInputChange}
                      ref={countryRef}
                    />
                  </div>
                  <div className="vf-form-col" style={{ width: "100%" }}>
                    <label>Categories</label>
                    <MPMultiSelectAutocompleteChipTextField
                      name={"categories"}
                      options={categoryList}
                      value={formData.categories}
                      onChange={handleInputChange}
                      ref={categoriesRef}
                    />
                  </div>
                  <MPSortingFilters
                    sort={sort}
                    setSort={handleSortChange}
                    setSortOrder={handleSortOrderChange}
                  />
                  <div className="vf-form-btn">
                    <input
                      type="button"
                      className="btn"
                      value="Search"
                      onClick={onSubmit}
                    />
                    <ClearButton onClick={handleClear} />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
);

const removeCategories = (obj) => {
  const { categories, ...rest } = obj;
  return rest;
};

export default MarketplaceFilters;
