import { Grid, TextField } from "@material-ui/core";
import { AppText } from "../../../../components/typography";
import { useStyles } from "../styles";
import { useAuthStyles } from "../../styles";
import { Divider } from "../../../../components/shared/divider";
import { SignUpCommonDetails } from "../common-details";
import { SignupOtherDetails } from "../other-details";
import { useState } from "react";
import { userSignup } from "../../../../apis/auth.api";
import { signupValidator } from "../validator";
import { useHistory } from "react-router";
import { PrimaryActionButton } from "../../../../components/buttons";
import { AppCheckbox } from "../../../../components/checkboxes";
import { acceptTermsHOC } from "../../../terms-and-conditions/accept-terms.hoc";
import {
  ENUM_SIGNUP_TYPE,
} from "../../../../enums";
import { ROUTE_PATHS } from "../../../../routes/route-paths";

const initialFields = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  title: "",
  industry: "",
  linkedInProfile: "",
  topicToDiscuss: "",
  password: "",
};

const initialErrors = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  title: "",
  industry: "",
  linkedInProfile: "",
  password: "",
  topicToDiscuss: "",
};

function SignupForm({
  isTermsReadCompleted,
  onTermsAcceptToggle,
  termsAccepted,
}) {
  const classes = useStyles();
  const authClasses = useAuthStyles();
  const [formFields, setFormField] = useState<any>(initialFields);
  const [formErrors, setFormErrors] = useState<any>(initialErrors);

  const history = useHistory();

  const validateForm = () => {
    const { errors, isError } = signupValidator(
      formFields,
      initialErrors,
      ENUM_SIGNUP_TYPE.SIGNUP
    );

    setFormErrors(errors);

    return isError;
  };

  const handleOnChange = (e) => {
    setFormField({
      ...formFields,
      [e.target.name]: e.target.value,
    });
    setFormErrors({
      ...formErrors,
      [e.target.name]: "",
    });
  };

  const onSignupSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      const response = await userSignup(formFields);

      if (response.success) {
        window.location.href = ROUTE_PATHS.EXTERNAL_WEBSITE_LINK;
      }
    }
  };

  return (
    <form onSubmit={onSignupSubmit}>
      <Grid item className={classes.details} xs={12}>
        <AppText fontSize={14} fontWeight="400">
          Execweb is an exclusive executives network so before we allow you
          access the list of technology executives we need to verify and approve
          your professional identity."
        </AppText>
      </Grid>
      <Grid item className={classes.details} xs={12}>
        <AppText fontSize={14} fontWeight="400">
          Once you sign up you agree to the terms and conditions and you agree
          you are under legally binding obligation to not contact any of the
          technology executives listed on Execweb outside this platform.
        </AppText>
      </Grid>

      <AppText textClass={authClasses.title} text="Sign Up" />

      <Divider>or</Divider>

      <SignUpCommonDetails
        handleOnChange={handleOnChange}
        formFields={formFields}
        formErrors={formErrors}
      />

      <TextField
        className={authClasses.textField}
        id="signup-password"
        label="Password"
        variant="outlined"
        type="password"
        name="password"
        fullWidth
        onChange={handleOnChange}
        value={formFields.password}
        error={formErrors.password ? true : false}
        helperText={formErrors.password}
        autoComplete={"off"}
      />

      <SignupOtherDetails
        handleOnChange={handleOnChange}
        formFields={formFields}
        formErrors={formErrors}
        signupType={ENUM_SIGNUP_TYPE.SIGNUP}
      />

      <AppCheckbox
        onChange={onTermsAcceptToggle}
        labelClass={classes.termsConditionsText}
        label="I Agree Terms & Conditions"
      />

      <PrimaryActionButton
        text={"Sign Up"}
        fullWidth={true}
        type="submit"
        textClass={classes.createButtonText}
        buttonClass={classes.signupButton}
        isDisabled={!isTermsReadCompleted || !termsAccepted}
      />
    </form>
  );
}

export default acceptTermsHOC(SignupForm);
