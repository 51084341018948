import { ROUTE_PATHS } from "../../../routes/route-paths";
import SvgIcons from "../../../svgs";

const BREADCRUMB_ICON_NAME = SvgIcons.Client;

const CLIENT_BREADCRUMB = {
  [ROUTE_PATHS.CLIENTS.LIST]: {
    isRoot: true,
    paths: [{ label: "Clients", icon: BREADCRUMB_ICON_NAME }],
    title: "Clients",
  },
  [ROUTE_PATHS.CLIENTS.ADD]: {
    paths: [
      {
        url: ROUTE_PATHS.CLIENTS.LIST,
        label: "Clients",
        icon: BREADCRUMB_ICON_NAME,
      },
      { label: "Add" },
    ],
    title: "Add",
  },
  [ROUTE_PATHS.CLIENTS.EDIT]: {
    paths: [
      {
        url: ROUTE_PATHS.CLIENTS.LIST,
        label: "Clients",
        icon: BREADCRUMB_ICON_NAME,
      },
      { label: "Edit" },
    ],
    title: "Edit",
  },
  [ROUTE_PATHS.CLIENTS.DETAILS]: {
    paths: [
      {
        url: ROUTE_PATHS.CLIENTS.LIST,
        label: "Clients",
        icon: BREADCRUMB_ICON_NAME,
      },
      { label: "{name}", isCustom: true },
    ],
    title: "Details",
  },
};

export { CLIENT_BREADCRUMB };
