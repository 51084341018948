import React from "react";
import { AppLayoutHeader } from "../../components/layouts/header/app-layout-header";

const appLayoutHOC = (ComposedComponent) => {
  class AppLayoutHOC extends React.Component<any, any> {
    constructor(props) {
      super(props);
      this.state = {
        pageConfig: {
          showLogo: true,
        },
      };
    }

    handlePageConfig = (config) => {
      this.setState({
        pageConfig: {
          ...this.state.pageConfig,
          ...config,
        },
      });
    };

    render() {
      return (
        <>
          <AppLayoutHeader configs={this.state.pageConfig} />
          <ComposedComponent
            {...this.props}
            setConfig={this.handlePageConfig}
          />
        </>
      );
    }
  }

  return AppLayoutHOC;
};

export default appLayoutHOC;
