import { ENUM_FORM_INPUT_TYPES, ENUM_VALIDATION_TYPES } from "../../../enums";

const CLIENT_DETAILS = [
  { field: "firstName", label: "First Name" },
  { field: "lastName", label: "Last Name" },
  { field: "email", label: "Email" },
  {
    field: "secondaryMembers",
    label: "Secondary Email",
    inputType: ENUM_FORM_INPUT_TYPES.MULTY_EMAIL_INPUT,
  },
  {
    field: "title",
    label: "Title",
    inputType: ENUM_FORM_INPUT_TYPES.AUTO_SELECTBOX,
  },
  { field: "company", label: "Company" },
  {
    field: "timezone",
    label: "Timezone",
    inputType: ENUM_FORM_INPUT_TYPES.AUTO_SELECTBOX,
  },

  { field: "linkedIn", label: "Linkedin" },
  {
    field: "no_of_emp",
    label: "# of Employees",
    inputType: ENUM_FORM_INPUT_TYPES.AUTO_SELECTBOX,
  },
  { field: "funding", label: "Funding" },
  { field: "revenues", label: "Revenues" },
  { field: "budget", label: "Budget" },
  {
    field: "target_no_of_emp",
    label: "Target # of Employees",
    inputType: ENUM_FORM_INPUT_TYPES.MULTI_SELECTBOX,
  },
  { field: "target_account", label: "Target Client Accounts" },
  {
    field: "target_industries",
    label: "Target Industries",
    inputType: ENUM_FORM_INPUT_TYPES.MULTI_SELECTBOX,
  },
  {
    field: "licenseForMeeting",
    label: "Total Number of meetings",
  },
  { field: "viewAmbassadorThreshold", label: "Number of visible ambassadors" },
  { field: "meetingThreshold", label: "Number of meetings - first batch" },
  {
    field: "viewNextBatchAmbassadorThreshold",
    label: "Number of meetings - follow-up batch",
  },
  {
    field: "allowUpdateQues",
    label: "Allow Typeform question update",
    inputType: ENUM_FORM_INPUT_TYPES.CHECKBOX,
  },
];

const CLIENT_FORM_ERROR_MAAPING = [
  {
    placeHolder: "First Name",
    fieldName: "firstName",
  },
  {
    placeHolder: "Last Name",
    fieldName: "lastName",
  },
  {
    placeHolder: "Email",
    type: ENUM_VALIDATION_TYPES.CORPORATE_EMAIL,
    fieldName: "email",
  },
  { fieldName: "company", placeHolder: "Company" },
  { fieldName: "budget", placeHolder: "Budget" },
  { fieldName: "linkedIn", placeHolder: "Linkedin" },
  {
    fieldName: "timezone",
    placeHolder: "Timezone",
  },
  {
    fieldName: "title",
    placeHolder: "Title",
  },
  {
    fieldName: "no_of_emp",
    placeHolder: "# of Employees",
  },
  {
    fieldName: "target_no_of_emp",
    placeHolder: "Target # of Employees",
  },
  {
    fieldName: "target_industries",
    placeHolder: "Target Industries",
  },
];

export { CLIENT_DETAILS, CLIENT_FORM_ERROR_MAAPING };
