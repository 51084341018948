import { ENUM_FORM_INPUT_TYPES, ENUM_VALIDATION_TYPES } from "../../../enums";

const PERSONAL_DETAILS = [
  { field: "firstName", label: "First Name" },
  { field: "lastName", label: "Last Name" },
  { field: "email", label: "Email" },
  { field: "linkedIn", label: "Linkedin" },
  { field: "picture", label: "Picture" },
  {
    field: "region",
    label: "Category",
    inputType: ENUM_FORM_INPUT_TYPES.AUTO_SELECTBOX,
  },
  {
    field: "state",
    label: "State",
    inputType: ENUM_FORM_INPUT_TYPES.AUTO_SELECTBOX,
  },
  { field: "city", label: "City" },
  {
    field: "timezone",
    label: "Timezone",
    inputType: ENUM_FORM_INPUT_TYPES.AUTO_SELECTBOX,
  },
];

const COMPANY_DETAILS = [
  {
    field: "title",
    label: "Title",
    inputType: ENUM_FORM_INPUT_TYPES.AUTO_SELECTBOX,
    requireOnChange: true
  },
  {
    field: "industry",
    label: "Industry",
    inputType: ENUM_FORM_INPUT_TYPES.AUTO_SELECTBOX,
  },
  { field: "company", label: "Company" },
  {
    field: "no_of_emp",
    label: "No of. Employees",
    inputType: ENUM_FORM_INPUT_TYPES.AUTO_SELECTBOX,
  },
  { field: "experience", label: "Experience" },
  {
    field: "previous_company",
    label: "Previous Company",
    inputType: ENUM_FORM_INPUT_TYPES.CHIP_TEXT_FIELD,
  },
];

const ROUND_TABLE_DETAILS = [
  {
    field: "round_table_package_12_rate",
    label: "1:1 virtual meetings Rate",
  },
  {
    field: "investment_budget",
    label: "Investment Budget",
  },
];

const AMBASSADOR_FORM_ERROR_MAAPING = [
  {
    placeHolder: "First Name",
    fieldName: "firstName",
  },
  {
    placeHolder: "Last Name",
    fieldName: "lastName",
  },
  {
    placeHolder: "Email",
    type: ENUM_VALIDATION_TYPES.EMAIL,
    fieldName: "email",
  },
  { placeHolder: "Company", fieldName: "company" },
  {
    fieldName: "title",
    placeHolder: "Title",
  },
  {
    fieldName: "timezone",
    placeHolder: "Timezone",
  },
  {
    placeHolder: "1:1 virtual meetings Rate",
    fieldName: "round_table_package_12_rate",
  },
  {
    fieldName: "timezone",
    placeHolder: "Timezone",
  },
  {
    fieldName: "no_of_emp",
    placeHolder: "No of. Employees",
  },
  { fieldName: "linkedIn", placeHolder: "Linkedin" },
  { fieldName: "industry", placeHolder: "Industry" },
];

export {
  PERSONAL_DETAILS,
  COMPANY_DETAILS,
  AMBASSADOR_FORM_ERROR_MAAPING,
  ROUND_TABLE_DETAILS,
};
