import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import OpportunityResponseFilterOptions from "./filter-options";
import { OpportunityResponseListTable } from "./table";
import { OPPORTUNITY_RESPONSE_LIST_COLUMNS } from "./table-config";
import { getOpportunityResponse } from "../../../apis/opportunity";
import ViewersList from "./viewers-list-modal";

function OpportunityResponseList({}) {
  const { selectedPageSize, selectedPage, selectedFilters } =
    useSelector((state: any) => state.opportunity.responseList.filters);
  const opportunityResponseList = useSelector(
    (state: any) => state.opportunity.responseList
  );
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [opportunityVendorId, setOpportunityVendorId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getOpportunityResponseList({
      selectedPage: 1,
      selectedPageSize,
      selectedFilters,
    });
  }, [selectedFilters]);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getOpportunityResponseList({
      selectedPage,
      selectedPageSize,
      selectedFilters,
    });
  };

  const getOpportunityResponseList = async ({
    selectedPage,
    selectedPageSize,
    selectedFilters,
  }) => {
    const payload = {
      selectedPage,
      selectedPageSize,
      selectedFilters,
    };

    const response = await dispatch(getOpportunityResponse(payload));
  };

  const handleModalClose = () => {
    setShowDetailsModal(false);
    setOpportunityVendorId(null);
  };

  const onShowDetailsClick = (row) => {
    setShowDetailsModal(true);
    setOpportunityVendorId(row?.id);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
      <OpportunityResponseFilterOptions />
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <OpportunityResponseListTable
            list={opportunityResponseList}
            columns={OPPORTUNITY_RESPONSE_LIST_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              selectedPage,
              selectedFilters,
            }}
            onDetailsClick={onShowDetailsClick}
            refreshList={getOpportunityResponseList}
          />
        </Grid>
      </Grid>
      {showDetailsModal && (
        <ViewersList
          opportunityVendorId={opportunityVendorId}
          handleModalClose={handleModalClose}
        />
      )}
    </Box>
  );
}

export default OpportunityResponseList;
