import { useEffect, useRef, useState } from "react";
import "../../css/marketplace.css";
import { Box, Checkbox, Divider, FormControlLabel, Grid } from "@mui/material";
import { IMAGES_PATH, PAGE_SIZES } from "../../constants";
import { useDispatch } from "react-redux";
import useEffectAfterMount from "../../hooks/useEffectAfterMount";
import {
  getMarketplace,
  getMarketplaceCategories,
} from "../../apis/marketplace.api";
import MarketplaceCategories from "./categories";
import MarketplaceFilters from "./filters";
import MarketplaceList from "./list";
import { PaginationContext } from "../../components/mui/table/simple-table";
import Paginator from "../../components/mui/pagination";
import { ROUTE_PATHS } from "../../routes/route-paths";
import { useHistory } from "react-router";
import { ButtonWithIcon } from "../../components/buttons";
import { generateMarketplaceFilterPayload } from "./utils";

export const INITIAL_MARKETPLACE_FILTER_DATA = {
  name: "",
  no_of_emp: "",
  country: "",
  categories: "",
  keywords: "",
};

export default function Marketplace() {
  const dispatch = useDispatch();
  const [marketplaceList, setMarketplaceList] = useState({
    data: [],
    total: 0,
  } as any);
  const [selectedPageSize, setSelectedPageSize] = useState(PAGE_SIZES[0]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [marketplaceCategoriesList, setMarketplaceCategoriesList] = useState(
    [] as any
  );
  const [toggleFilter, setToggleFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(INITIAL_MARKETPLACE_FILTER_DATA);
  const [showRespondedOnly, setShowRespondedOnly] = useState(false);
  const filterRef: any = useRef();
  const [selectedCategory, setSelectedCategory] = useState("");
  const history = useHistory();

  useEffect(() => {
    fetchMarketPlaceList();
  }, []);

  useEffectAfterMount(() => {
    handleScroll();
  }, [selectedPage, selectedPageSize]);

  function handleScroll() {
    document
      .getElementById("marketplace-vendor-list-wrapper")
      ?.scrollIntoView({ behavior: "smooth" });
  }

  const fetchMarketPlaceList = async () => {
    try {
      const [categoriesListResponse]: any = await Promise.all([
        dispatch(getMarketplaceCategories()),
      ]);

      setMarketplaceCategoriesList(categoriesListResponse.data);

      handleCategoryChange({
        category: categoriesListResponse?.data?.[0]?.category,
      });
    } catch (error) {}
  };

  const handleFilterChange = async (payload: any) => {
    let tempFilters = {
      ...filters,
      ...payload,
    };

    if (payload?.name !== "") {
      tempFilters["categories"] = "";
    } else {
      tempFilters["categories"] =
        payload?.categories ||
        selectedCategory ||
        marketplaceCategoriesList?.[0]?.category;
    }

    setLoading(true);
    setToggleFilter(false);

    const requestPayload = generateMarketplaceFilterPayload(tempFilters);
    const [marketplaceListResponse] = await Promise.all([
      dispatch(
        getMarketplace(requestPayload, {
          selectedPage: 1,
          selectedPageSize,
          respondedOnly: false,
        })
      ),
    ]);
    setLoading(false);
    setFilters(tempFilters);
    setSelectedPage(1);
    setShowRespondedOnly(false);
    setMarketplaceList(marketplaceListResponse);

    handleScroll();
  };

  const onPageSizeChange = async (newValue) => {
    let tempFilters = {
      ...filters,
    };

    tempFilters["categories"] =
      filters?.categories ||
      selectedCategory ||
      marketplaceCategoriesList?.[0]?.category;
    setSelectedPageSize(newValue);
    setSelectedPage(1);
    setLoading(true);
    const requestPayload = generateMarketplaceFilterPayload(tempFilters);

    const [marketplaceListResponse] = await Promise.all([
      dispatch(
        getMarketplace(requestPayload, {
          selectedPage: 1,
          selectedPageSize: newValue,
          respondedOnly: showRespondedOnly,
        })
      ),
    ]);
    setLoading(false);
    setMarketplaceList(marketplaceListResponse);
  };

  const onPageChange = async (newValue) => {
    let tempFilters = {
      ...filters,
    };

    tempFilters["categories"] =
      filters?.categories ||
      selectedCategory ||
      marketplaceCategoriesList?.[0]?.category;

    setSelectedPage(newValue);
    setLoading(true);
    const requestPayload = generateMarketplaceFilterPayload(tempFilters);
    const [marketplaceListResponse] = await Promise.all([
      dispatch(
        getMarketplace(requestPayload, {
          selectedPage: newValue,
          selectedPageSize,
          respondedOnly: showRespondedOnly,
        })
      ),
    ]);
    setLoading(false);
    setMarketplaceList(marketplaceListResponse);
  };

  const handleToggleFilter = () => {
    setToggleFilter(!toggleFilter);
  };

  const handleCategoryChange = async (item: any) => {
    let tempFilters = {
      ...INITIAL_MARKETPLACE_FILTER_DATA,
      categories: item?.category,
    };
    setLoading(true);

    setSelectedCategory(item?.category);
    const requestPayload = generateMarketplaceFilterPayload(tempFilters);
    const [marketplaceListResponse] = await Promise.all([
      dispatch(
        getMarketplace(requestPayload, {
          selectedPage: 1,
          selectedPageSize,
          respondedOnly: false,
        })
      ),
    ]);
    setLoading(false);
    setSelectedPage(1);
    tempFilters["categories"] = "";
    setFilters(tempFilters);
    setShowRespondedOnly(false);
    setMarketplaceList(marketplaceListResponse);
    handleScroll();
    filterRef?.current?.handleClear();
  };

  const handleFilterClear = async (e) => {
    e?.preventDefault();
    let tempFilters = {
      name: "",
      stage: "",
      revenue: "",
      no_of_emp: "",
      founded: "",
      country: "",
      keywords: "",
      categories: "",
      sort: { by: "name", order: "asc" },
    };

    if (tempFilters?.name !== "") {
      tempFilters["categories"] = "";
      setSelectedCategory("");
    } else {
      tempFilters["categories"] =
        selectedCategory || marketplaceCategoriesList?.[0]?.category;
      setSelectedCategory(tempFilters["categories"]);
    }

    setLoading(true);
    setToggleFilter(false);

    const requestPayload = generateMarketplaceFilterPayload(tempFilters);

    const [marketplaceListResponse] = await Promise.all([
      dispatch(
        getMarketplace(requestPayload, {
          selectedPage: 1,
          selectedPageSize,
          respondedOnly: showRespondedOnly,
        })
      ),
    ]);
    setLoading(false);
    tempFilters["categories"] = "";
    setFilters(tempFilters);
    setSelectedPage(1);
    setMarketplaceList(marketplaceListResponse);

    handleScroll();
    filterRef?.current?.handleClear();
  };

  const handleAcknowledgeChange = async (e) => {
    setLoading(true);
    setShowRespondedOnly(e.target.checked);

    const isChecked = e.target.checked;

    const tempFilters = { ...INITIAL_MARKETPLACE_FILTER_DATA };

    if (isChecked) {
      tempFilters["categories"] = "";
      setSelectedCategory("");
    } else {
      tempFilters["categories"] =
        selectedCategory || marketplaceCategoriesList?.[0]?.category;
      setSelectedCategory(marketplaceCategoriesList?.[0]?.category);
    }

    const requestPayload = generateMarketplaceFilterPayload(tempFilters);
    const [marketplaceListResponse] = await Promise.all([
      dispatch(
        getMarketplace(requestPayload, {
          selectedPage: 1,
          selectedPageSize,
          respondedOnly: e.target.checked,
        })
      ),
    ]);
    setLoading(false);
    setSelectedPage(1);
    tempFilters["categories"] = "";
    setFilters({ ...tempFilters });

    setMarketplaceList(marketplaceListResponse);
  };

  const checkFilterApplied = () => {
    const {
      name,
      no_of_emp,
      country,
      keywords,
      categories,
      advancedFilters,
      sort,
    }: any = filters;

    return (
      name ||
      no_of_emp ||
      country ||
      keywords ||
      categories ||
      (advancedFilters && advancedFilters?.length > 0) ||
      (sort && sort?.by !== "name") ||
      (sort && sort?.order !== "asc")
    );
  };

  const handleNewMarketplace = (e) => {
    e.preventDefault();
    history.push(ROUTE_PATHS.MARKETPLACE.ADD);
  };

  return (
    <section className="middle-block" id="marketplace-vendor-list-wrapper">
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <h3>Marketplace</h3>
        <ButtonWithIcon
          handleOnClick={handleNewMarketplace}
          text={"ADD"}
          background={"#0000ac"}
          icon={{ position: "startIcon", name: "add" }}
        />
      </Box>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2.5} md={3}>
          <MarketplaceCategories
            list={marketplaceCategoriesList}
            onFilterChange={handleCategoryChange}
            selectedCategory={selectedCategory}
            filterCategories={filters?.categories}
          />
        </Grid>
        <Grid item xs={12} sm={9.5} md={9}>
          <div className="vendor-detail-block">
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <div className="filter-btn" onClick={handleToggleFilter}>
                  <a className="btn">
                    <img src={`${IMAGES_PATH}/filter_icon.svg`} />
                    <span>Filter</span>
                  </a>
                </div>

                {checkFilterApplied() && (
                  <a
                    href=""
                    className="clear-filter"
                    style={{ paddingBottom: 20 }}
                    onClick={handleFilterClear}
                  >
                    <img src={`${IMAGES_PATH}/close_clear.png`} />
                    Clear Filter
                  </a>
                )}
              </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={showRespondedOnly}
                    sx={{ color: "primary.main" }}
                  />
                }
                label="Acknowledged"
                sx={{ color: "primary.main" }}
                onChange={handleAcknowledgeChange}
              />
            </Box>

            <MarketplaceFilters
              categoryList={marketplaceCategoriesList?.map((d) => d?.category)}
              onFilterChange={handleFilterChange}
              data={filters}
              onClose={handleToggleFilter}
              open={toggleFilter}
              ref={filterRef}
            />

            <MarketplaceList loading={loading} list={marketplaceList?.data} />
            {marketplaceList?.data?.length > 0 && (
              <Grid item container justifyContent={"center"}>
                <PaginationContext.Provider
                  value={{
                    onPageChange: onPageChange,
                    onPageSizeChange: onPageSizeChange,
                    totalRecords: marketplaceList?.total,
                    showPageSize: marketplaceList?.total > PAGE_SIZES?.[0],
                    selectedPageSize: selectedPageSize,
                    selectedPage: selectedPage,
                  }}
                >
                  <Paginator />
                </PaginationContext.Provider>
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    </section>
  );
}
