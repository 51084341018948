export const REGEX = {
  EMAIL: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{1,}$/),
  NAME: new RegExp(/^[a-zA-Z\s]+$/),
  URL: new RegExp(
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
  ),
  NUMBERS: {
    POSITIVE_INTEGER_NUMBER: new RegExp(/^[1-9]\d*$/),
    NUMBER_WITH_DOT: new RegExp(/^[0-9]{1,9}([,.][0-9]{1,2})?$/),
  },
  PHONE: new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/),
  CURRENCY: {
    USD: new RegExp(/^(\d{1,3})(,\d{1,3})*(\.\d{1,})?$/),
  },
};

export const IMAGES_PATH = process.env.PUBLIC_URL + "/images";

export const GLOBAL_TEXT = {
  HELP_AND_SUPPORT:
    "If you would like to talk to one of our representatives please call 1-855-111-2222",
  SUPPORT_EMAIL_ADDRESS: "support@execweb.com",
  SEND_INVITE_DEFAULT_MESSAGES: {
    INVITE_TO_HOST: `Hello {First Name}\n\nI'd like to invite you to host a virtual roundtable with 5-20 interested technology executives to discuss the topics I have highlighted in my project overview.\n\nBest,\n\nVal T.`,
    STRATEGY_CALL: `Hello! \n\nI’d would like to request a 1:1 virtual meeting with you and get your perspectives on the several questions I have regarding the cybersecurity industry. Please let me know if you’re available and interested in having a strategy call.\n\nBest,\n\nVal T.`,
    PACKAGE: `Hello! \n\nI’d would like to book a package of {meeting_type}. Please let me know if you’re available and interested in partnering with me.\n\nBest,\n\nVal T.`,
    ONE_TO_ONE_CALL: `Hello!\n\nI'd like to invite you to record a video of your responses to my questions. I am looking forward to getting your insights and answers to my questions.\n\nBest,\n\nVal T.`,
  },
  PACKAGE_DETAILS: {
    INDIVIDUAL_ROUNDTABLE:
      "{package_rate} (30% discount to individual roundtable)",
    INDIVIDUAL_VIRTUAL_MEETING:
      "{package_rate} (30% discount to individual 1:1 virtual meeting)",
  },
  INVITATION_RESCHEDULE_LATE_NIGHT_MESSAGE:
    "*Note: You have selected late night meeting time for ambassador or client",
  ALERT_MESSAGES: {
    CONFIRM_DELETE: "Are you sure you want to delete?",
    CONFIRM_CONVERT_TO_VENDOR: "Are you sure you want to convert to Vendor?",
    CONFIRM_ARCHIVE: "Are you sure you want to archive?",
    CONFIRM_CANCEL: "Are you sure you want to cancel?",
    CONFIRM_LATE_NIGHT_SLOT_SELECTION_AMBASSADOR:
      "You have selected late night or early morning meeting time for ambassador. Are you sure you want to proceed?",
    CONFIRM_LATE_NIGHT_SLOT_SELECTION_CLIENT:
      "You have selected late night or early morning meeting time for client. Are you sure you want to proceed?",
    CONFIRM_REJECT: "Are you sure you want to reject?",
    CONFIRM_APPROVE: "Are you sure you want to approve?",
    REQUEST_INVITATION_COMPLETE: "Complete Invitation",
  },
};

export const API_TIMEOUT = 60000;
export const DEFAULT_SELECTED_PAGE = 1;
export const DEFAULT_SELECTED_PAGE_SIZE = 10;

export const PAGE_SIZES = [10, 20, 50, 100];

export const ROOT_PATH = "/admin-portal";

export const drawerWidth = 240;
