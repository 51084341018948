function TermsOfServices({ onPrivacyPolicyClick }) {
  return (
    <div>
      <h1
        dir="ltr"
        style={{
          lineHeight: "1.3800000000000001",
          textAlign: "center",
          marginTop: "0pt",
          marginBottom: "6pt",
        }}
      >
        <span
          style={{
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 700,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap",
          }}
        >
          EXECWEB PLATFORM TERMS OF SERVICE
        </span>
      </h1>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Introduction
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              . &nbsp;
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Execweb Inc. (“
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Execweb
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ” or “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  we
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”) provides a platform for identifying, engaging, and managing
                  high-level subject matter experts (the “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Platform
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”). The following terms and conditions, together with any
                  documents incorporated by reference herein (these “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Terms
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”), govern your access to and use of the Platform.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  By making use of the Platform, you agree to these Terms.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Accessing the Platform; Account Security
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Registration
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . To use or access the Platform, you are required to register
                  for a client account. As part of your registration, we may ask
                  you to provide information such as name, address or payment
                  information (such information, “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Registration Information
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”). You agree to provide us with accurate, complete and
                  updated Registration Information.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Corporate Account Control
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . If you create a client account as a representative of or on
                  behalf of an organization (e.g. by using a corporate email
                  address), that organization will be entitled to control and
                  modify that client account and to otherwise exercise its
                  rights under these Terms. If your organization elects to
                  replace you as the representative with ultimate authority for
                  the client account, we will provide you with notice following
                  such election and you agree to take any actions reasonably
                  requested by us or your organization to facilitate the
                  transfer of authority to a new representative of the
                  organization. &nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Account Security
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . You agree to safeguard your client account and to make sure
                  that others do not have access to your client account or
                  password. You are solely responsible for any activity on your
                  client account and for maintaining the confidentiality and
                  security of your password. We are not liable for any acts or
                  omissions by you in connection with your client account. You
                  must immediately notify us if you know or have any reason to
                  suspect that your client account has been accessed without
                  your authorization or that your client account or password has
                  been stolen, misappropriated or otherwise compromised.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Eligibility
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . If you are an individual, you hereby represent and warrant
                  that you are at or beyond the age of majority within your
                  jurisdiction and, in any event, older than eighteen (18) years
                  of age. If you are under the age of majority in your
                  jurisdiction, please discontinue your use of the Platform.
                  Execweb reserves the right to ask for proof of age and to
                  withhold or terminate your use of the Platform if you are
                  below the speciﬁed age requirement. You represent and warrant
                  that you have the right, authority and capacity to agree to
                  these Terms and to abide by all of the terms and conditions
                  herein.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Geographical Limitations
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Accessing or using the Platform may be prohibited or
                  restricted in certain countries or jurisdictions. Your right
                  to use or access any of the Platform is void in any
                  jurisdiction where such use or access is prohibited. You are
                  responsible for complying with the laws and regulations of the
                  country, territory or jurisdiction from which you access or
                  use the Platform. By accessing or using the Platform, you
                  represent and warrant that you are not located in, under the
                  control of, or a national or resident of, as the case may be,
                  any person, organization or country which the United States
                  has (i) identified as a “Specially Designated National,” (ii)
                  placed on the Commerce Department’s Table of Deny Orders or
                  (iii) specified as the target of a commercial embargo.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Usage Monitoring
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . In order to improve the Platform for the benefit of you,
                  your affiliates, and Execweb clients in general, you hereby
                  agree that Execweb may, but is not obligated to, monitor the
                  use of the Services by you and your affiliates.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Platform Content
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Execweb IP
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . The Platform and its entire contents, features, and
                  functionality (including but not limited to all information,
                  software, text, displays, images, video, and audio, and the
                  design, selection, and arrangement thereof) are owned by
                  Execweb, its licensors, or other providers of such material
                  (collectively, “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Execweb IP
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”) and are protected by United States and international
                  copyright, trademark, patent, trade secret, and other
                  intellectual property or proprietary rights laws.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Limited License
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Execweb grants you a limited, revocable, non-exclusive,
                  non-sublicensable, non-transferable license to access and use
                  the Platform and other Execweb IP solely in accordance with
                  these Terms. Such license is automatically revoked upon a
                  termination of these Terms or your violation thereof. Except
                  as explicitly provided in these Terms, neither Execweb nor its
                  licensors grant you any right, license, title or any related
                  intellectual property rights in the Platform or any other
                  Execweb IP. You shall not reproduce, distribute, modify,
                  retransmit, or publish any Execweb IP or any other
                  copyrighted, trademarked, patented or otherwise protected
                  material without the express written consent of the applicable
                  owner or licensor.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Ambassador Content
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Certain content on the Platform is created and developed by
                  the experts and executives under contract on the Platform (“
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Executive Ambassadors
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”) and provided to Execweb under license (“
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Ambassador Content
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”). Execweb cannot guarantee and makes no representations as
                  to the accuracy or quality of Ambassador Content, all of which
                  is offered “as is.” Execweb does not develop, endorse, and is
                  not responsible for Ambassador Content. The use of a company
                  or entity name in Executive Ambassador biographical or
                  employment information should not be construed as an express
                  or implied endorsement by such company or entity of Execweb.
                  Executive Ambassadors have agreed to be bound by the certain
                  terms and conditions and have represented, among other things,
                  that they will not disclose information that is subject to a
                  confidentiality obligation or participate in activities in
                  violation of any agreements or duties owed to employers or
                  other third parties. Execweb relies on the accuracy of these
                  representations of the Executive Ambassador and does not
                  necessarily seek independent verification.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Feedback
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . To the extent that you give us feedback, comments, or
                  suggestions concerning the Platform (collectively, “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Feedback
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”), you acknowledge and agree that all Feedback is Execweb’s
                  sole and exclusive property. You agree to irrevocably assign
                  and transfer to us, and hereby do assign and transfer to us
                  all of your right, title, and interest in and to the Feedback,
                  including all intellectual property rights therein. To the
                  extent that the foregoing assignment is ineffective for
                  whatever reason, you agree to grant and hereby grant to
                  Execweb a non-exclusive, perpetual, irrevocable, royalty free,
                  worldwide license (with the right to grant and authorize
                  sublicenses) to make, have made, use, import, offer for sale,
                  sell, reproduce, distribute, modify, adapt, prepare derivative
                  works of, display, perform and otherwise exploit such Feedback
                  without restriction. At Execweb’s request and expense, you
                  shall execute documents and take such further acts as we may
                  reasonably request to assist us in acquiring, perfecting and
                  maintaining our intellectual property rights in and other
                  legal protections for the Feedback.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Recorded Content
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Execweb may record roundtable discussions for syndication on
                  its platform. Your hereby irrevocably grants Execweb
                  permission, authorization, and license to produce such
                  recordings and to make them, or any modification thereof
                  available to Execweb’s clients or potential clients in any
                  medium, throughout the world and without any further consent
                  from or royalty to you.&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  This Section does not apply to 1-on-1 strategy calls, for
                  which Execweb may separately seek your permission on a
                  case-by-case basis.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Use of the Platform
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Legal Use
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . You agree not to use the Platform in a manner that is
                  contrary to law, including, without limitation, applicable
                  privacy laws, export or import controls and sanctions.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Circumnavigating Platform Policies
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . You agree to use only the tools provided on the Platform to
                  contact our Executive Ambassadors. You agree not to contact
                  such experts and executives directly regarding the types of
                  engagements offered on the Platform or to negotiate payment
                  outside of the Platform for any such engagements. This section
                  does not apply to the extent that you can show a pre-existing
                  professional relationship between you and the relevant
                  Executive Ambassadors.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Interfering with the Platform
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . You agree not to interfere with or disrupt the Platform or
                  servers, networks or devices connected to the Platform,
                  including by transmitting any worms, viruses, spyware, malware
                  or any other code of a destructive or disruptive nature. You
                  shall not inject content or code or otherwise alter or
                  interfere with the way any of the Platform are rendered or
                  displayed in a user’s browser or device. In addition, you
                  agree not to:
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      probe, scan or test the vulnerability of any system or
                      network;&nbsp;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      breach or otherwise bypass any security or authentication
                      measures;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      access, tamper with or use non-public areas or parts of
                      the Platform, or shared areas of the Platform that Execweb
                      has not invited you to access;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      take apart, decompile or reverse engineer any part of the
                      Platform in an effort to access things such as source code
                      or algorithms;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      access, search or create accounts for the Platform by any
                      means (for example, scraping, spidering or crawling) other
                      than our publicly supported interfaces;
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      take action that imposes an unreasonable load on our
                      infrastructure or that of our third party providers (where
                      Execweb reserves the right to determine what is reasonable
                      or unreasonable); and
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      provide your password to any other person, use any other
                      person’s username and password, or otherwise manage the
                      Platform through shared credentials (unless such
                      management explicitly approved by Execweb).
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Spamming and Phishing
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . You agree not to use the Platform to send spam, emails that
                  violate the CAN-SPAM Act or other laws, emails to people who
                  have not explicitly consented (where such consent is required)
                  or other unsolicited communications (bulk or otherwise),
                  promotions or advertisements. We reserve the right to
                  determine what constitutes spam. You also agree not to send
                  altered, deceptive or false source-identifying information
                  (including any misleading or incorrect names, email addresses
                  or subject lines), including, without limitation, phishing or
                  spoofing.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Deceptive, Fraudulent or Criminal Behavior
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . You agree not to, and agree to use your best efforts to
                  ensure that your affiliates do not: (i) use the Platform to
                  act in a way that is false, fraudulent, inaccurate or
                  deceiving; (ii) impersonate another person, company or entity;
                  (iii) engage in misleading or unethical marketing or
                  advertising; (iv) use unauthorized credit cards, debit cards
                  or other unauthorized payment devices on the Platform; (v)
                  promote or advertise products or services other than your own
                  without appropriate authorization; (vi) sell the Platform
                  unless specifically authorized to do so by Execweb; (vii)
                  infringe or misappropriate anyone’s copyright, trademark or
                  other intellectual property rights or to violate anyone’s
                  privacy or publicity rights; or (viii) violate any laws
                  through your use of the Platform, including, without
                  limitation, all local laws regarding online conduct and
                  acceptable content.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Harrassment
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . You agree not to: (i) threaten, harass or abuse any
                  individuals; (ii) incite violence; (iii) publish sexually
                  explicit or obscene material; (iv) condone or promote
                  self-harm; or (v) condone or promote violence against any
                  person or group based on race, ethnicity, nationality,
                  religion, gender, gender identity, sexual preference, age or
                  disability.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Consequences of Prohibited Use
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . You shall use your best efforts to promptly notify Execweb
                  of any act or omission by you or your affiliates that has
                  resulted in or may likely result in the violation of this
                  Section. If we conclude, in our sole discretion, that you have
                  misused the Platform, we may take corrective action. We do our
                  best to ensure fair outcomes, but in all cases, we reserve the
                  right to remove any content or suspend or terminate your use
                  of or access to the Platform, without liability or notice to
                  you, at any time and for any reason (except where prohibited
                  by applicable law). You may report violations of these
                  guidelines directly to Execweb by emailing&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  help@execweb.com
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  .
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Reservation of Rights
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              . We reserve the following rights without limitation, which we may
              exercise at any time and in our sole discretion, and without
              liability or notice to you (except where prohibited by applicable
              law): (a) we may change parts or all of the Platform and its
              functionality; (b) we may suspend or discontinue parts or all of
              the Platform; (c) we may, subject to the termination provisions of
              these Terms, terminate, suspend, restrict or disable your access
              to or use of parts or all of the Platform; (d) we may delete or
              remove any content and (e) we may change our eligibility criteria
              to use the Platform (and if such eligibility criteria changes are
              prohibited by law where you live, we may revoke your right to use
              the Platform in that jurisdiction). Execweb reserves all rights
              not explicitly granted to you in these Terms.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Privacy
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              . All information we collect on the Platform is subject to
              our&nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              <a href="#" onClick={onPrivacyPolicyClick}>
                {" "}
                Privacy Policy{" "}
              </a>
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "#ffff00",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            ></span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              . By using the Platform, you consent to all actions taken by us
              with respect to your information in compliance with the Privacy
              Policy.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Confidential Information
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .&nbsp;
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You agree not to disclose or attempt to use or personally
                  benefit from any Confidential Information, as defined below,
                  you learn on the Platform. This obligation shall continue
                  until such time as the Confidential Information has become
                  publicly known through no action of your own. Confidential
                  Information shall include: (i) information about actual or
                  potential business, investment or trading decisions or
                  transactions of any Execweb client; (ii) any other
                  confidential information of Execweb, its clients, and
                  Executive Ambassadors, and (iii) any intellectual property,
                  including without limitation any trade secrets, know-how, or
                  copyrighted information, of Execweb or its clients. If you are
                  compelled by order of a court or other governmental or legal
                  body (or have notice that such an order is being sought) to
                  divulge any Confidential Information, you agree to promptly
                  notify Execweb and cooperate fully with Execweb in protecting
                  such information to the extent possible under applicable law.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You understand that an Executive Ambassador’s capacity to
                  consult may be limited by other research collaborations or
                  obligations they may have to entities or institutions with
                  which they are affiliated or employed. Executive Ambassadors
                  are also instructed not to reveal, and you shall not knowingly
                  seek out from them, any material nonpublic information or
                  confidential information concerning any company or entity with
                  which they have or have had a consulting, advisory, employment
                  or other relationship. To the extent you know about any such
                  limitations or obligations, you agree to respect them in your
                  interactions with Executive Ambassadors. You confirm that you
                  shall not knowingly or recklessly use the Platform to access
                  Executive Ambassadors who are employed by a company reasonably
                  considered a direct competitor of yours or, if applicable, one
                  of your business units, actively managed portfolio companies,
                  affiliates or subsidiaries or the client you are acting on
                  behalf of and will decline any discussion with any such
                  Council Member.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Digital Millennium Copyright Act Notice
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .&nbsp;
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  We take claims of copyright infringement seriously. We will
                  respond to notices of alleged copyright infringement that
                  comply with applicable law. If you believe any materials
                  accessible within the Platform infringe on your copyright, you
                  may request removal of those materials (or removal access to
                  them) from the Platform by submitting written notification to
                  our copyright agent designated below. In accordance with the
                  Online Copyright Infringement Liability Limitation Act of the
                  Digital Millennium Copyright Act (17 U.S.C. § 512) (the “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  DMCA
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”), the written notice (the “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  DMCA Notice
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”) must include substantially the following:
                </span>
              </p>
              <ol
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  paddingInlineStart: "48px",
                }}
              >
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Your physical or electronic signature.
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Identification of the copyrighted work you believe to have
                      been infringed or, if the claim involves multiple works on
                      the Website, a representative list of such works.
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Identification of the material you believe to be
                      infringing in a sufficiently precise manner to allow us to
                      locate that material.
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Adequate information by which we can contact you
                      (including your name, postal address, telephone number,
                      and, if available, email address).
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      A statement that you have a good faith belief that use of
                      the copyrighted material is not authorized by the
                      copyright owner, its agent, or the law.
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      A statement that the information in the written notice is
                      accurate.
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      A statement, under penalty of perjury, that you are
                      authorized to act on behalf of the copyright owner.
                    </span>
                  </p>
                </li>
                <li
                  aria-level={3}
                  dir="ltr"
                  style={{
                    listStyleType: "lower-roman",
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre",
                  }}
                >
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.3800000000000001",
                      textAlign: "justify",
                      marginTop: "0pt",
                      marginBottom: "6pt",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Our designated copyright agent to receive DMCA Notices is:
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      &nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Attn: Copyright Agent
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      &nbsp;
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      Execweb Inc.
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      2807 Jackson Avenue Floor 5, Long Island City, NY 11101
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      516-703-1311
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "12pt",
                        fontFamily: '"Times New Roman"',
                        color: "#000000",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      <span
                        className="Apple-tab-span"
                        style={{ whiteSpace: "pre" }}
                      >
                        &nbsp; &nbsp;&nbsp;
                      </span>
                    </span>
                    <a
                      href="mailto:copyright@execweb.com"
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: '"Times New Roman"',
                          color: "#0000ff",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "underline",
                          WebkitTextDecorationSkip: "none",
                          textDecorationSkipInk: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        copyright@Execweb.com
                      </span>
                    </a>
                  </p>
                </li>
              </ol>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Failure of Notice Requirements
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . If you fail to comply with all of the requirements of
                  Section 512(c)(3) of the DMCA, your DMCA Notice may not be
                  effective.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Misrepresentation
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Please be aware that if you knowingly materially
                  misrepresent that material or activity on the Platform is
                  infringing your copyright, you may be held liable for damages
                  (including costs and attorneys' fees) under Section 512(f) of
                  the DMCA.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Repeat Infringers
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . It is our policy in appropriate circumstances to disable or
                  terminate the client accounts of users who repeatedly infringe
                  copyright laws.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Paid Services and Fees
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .&nbsp;
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Authorization
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Execweb charges fees for certain services on the Platform (“
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Paid Services
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”). If you use a credit card to enroll in a Paid Service on
                  the Platform, you hereby authorize Execweb to charge that
                  credit card the amount indicated when you enrolled in the Paid
                  Service.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Refunds
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Refunds are evaluated on a case-by-case basis. If you would
                  like to request a refund, or, if you believe your credit card
                  has been charged in error, please contact us at&nbsp;
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  contact@execweb.com
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  .&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Payment Information
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . You agree to provide Execweb with valid payment information
                  in connection with your purchase of any Paid Services. By
                  providing Execweb with your payment information, you agree
                  that (i) Execweb is authorized to immediately invoice your
                  client account for all fees due and payable to Execweb, (ii)
                  Execweb is authorized to share any payment information and
                  instructions required to complete the payment transactions
                  with its third-party payment service providers (e.g., credit
                  card transaction processing, merchant settlement, and related
                  services), and (iii) no additional notice or consent is
                  required for the foregoing authorizations. You agree to
                  immediately notify Execweb of any change in your payment
                  information.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Fee Changes
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . We may change our fees at any time. We will provide you with
                  advance notice of these fee changes via the Platform. New fees
                  will not apply retroactively. If you do not agree with any fee
                  changes, you have the right to reject the change by canceling
                  the applicable Paid Service before your next payment date.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Taxes
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Fees are stated exclusive of any taxes, levies, duties or
                  similar governmental assessments of any nature, including, for
                  example, value-added, sales, use or withholding taxes,
                  assessable by any jurisdiction (collectively, “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Taxes
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”). You are responsible for paying all Taxes and all other
                  charges incidental to purchasing any of the Paid Services,
                  except for those taxes based on Execweb’s net income. If any
                  payments for any part of the Paid Services are subject to
                  withholding tax by any government, you agree to reimburse us
                  for such withholding tax. Where Execweb does not have a legal
                  obligation to pay or collect indirect Taxes on a sale of any
                  of the Paid Services to you, you may be required to
                  self-assess those Taxes under the applicable laws of the
                  country where you are established, have a permanent address or
                  usually reside.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Currency
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Unless otherwise stated, all monetary amounts specified in
                  these Terms are in reference to lawful currency of the United
                  States of America.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Documentation and Support
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Documentation
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Execweb may make manuals, handbooks, and installation
                  guides, or similar instructive material available on the
                  Platform from time to time (the “
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Documentation
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ”). Subject to these Terms, Execweb hereby grants you a
                  limited, revocable, non-exclusive, non-sublicensable, and
                  non-transferable license to use and make a reasonable number
                  of copies of the Documentation solely for your internal
                  business purposes in connection with your use of the Platform.
                  This license is revoked automatically upon a termination of
                  your account pursuant to these Terms.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Support
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Execweb shall make the Documentation accessible through the
                  Platform. In addition, you may contact our customer support
                  team at&nbsp;
                </span>
                <a
                  href="mailto:support@execweb.com"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "12pt",
                      fontFamily: '"Times New Roman"',
                      color: "#0000ff",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "underline",
                      WebkitTextDecorationSkip: "none",
                      textDecorationSkipInk: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    support@execweb.com
                  </span>
                </a>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  .
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Termination
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .&nbsp;
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Generally
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . You understand and agree that Execweb may, in its sole
                  discretion and at any time, terminate your password, your
                  client account, or access to any Platform Content, for any
                  reason. Execweb may also, in its sole discretion and at any
                  time, discontinue any part of the Platform or limit your
                  access thereto, for any reason. If Execweb deems you to be in
                  violation of these Terms, you understand and agree that
                  Execweb may take any one or more of the foregoing actions
                  without prior notice to you. If Execweb has not deemed you to
                  be in violation of these Terms, Execweb shall provide you with
                  two (2) weeks prior written notice before taking such actions.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Client Data
                </span>
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  . Should Execweb take any of the actions described in the
                  subsection above, it may, in its sole discretion, immediately
                  deactivate or delete any information related your client
                  account. You understand and agree that Execweb shall not have
                  any liability to you or any other person for any termination
                  of your access to any part of the Platform or the removal of
                  any information concerning your client account.
                </span>
              </p>
            </li>
          </ol>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: "decimal",
            fontSize: "12pt",
            fontFamily: '"Times New Roman"',
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre",
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt",
            }}
          >
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Miscellaneous Provisions
            </span>
            <span
              style={{
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              . &nbsp;
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  If any term or provision of this Services Agreement is
                  invalid, illegal, or unenforceable in any jurisdiction, such
                  invalidity, illegality, or unenforceability shall not affect
                  any other term or provision of this Services Agreement or
                  invalidate or render unenforceable such term or provision in
                  any other jurisdiction.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  This Services Agreement may be executed in counterparts, each
                  of which shall be deemed an original, but all of which
                  together shall be deemed to be one and the same agreement. A
                  signed copy of this Services Agreement delivered by facsimile,
                  email or other means of electronic transmission shall be
                  deemed to have the same legal effect as delivery of an
                  original signed copy of this Services Agreement.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  This Services Agreement, and any other documents incorporated
                  herein by reference, constitutes the sole and entire agreement
                  of the parties hereto with respect to the subject matter
                  contained herein and therein, and supersedes all prior and
                  contemporaneous understandings, agreements, representations,
                  and warranties, both written and oral, with respect to such
                  subject matter.&nbsp;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  THE CONTENT AND FUNCTIONALITY OF THE PLATFORM IS PROVIDED WITH
                  THE UNDERSTANDING THAT EXECWEB IS NOT HEREIN ENGAGED IN
                  RENDERING PROFESSIONAL ADVICE AND SERVICES TO YOU. ALL CONTENT
                  AND FUNCTIONALITY ON THE PLATFORM IS PROVIDED “AS IS,” WITHOUT
                  WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
                  WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY,
                  FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
                  EXECWEB MAKES NO WARRANTIES, EXPRESS OR IMPLIED, AS TO THE
                  OWNERSHIP, ACCURACY, OR ADEQUACY OF THE PLATFORM CONTENT OR
                  THAT THE FUNCTIONALITY ON THIS PLATFORM WILL BE UNINTERRUPTED
                  OR ERROR-FREE. YOU HEREBY ACKNOWLEDGE THAT YOUR USE OF THE
                  EXECWEB PLATFORM IS AT YOUR SOLE RISK. UNDER NO CIRCUMSTANCES
                  SHALL EXECWEB OR ANY OF ITS PREDECESSORS, SUCCESSORS, PARENTS,
                  SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, SHAREHOLDERS,
                  INVESTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, ATTORNEYS AND
                  THEIR RESPECTIVE HEIRS, SUCCESSORS AND ASSIGNS BE LIABLE FOR
                  ANY DAMAGES, INCLUDING DIRECT, INCIDENTAL, PUNITIVE, SPECIAL,
                  CONSEQUENTIAL OR EXEMPLARY DAMAGES THAT DIRECTLY OR INDIRECTLY
                  RESULT FROM THE USE OF, OR THE INABILITY TO USE, THIS SITE OR
                  THE INFORMATION CONTAINED ON THIS SITE OR OBTAINED FROM YOUR
                  USE OF THIS SITE, INCLUDING FOR VIRUSES ALLEGED TO HAVE BEEN
                  OBTAINED FROM THE SITE, EVEN IF EXECWEB HAS BEEN ADVISED OF
                  THE POSSIBILITY OF SUCH DAMAGES OR LOSSES AND REGARDLESS OF
                  THE THEORY OF LIABILITY.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  As a condition of your use of Execweb’s platform, Sponsor
                  agrees to indemnify, defend and hold Execweb, our officers,
                  directors, employees, agents and representatives harmless from
                  and against all claims, damages, losses, costs (including
                  reasonable attorney’s fees), or other expenses that arise
                  directly or indirectly out of or from (a) Sponsor’s violation
                  of this Services Agreement; (b) Sponsor’s use of the platform;
                  or (c) Sponsor’s violation of the rights of any third party.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  This Services Agreement may only be amended, modified, or
                  supplemented by an agreement in writing signed by each party
                  hereto.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  All matters arising out of or relating to this Services
                  Agreement shall be governed in accordance with the laws of the
                  State of New York without reference to the principles of
                  conflicts of laws thereof. You agree to submit to the personal
                  and exclusive jurisdiction of the courts located within the
                  State of New York, County of New York with respect to all
                  disputes arising out of or related to these Terms. If any part
                  of these terms is unlawful, void, or unenforceable, that part
                  will be deemed severable and will not affect the validity and
                  enforceability of the remaining provisions.
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "12pt",
                fontFamily: '"Times New Roman"',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt",
                }}
              >
                <span
                  style={{
                    fontSize: "12pt",
                    fontFamily: '"Times New Roman"',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  All sections contained within these Terms that, by their
                  nature, should survive termination shall survive termination.
                </span>
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </div>
  );
}

export { TermsOfServices };
