import { IMAGES_PATH } from "../../../constants";


export default function MPAdvanceFilters({
  advFilters,
  setAdvFilters,
  advFilterErrors,
  setAdvFilterErrors,
}) {
  const advFilterOptions = {
    funding: ["more than", "less than", "equal to"],
    revenue: ["more than", "less than", "equal to"],
    founded: ["before", "after", "exact"],
  };
  const handleAdvFilterChange = (index, key, value) => {
    const newAdvFilters = [...advFilters];

    if (
      key === "value" &&
      (advFilters[index].field === "revenue" ||
        advFilters[index].field === "founded")
    ) {
      const numericValue = value.replace(/\D/g, "").slice(0, 5);
      newAdvFilters[index][key] = numericValue;
    } else {
      newAdvFilters[index][key] = value;
    }

    if (key === "field" && value) {
      newAdvFilters[index].condition = advFilterOptions[value][0];
      newAdvFilters[index].value = "";
    }

    setAdvFilters(newAdvFilters);

    const newErrors = [...advFilterErrors];
    newErrors[index] = null;
    setAdvFilterErrors(newErrors);
  };

  const addAdvFilter = () => {
    setAdvFilters([...advFilters, { field: "", condition: "", value: "" }]);
  };

  const removeAdvFilter = (index) => {
    const newAdvFilters = advFilters.filter((_, i) => i !== index);
    setAdvFilters(newAdvFilters);

    const newErrors = advFilterErrors.filter((_, i) => i !== index);
    setAdvFilterErrors(newErrors);
  };

  const getPlaceholder = (field) => {
    if (field === "revenue") {
      return "(millions)";
    }
    if (field === "founded") {
      return "(in years)";
    }
    if (field === "funding") {
      return "(millions)";
    }
    return "";
  };

  const getDisabledOptions = () => {
    return advFilters.map((filter) => filter.field).filter((field) => field);
  };

  const allFiltersSelected = () => {
    return Object.keys(advFilterOptions).length === advFilters.length;
  };

  const isAddButtonDisabled = () => {
    const lastFilter = advFilters[advFilters.length - 1];
    return !lastFilter.field || !lastFilter.condition || !lastFilter.value;
  };
  return (
    <>
      {advFilters?.map((filter, index) => (
        <div className="vf-form-row" key={index}>
          <div className="vf-cnd-col">
            <select
              className="form-control form-control-2"
              value={filter.field}
              onChange={(e) =>
                handleAdvFilterChange(index, "field", e.target.value)
              }
              style={{
                border:
                  advFilterErrors[index] && !filter.field
                    ? "1px solid red"
                    : "",
              }}
            >
              <option value={""}>Select</option>
              {Object.keys(advFilterOptions).map((key) => (
                <option
                  key={key}
                  value={key}
                  disabled={getDisabledOptions().includes(key)}
                >
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </option>
              ))}
            </select>
            <select
              className="form-control form-control-3"
              value={filter.condition}
              onChange={(e) =>
                handleAdvFilterChange(index, "condition", e.target.value)
              }
              style={{
                border:
                  advFilterErrors[index] && !filter.condition
                    ? "1px solid red"
                    : "",
              }}
              disabled={!filter.field}
            >
              <option value={""}>Select</option>
              {advFilterOptions[filter.field]?.map((item, idx) => (
                <option key={idx} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <input
              type="text"
              className="form-control form-control-4"
              value={filter.value}
              placeholder={getPlaceholder(filter.field)}
              onChange={(e) =>
                handleAdvFilterChange(index, "value", e.target.value)
              }
              onKeyDown={(e) => {
                if (
                  !/[0-9]/.test(e.key) &&
                  e.key !== "Backspace" &&
                  e.key !== "Delete" &&
                  e.key !== "ArrowLeft" &&
                  e.key !== "ArrowRight" &&
                  e.key !== "Tab"
                ) {
                  e.preventDefault();
                }
              }}
              style={{
                border:
                  advFilterErrors[index] && !filter.value
                    ? "1px solid red"
                    : "",
              }}
              disabled={!filter.condition}
            />
            {!allFiltersSelected() && !isAddButtonDisabled() && (
              <button
                type="button"
                className="add-filter"
                onClick={addAdvFilter}
                disabled={isAddButtonDisabled()}
              >
                <img src={`${IMAGES_PATH}/add_icon.svg`} />
              </button>
            )}
            {advFilters?.length > 1 && (
              <button
                type="button"
                className="remove-col"
                onClick={() => removeAdvFilter(index)}
              >
                <img
                  src={`${IMAGES_PATH}/close_clear.png`}
                  height={25}
                  width={25}
                />
              </button>
            )}
          </div>
        </div>
      ))}
    </>
  );
}
