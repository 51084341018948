import Login from "../screens/auth/login";
import Signup from "../screens/auth/signup";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import { Logout } from "../screens/auth/logout";
import { appLayoutHOC } from "../HOC/layouts";
import { Route, withRouter, Switch, Redirect } from "react-router";
import { compose } from "redux";
import { PageNotFound } from "../components/errors";
import { ROUTE_PATHS } from "./route-paths";
import authLayoutHOC from "../HOC/layouts/auth-layout";

export const authRoutePaths = {
  auth: {
    LOGIN: "/login",
    SIGNUP: "/signup",
    BECOME_A_HOST: "/signup",
    LINKEDIN: "/linkedin-success",
    LOGOUT: "/logout",
  },
};

function AuthRoutes() {
  return (
    <Switch>
      <Route
        exact
        path={ROUTE_PATHS.auth.LINKEDIN}
        component={compose(withRouter, appLayoutHOC)(LinkedInPopUp)}
      />
      <Route
        exact
        path={ROUTE_PATHS.auth.LOGIN}
        component={compose(withRouter, authLayoutHOC)(Login)}
      />
      <Route
        exact
        path={ROUTE_PATHS.auth.SIGNUP}
        component={compose(withRouter, appLayoutHOC)(Signup)}
      />
      <Route
        exact
        path={ROUTE_PATHS.auth.BECOME_A_HOST}
        component={compose(withRouter, appLayoutHOC)(Signup)}
      />
      <Route
        exact
        path={ROUTE_PATHS.auth.LOGOUT}
        component={compose(withRouter, appLayoutHOC)(Logout)}
      />

      <Route
        path={ROUTE_PATHS.NOT_FOUND}
        exact={true}
        component={PageNotFound}
      />

      <Redirect to={ROUTE_PATHS.NOT_FOUND} />
    </Switch>
  );
}

export { AuthRoutes };
