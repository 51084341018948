import { FILE_TYPE_ICONS, FILTER_OPERATORS_LIST } from "../../constants/lists";
import { amountFormat } from "../../services/utils";

const fileUploadTypes = [
  { type: "onboardingGuide", label: "Implementation steps/timeline" },
  { type: "permissions", label: "Details on requirements for a pilot/POC" },
  {
    type: "agreement",
    label: "Standard or proposed contract/licensing/subscription agreement",
  },
  {
    type: "technicalDocs",
    label:
      "Technical Documentation Access: Providing access to technical documentation",
  },
  {
    type: "demoLink",
    label:
      "Personal gmail account try out a demo or trial instance in the Cloud",
  },
  {
    type: "useCase",
    label: "Detailed Use Case Examples",
  },
];

export const MP_COMPANY_DETAILS = [
  { label: "Revenues", key: "revenue", prefix: "$" },
  { label: "Employee Size", key: "no_of_emp" },
  { label: "Funding", key: "funding", prefix: "$" },
  { label: "Founded", key: "founded" },
  { label: "Country", key: "country" },
  { label: "State", key: "state" },
  { label: "Fund Year Last", key: "fundYear" },
  { label: "Contact", key: "contact", className: "full-width" },
  {
    label: "Pricing Structure",
    key: "pricingStructure",
    className: "full-width",
  },
  { label: "Pricing", key: "pricing", className: "full-width" },
  { label: "Address", key: "address", className: "full-width" },
];

export const MP_INTRESTED_IN_ICONS = {
  "1:1 Introductory Sales Meetings": "/introductory-sales.svg",
  "1:1 Advisory Meetings": "/advisory-meetings.svg",
  "Advisory Board Members": "/advisory-board-memeber.svg",
  Investors: "/investors.svg",
};

export const QuestionaireQuestions = [
  {
    label: "Have you spoken with [vendor_name] in the past?",
    name: "spokenWithVendor",
    options: ["Yes", "No"],
    width: { xs: 12, md: 6 },
  },
  {
    label: "How soon would like to buy?",
    name: "purchaseTimeline",
    options: [
      "Within 3 months",
      "Within 6 months",
      "Within 1 year",
      "Undecided",
    ],
    width: { xs: 12, md: 6 },
  },
  {
    label: "Are you replacing an existing solution?",
    name: "replacingSolution",
    options: [
      "No, this is filling a business need",
      "Yes, this will replace a current product",
    ],
    subQuestion: {
      parentAnswer: "Yes, this will replace a current product",
      label: "What product/vendor are you looking to replace?",
      type: "text",
    },
    width: { xs: 12, md: 12 },
  },
  {
    label: "Are you evaluating competitors?",
    name: "evaluatingCompetitors",
    options: ["No", "Yes"],
    subQuestion: {
      parentAnswer: "Yes",
      label: "What product/vendor are you looking to evaluate?",
      type: "text",
    },
    width: { xs: 12, md: 12 },
  },
  {
    label: "Where are you in the sales cycle?",
    name: "salesCycleStage",
    options: [
      "I am in early conversations",
      "I have received an initial quote",
      "I have finalized terms and pricing",
    ],
    width: { xs: 12, md: 4 },
  },
  {
    label: "Desired term length",
    name: "termLength",
    options: ["One year term", "Two year term", "Three year term"],
    width: { xs: 12, md: 4 },
  },
  {
    label: "Desired billing",
    name: "billingPreference",
    options: ["Monthly", "Quarterly", "Semi-annual", "Annual"],
    width: { xs: 12, md: 4 },
  },
];

export function getNoOfEmpLabel(list, id) {
  return list?.find((n) => n?.id === id)?.label;
}

export function createMPPricingList() {
  const list = [];

  for (let i = 1; i <= 3; i++) {
    let price = "";
    let count = 0;

    // Construct the price string with dollar signs and commas
    for (let j = 0; j < i; j++) {
      if (count > 0 && count % 3 === 0) {
        price = "," + price;
      }
      price = "$" + price;
      count++;
    }

    // Calculate the numerical value for the range based on 1,000 per dollar sign
    const value = Math.pow(10, i - 1) * 1000;

    // Create the label string
    const label = `${price} - ${value}`;
    const formattedLabel = `${price} - ${value.toLocaleString()}`;

    // Push the object to the list
    list.push({
      label,
      id: value,
      formattedLabel,
      previewLabel: amountFormat(value),
    });
  }

  return list;
}

export function convertToHTTPSURL(url) {
  if (!url?.startsWith("http://") && !url?.startsWith("https://")) {
    url = "https://" + url;
  }
  return url;
}

export function getFileTypeIcon(file) {
  if (!file) return FILE_TYPE_ICONS.default;

  if (file?.[0] instanceof File) {
    if (!file[0]) return FILE_TYPE_ICONS.default;
    const tFile = file[0];
    const fileType = tFile?.name?.split(".")?.pop();
    return FILE_TYPE_ICONS?.[fileType] || FILE_TYPE_ICONS?.default;
  } else {
    return FILE_TYPE_ICONS?.default;
  }
}

export function getFileName(fileName) {
  return (
    fileUploadTypes?.find((fut) => fut?.type === fileName)?.label || fileName
  );
}

export function getPayload({ formInputs, lists }) {
  return {
    ...formInputs,
    interestedId: Array.isArray(formInputs?.interestedId)
      ? formInputs?.interestedId?.map((k) => k?.label !== "")?.join(",")
      : formInputs?.interestedId,
    no_of_emp: lists?.noOfEmp?.data?.find(
      (n) => n?.id === formInputs?.no_of_emp
    )?.id,
    pricingStructure: Array.isArray(formInputs?.pricingStructure)
      ? formInputs?.pricingStructure?.join(",")
      : formInputs?.pricingStructure,
    keywords: Array.isArray(formInputs?.keywords)
      ? formInputs?.keywords?.map((k) => k?.label)?.join(",")
      : formInputs?.keywords,
    funding: formInputs?.funding ? formInputs?.funding : 0,
    revenue: formInputs?.revenue ? formInputs?.revenue : 0,
  };
}

export function downloadFile(file) {
  const isFileArray = file?.length > 0 && file?.[0] instanceof File;
  const fileObj = isFileArray ? file?.[0] : null;

  const url = fileObj ? URL.createObjectURL(fileObj) : file;
  const a = document.createElement("a");
  a.href = url;
  a.download = fileObj ? fileObj.name : "download";
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  if (fileObj) {
    URL.revokeObjectURL(url);
  }
}

export function generateMarketplaceFilterPayload(filters) {
  const p = [];
  const { advancedFilters, sort, ...restFilters } = filters;

  Object.keys?.(restFilters)?.forEach?.((key) => {
    if (Array.isArray(filters[key])) {
      const arrKeys = ["no_of_emp"];
      if (arrKeys.includes(key) && filters[key]?.length > 0) {
        const arrayOfObjKeyPair: any = {
          no_of_emp: { valueField: "id", operator: "in" },
          keywords: { operator: "sin" },
          country: { operator: "in" },
        };

        if (key === "no_of_emp") {
          const v = filters[key]?.map(
            (d) => d?.[arrayOfObjKeyPair[key]?.["valueField"]]
          );

          p.push({
            field: key,
            value: v,
            operator: arrayOfObjKeyPair[key]?.operator,
          });
        } else {
          p.push({
            field: key,
            value: filters[key],
            operator: arrayOfObjKeyPair[key]?.operator,
          });
        }
      }
    } else if (filters[key]) {
      const arrKeys = ["country", "keywords", "categories"];
      const arrayOfObjKeyPair: any = {
        no_of_emp: { operator: "in" },
        keywords: { operator: "sin" },
        country: { operator: "in" },
        categories: { operator: "in" },
      };
      p.push({
        field: key,
        value: arrKeys?.includes(key) ? filters[key]?.split(",") : filters[key],
        operator: arrKeys?.includes(key)
          ? arrayOfObjKeyPair[key]?.operator
          : "contains",
      });
    }
  });

  filters?.advancedFilters?.forEach((item) => {
    const operator = FILTER_OPERATORS_LIST?.find((d) =>
      d?.tags.includes(item?.condition)
    )?.value;

    p.push({
      field: item?.field,
      value: item?.value,
      operator,
    });
  });

  return {
    filters: p,
    sort: {
      [sort?.by ?? "name"]: !sort?.order ? 1 : sort?.order === "asc" ? 1 : -1,
    },
  };
}
