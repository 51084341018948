import { postApi, putApi } from "../services/axios";
import APIs from ".";
import store from "../redux";
import { setUserInfo, updateUserInfo } from "../redux/modules/auth/actions";
import { resetStore } from "../redux/modules";

function setTokenData(data) {
  localStorage.setItem("token", `Bearer ${data.token}`);
  localStorage.setItem("user", JSON.stringify({ ...data }));
}

export async function userSignup(payload) {
  const res = await postApi(APIs.AUTH.SIGNUP, payload, {
    validateUser: false,
  });

  if (res.success) {
    setTokenData(res.data);
  }

  return res;
}

export async function userBecomeHost(payload) {
  const res = await postApi(APIs.AUTH.BECOME_A_HOST, payload, {
    validateUser: false,
  });

  return res;
}

export async function userLogin(payload) {
  const res = await postApi(APIs.AUTH.LOGIN, payload, {
    validateUser: false,
  });

  if (res.success) {
    setTokenData(res.data);
  }

  return res;
}

export async function userLinkedInLogin(payload) {
  const res = await postApi(APIs.AUTH.LINKEDIN, payload, {
    validateUser: false,
  });

  if (res.success) {
    setTokenData(res.data);
  }

  return res;
}

export async function hostLinkedInLogin(payload) {
  const res = await postApi(APIs.AUTH.BECOME_A_HOST_LINKEDIN, payload, {
    validateUser: false,
  });

  return res;
}

export async function validateToken() {
  if (localStorage.user) {
    const data = JSON.parse(localStorage.user);

    store.dispatch(
      setUserInfo({
        user: { ...data, isLoggedIn: true },
      })
    );

    return {
      success: true,
    };
  }

  return {
    success: false,
  };
}

export async function userLogout() {
  localStorage.clear();

  store.dispatch(resetStore());
}

export async function signupOtherDetails(payload, userId) {
  let userData = store.getState().auth.user;
  const res = await putApi(APIs.AUTH.SIGNUP_OTHER_DETAILS(userId), payload, {
    validateUser: true,
  });

  if (res.success) {
    userData = {
      ...userData,
      ...res.data,
    };

    store.dispatch(updateUserInfo(userData));
    localStorage.setItem("user", JSON.stringify(userData));
  }

  return res;
}
