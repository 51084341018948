import { Grid } from "@mui/material";
import { SummaryItem } from "./item";

function DashboardSummary({ summaryData }) {
  return (
    <Grid container spacing={3}>
      {summaryData.map((item, index) => (
        <Grid key={index} item xs={12} md={6} lg={3} sm={6}>
          <SummaryItem item={item} />
        </Grid>
      ))}
    </Grid>
  );
}

export { DashboardSummary };
