import { SimpleTable } from "../../../../components/mui/table";
import { ENUM_CURRENCY, RENDERER_TYPES } from "../../../../enums";
import {
  DATE_FORMATS,
  formatDate,
} from "../../../../services/date-and-time.utils";
import { numberFormat } from "../../../../services/utils";

function ClientCampaignListTable({
  list,
  columns,
  handlePageFilters,
  appliedFilters,
}) {
  const { selectedPage, selectedPageSize } = appliedFilters;

  const handleTDRenderer = (row, column) => {
    switch (column.rendererType) {
      case RENDERER_TYPES.FULL_DATE_FROM:
        return `${formatDate(
          row.date,
          DATE_FORMATS.fullDateWithTime
        )}`;
      case RENDERER_TYPES.AMOUNT:
        return `${numberFormat(row[column.field], ENUM_CURRENCY.USD)}`;
      default:
        break;
    }
  };
  return (
    <SimpleTable
      tdRenderer={handleTDRenderer}
      rows={list?.data}
      columns={columns}
      fetching={list?.loading}
      total={list?.total}
      onPageSettingsChange={handlePageFilters}
      selectedPage={selectedPage}
      selectedPageSize={selectedPageSize}
      showPagination={false}
    ></SimpleTable>
  );
}

export { ClientCampaignListTable };
