import {
  DEFAULT_SELECTED_PAGE,
  DEFAULT_SELECTED_PAGE_SIZE,
} from "../../../constants";

const SET_CLIENTS_LIST = "SET_CLIENTS_LIST";
const SET_CLIENT_LIST_FILTERS = "SET_CLIENT_LIST_FILTERS";
const SET_CLIENTS_LIST_LOADING = "SET_CLIENTS_LIST_LOADING";

const initalClientListState = {
  data: undefined,
  loading: null,
  filters: {
    selectedPage: DEFAULT_SELECTED_PAGE,
    selectedPageSize: DEFAULT_SELECTED_PAGE_SIZE,
    searchValue: "",
  },
  total: undefined,
};

const setClientListLoading = (payload) => ({
  type: SET_CLIENTS_LIST_LOADING,
  payload,
});

const setClientsList = (payload) => ({
  type: SET_CLIENTS_LIST,
  payload,
});

const setClientListFilters = (payload = undefined) => ({
  type: SET_CLIENT_LIST_FILTERS,
  payload,
});

export {
  initalClientListState,
  setClientListLoading,
  setClientsList,
  setClientListFilters,
  SET_CLIENTS_LIST,
  SET_CLIENT_LIST_FILTERS,
  SET_CLIENTS_LIST_LOADING,
};
