import { Box, Grid } from "@mui/material";
import { AutoSelectbox } from "../../../../components/selectbox/autoselect";
import { useState } from "react";
import {
  PRIORITY_FIELDS,
  PRIORITY_FIELDS_FORM_ERROR_MAAPING,
} from "./form-data";
import { useSelector } from "react-redux";
import { CONST_INTRESTED_IN_PRIORITY_LIST } from "../../../../constants/lists";
import { SaveAndCancelButton } from "../../../../components/buttons";
import { validateForm } from "../../../../services/validators";

const initialFields: any = {
  label: "",
  priority: "",
};

const initialErrors = {
  ...initialFields,
};

export default function AddOrUpdateIntrested({
  onSubmit,
  onCancel,
  selectedIntrested,
  priorityList = [],
}) {
  const [formFields, setFormField] = useState(
    selectedIntrested || initialFields
  );
  const [formErrors, setFormErrors] = useState(initialErrors);
  const { topics } = useSelector((state: any) => state.common);

  const handleOnChange = (e) => {
    setFormField({
      ...formFields,
      [e.target.name]: e.target.value || "",
    });

    setFormErrors({
      ...formErrors,
      [e.target.name]: "",
    });
  };

  const handleSubmit = () => {
    const { errors, isError } = validateForm(
      formFields,
      initialErrors,
      PRIORITY_FIELDS_FORM_ERROR_MAAPING
    );

    if (!isError) {
      onSubmit({
        ...formFields,
        priority: formFields?.priority?.toLowerCase(),
      });
    } else {
      setFormErrors(errors);
    }
  };

  const getList = (field) => {
    switch (field) {
      case "label":
        return topics?.data;
      case "priority":
        return CONST_INTRESTED_IN_PRIORITY_LIST;
      default:
        return [];
    }
  };

  return (
    <Grid container>
      {PRIORITY_FIELDS?.map((item) => (
        <Grid item xs={12}>
          <AutoSelectbox
            label={item.label}
            field={item.field}
            id={item.field}
            textfieldStyle={{ height: 15 }}
            list={getList(item.field)}
            onChange={handleOnChange}
            listProperties={item?.listProperties}
            value={formFields[item.field]}
            error={formErrors[item.field]}
            selectedItemList={priorityList}
          />
        </Grid>
      ))}
      <Box display={"flex"} justifyContent={"flex-end"} width={"100%"} mt={2}>
        <SaveAndCancelButton
          primaryText={selectedIntrested ? "Update" : "Add"}
          defaultText={"Cancel"}
          onPrimaryActionClick={handleSubmit}
          onDefaultButtonClick={onCancel}
        />
      </Box>
    </Grid>
  );
}
