import Autocomplete from "@material-ui/lab/Autocomplete";
import { AppText } from "../typography";
import {
  makeStyles,
  Chip,
  TextField,
  FormControl,
  FormHelperText,
} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  error: {
    color: "#f44336",
  },
}));

function ChipTextField({
  fullWidth = true,
  label,
  field,
  onChange,
  error,
  id,
  limitTags = 4,
  autoCompleteSize = "small",
  chipSize = "small",
  textFieldVariant = "outlined",
  chipVariant = "outlined",
  value,
  inlineLabel = false,
  textfieldStyle = {},
  invalidChips = [],
}: any) {
  const classes = useStyles();

  const updatedValue = value
    ? Array.isArray(value)
      ? value
      : value?.split(",")
    : [];

  return (
    <FormControl fullWidth={fullWidth}>
      {!inlineLabel && <AppText text={label} />}
      <Autocomplete
        multiple
        id={id}
        onBlur={(e: any) => {
          e.target.value &&
            onChange({
              target: {
                name: field,
                value: [...updatedValue, e.target.value],
              },
            });
        }}
        size={autoCompleteSize}
        limitTags={limitTags}
        options={[]}
        value={updatedValue}
        placeholder={inlineLabel ? label : ""}
        onChange={(e, inputValue) => {
          onChange({
            target: {
              name: field,
              value: inputValue,
            },
          });
        }}
        freeSolo
        renderTags={(inputValue, getTagProps) =>
          inputValue.map((option, index) => (
            <Chip
              size={chipSize}
              variant={chipVariant}
              label={option}
              {...getTagProps({ index })}
              color={
                invalidChips.length && invalidChips.includes(index)
                  ? "secondary"
                  : "default"
              }
            />
          ))
        }
        renderInput={(params) => {
          const inputProps: any = params.inputProps;
          inputProps.autoComplete = "off";
          inputProps.style = { ...textfieldStyle };

          return (
            <TextField
              error={error ? true : false}
              {...params}
              id={field}
              variant={textFieldVariant}
              name={field}
              placeholder={inlineLabel && !updatedValue ? label : ""}
              label={inlineLabel ? label : ""}
            />
          );
        }}
      />
      <FormHelperText className={classes.error}>{error}</FormHelperText>
    </FormControl>
  );
}

export { ChipTextField };
