import { Grid } from "@material-ui/core";
import { Typography } from "@mui/material";
import { useState } from "react";
import { SaveAndCancelButton } from "../../../components/buttons";
import { AppDatePicker } from "../../../components/datepickers";
import { AppModalDialogBox } from "../../../components/modals";
import { GLOBAL_TEXT } from "../../../constants";
import {
  convertToTimezone,
  DATE_FORMATS,
} from "../../../services/date-and-time.utils";
import { checkTimeslotSelectionForLateNight } from "../../../services/validators";
import { ClientTimezoneSlotDetails } from "../../ambassador-client-slot-selection/client-timezone-slot-details";
import { AmbassadorTimezoneSlotDetails } from "../../client-ambassador-slot-selection/ambassador-timezone-slot-details";
import { EmailAddressBox } from "../../components/email-addressbox";

function RescheduleInvitationModal({
  handleModalClose,
  invitationId,
  handleSubmit,
  invitationData,
  selectedValue,
}) {
  const initialSelectedDateTime = convertToAmbassadorAndClientTimezone(
    new Date(selectedValue.from)
  );
  const [selectedDateTime, setSelectedDateTime]: any = useState(
    initialSelectedDateTime
  );
  const [emailIds, setEmailds]: any = useState({
    list: [],
    invalidEmailIds: [],
  });
  const [notify, setNotify] = useState("");

  function convertToAmbassadorAndClientTimezone(date) {
    const to = new Date(date?.getTime() + 45 * 60000);
    return {
      from: date,
      to,
      clientTimezoneConvertedDateTimeFrom: convertToTimezone(
        date,
        invitationData.client.timezone?.value,
        DATE_FORMATS.fullDateWithTime
      ),
      clientTimezoneConvertedDateTimeTo: convertToTimezone(
        to,
        invitationData.client.timezone?.value,
        DATE_FORMATS.fullDateWithTime
      ),
      ambassadorTimezoneConvertedDateTimeFrom: convertToTimezone(
        date,
        invitationData.ambassador.timezone?.value,
        DATE_FORMATS.fullDateWithTime
      ),
      ambassadorTimezoneConvertedDateTimeTo: convertToTimezone(
        to,
        invitationData.ambassador.timezone?.value,
        DATE_FORMATS.fullDateWithTime
      ),
    };
  }

  const handleDateChange = (date) => {
    const selectedDatetimes = convertToAmbassadorAndClientTimezone(date);

    const isValid = [
      "clientTimezoneConvertedDateTimeFrom",
      "ambassadorTimezoneConvertedDateTimeFrom",
    ]
      .map((datetime) => {
        return checkTimeslotSelectionForLateNight(selectedDatetimes[datetime]);
      })
      .every((datetime) => datetime === false);

    !isValid
      ? setNotify(GLOBAL_TEXT.INVITATION_RESCHEDULE_LATE_NIGHT_MESSAGE)
      : setNotify("");

    setSelectedDateTime(selectedDatetimes);
  };

  const handleOnEmailAddressesChange = (payload) => {
    setEmailds(payload);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!emailIds.invalidEmailIds.length) {
      const payload = {
        updatedTimeSlot: {
          to: selectedDateTime.to,
          from: selectedDateTime.from,
        },
        additionalMembers: emailIds.list.map((email) => {
          return { email };
        }),
        id: invitationId,
      };

      handleSubmit(payload);
    }
  };

  return (
    <AppModalDialogBox
      isModalOpen={true}
      title="Reschedule"
      handleClose={handleModalClose}
      maxHeight={600}
      actionButtonRenderer={
        <SaveAndCancelButton
          primaryText={"Submit"}
          defaultText={"Cancel"}
          onPrimaryActionClick={onSubmit}
          onDefaultButtonClick={handleModalClose}
          isPrimaryButtonDisabled={emailIds.invalidEmailIds.length ? true: false}
        />
      }
      size={"sm"}
    >
      <Grid container spacing={2} direction="column">
        <Grid item container>
          <Grid item xs={2}>
            <Typography variant="caption">From: </Typography>
          </Grid>
          <Grid item>
            <AppDatePicker
              dateFormat={"Pp"}
              showTimeSelect
              selectedDate={selectedDateTime.from}
              setDate={(date) => handleDateChange(date)}
              minDate={new Date()}
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={2}>
            <Typography variant="caption">To: </Typography>
          </Grid>
          <Grid item>
            <AppDatePicker
              dateFormat={"Pp"}
              showTimeInput
              showTimeSelect
              readOnly
              selectedDate={selectedDateTime.to}
              minDate={new Date()}
              setDate={(date) => {}}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={6}>
            <AmbassadorTimezoneSlotDetails
              name={invitationData.ambassador.firstName}
              from={selectedDateTime.ambassadorTimezoneConvertedDateTimeFrom}
              to={selectedDateTime.ambassadorTimezoneConvertedDateTimeTo}
            />
          </Grid>
          <Grid item xs={6}>
            <ClientTimezoneSlotDetails
              name={invitationData.client.firstName}
              from={selectedDateTime.clientTimezoneConvertedDateTimeFrom}
              to={selectedDateTime.clientTimezoneConvertedDateTimeTo}
            />
          </Grid>
          <Typography
            fontSize={12}
            sx={{ color: "red", mt: 1 }}
          >{`${notify}`}</Typography>
        </Grid>
        <EmailAddressBox onValueChange={handleOnEmailAddressesChange} />
      </Grid>
    </AppModalDialogBox>
  );
}

export { RescheduleInvitationModal };
