import { Box, Divider, Grid, Typography } from "@mui/material";
import { AppChip } from "../../../components/chips";
import { LabelAndValue } from "../../../components/typography";
import { getPriorityColor } from "../add-or-update/priorities/intrested-block";

function AmbassadorListExpandedRowData({ data, index }: any) {
  return (
    <Box component={"div"} display="flex" sx={{ ml: 10, mt: 1, mb: 3 }}>
      <Grid spacing={2} container flexDirection={"column"}>
        <Grid item>
          <Typography fontSize={13} fontWeight="600">
            More Information
          </Typography>
        </Grid>
        <Divider sx={{ border: "1px solid #F2F3F8" }} />
        <Grid item>
          <Box component={"div"} display={"flex"} flexDirection="row">
            <LabelAndValue
              label={"Location"}
              size={13}
              font
              value={`${data.city}, ${data.state}`}
              labelxs={2}
              labelmd={2}
              labelsm={2}
              labelColor="#a7abc3"
              valueColor="#6c7293"
              fontWeight={{
                label: 600,
                value: 600,
              }}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box component={"div"} display={"flex"} flexDirection="row">
            <LabelAndValue
              label={"Investment Budget"}
              size={13}
              font
              value={`${data.investment_budget || "0"}`}
              labelxs={2}
              labelmd={2}
              labelsm={2}
              labelColor="#a7abc3"
              valueColor="#6c7293"
              fontWeight={{
                label: 600,
                value: 600,
              }}
            />
          </Box>
        </Grid>
        <Grid item>
          <Grid component={"div"} display={"flex"} flexDirection="row">
            <Grid item xs={2}>
              <Typography
                sx={{ pr: 1 }}
                fontSize={13}
                fontWeight="600"
                color="#a7abc3"
              >
                Previous Companies:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              {data.previous_company?.split(",").map((item, i) => (
                <AppChip
                  key={`previous_company-${i}`}
                  bgColor="#F2F3F8"
                  style={{ marginRight: 3, marginBottom: 3 }}
                  label={item}
                  fontColor={"#6C7293"}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid component={"div"} display={"flex"} flexDirection="row">
            <Grid item xs={2}>
              <Typography
                sx={{ pr: 1 }}
                fontSize={13}
                fontWeight="600"
                color="#a7abc3"
              >
                Areas of Expertise:
              </Typography>
            </Grid>
            <Grid item xs={10} container gap={0.5}>
              {data.topics?.map((item, i) => (
                <AppChip
                  key={`topics-${i}`}
                  style={{
                    marginRight: 3,
                    marginBottom: 3,
                    backgroundColor: getPriorityColor(item?.priority),
                  }}
                  label={item?.label}
                  fontColor={"white"}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid component={"div"} display={"flex"} flexDirection="row">
            <Grid item xs={2}>
              <Typography
                sx={{ pr: 1 }}
                fontSize={13}
                fontWeight="600"
                color="#a7abc3"
              >
                Vendor Intrested in:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              {data.vendor_interested_in?.split(",").map((item, i) => (
                <AppChip
                  key={`vendor_intrested_in-${i}`}
                  bgColor="#F2F3F8"
                  style={{ marginRight: 3, marginBottom: 3 }}
                  label={item}
                  fontColor={"#6C7293"}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export { AmbassadorListExpandedRowData };
