import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useHistory } from "react-router";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Popover,
  Toolbar,
} from "@mui/material";
import { ROUTE_PATHS } from "../../../../routes/route-paths";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { setOpenSidebar } from "../../../../redux/modules/UI/actions";
import { useDispatch, useSelector } from "react-redux";
import { drawerWidth } from "../../../../constants";
import { MouseEvent, useState } from "react";
import { Logo } from "../../../logo";

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    background: "#070639",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(11),
      },
    }),
  },
}));

function AdminPanelSidebar({ list, selected }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { openSidebar } = useSelector((state: any) => state.UI);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleOnItemClick = (item) => {
    history.push(item.path);
  };

  return (
    <Drawer variant="permanent" open={openSidebar}>
      <Toolbar
        sx={{
          display: { xs: "flex", sm: "flex", md: "none" },
          alignItems: "center",
          justifyContent: openSidebar ? "flex-end" : "center",
        }}
      >
        <IconButton onClick={() => dispatch(setOpenSidebar(!openSidebar))}>
          <MenuIcon sx={{ color: "white", fontSize: 34 }} />
        </IconButton>
      </Toolbar>
      <Divider />
      <Box
        component={"div"}
        sx={{ display: openSidebar ? "flex" : "none" }}
        justifyContent={"center"}
        alignItems="center"
        style={{ marginTop: 37, marginBottom: 42 }}
      >
        <Logo />
        <IconButton onClick={handleClick}>
          <ExpandMoreIcon htmlColor="white" />
        </IconButton>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          id={id}
          onClose={handleClose}
          open={open}
          anchorEl={anchorEl}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List>
            <ListItem>
              <Typography fontSize={14} fontWeight="400">
                Select Organization
              </Typography>
            </ListItem>
            <Divider />
            <ListItem>
              <Typography
                fontSize={14}
                fontWeight="400"
                sx={{ color: "#666666", mt: 1, mb: 1 }}
              >
                Execweb (current)
              </Typography>
            </ListItem>
            <ListItem>
              <Button
                onClick={() => {}}
                fullWidth
                sx={{
                  fontSize: 11,
                  width: 162,
                  color: "#070639",
                  height: 36,
                  mt: 2,
                  fontWeight: 500,
                  p: 0,
                }}
                variant="outlined"
              >
                create new organisation
              </Button>
            </ListItem>
          </List>
        </Popover>
      </Box>

      <List>
        {list.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ mb: 4 }}>
            <ListItemButton onClick={() => handleOnItemClick(item)}>
              <ListItemIcon>
                {item?.icon ?? <img src={item.image} />}
              </ListItemIcon>
              <Typography
                sx={{
                  color: selected?.label === item.label ? "#FFFFFF" : "#979797",
                  fontWeight: selected ? 700 : 500,
                  fontSize: 14,
                }}
              >
                {item.label}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

export { AdminPanelSidebar };
