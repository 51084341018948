import { Paper, Grid, makeStyles } from "@material-ui/core";
import { Image } from "../components/images";
import { AppText } from "../components/typography";

const useStyles = makeStyles((theme) => ({
  root: { height: "90%" },
  textWrapper: { maxWidth: 462, textAlign: "center" },
  text: {
    marginTop: theme.spacing(2),
    fontSize: 16,
    fontWeight: 600,
    maxWidth: 462,
    textAlign: "center",
  },
  content: { marginTop: theme.spacing(5) },
  contentWrapper: { padding: "3%" },
  helpLineTextWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: theme.spacing(2, 2),
  },
}));

function SuccessMessageTemplate({ successMessage = "" }: any) {
  const classes = useStyles();

  return (
    <>
      <Grid
        className={classes.root}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Paper className={classes.contentWrapper}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            item
            xs={12}
            className={classes.content}
          >
            <Image name="payment-thank-you" />

            <AppText textClass={classes.text} text={successMessage} />
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}

export { SuccessMessageTemplate };
