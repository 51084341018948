import { useState } from "react";
import { AppModalDialogBox } from "../../../../components/modals";
import { SaveAndCancelButton } from "../../../../components/buttons";
import { newBudgetSubmit } from "../../../../apis/clients.api";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { LabelAndValue } from "../../../../components/typography";
import { numberFormat } from "../../../../services/utils";
import { ENUM_CURRENCY } from "../../../../enums";
import { AppTextField } from "../../../../components/textfields";

function AddCampaignModal({ selectedClient, handleModalClose }) {
  const [budget, setBudget] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    const text = e.target.value;
    setBudget(e.target.value);
  };

  const onSubmit = async () => {
    if (budget === "") {
      setError("Please enter a budget");
      return false;
    }

    setError("");

    const response: any = await dispatch(
      newBudgetSubmit(selectedClient?.id, {budget})
    );

    if (response.success) {
      handleModalClose();
    }
  };

  return (
    <AppModalDialogBox
      isModalOpen={true}
      title="Add Campaign"
      handleClose={handleModalClose}
      maxHeight={600}
      actionButtonRenderer={
        <SaveAndCancelButton
          primaryText={"Submit"}
          defaultText={"Cancel"}
          onPrimaryActionClick={onSubmit}
          onDefaultButtonClick={handleModalClose}
        />
      }
      size={"sm"}
    >
      <Box display={"flex"} flexDirection="column">
        <LabelAndValue
          label="Current Budget"
          value={numberFormat(selectedClient?.budget, ENUM_CURRENCY.USD)}
        />

        <AppTextField
          id={"budget"}
          name={"budget"}
          label={"Enter new budget"}
          onChange={handleOnChange}
          value={budget}
          error={error}
          autoComplete={"off"}
          rootStyle={{ marginTop: 10 }}
        />
      </Box>
    </AppModalDialogBox>
  );
}

export { AddCampaignModal };
