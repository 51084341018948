import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clearNotification } from "../services/utils";

function Notifications() {
  const { show, type, message, closeOnClick, timeout } = useSelector(
    (state: any) => state.UI.showNotifications
  );

  if (show) {
    switch (type) {
      case "success":
        toast.success(message, {
          position: toast.POSITION.TOP_RIGHT,
          toastId: "toastsuccess",
          onClose: () => clearNotification(),
          closeOnClick,
          autoClose: timeout,
        });
        break;
      case "error":
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
          toastId: "toasterror",
          onClose: () => clearNotification(),
        });
        break;

      default:
        break;
    }
  }

  return show && <ToastContainer autoClose={2000} />;
}

export { Notifications };
