import {
  DEFAULT_SELECTED_PAGE,
  DEFAULT_SELECTED_PAGE_SIZE,
} from "../../../constants";

const SET_OPPORTUNITY_RESPONSE_LIST_LOADING =
  "SET_OPPORTUNITY_RESPONSE_LIST_LOADING";
const SET_OPPORTUNITY_RESPONSE_LIST = "SET_OPPORTUNITY_RESPONSE_LIST";
const SET_OPPORTUNITY_RESPONSE_LIST_FILTERS =
  "SET_OPPORTUNITY_RESPONSE_LIST_FILTERS";
const RESET_OPPORTUNITY_RESPONSE_LIST_FILTERS =
  "RESET_OPPORTUNITY_RESPONSE_LIST_FILTERS";

const initialOpportunityResponseListState = {
  data: undefined,
  loading: null,
  filters: {
    selectedPage: DEFAULT_SELECTED_PAGE,
    selectedPageSize: DEFAULT_SELECTED_PAGE_SIZE,
    selectedFilters: {
      companyName: "",
      lookingFor: [],
      industryCategory: [],
    },
  },
  total: undefined,
};

const setOpportunityResponseListLoading = (payload) => ({
  type: SET_OPPORTUNITY_RESPONSE_LIST_LOADING,
  payload,
});

const setOpportunityResponseList = (payload) => ({
  type: SET_OPPORTUNITY_RESPONSE_LIST,
  payload,
});

const setOpportunityResponseListFilters = (payload = undefined) => ({
  type: SET_OPPORTUNITY_RESPONSE_LIST_FILTERS,
  payload,
});

const resetOpportunityResponseListFilters = () => ({
  type: RESET_OPPORTUNITY_RESPONSE_LIST_FILTERS,
});

export {
  initialOpportunityResponseListState,
  setOpportunityResponseListLoading,
  setOpportunityResponseList,
  setOpportunityResponseListFilters,
  resetOpportunityResponseListFilters,
  SET_OPPORTUNITY_RESPONSE_LIST_LOADING,
  SET_OPPORTUNITY_RESPONSE_LIST,
  SET_OPPORTUNITY_RESPONSE_LIST_FILTERS,
  RESET_OPPORTUNITY_RESPONSE_LIST_FILTERS,
};
