import { useState } from "react";
import { AppModalDialogBox } from "../../../components/modals";
import { SaveAndCancelButton } from "../../../components/buttons";
import { AutoSelectbox } from "../../../components/selectbox/autoselect";
import { searchClients } from "../../../apis/clients.api";
import { inviteClintAmbassadorSubmit } from "../../../apis/ambassador-invite.api";
import { useHistory } from "react-router";
import { Divider, Box, Typography } from "@mui/material";
import { DetailsCard } from "../../components/details-card";
import { ROUTE_PATHS } from "../../../routes/route-paths";

function CreateInvitationModal({ selectedAmbassadors = [], handleModalClose }) {
  const [clientName, setClientName] = useState("");
  const [clientList, setClientList] = useState([]);
  const [clientSelectionError, setClientSelectionError] = useState("");
  const history = useHistory();

  const handleOnClientNameChange = async (e) => {
    const text = e.target.value;
    setClientName(e.target.value);
    setClientSelectionError("");

    if (text?.length < 3) return false;

    const res = await searchClients({
      selectedPage: 1,
      selectedPageSize: 10,
      searchValue: text,
    });

    if (res.success) {
      setClientList(res.data);
    }
  };

  const onSubmit = async () => {
    if (clientName === "") {
      setClientSelectionError("Please select a client");
      return false;
    }

    const selectedClient: any = clientList.find(
      (clientItem) => clientItem.firstName === clientName
    );

    if (selectedClient) {
      const payload = {
        ambassadorIds: selectedAmbassadors.map(({ id }) => id),
        clientId: selectedClient.id,
      };

      setClientSelectionError("");

      const response = await inviteClintAmbassadorSubmit(payload);

      if (response.success) {
        history.push(ROUTE_PATHS.INVITATIONS.ROOT);
        handleModalClose();
      }
    } else {
      setClientSelectionError("Please select a valid client");
    }
  };

  return (
    <AppModalDialogBox
      isModalOpen={true}
      title="Invite Ambassador"
      handleClose={handleModalClose}
      maxHeight={600}
      actionButtonRenderer={
        <SaveAndCancelButton
          primaryText={"Invite"}
          defaultText={"Cancel"}
          onPrimaryActionClick={onSubmit}
          onDefaultButtonClick={handleModalClose}
        />
      }
      size={"sm"}
    >
      <AutoSelectbox
        list={clientList}
        listProperties={{
          field: "firstName",
          value: "id",
        }}
        requireOnChange
        onChange={handleOnClientNameChange}
        label={"Select Client"}
        error={clientSelectionError}
        labelStyle={{
          fontSize: 16,
          fontWeight: 400,
          color: "#000000DE",
        }}
        textfieldStyle={{ height: 22 }}
      />

      <Box display={"flex"} flexDirection="column">
        <Typography fontSize={16} sx={{ mt: 3, mb: 1 }} fontWeight={400}>
          {"Selected Ambassador"}
        </Typography>

        {selectedAmbassadors?.map((ambassador, index) => (
          <Box sx={{ mb: 2 }}>
            <Box sx={{ mb: 2 }}>
              <DetailsCard details={ambassador} key={index} />
            </Box>
            {index !== selectedAmbassadors.length - 1 && <Divider />}
          </Box>
        ))}
      </Box>
    </AppModalDialogBox>
  );
}

export { CreateInvitationModal };
