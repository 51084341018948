import { CategoryListSkeleton } from "./list-skeleton";

export default function MarketplaceCategories({
  list = [],
  onFilterChange,
  selectedCategory,
  filterCategories,
}) {
  const handleCategoryItemClick = (item) => {
    onFilterChange(item);
  };

  const totalVendorRecords = list?.reduce((acc, item) => acc + item?.count, 0);

  if (!list?.length) {
    return <CategoryListSkeleton />;
  }

  return (
    <div className="vendor-listing-block element-with-scroll">
      <div className="categories-title">
        <h4>Categories</h4>
        <h4>{totalVendorRecords}</h4>
      </div>
      <ul className="vendor-list">
        {list?.map((mpc, index) => (
          <li
            key={`marketplace-categories-${mpc?.id}-${index}`}
            className="clickable"
            onClick={() => handleCategoryItemClick(mpc)}
            title={mpc?.category}
          >
            <a
              style={{
                color:
                  filterCategories?.indexOf(mpc?.category) > -1
                    ? "#0000ac"
                    : !filterCategories && selectedCategory === mpc?.category
                    ? "#0000ac"
                    : "black",
              }}
            >
              <span className="list-title">{mpc?.category}</span>
              <span className="list-count">{mpc?.count}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
