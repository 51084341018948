import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Searchbox from "../../../../components/searchbox";
import { AmbassadorListTable } from "../../../ambassadors/list/table";
import AmbassadorFilterOptions from "../../../ambassadors/list/filter-options";
import { compose } from "redux";
import { CLIENT_AMBASSADOR_LIST_COLUMNS } from "./table-config";
import ambassadorHOC from "../../../ambassadors/ambassador-hoc";
import {
  getClientSelectedAmbassadorList,
  saveClientAmbasadorList,
} from "../../../../apis/clients.api";
import { useParams } from "react-router";

function ClientAmbassadorMasterList({
  getAmbassadorList,
  ambassadorList,
  toggleMenuOptions,
}) {
  const dispatch = useDispatch();
  const clientDetails = useSelector(
    (state: any) => state.clients.selectedClient
  );
  const initSelectedAmbassadors =
    clientDetails?.target_ambassadors?.map((sa) => {
      return { id: sa };
    }) || [];
  const { selectedPageSize, searchValue, selectedPage, selectedFilters } =
    useSelector((state: any) => state.ambassadors.list.filters);
  const [selectedAmbassadors, setSelectedAmbassadors] = useState(
    initSelectedAmbassadors
  );
  const [showSelected, setShowSelected] = useState(false);
  const { id }: any = useParams();
  const [selectedAmbassadorList, setSelectedAmbassadorList] = useState({
    data: null,
    total: null,
    loading: null,
  });

  useEffect(() => {
    getAmbassadorList({
      selectedPage,
      selectedPageSize,
      searchValue,
      selectedFilters,
    });
  }, []);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getAmbassadorList({
      selectedPage,
      selectedPageSize,
      searchValue,
      selectedFilters
    });
  };

  const handleSearchValueChange = (value = "") => {
    getAmbassadorList({
      searchValue: value,
      selectedPage: 1,
      selectedPageSize
    });
  };

  const handleOnRowSelectionChange = ({ row }) => {
    const tempSelectedAmbassadors = [...selectedAmbassadors];
    const selectedIds = [...tempSelectedAmbassadors].map(({ id }) => id);
    const indexOfSelectedRow = selectedIds.indexOf(row.id);

    if (indexOfSelectedRow === -1) {
      setSelectedAmbassadors([...selectedAmbassadors, row]);

      handleOnSave([...selectedAmbassadors, row]);
    } else {
      tempSelectedAmbassadors.splice(indexOfSelectedRow, 1);
      setSelectedAmbassadors(tempSelectedAmbassadors);

      handleOnSave(tempSelectedAmbassadors);
    }
  };

  const toggleShowSelected = async () => {
    setShowSelected(!showSelected);

    if (!showSelected) {
      const response: any = await dispatch(
        getClientSelectedAmbassadorList(id, showSelected)
      );

      if (response.success) {
        setSelectedAmbassadorList({
          ...selectedAmbassadorList,
          data: response.data,
          loading: false,
        });

        return;
      }
    }

    getAmbassadorList({
      selectedPage: 1,
      selectedPageSize
    });
  };

  const handleOnSave = async (selected) => {
    const {
      firstName,
      lastName,
      email,
      title,
      company,
      no_of_emp,
      funding,
      revenues,
      target_industries,
      target_no_of_emp,
      target_account,
      linkedIn,
      timezone,
      viewAmbassadorThreshold,
      meetingThreshold,
      viewNextBatchAmbassadorThreshold,
      licenseForMeeting,
    } = clientDetails;
    const payload = {
      firstName,
      lastName,
      email,
      title,
      company,
      no_of_emp,
      funding,
      revenues,
      target_industries,
      target_no_of_emp,
      target_account,
      linkedIn,
      timezone,
      viewAmbassadorThreshold,
      meetingThreshold,
      viewNextBatchAmbassadorThreshold,
      licenseForMeeting,
      target_ambassadors: selected.map((sa) => sa?.id),
    };

    const response = await dispatch(saveClientAmbasadorList(id, payload));

    showSelected &&
      getAmbassadorList({
        selectedPage,
        selectedPageSize,
        searchValue,
        selectedFilters,
      });
  };

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        spacing={1}
        justifyContent={"space-between"}
      >
        <Grid item xs={6} md={6}>
          <Searchbox
            onEnter={handleSearchValueChange}
            value={searchValue}
            onClear={() => handleSearchValueChange("")}
          />
        </Grid>
        <Grid item xs={6} container justifyContent={"flex-end"}>
          <Button
            variant={showSelected ? "contained" : "outlined"}
            onClick={toggleShowSelected}
          >
            {showSelected ? "SHOW ALL" : "SHOW SELECTED"}
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} direction="column">
        <Grid item>
          <AmbassadorFilterOptions />
        </Grid>
        <Grid item>
          <AmbassadorListTable
            list={showSelected ? selectedAmbassadorList : ambassadorList}
            toggleMenuOptions={toggleMenuOptions}
            columns={CLIENT_AMBASSADOR_LIST_COLUMNS}
            handlePageFilters={handlePageFilters}
            appliedFilters={{
              selectedPageSize,
              searchValue,
              selectedPage,
              selectedFilters,
            }}
            showPagination={showSelected? false: true}
            onRowSelect={handleOnRowSelectionChange}
            selectedRows={{
              field: "id",
              values: selectedAmbassadors.map(({ id }) => id),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default compose(ambassadorHOC)(ClientAmbassadorMasterList);
