export const INITIAL_SLOTS = [
  {
    selectedSlotTimeFrom: "",
    selectedSlotTimeTo: "",
    error: "",
  },
  {
    selectedSlotTimeFrom: "",
    selectedSlotTimeTo: "",
    error: "",
  },
  {
    selectedSlotTimeFrom: "",
    selectedSlotTimeTo: "",
    error: "",
  },
  {
    selectedSlotTimeFrom: "",
    selectedSlotTimeTo: "",
    error: "",
  },
];