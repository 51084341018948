import { Box, IconButton, Typography } from "@mui/material";
import { SimpleTable } from "../../../components/mui/table";
import { RENDERER_TYPES } from "../../../enums";
import {
  DATE_FORMATS,
  formatDate,
} from "../../../services/date-and-time.utils";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useState } from "react";
import ViewTemplateModal from "./view-template-modal";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

function OpportunityListTable({
  list,
  columns,
  handlePageFilters,
  appliedFilters,
  showPagination = true,
}: any) {
  const { selectedPage, selectedPageSize } = showPagination
    ? appliedFilters
    : { selectedPage: 1, selectedPageSize: 1 };
  const [selectedItem, setSelectedItem] = useState(null);
  const [showTemplate, setShowTemplate] = useState(false);

  const onShowTemplateClick = (item) => {
    setSelectedItem(item);
    setShowTemplate(true);
  };

  const onShowTemplateClose = (item) => {
    setSelectedItem(null);
    setShowTemplate(false);
  };

  const handleTDRenderer = (row, column) => {
    switch (column.rendererType) {
      case RENDERER_TYPES.CHIP_LIST:
        return row?.[column.field]?.map((item, i) => (
          <Box display={"flex"} alignItems={"center"} flexDirection={"row"}>
            <EmailOutlinedIcon fontSize="inherit" />
            <Typography
              key={`opportunity-email-${i}`}
              component={"p"}
              fontSize={13}
              ml={0.5}
              color={"#6C7293"}
              fontWeight={500}
            >
              {item}
            </Typography>
          </Box>
        ));
      case RENDERER_TYPES.FULL_DATE_FROM:
        return `${formatDate(
          row?.[column.field],
          DATE_FORMATS.fullDateWithTime
        )}`;
      case RENDERER_TYPES.ACTION:
        return (
          <IconButton onClick={() => onShowTemplateClick(row)}>
            <ContentPasteIcon />
          </IconButton>
        );
      default:
        return row?.[column.field];
    }
  };

  return (
    <>
      <SimpleTable
        tdRenderer={handleTDRenderer}
        rows={list?.data}
        columns={columns}
        total={list?.total}
        fetching={list?.loading}
        onPageSettingsChange={handlePageFilters}
        selectedPage={selectedPage}
        selectedPageSize={selectedPageSize}
        showPagination={showPagination}
      />
      {showTemplate && (
        <ViewTemplateModal data={selectedItem?.body} onClose={onShowTemplateClose} />
      )}
    </>
  );
}

export { OpportunityListTable };
