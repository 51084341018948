import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, ListItemText } from "@mui/material";
import { ISelectOption } from "../models";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      fontSize: 13,
    },
  },
};

interface MultipleSelectProps {
  label: string;
  options: ISelectOption[];
  selectedValues?: ISelectOption[];
  onValueChange?: any;
  valueField?: string;
  labelField?: string;
  name: string;
}

export default function MultipleSelect({
  label,
  options,
  selectedValues = [],
  onValueChange,
  valueField,
  labelField,
  name,
}: MultipleSelectProps) {
  const renderSelected = (e) => {
    return options
      ?.filter((v) => e?.includes(v[valueField]))
      ?.map((v) => v[labelField])
      ?.join(",");
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        name={name}
        value={selectedValues}
        onChange={onValueChange}
        input={<OutlinedInput label={label} />}
        renderValue={renderSelected}
        MenuProps={{
          ...MenuProps,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
      >
        {options.map((item) => (
          <MenuItem key={item[valueField]} value={item[valueField]}>
            <Checkbox checked={selectedValues.includes(item[valueField])} />
            <ListItemText
              sx={{ maxWidth: 300, whiteSpace: "normal" }}
              primary={item[labelField]}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
