import { Typography } from "@mui/material";

function SupportHelpLineText({ textColor = "white" }: any) {
  return (
    <Typography sx={{ color: textColor }} fontSize={20} fontWeight={500}>
      {"(516) 703-1311"}
    </Typography>
  );
}

export { SupportHelpLineText };
