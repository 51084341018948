import * as React from "react";
import { Grid, Pagination } from "@mui/material";
import PageSize from "../pagesize";
import { AppText } from "../../typography";
import { PaginationContext } from "../table/simple-table";

export default function Paginator() {
  const {
    showPageSize,
    totalRecords,
    selectedPageSize,
    selectedPage,
    onPageChange,
  }:any = React.useContext(PaginationContext);

  return (
    <Grid
      container
      justifyContent={showPageSize ? "space-between" : "flex-start"}
    >
      <Grid item>
        <Pagination
          showFirstButton={true}
          showLastButton={true}
          color="primary"
          shape="rounded"
          count={Math.ceil(totalRecords / selectedPageSize)}
          page={selectedPage}
          onChange={(event, value) => onPageChange(value)}
        />
      </Grid>
      {showPageSize && (
        <Grid item>
          <div style={{ display: "flex", alignItems: "center" }}>
            <PageSize />
            <AppText color="#646C9A">
              Showing {(selectedPage - 1) * selectedPageSize + 1}-
              {totalRecords <=
              (selectedPage - 1) * selectedPageSize + selectedPageSize
                ? totalRecords
                : (selectedPage - 1) * selectedPageSize + selectedPageSize}{" "}
              of {totalRecords}
            </AppText>
          </div>
        </Grid>
      )}
    </Grid>
  );
}
