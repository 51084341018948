import { RENDERER_TYPES } from "../../../enums";

const OPPORTUNITY_RESPONSE_LIST_COLUMNS = [
  {
    title: "Email",
    field: "email",
    dataType: "string",
  },
  {
    title: "Company",
    field: "companyName",
    dataType: "string",
  },
  {
    title: "Industry",
    field: "industryCategory",
    dataType: "string",
    rendererType: RENDERER_TYPES.CHIP_LIST,
  },
  {
    title: "Solution",
    field: "solutions",
    dataType: "string",
  },
  {
    title: "Funding",
    field: "funding",
    dataType: "string",
    rendererType: RENDERER_TYPES.AMOUNT,
  },
  {
    title: "Looking For",
    field: "lookingFor",
    dataType: "string",
    rendererType: RENDERER_TYPES.CHIP_LIST,
  },
  {
    title: "Views",
    field: "viewCount",
    dataType: "string",
    rendererType: RENDERER_TYPES.ACTION
  },
  {
    title: "Action",
    field: "action",
    dataType: "string",
    rendererType: RENDERER_TYPES.ACTION
  },
];

export { OPPORTUNITY_RESPONSE_LIST_COLUMNS };
