import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { LabelAndValue } from "../../../components/typography";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { convertToFullName } from "../../../services/utils";
import { MeetingStatus } from "../../invitations/components/meeting-status";
import { Link } from "react-router-dom";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { AddMemberModal } from "./add-member-modal";
import { getClientDetails } from "../../../apis/clients.api";
import { getAmbassadorDetails } from "../../../apis/ambassadors.api";
import { useDispatch } from "react-redux";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip placement="left" arrow {...props} classes={{ popper: className }} />
))(({ theme }: any) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    width: 400,
    maxHeight: 300,
    background: "white",
    padding: theme.spacing(2),
    border: "1px solid lightgray",
  },
}));

function DashboardCalendarEventTooltip({ event, title }) {
  const { ambassadorId, clientId, datetime, zoomURL } = event;
  const [showAddMemberDilogbox, setShowAddMemberDilogbox] = useState(false);
  const [ambassador, setAmbassador]: any = useState();
  const [client, setClient]: any = useState();
  const dispatch = useDispatch();

  const fetchInvitationUsers = async () => {
    try {
      const [clientDetails, ambassadorDetails]: any = await Promise.all([
        dispatch(getClientDetails(clientId)),
        dispatch(getAmbassadorDetails(ambassadorId)),
      ]);

      setAmbassador(ambassadorDetails?.data);
      setClient(clientDetails?.data);
    } catch (error) {}
  };

  return (
    <>
      <HtmlTooltip
        enterTouchDelay={0}
        onMouseEnter={fetchInvitationUsers}
        title={
          <React.Fragment>
            <Grid container flexDirection={"column"}>
              <Box sx={{ mb: 2 }} alignItems="center" display="flex">
                <AccessTimeIcon color="primary" />
                <Typography sx={{ color: "black", ml: 1, fontSize: 14 }}>
                  {title}
                </Typography>
              </Box>
              <LabelAndValue
                sx={{ mb: 1 }}
                labelxs={5}
                labelmd={5}
                labelsm={5}
                size={13}
                valuexs={7}
                label="Time"
                value={`${datetime}`}
              />
              <LabelAndValue
                sx={{ mb: 1 }}
                labelxs={5}
                labelmd={5}
                labelsm={5}
                size={13}
                valuexs={7}
                label="Ambassador"
                value={convertToFullName(
                  ambassador?.firstName,
                  ambassador?.lastName
                )}
              />
              <LabelAndValue
                sx={{ mb: 1 }}
                labelxs={5}
                labelmd={5}
                labelsm={5}
                valuexs={7}
                label="Client"
                size={13}
                value={convertToFullName(client?.firstName, client?.lastName)}
              />

              {zoomURL && (
                <>
                  <Box sx={{ mb: 2 }} alignItems="center" display="flex">
                    <VideocamOutlinedIcon color="primary" />
                    <Link target="_blank" to={{ pathname: zoomURL }}>
                      <Typography sx={{ ml: 1, fontSize: 14 }}>
                        {"Meeting Link"}
                      </Typography>
                    </Link>
                  </Box>
                  <Box sx={{ mb: 2 }} alignItems="center" display="flex">
                    <PersonAddIcon color="warning" />
                    <Link
                      to=""
                      onClick={(e) => {
                        e.preventDefault();
                        setShowAddMemberDilogbox(true);
                      }}
                    >
                      <Typography sx={{ ml: 1, fontSize: 14 }}>
                        {"Add Member"}
                      </Typography>
                    </Link>
                  </Box>
                </>
              )}
              <MeetingStatus data={event} />
            </Grid>
          </React.Fragment>
        }
      >
        <Typography
          sx={{ pl: 1, pt: 0.2, fontSize: 11, fontWeight: 400, color: "white" }}
        >
          <span className="event-dot mr3"></span>
          {title}
        </Typography>
      </HtmlTooltip>

      {showAddMemberDilogbox && (
        <AddMemberModal
          event={event}
          title={title}
          onClose={() => setShowAddMemberDilogbox(false)}
        />
      )}
    </>
  );
}

export { DashboardCalendarEventTooltip };
