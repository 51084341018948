import ListSkeleton from "./list-skeleton";
import ProfileCard from "./profile-card";

export default function MarketplaceList({ list = [], loading }) {
  if (loading) {
    return <ListSkeleton />;
  }

  if (!list?.length)
    return (
      <div className="no-data-found">
        <h2>No Data Found.</h2>
      </div>
    );

  return (
    <>
      {list?.map((mp) => (
        <ProfileCard
          showEditBtn
          key={`marketplace-list-${mp.id}`}
          data={mp}
          fileUploads={{
            onboardingGuide: mp?.onboardingGuide,
            permissions: mp?.permissions,
            agreement: mp?.agreement,
            technicalDocs: mp?.technicalDocs,
            demoLink: mp?.demoLink,
            useCase: mp?.useCase,
          }}
          logo={mp.logo}
        />
      ))}
    </>
  );
}
