import { useEffect, useState } from "react";
import { AppTextField } from "../../../components/textfields";
import {
  COMPANY_DETAILS,
  AMBASSADOR_FORM_ERROR_MAAPING,
  PERSONAL_DETAILS,
  ROUND_TABLE_DETAILS,
} from "./form-data";
import { useStyles } from "./styles";
import { SaveAndCancelButton } from "../../../components/buttons";
import {
  ENUM_AUTOSELECT_LIST_TYPES,
  ENUM_FORM_INPUT_TYPES,
} from "../../../enums";
import { useHistory, useParams, Prompt } from "react-router";
import { validateForm } from "../../../services/validators";
import { useDispatch, useSelector } from "react-redux";
import { ChipTextField } from "../../../components/chips/chip-textfield";
import { AutoSelectbox } from "../../../components/selectbox/autoselect";
import { AppAlertBox } from "../../../components/modals/alert-box";
import {
  addAmbassador,
  getAmbassadorDetails,
  updateAmbassador,
  uploadAmbassadorPhotoUpload,
} from "../../../apis/ambassadors.api";
import { resetSelectedAmbassador } from "../../../redux/modules/ambassadors/actions";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { ROUTE_PATHS } from "../../../routes/route-paths";
import { GLOBAL_TEXT } from "../../../constants";
import { PhotoFileUpload } from "../../../components/images/crop-image-upload";
import AmbassadorIntrestedIn from "./priorities";

const initialFields: any = {
  firstName: "",
  lastName: "",
  email: "",
  linkedIn: "",
  picture: "",
  city: "",
  region: "",
  state: "",
  title: "",
  industry: "",
  company: "",
  no_of_emp: "",
  experience: "",
  previous_company: "",
  round_table_package_12_rate: "",
  investment_budget: "",
  timezone: "",
};

const initialErrors = {
  ...initialFields,
};

function AddOrUpdateAmbassador() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: any = useParams();
  const [formFields, setFormField] = useState(initialFields);
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [blockNavigation, setBlockNavigation] = useState(true);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [initialFieldData, setInitialFieldData] = useState(initialFields);
  const [logo, setLogo]: any = useState({ preview: "", file: "" });
  const { selectedAmbassador } = useSelector((state: any) => state.ambassadors);
  const { timezones, noOfEmp, industry, title, state, region } = useSelector(
    (state: any) => state.common
  );
  const [priorities, setPriorities] = useState([]);

  useEffect(() => {
    onLoad();

    return () => {
      dispatch(resetSelectedAmbassador());
    };
  }, []);

  useEffect(() => {
    showCancelConfirm && setBlockNavigation(false);
  }, [showCancelConfirm]);

  useEffect(() => {
    const logoFile: any = "";
    if (selectedAmbassador) {
      const data = {
        ...selectedAmbassador,
        previous_company: selectedAmbassador?.previous_company?.split(","),
      };
      setFormField(data);

      setInitialFieldData(data);

      setLogo({
        ...logo,
        file: logoFile,
        preview: data?.photo,
      });
    }
  }, [selectedAmbassador]);

  const onLoad = async () => {
    const requests = [...(id ? [dispatch(getAmbassadorDetails(id))] : [])];

    await Promise.all(requests);
  };

  const handleOnChange = (e) => {
    setFormField({
      ...formFields,
      [e.target.name]: e.target.value || "",
    });

    setFormErrors({
      ...formErrors,
      [e.target.name]: "",
    });
  };

  const handleAvatarUpload = (file) => {
    setLogo({ ...logo, file, preview: URL.createObjectURL(file) });
  };

  const handleOnItemRemove = (removeItem, field) => {
    const formData: any = { ...formFields };

    const index = formData[field].indexOf(removeItem);
    if (index > -1) {
      formData[field].splice(index, 1);
    }

    setFormField({ ...formData });
  };

  const submitPhotoUpload = async (resId) => {
    const logoUploadData = new FormData();
    const idx = resId || id;

    if (logo?.file) {
      logoUploadData.append("file", logo?.file, logo?.file?.name);
      await uploadAmbassadorPhotoUpload(idx, logoUploadData);
    }

    history.push(ROUTE_PATHS.AMBASSADORS.LIST);
  };

  const onSubmit = async (e) => {
    setBlockNavigation(false);

    const { errors, isError } = validateForm(
      formFields,
      initialErrors,
      AMBASSADOR_FORM_ERROR_MAAPING
    );

    if (!isError) {
      const payload = {
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        email: formFields.email,
        linkedIn: formFields.linkedIn,
        picture: formFields.picture,
        city: formFields.city,
        state: formFields.state,
        title: formFields.title,
        industry: formFields.industry,
        company: formFields.company,
        timezone: formFields.timezone,
        investment_budget: formFields?.investment_budget || "0",
        no_of_emp: noOfEmp?.data?.find((item) =>
          formFields?.no_of_emp?.includes(item.label)
        )?._id,
        experience: formFields?.experience
          ? Number(formFields?.experience)
          : null,
        round_table_package_12_rate: formFields?.round_table_package_12_rate
          ? Number(formFields?.round_table_package_12_rate)
          : null,
        previous_company: formFields?.previous_company
          ? formFields?.previous_company?.join(",")
          : "",
        region: formFields.region?.value,
        topics: [...priorities],
      };

      const response: any = id
        ? await updateAmbassador(id, payload)
        : await addAmbassador(payload);

      if (response.success) {
        submitPhotoUpload(response?.data?.id);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const onCancel = () => {
    if (initialFieldData !== formFields) {
      setShowCancelConfirm(true);
    } else {
      setShowCancelConfirm(false);
      history.push(ROUTE_PATHS.AMBASSADORS.LIST);
    }
  };

  const onCancelConfirm = () => {
    history.push(ROUTE_PATHS.AMBASSADORS.LIST);
  };

  const onRouteChange = (nextLocation) => {
    if (
      nextLocation.pathname === ROUTE_PATHS.AMBASSADORS.LIST &&
      !blockNavigation
    ) {
      return true;
    }

    setShowCancelConfirm(true);
    return false;
  };

  const getList = (field) => {
    switch (field) {
      case "title":
        return title?.data || [];
      case "industry":
        return industry?.data || [];
      case "no_of_emp":
        return noOfEmp?.data || [];
      case "state":
        return state?.data || [];
      default:
        return [];
    }
  };

  const handlePriorityChange = (p) => {
    setPriorities(p);
  };

  function renderInput(item) {
    switch (item.inputType) {
      case ENUM_FORM_INPUT_TYPES.CHIP_TEXT_FIELD:
        return (
          <ChipTextField
            label={item.label}
            id={item.field}
            field={item.field}
            onChange={handleOnChange}
            error={formErrors[item.field]}
            value={formFields[item.field]}
            inputFieldClass={classes.textinput}
            limitTags={1}
            inlineLabel
            textfieldStyle={{ height: 27 }}
          />
        );
      case ENUM_FORM_INPUT_TYPES.AUTO_SELECTBOX:
        switch (item.field) {
          case "timezone":
            return (
              <AutoSelectbox
                label={item.label}
                field={item.field}
                id={item.field}
                inlineLabel={true}
                inputFieldClass={classes.textinput}
                textfieldStyle={{ height: 15 }}
                list={timezones?.data || []}
                onChange={handleOnChange}
                value={formFields[item.field]}
                error={formErrors[item.field]}
                listProperties={{
                  field: "text",
                  value: "value",
                }}
                listType={ENUM_AUTOSELECT_LIST_TYPES.ARRAY_OF_OBJECT}
                requireOnChange={item.requireOnChange}
              />
            );
          case "region":
            return (
              <AutoSelectbox
                label={item.label}
                field={item.field}
                id={item.field}
                inlineLabel={true}
                inputFieldClass={classes.textinput}
                textfieldStyle={{ height: 15 }}
                list={region?.data || []}
                onChange={handleOnChange}
                value={formFields[item.field]}
                error={formErrors[item.field]}
                listType={ENUM_AUTOSELECT_LIST_TYPES.ARRAY_OF_OBJECT}
                requireOnChange={item.requireOnChange}
                listProperties={{
                  field: "value",
                  value: "value",
                }}
              />
            );
          default:
            return (
              <AutoSelectbox
                label={item.label}
                field={item.field}
                id={item.field}
                inlineLabel={true}
                inputFieldClass={classes.textinput}
                textfieldStyle={{ height: 15 }}
                list={getList(item.field)}
                onChange={handleOnChange}
                listProperties={{
                  field: "label",
                  value: "label",
                }}
                value={formFields[item.field]}
                error={formErrors[item.field]}
                requireOnChange={item.requireOnChange}
              />
            );
        }
      default:
        return (
          <AppTextField
            id={item.field}
            name={item.field}
            label={item.label}
            inlineLabel
            onChange={handleOnChange}
            value={formFields[item.field]}
            error={formErrors[item.field]}
            autoComplete={"off"}
            inputFieldClass={classes.textinput}
          />
        );
    }
  }

  return (
    <Container maxWidth="lg">
      {id && <Prompt when={blockNavigation} message={onRouteChange} />}

      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems={"center"}
        >
          <Paper className={classes.paper}>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{ marginTop: 5 }}
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <PhotoFileUpload
                  preview={logo.preview}
                  onUpload={handleAvatarUpload}
                  aspect={1}
                  cropShape="round"
                  filename="company-logo"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize={15} fontWeight={"500"} color="#000000">
                  Personal Details
                </Typography>
              </Grid>
              {PERSONAL_DETAILS.map((item, index) => (
                <Grid item xs={4} key={index}>
                  {renderInput(item)}
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{ marginTop: 5 }}
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography fontSize={15} fontWeight={"500"} color="#000000">
                  Company Details
                </Typography>
              </Grid>
              {COMPANY_DETAILS.map((item, index) => (
                <Grid item xs={4} key={index}>
                  {renderInput(item)}
                </Grid>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              style={{ marginTop: 5 }}
              container
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography fontSize={15} fontWeight={"500"} color="#000000">
                  Rate Details
                </Typography>
              </Grid>
              {ROUND_TABLE_DETAILS.map((item, index) => (
                <Grid item xs={4} key={index}>
                  {renderInput(item)}
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <AmbassadorIntrestedIn
                data={selectedAmbassador}
                onPriorityChange={handlePriorityChange}
              />
            </Grid>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 30,
              }}
            >
              <SaveAndCancelButton
                primaryText={id ? "Update" : "Save"}
                defaultText={"Cancel"}
                onPrimaryActionClick={onSubmit}
                onDefaultButtonClick={onCancel}
              />
            </Box>
          </Paper>
        </Grid>
      </Box>

      <AppAlertBox
        isModalOpen={showCancelConfirm}
        onConfirm={onCancelConfirm}
        onCancel={() => setShowCancelConfirm(false)}
        message={GLOBAL_TEXT.ALERT_MESSAGES.CONFIRM_CANCEL}
      />
    </Container>
  );
}

export { AddOrUpdateAmbassador };
