import { RENDERER_TYPES } from "../../../enums";

const INVITATION_LIST_COLUMNS = [
  {
    title: "Ambassador",
    field: "ambassador",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_NAME,
    width: "20%"
  },
  {
    title: "Client",
    field: "client",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_NAME,
    width: "20%"
  },
  {
    title: "Timeslot",
    field: "selectedTimeSlot",
    dataType: "string",
    rendererType: RENDERER_TYPES.DATE_RANGE,
    width: "20%"
  },
  {
    title: "Status",
    field: "status",
    dataType: "string",
    rendererType: RENDERER_TYPES.MEETING_STATUS,
    width: "10%"
  },
  { title: "Actions", rendererType: RENDERER_TYPES.ACTION, align: "center", width: "17%" },
];

export { INVITATION_LIST_COLUMNS };
