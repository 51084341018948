import store from "../redux";
import { reportServerError, setUILoading } from "../redux/modules/UI/actions";
import axios from "axios";
import { getResErrorMsg, showNotification } from "./utils";
import { API_TIMEOUT } from "../constants";

const { REACT_APP_API_URL } = process.env;

const defaultOptions: any = {
  validateUser: true,
};

function getHeaders(options) {
  return {
    headers: {
      ...(options?.validateUser && { Authorization: localStorage.token }),
    },
  };
}

function handleServerError(err, options) {
  let { message, responseStatus }: any = getResErrorMsg(err);

  !options.externalUrl && showNotification({
    message,
    type: "error",
  });

  if (responseStatus !== 422 && !options.externalUrl) {
    store.dispatch(
      reportServerError({
        error: true,
        message,
        responseStatus,
      })
    );
  }

  return {
    success: false,
    message,
  };
}

function handleSuccessResponse(res) {
  if (res.data.message) {
    showNotification({
      message: res.data.message,
      type: "success",
    });
  }

  return res.data;
}

export function getApi(url, options = defaultOptions) {
  store.dispatch(setUILoading(true));
  options.loadingFn && store.dispatch(options.loadingFn(true));

  const endpoint = options.externalUrl ? url : REACT_APP_API_URL + url;

  return axios
    .get(endpoint, {
      timeout: API_TIMEOUT,
      ...getHeaders(options),
    })
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleServerError(err, options))
    .finally(() => {
      store.dispatch(setUILoading(false));
      options.loadingFn && store.dispatch(options.loadingFn(false));
    });
}

export function postApi(url, payload, options = defaultOptions) {
  store.dispatch(setUILoading(true));
  options.loadingFn && store.dispatch(options.loadingFn(true));

  const endpoint = options.externalUrl ? url : REACT_APP_API_URL + url;

  return axios
    .post(endpoint, payload, {
      timeout: API_TIMEOUT,
      ...getHeaders(options),
    })
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleServerError(err, options))
    .finally(() => {
      store.dispatch(setUILoading(false));
      options.loadingFn && store.dispatch(options.loadingFn(false));
    });
}

export function putApi(url, payload, options = defaultOptions) {
  store.dispatch(setUILoading(true));
  options.loadingFn && store.dispatch(options.loadingFn(true));

  return axios
    .put(REACT_APP_API_URL + url, payload, {
      timeout: API_TIMEOUT,
      ...getHeaders(options),
    })
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleServerError(err, options))
    .finally(() => {
      store.dispatch(setUILoading(false));
      options.loadingFn && store.dispatch(options.loadingFn(false));
    });
}

export function patchtApi(url, payload, options = defaultOptions) {
  store.dispatch(setUILoading(true));
  options.loadingFn && store.dispatch(options.loadingFn(true));

  return axios
    .patch(REACT_APP_API_URL + url, payload, {
      timeout: API_TIMEOUT,
      ...getHeaders(options),
    })
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleServerError(err, options))
    .finally(() => {
      store.dispatch(setUILoading(false));
      options.loadingFn && store.dispatch(options.loadingFn(false));
    });
}

export function deleteApi(url, payload, options = defaultOptions) {
  store.dispatch(setUILoading(true));
  options.loadingFn && store.dispatch(options.loadingFn(true));

  return axios
    .delete(REACT_APP_API_URL + url, {
      timeout: API_TIMEOUT,
      ...getHeaders(options),
      data: payload,
    })
    .then((res) => handleSuccessResponse(res))
    .catch((err) => handleServerError(err, options))
    .finally(() => {
      store.dispatch(setUILoading(false));
      options.loadingFn && store.dispatch(options.loadingFn(false));
    });
}
