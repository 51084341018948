import { RENDERER_TYPES } from "../../../../enums";

const CLIENT_PAYMENTS_COLUMNS = [
  { field: "expandable" },
  {
    title: "Ambassador",
    field: "ambassador",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_NAME_WITH_AVATAR,
  },
  {
    title: "Title",
    field: "title",
  },
  {
    title: "Company",
    field: "company",
  },
  {
    title: "Amount",
    field: "total",
    dataType: "string",
    rendererType: RENDERER_TYPES.AMOUNT,
  },
];

const AMBASSADOR_WISE_PAYMENTS_COLUMNS = [
  {
    title: "Date",
    field: "createdAt",
    dataType: "string",
    rendererType: RENDERER_TYPES.FULL_DATE,
  },
  {
    title: "Amount",
    field: "amount",
    dataType: "string",
    rendererType: RENDERER_TYPES.AMOUNT,
  },
  {
    title: "Status",
    field: "status",
    dataType: "string",
    rendererType: RENDERER_TYPES.PAYMENT_STATUS,
  },
];

const CLIENT_PAYMENTS_ROWS = [
  {
    firstName: "Kevin",
    lastName: "Foster",
    title: "VP of IT Governance, Risk and Compliance",
    company: "IBM",
    amount: 50000,
    payments: [
      {
        date: "12/02/2022",
        amount: 20000,
        status: "SUCCESS",
      },
      {
        date: "14/01/2022",
        amount: 20000,
        status: "SUCCESS",
      },
      {
        date: "01/12/2022",
        amount: 10000,
        status: "SUCCESS",
      },
    ],
  },
  {
    firstName: "Kevin",
    lastName: "Foster",
    title: "VP of IT Governance, Risk and Compliance",
    company: "IBM",
    amount: 50000,
    payments: [
      {
        date: "12/02/2022",
        amount: 20000,
        status: "SUCCESS",
      },
      {
        date: "14/01/2022",
        amount: 20000,
        status: "SUCCESS",
      },
      {
        date: "01/12/2022",
        amount: 10000,
        status: "SUCCESS",
      },
    ],
  },
  {
    firstName: "Kevin",
    lastName: "Foster",
    title: "VP of IT Governance, Risk and Compliance",
    company: "IBM",
    amount: 50000,
    payments: [
      {
        date: "12/02/2022",
        amount: 20000,
        status: "SUCCESS",
      },
      {
        date: "14/01/2022",
        amount: 20000,
        status: "SUCCESS",
      },
      {
        date: "01/12/2022",
        amount: 10000,
        status: "SUCCESS",
      },
    ],
  },
];

export {
  CLIENT_PAYMENTS_COLUMNS,
  AMBASSADOR_WISE_PAYMENTS_COLUMNS,
  CLIENT_PAYMENTS_ROWS,
};
