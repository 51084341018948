import { connect } from "react-redux";
import React from "react";
import { withRouter } from "react-router";
import {
  deleteAmbassador,
  getAmbassadorDetails,
  getAmbassadorMeetings,
  getAmbassadorPayments,
  getAmbassadors,
  getAmbassadorsSimpleList,
} from "../../apis/ambassadors.api";
import { AppAlertBox } from "../../components/modals/alert-box";
import { DEFAULT_SELECTED_PAGE_SIZE, GLOBAL_TEXT } from "../../constants";
import { convertToUrl } from "../../services/utils";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { resetAmbassadorStore } from "../../redux/modules/ambassadors/actions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { animateScroll as scroll } from "react-scroll";
import { ROUTE_PATHS } from "../../routes/route-paths";

const ambassadorHOC = (ComposedComponent) => {
  class AmbassadorHOC extends React.Component<any, any> {
    constructor(props) {
      super(props);

      this.state = {
        showDeleteConfirm: false,
        selectedId: null,
        anchorEl: null,
      };
    }

    componentWillUnmount(): void {
      if (
        !this.props.history.location.pathname.startsWith(
          ROUTE_PATHS.AMBASSADORS.ROOT
        )
      ) {
        this.props.dispatchResetAmbassadorStore();
      }
    }

    onAddAmbassadorClick = () => {
      this.props.history.push(ROUTE_PATHS.AMBASSADORS.ADD);
    };

    onGetAmbassadorDetails = (id) => {
      this.props.dispatchGetAmbassadorDetails(id);
    };

    getAmbassadorsList = ({
      selectedPage = 1,
      selectedPageSize = DEFAULT_SELECTED_PAGE_SIZE,
      searchValue = "",
      selectedFilters = {}
    } = {}) => {
      this.props.dispatchGetAmbassadorList({
        selectedPage,
        selectedPageSize,
        searchValue,
        selectedFilters
      });

      scroll.scrollToTop();
    };

    getAmbassadorSimpleList = ({
      selectedPage = 1,
      selectedPageSize = DEFAULT_SELECTED_PAGE_SIZE,
      searchValue = "",
      selectedFilters = {},
    } = {}) => {
      this.props.dispatchGetAmbassadorSimpleList({
        selectedPage,
        selectedPageSize,
        searchValue,
        selectedFilters,
      });

      scroll.scrollToTop();
    };

    getAmbassadorMeetingsList = ({
      selectedPage = 1,
      selectedPageSize = DEFAULT_SELECTED_PAGE_SIZE,
      ambassadorId = this.props.selectedAmbassador.id,
    } = {}) => {
      this.props.dispatchGetAmbassadorMeetingsList({
        selectedPage,
        selectedPageSize,
        ambassadorId,
      });
    };

    getAmbassadorPaymentsList = ({
      selectedPage = 1,
      selectedPageSize = DEFAULT_SELECTED_PAGE_SIZE,
      ambassadorId = this.props.selectedAmbassador.id,
    } = {}) => {
      this.props.dispatchGetAmbassadorPaymentsList({
        selectedPage,
        selectedPageSize,
        ambassadorId,
      });
    };

    onEditClick = (id) => {
      const path = convertToUrl(ROUTE_PATHS.AMBASSADORS.EDIT, {
        ":id": id,
      });

      this.props.history.push(path);
    };

    onDeleteClick = (id) => {
      this.setState({
        ...this.state,
        showDeleteConfirm: true,
        selectedId: id,
        anchorEl: null,
      });
    };

    onViewlick = (id) => {
      const path = convertToUrl(ROUTE_PATHS.AMBASSADORS.DETAILS, {
        ":id": id,
        ":tab": "info",
      });

      this.props.history.push(path);
    };

    onDeleteConfirm = async () => {
      const response = await deleteAmbassador(this.state.selectedId);

      this.setState(
        {
          ...this.state,
          selectedId: null,
          showDeleteConfirm: false,
        },
        () => {
          this.props.history.push(ROUTE_PATHS.AMBASSADORS.LIST);
        }
      );
    };

    toggleMenuOptions = (
      event: React.MouseEvent<HTMLButtonElement>,
      id: any
    ) => {
      this.setState({
        ...this.state,
        anchorEl: this.state.anchorEl ? null : event.currentTarget,
        selectedId: id,
      });
    };

    render() {
      const open = Boolean(this.state.anchorEl);
      const popoverId = open ? "ambassador-list-action-items" : undefined;

      return (
        <>
          <ComposedComponent
            onViewClick={this.onViewlick}
            onDeleteClick={this.onDeleteClick}
            onEditClick={this.onEditClick}
            onAddAmbassadorClick={this.onAddAmbassadorClick}
            toggleMenuOptions={this.toggleMenuOptions}
            getAmbassadorList={this.getAmbassadorsList}
            getAmbassadorSimpleList={this.getAmbassadorSimpleList}
            getAmbassadorMeetingsList={this.getAmbassadorMeetingsList}
            getAmbassadorPaymentsList={this.getAmbassadorPaymentsList}
            getAmbassadorDetails={this.onGetAmbassadorDetails}
            ambassadorList={this.props.list}
            ambassadorMeetingsList={this.props.ambassadorMeetingsList}
            ambassadorPaymentsList={this.props.ambassadorPaymentsList}
            ambassadorDetails={this.props.selectedAmbassador}
            {...this.props}
          />

          <Menu
            autoFocus={false}
            id={popoverId}
            open={open}
            anchorEl={this.state.anchorEl}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={this.toggleMenuOptions}
          >
            <MenuItem onClick={() => this.onViewlick(this.state.selectedId)}>
              <ListItemIcon>
                <VisibilityIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>View</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => this.onEditClick(this.state.selectedId)}>
              <ListItemIcon>
                <BorderColorIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => this.onDeleteClick(this.state.selectedId)}>
              <ListItemIcon>
                <DeleteOutlineIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </Menu>

          <AppAlertBox
            isModalOpen={this.state.showDeleteConfirm}
            onConfirm={this.onDeleteConfirm}
            onCancel={() =>
              this.setState({
                ...this.state,
                selectedId: null,
                showDeleteConfirm: false,
              })
            }
            message={GLOBAL_TEXT.ALERT_MESSAGES.CONFIRM_DELETE}
          />
        </>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      list: state.ambassadors.list,
      selectedAmbassador: state.ambassadors.selectedAmbassador,
      ambassadorMeetingsList: state.ambassadors.info.meetings,
      ambassadorPaymentsList: state.ambassadors.info.payments,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      dispatchGetAmbassadorList: (payload) => dispatch(getAmbassadors(payload)),
      dispatchGetAmbassadorSimpleList: (payload) => dispatch(getAmbassadorsSimpleList(payload)),
      dispatchGetAmbassadorMeetingsList: (payload) =>
        dispatch(getAmbassadorMeetings(payload)),
      dispatchGetAmbassadorPaymentsList: (payload) =>
        dispatch(getAmbassadorPayments(payload)),
      dispatchDeleteAmbassador: (id) => dispatch(deleteAmbassador(id)),
      dispatchGetAmbassadorDetails: (id) => dispatch(getAmbassadorDetails(id)),
      dispatchResetAmbassadorStore: () => dispatch(resetAmbassadorStore()),
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(AmbassadorHOC));
};

export default ambassadorHOC;
