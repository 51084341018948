import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Backdrop from "@material-ui/core/Backdrop";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#1a90ff",
      backgroundColor: "transparent",
    },
  })
);

function AppSpinner() {
  const classes = useStyles();
  const { isLoading } = useSelector((state: any) => state.UI);

  return (
    isLoading && (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  );
}

export { AppSpinner };
