import { Grid } from "@material-ui/core";
import { useState } from "react";
import { AppTextField } from "../../../../components/textfields";
import {
  INVITATION_ACCEPT_FORM_FIELDS,
  INVITATION_ACCEPT_FORM_ERROR_MAAPING,
} from "./form-data";
import { useStyles } from "./styles";
import { SaveAndCancelButton } from "../../../../components/buttons";
import { validateForm } from "../../../../services/validators";
import { Box } from "@mui/material";
import { EmailAddressBox } from "../../../components/email-addressbox";

const initialFields: any = {
  zoomRecodingUrl: "",
  ambassadorFeedbackUrl: "",
  clientFeedbackUrl: "",
};

const initialErrors = {
  ...initialFields,
};

function InvitationAcceptModal({ onAcceptSubmit, onAcceptCancel }) {
  const classes = useStyles();
  const [formFields, setFormField] = useState(initialFields);
  const [formErrors, setFormErrors] = useState(initialErrors);
  const [emailIds, setEmailds]: any = useState({
    list: [],
    invalidEmailIds: [],
  });

  const handleOnEmailAddressesChange = (payload) => {
    setEmailds(payload);
  };

  const handleOnChange = (e) => {
    setFormField({
      ...formFields,
      [e.target.name]: e.target.value,
    });

    setFormErrors({
      ...formErrors,
      [e.target.name]: "",
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const { errors, isError } = validateForm(
      formFields,
      initialErrors,
      INVITATION_ACCEPT_FORM_ERROR_MAAPING
    );

    if (!isError && !emailIds.invalidEmailIds.length) {
      onAcceptSubmit({
        ...formFields,
        additionalMembers: emailIds.list.map((email) => {
          return { email };
        })
      });
    } else {
      setFormErrors(errors);
    }
  };

  function renderInput(item) {
    switch (item.inputType) {
      default:
        return (
          <AppTextField
            id={item.field}
            name={item.field}
            label={item.label}
            inlineLabel
            onChange={handleOnChange}
            value={formFields[item.field]}
            error={formErrors[item.field]}
            autoComplete={"off"}
            inputFieldClass={classes.textinput}
          />
        );
    }
  }

  return (
    <Grid container direction="row" style={{ marginBottom: 10 }}>
      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        container
        spacing={2}
        style={{ marginBottom: 35 }}
      >
        {INVITATION_ACCEPT_FORM_FIELDS.map((item, index) => (
          <Grid item md={12} sm={12} xs={12} key={index}>
            {renderInput(item)}
          </Grid>
        ))}
        <Grid item md={12}>
          <EmailAddressBox inlineLabel onValueChange={handleOnEmailAddressesChange} />
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <SaveAndCancelButton
            primaryText={"Submit"}
            defaultText={"Cancel"}
            onPrimaryActionClick={onSubmit}
            onDefaultButtonClick={onAcceptCancel}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export { InvitationAcceptModal };
