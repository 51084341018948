import { Grid, Box } from "@mui/material";
import { animateScroll as scroll } from "react-scroll";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { ITableColumn } from "../../../components/mui/table/models";
import { SimpleTable } from "../../../components/mui/table";
import AmbassadorFilterOptions from "./filter-options";
import ambassadorHOC from "../ambassador-hoc";

const AmbassadorColumns: ITableColumn[] = [
  {
    title: "Title",
    field: "title",
  },
  // {
  //   title: "Est. Employees",
  //   field: "no_of_emp",
  // },
  {
    title: "Industry",
    field: "industry",
  },
];

function AmbassadorSimpleList({ getAmbassadorSimpleList, ambassadorList }) {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const { selectedPageSize, searchValue, selectedPage, selectedFilters } =
    useSelector((state: any) => state.ambassadors.list.filters);

  useEffect(() => {
    getAmbassadorSimpleList({
      selectedPage,
      selectedPageSize,
      searchValue,
      selectedFilters,
    });

    scrollToTop();
  }, []);

  const handlePageFilters = ({ selectedPage, selectedPageSize }) => {
    getAmbassadorSimpleList({
      selectedPage,
      selectedPageSize,
      searchValue,
      selectedFilters,
    });
  };

  return (
    <Box m={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AmbassadorFilterOptions simpleList isEstEmployee={false} />
        </Grid>
        <Grid item xs={12}>
          <SimpleTable
            rows={ambassadorList?.data}
            fetching={ambassadorList?.loading}
            columns={AmbassadorColumns}
            total={ambassadorList?.total}
            onPageSettingsChange={handlePageFilters}
            selectedPage={selectedPage}
            selectedPageSize={selectedPageSize}
          ></SimpleTable>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ambassadorHOC(AmbassadorSimpleList);
