import { Grid, Container, Backdrop, CircularProgress } from "@mui/material";
import { ButtonWithIcon } from "../../components/buttons";
import { DashboardMeetingCalendar } from "./meeting-calendar";
import { DashboardSummary } from "./summary-blocks";
import { useHistory } from "react-router";
import { ROUTE_PATHS } from "../../routes/route-paths";
import {
  getDashboardEvents,
  getDashboardSummaryData,
} from "../../apis/dashboard.api";
import { useDispatch } from "react-redux";
import {
  DATE_FORMATS,
  formatDate,
  get2ndDateOfMonth,
} from "../../services/date-and-time.utils";
import { useState } from "react";
import {
  createDashboardEvents,
  createDashboardSummary,
} from "../../services/utils";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#1a90ff",
      backgroundColor: "transparent",
    },
  })
);

function AdminDashboard() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [events, setEvents]: any = useState([]);
  const [summaryData, setSummaryData]: any = useState([]);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const handleOnStartAndEndDateChange = async (arg) => {
    let events = [];
    const currentStartDate = arg.view.getCurrentData().viewApi.currentStart;
    const dateRange = {
      startDate: formatDate(arg.start, DATE_FORMATS.yyymmdd),
      endDate: formatDate(arg.end, DATE_FORMATS.yyymmdd),
    };
    const currentDate = {
      date: formatDate(
        get2ndDateOfMonth(currentStartDate),
        DATE_FORMATS.yyymmdd
      ),
    };

    setIsLoading(true);

    const res: any = await Promise.allSettled([
      dispatch(getDashboardSummaryData(currentDate)),
      dispatch(getDashboardEvents(dateRange)),
    ]);

    const summaryData = createDashboardSummary(res[0]);
    events = createDashboardEvents(res[1]);

    setSummaryData(summaryData);
    setEvents(events);

    setIsLoading(false);
  };

  return (
    <Grid container spacing={2}>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid item xs={12}>
        <DashboardSummary summaryData={summaryData} />
      </Grid>
      <Grid item xs={12}>
        <Container>
          <Grid
            item
            justifyContent={"flex-end"}
            display="flex"
            sx={{ mt: 5, mb: 1 }}
          >
            <ButtonWithIcon
              handleOnClick={() =>
                history.push(ROUTE_PATHS.INVITATIONS.NEW_INVITATION)
              }
              text={"ADD"}
              icon={{ position: "startIcon", name: "add" }}
            />
          </Grid>
          <Grid item xs={12}>
            <DashboardMeetingCalendar
              events={events}
              onStartAndEndDateChange={handleOnStartAndEndDateChange}
            />
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export { AdminDashboard };
