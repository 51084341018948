import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useContext } from "react";
import { PaginationContext } from "../table/simple-table";
import { PAGE_SIZES } from "../../../constants";

export default function PageSize() {
  const { onPageSizeChange, selectedPageSize }: any =
    useContext(PaginationContext);

  return (
    <FormControl sx={{ m: 1, minWidth: 65 }} size="small">
      <Select
        displayEmpty
        labelId="demo-select-small"
        id="demo-select-small"
        onChange={(e) => onPageSizeChange(e.target.value)}
        value={selectedPageSize}
        inputProps={{ "aria-label": "Without label" }}
        style={{
          height: 31,
          background: "rgba(147, 162, 221, 0.12)",
          borderRadius: "4px",
        }}
      >
        {PAGE_SIZES.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
