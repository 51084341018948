import { Box, Typography } from "@mui/material";
import { compose } from "redux";
import ambassadorHOC from "../../../ambassadors/ambassador-hoc";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClientAmbassadorMasterList from "./ambassador-list";
import ClientCriteriaAmbassador from "./client-criteria-ambassador";

function ClientAmbassadors() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            background: "#070639",
            color: "white",
          }}
        >
          <Typography>Ambassador Based on criteria</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ClientCriteriaAmbassador />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            background: "#070639",
            color: "white",
          }}
        >
          <Typography>Select Ambassadors</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ClientAmbassadorMasterList />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default compose(ambassadorHOC)(ClientAmbassadors);
