import {
  makeStyles,
  withStyles,
  Dialog,
  IconButton,
  Typography,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { AppText } from "../typography";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
  title: {
    fontSize: 16,
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <AppText textClass={classes.title}>{children}</AppText>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function AppModalDialogBox({
  title,
  actionButton,
  actionButtonRenderer,
  titleRenderer,
  dialogContainerClass,
  children,
  isModalOpen,
  handleClose,
  size = "sm",
  handleScroll,
  maxHeight,
}: any) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
      id="custom-modal-dialog"
      fullWidth
      maxWidth={size}
      style={{
        zIndex: 1000,
        ...(maxHeight && { maxHeight }),
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent onScroll={handleScroll} dividers>
        {children}
      </DialogContent>
      {actionButtonRenderer && (
        <DialogActions>{actionButtonRenderer}</DialogActions>
      )}
    </Dialog>
  );
}

export { AppModalDialogBox };
