import { Box, Card, Icon, Divider, Typography } from "@mui/material";
import { ENUM_CURRENCY, RENDERER_TYPES } from "../../../enums";
import {
  getPercentageIncreaseOrDescrease,
  numberFormat,
} from "../../../services/utils";

function SummaryItem({ item }) {
  const handleRenderer = () => {
    switch (item.rendererType) {
      case RENDERER_TYPES.AMOUNT:
        return numberFormat(item.currentMonth, ENUM_CURRENCY.USD);

      default:
        return numberFormat(item.currentMonth);
    }
  };

  return (
    <Card
      component={"fieldset"}
      elevation={5}
      style={{ border: 0, height: 134, width: 258 }}
    >
      <legend>
        <Box
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          sx={{
            background: "#070639",
            height: "50px",
            width: "50px",
            borderRadius: "10px",
          }}
        >
          <Icon sx={{ color: "white" }}>{item.icon}</Icon>
        </Box>
      </legend>

      <Box sx={{ mt: -1, mb: 1 }} textAlign="right">
        <Typography sx={{ fontSize: 12, fontWeight: 500, color: "#000000" }}>
          {item.label}
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 500,
            color: item?.currentMonth > item?.lastCount ? "#41C197" : "#F65C8D",
          }}
        >
          {handleRenderer()}
        </Typography>
      </Box>

      <Box component={"div"}>
        <Divider sx={{ border: "1px solid #979797" }} />
        <Box display={"flex"}>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 500,
              color:
                item?.currentMonth > item?.lastCount ? "#41C197" : "#F65C8D",
              pt: 1,
              mr: 0.5,
            }}
          >
            {`${getPercentageIncreaseOrDescrease(
              item?.currentMonth,
              item?.lastCount
            )}%`}
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 500,
              pt: 1,
              color: "#000000",
            }}
          >
            than last Month
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export { SummaryItem };
