import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  textinput: {
    height: "48px",
    maxWidth: "310px",
  },
}));
