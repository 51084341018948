import { AppModalDialogBox } from "../../../components/modals";

export default function ViewTemplateModal({ data, onClose }) {
  return (
    <AppModalDialogBox
      isModalOpen={true}
      title="Template"
      handleClose={onClose}
      maxHeight={600}
      size={"md"}
    >
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </AppModalDialogBox>
  );
}
